import {
  GET_DPO_REQUEST,
  DPO_REQUEST_SUCCESS,
  DPO_REQUEST_SUCCESS_MESSAGE,
  HIDE_DPO_REQUEST_ERROR_MESSAGE,
  HIDE_DPO_REQUEST_SUCCESS_MESSAGE,
  DPO_REQUEST_LOADER,
  DPO_REQUEST_ERROR_MESSAGE,
  DPO_REQUEST_RESET,
} from "constants/ActionTypes";

export const getDpoRequestData = (data) => {
  return {
    type: GET_DPO_REQUEST,
    payload: data
  };
};

export const requestDpoSuccess = (data) => {
  return {
    type: DPO_REQUEST_SUCCESS,
    payload: data
  };
};

export const requestDpoSuccessMessage = (data) => {
  return {
    type: DPO_REQUEST_SUCCESS_MESSAGE,
    payload: data
  }
}

export const showRequestDpoError = (data) => {
  return {
    type: DPO_REQUEST_ERROR_MESSAGE,
    payload: data
  };
};

export const hideRequestDpoMessage = () => {
  return {
    type: HIDE_DPO_REQUEST_ERROR_MESSAGE,
  };
};

export const hideRequestDpoSuccessMessage = () => {
  return {
    type: HIDE_DPO_REQUEST_SUCCESS_MESSAGE,
  };
};

export const requestDpoLoader = () => {
  return {
    type: DPO_REQUEST_LOADER,
  };
};

export const requestDpoReset = () => {
  return {
    type: DPO_REQUEST_RESET,
  };
};