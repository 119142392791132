/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, CardBody, CardHeader, p } from "reactstrap";
import Button from "@material-ui/core/Button";
// import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import ContainerHeader from "components/ContainerHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Widget from "../../../../../components/Widget";

export const LoreMasterPlanEnhancements = ({ productId, productName }) => {
  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile custom-kyp-fonts">
        <div className="card-header">
          <h4 className="card-title mb-0 text-uppercase">{productName}</h4>
        </div>
        <div className="jr-tabs-classic">
          <div className="jr-tabs-content jr-task-list pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <h3 className="mb-2">Product Code</h3>
                  <p>LORE-1</p>
                  <h3 className="mb-2">Product Summary</h3>
                  <p>
                    Lore is a unique funeral cover product with a high payout
                    that continues to take care of your family after your
                    passing. Here are some of the great benefits to you:
                    <ol>
                      <li>
                        Maximum Lump sum benefit of up to P100,000 for natural
                        death or double lump sum benefit on accidental death of
                        policy holder and Spouse.
                      </li>
                      <li>
                        Pays a maximum monthly payment of up to 5,000 for 12
                        months after the death of the policy holder.
                      </li>
                      <li>
                        Policy holder stops payment of premiums after the age of
                        65; Botswana Life begins to pay the premiums on his/her
                        behalf.
                      </li>
                      <li>
                        Six (6) months’ premiums worth of cash back every four
                        (4) years.
                      </li>
                      <li>Policyholder is covered for their entire life.</li>
                      <li>There are no medical exams required.</li>
                      <li>
                        There is option to cover nuclear family (spouse and up
                        to 6 children), up to 4 parents and parents in-law and
                        up to 10 extended family members.
                      </li>
                    </ol>
                  </p>
                </div>
                <div>
                  <h3 className="mb-2">Age Limits</h3>
                  <div className="table-responsive-material custom-responsive-border">
                    <Table className="custom-page-table table-bordered">
                      <TableHead>
                        <TableRow>
                          <TableCell>Category of member </TableCell>
                          <TableCell>Min entry age next birthday </TableCell>
                          <TableCell>Max entry age next birthday </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{}}>Life Assured</TableCell>
                          <TableCell align="left">18 </TableCell>
                          <TableCell align="left">55 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>Spouse</TableCell>
                          <TableCell align="left">18 </TableCell>
                          <TableCell align="left">55 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>Children</TableCell>
                          <TableCell align="left">0 </TableCell>
                          <TableCell align="left">20 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>Exended Family</TableCell>
                          <TableCell align="left">0 </TableCell>
                          <TableCell align="left">85 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>
                            Parents/Parents-in-law
                          </TableCell>
                          <TableCell align="left">22 </TableCell>
                          <TableCell align="left">85 </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
                <div>
                  <h3 className="mb-2">Sum Assured</h3>
                  <div className="table-responsive-material custom-responsive-border">
                    <Table className="custom-page-table table-bordered">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="center">Option 1</TableCell>
                          <TableCell align="center">Option 2</TableCell>
                          <TableCell align="center">Option 3</TableCell>
                          <TableCell align="center">Option 4</TableCell>
                          <TableCell align="center">Option 5</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell rowspan="2" style={{ maxWidth: 200 }}>
                            Member:lump sum:income(12 months)
                          </TableCell>
                          <TableCell>20000</TableCell>
                          <TableCell>30000</TableCell>
                          <TableCell>40000 </TableCell>
                          <TableCell>50000 </TableCell>
                          <TableCell>100000 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>1250</TableCell>
                          <TableCell>1500</TableCell>
                          <TableCell>1750 </TableCell>
                          <TableCell>4000 </TableCell>
                          <TableCell>5000 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Spouse</TableCell>
                          <TableCell>20000</TableCell>
                          <TableCell>30000</TableCell>
                          <TableCell>40000 </TableCell>
                          <TableCell>50000 </TableCell>
                          <TableCell>100000 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ maxWidth: 200 }}>
                            Children (16-21 years)
                          </TableCell>
                          <TableCell>10000</TableCell>
                          <TableCell>15000</TableCell>
                          <TableCell>20000 </TableCell>
                          <TableCell>20000 </TableCell>
                          <TableCell>45000 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ maxWidth: 200 }}>
                            Children (0-16 years, excl still born)
                          </TableCell>
                          <TableCell>5000</TableCell>
                          <TableCell>10000</TableCell>
                          <TableCell>15000 </TableCell>
                          <TableCell>15000 </TableCell>
                          <TableCell>30000 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ maxWidth: 200 }}>
                            Optional extended family/Parents
                          </TableCell>
                          <TableCell>5000</TableCell>
                          <TableCell>7500</TableCell>
                          <TableCell>10000 </TableCell>
                          <TableCell>12500 </TableCell>
                          <TableCell>25000 </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <p>
                      Still born: P 2,500 the pregnancy should be at least 28
                      weeks
                    </p>
                  </div>
                  <h3 className="mb-2">Premium Limits</h3>
                  <p> N/A</p>
                </div>
                <div>
                  <h3 className="mb-2">Policy Term</h3>
                  <ul>
                    <li> Whole of life cover</li>
                  </ul>
                </div>
                <div>
                  <Button
                    className="custom-btn-ach"
                    component={Link}
                    to="/home/know-your-products"
                    variant="contained"
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </>
  );
};
