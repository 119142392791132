import { httpGet } from "util/REST";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CLAIM_STATUS_DATA,
  RESET_CLAIM_STATUS_DATA,
  GET_CASHBACK_STATUS_DATA,
  GET_MATURITY_STATUS_DATA,
} from "../constants/ActionTypes";

//getClaimStatuses

export const getClaimStatus = (omang) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet(`getClaimCaseStatus` + `?idNumber=${omang}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_CLAIM_STATUS_DATA,
            payload: data.response.claimStatus ? data.response.claimStatus : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // eslint-disable-next-line no-console
        console.log("Error****:", error.message);
      });
  };
};

export const getCashbackStatus = (omang) => {
  return async function (dispatch) {
    dispatch({ type: FETCH_START });
    httpGet(`getCashBackDetailsByID` + `?idNumber=${omang}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_CASHBACK_STATUS_DATA,
            payload: data.response.cashBackDetailsCollection
              ? data.response.cashBackDetailsCollection
              : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // eslint-disable-next-line no-console
        console.log("Error****:", error.message);
      });
  };
};

export const getMaturityStatus = (omang) => {
  return async function (dispatch) {
    dispatch({ type: FETCH_START });
    httpGet(`getMaturityDetailsByID?idNumber=${omang}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_MATURITY_STATUS_DATA,
            payload: data.response.detailsCollection
              ? data.response.detailsCollection
              : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // eslint-disable-next-line no-console
        console.log("Error****:", error.message);
      });
  };
};

export const resetClaimStatusData = () => {
  return {
    type: RESET_CLAIM_STATUS_DATA,
  };
};
