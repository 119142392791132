/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import MaterialTable from "@material-table/core";
import { Link } from "react-router-dom";
import { Button, MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import moment from "moment";
import { date } from "yup";
import { round } from "@amcharts/amcharts4/.internal/core/utils/Time";
const theme = createMuiTheme({
  overrides: {
    MuiToolbar: { root: { display: "none" } },
    MuiPaper: { elevation2: { boxShadow: "none" } },
  },

  palette: {
    rowStyle: {
      minWidth: "140px",
    },

    secondary: {
      main: "#289bca",
    },
  },
});

function getCommaSeparated(number) {
  const fixedNumber = Number.parseFloat(number).toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function PremiumStatement({
  data,
  year,
  loading,
  policyNumber,
  dropDownData,
}) {
  return (
    <>
      <div className="custom-premium-title">
        <table className="table-responsive " border="1">
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  color: "black",
                  padding: "0px 10px ",
                  minWidth: "100px",
                }}
              >
                Month{" "}
              </th>

              <th
                style={{
                  textAlign: "right",
                  color: "black",
                  padding: "0px 12px 0px 6px",
                  minWidth: "100px",
                }}
              >
                Expected Premium (P)
              </th>

              <th
                style={{
                  textAlign: "right",
                  color: "black",
                  padding: "0px 12px 0px 6px",
                  minWidth: "100px",
                }}
              >
                Paid Amount (P)
              </th>

              <th
                style={{
                  textAlign: "center",
                  color: "black",
                  padding: "0px 10px",
                  minWidth: "100px",
                }}
              >
                Paid Date
              </th>

              <th
                style={{
                  textAlign: "left",
                  color: "black",
                  padding: "0px 6px 0px 12px",
                  minWidth: "100px",
                }}
              >
                Payment Mode
              </th>

              <th
                style={{
                  textAlign: "left",
                  color: "black",
                  padding: "0px 6px 0px 12px",
                  minWidth: "150px",
                }}
              >
                Payment Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((i, index) => {
                return (
                  <tr key={index} style={{ color: "black" }}>
                    <td style={{ textAlign: "center" }}>
                      {moment(i.dueDate).format("MMM-YYYY")}
                    </td>

                    <td style={{ textAlign: "right", padding: "0px 6px" }}>
                      {getCommaSeparated(i.expectedPremium)}
                    </td>

                    <td style={{ textAlign: "right", padding: "0px 6px" }}>
                      {getCommaSeparated(i.amount)}
                    </td>

                    <td style={{ textAlign: "center" }}>
                      {i.amount !== "0"
                        ? moment(i.receivedDate).format("DD/MM/YYYY")
                        : "N/A"}
                    </td>

                    <td style={{ textAlign: "left", padding: "0px 6px" }}>
                      {i.amount !== "0" ? i.paymode : "N/A"}
                    </td>

                    <td style={{ textAlign: "left", padding: "0px 6px" }}>
                      {i.amount !== "0" ? i.status : "N/A"}
                    </td>
                  </tr>
                );
              })
            ) : loading ? (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  Loading...
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ textAlign: "center" }}>
          {year && year.length > 1 && data && !loading && (
            <Button
              className="custom-btn-ach"
              color="primary"
              variant="contained"
              to={{
                pathname: "/home/pdf-page",
                data,
                year,
                policyNumber,
                dropDownData,
              }}
              component={Link}
              disabled={loading || (data && data.length < 1) || year === ""}
            >
              Generate Statement
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
