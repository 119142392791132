import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpPost } from "util/REST";
import {
  requestKycSuccess,
  showRequestKycError,
} from "actions/AdminAllKycRequests";
import { GET_KYC_REQUEST } from "constants/ActionTypes";

const getAllKycApiRequest = async (data) =>
  await httpPost("kyc-requests", data)
    .then((data) => data)
    .catch((error) => error);

function* getAllKycusers({ payload }) {
  try {
    let body = payload;
    const kycData = yield call(getAllKycApiRequest, body);
    if (kycData && kycData.status >= 400) {
      yield put(showRequestKycError(kycData));
    } else {
      yield put(requestKycSuccess(kycData.response));
    }
  } catch (error) {
    yield put(showRequestKycError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(GET_KYC_REQUEST, getAllKycusers)]);
}
