import { all, call, put, takeLatest } from "redux-saga/effects";
import { httpPut } from "util/REST";
import {
  forgotResetPasswordSuccess,
  forgotResetPasswordError,
} from "actions/ForgotResetPassword";
import { CHANGE_PASSWORD } from "constants/ActionTypes";

const changePasswordApi = async (data) =>
  await httpPut("resetPwd", data)
    .then((data) => data)
    .catch((error) => error);

function* changePasswordReq({ payload }) {
  try {
    let body = payload;
    const forgetResetPwd = yield call(changePasswordApi, body);
    if (forgetResetPwd && forgetResetPwd.status >= 400) {
      yield put(forgotResetPasswordError(forgetResetPwd.message));
    } else {
      yield put(forgotResetPasswordSuccess(forgetResetPwd.status));
    }
  } catch (error) {
    yield put(forgotResetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(CHANGE_PASSWORD, changePasswordReq)]);
}
