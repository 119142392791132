import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpPost } from "util/REST";
import {
  mobileOrEmailOtpSuccess,
  mobileOrEmailOtpErrorMessage,
} from "actions/mobileOrEmailOtp";
import { POST_MOBILE_EMAIL_OTP } from "constants/ActionTypes";

const postMobileEmailOtpApi = async (data) =>
  await httpPost("verifyOTPOfMobileOrEmail", data)
    .then((data) => data)
    .catch((error) => error);

function* PostMobileEmailOtpReq({ payload }) {
  try {
    let body = payload;
    const otpResponse = yield call(postMobileEmailOtpApi, body);
    if (otpResponse && otpResponse.status >= 400) {
      yield put(mobileOrEmailOtpErrorMessage(otpResponse.message));
    } else {
      yield put(mobileOrEmailOtpSuccess(otpResponse.status));
    }
  } catch (error) {
    yield put(mobileOrEmailOtpErrorMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(POST_MOBILE_EMAIL_OTP, PostMobileEmailOtpReq)]);
}
