// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

//Contact Module const

export const SHOW_MESSAGE = "show_message";
export const SHOW_ERROR_MESSAGE = "show_error_message";
export const HIDE_MESSAGE = "hide_message";

export const FETCH_ALL_CONTACT = "fetch_all_contact";
export const FETCH_ALL_CONTACT_SUCCESS = "fetch_all_contact_success";
export const ADD_FAVOURITE = "add_favourite";
export const ON_CONTACT_SELECT = "on_contact_select";
export const ON_ADD_CONTACT = "on_add_contact";
export const ON_CONTACT_CLOSE = "on_contact_close";
export const ON_FILTER_OPTION_SELECT = "on_filter_option_select";
export const ON_SAVE_CONTACT = "on_save_contact";
export const ON_DELETE_CONTACT = "on_delete_contact";
export const ON_DELETE_SELECTED_CONTACT = "on_delete_selected_contact";
export const FILTER_CONTACT = "filter_contact";
export const GET_ALL_CONTACT = "get_all_contact";
export const GET_UNSELECTED_ALL_CONTACT = "get_unselected_all_contact";
export const ON_ALL_CONTACT_SELECT = "on_all_contact_select";
export const UPDATE_SEARCH_USER = "update_search_user";
export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNIN_GOOGLE_USER = "signin_google_user";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER = "signin_facebook_user";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER = "signin_twitter_user";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER = "signin_github_user";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";

// Chat Module const

export const FETCH_ALL_CHAT_USER = "fetch_all_chat_user";
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  "fetch_all_chat_user_conversation";
export const FETCH_ALL_CHAT_USER_SUCCESS = "fetch_all_chat_user_success";
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  "fetch_all_chat_user_conversation_success";
export const FILTER_USERS = "filter_users";
export const ON_SELECT_USER = "on_select_user";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const USER_INFO_STATE = "user_info_state";
export const SUBMIT_COMMENT = "submit_comment";
export const UPDATE_MESSAGE_VALUE = "update_message_value";
export const UPDATE_SEARCH_CHAT_USER = "update_search_chat_user";

//// Mail Module const

export const ADD_LABEL = "ADD_LABEL";
export const GET_ALL_MAIL = "get_all_mail";
export const FETCH_ALL_MAIL = "fetch_all_mail";
export const FETCH_ALL_MAIL_SUCCESS = "fetch_all_mail_success";
export const GET_IMPORTANT_MAIL = "get_important_mail";
export const GET_NAV_FILTERS = "get_nav_filters";
export const GET_NAV_LABELS = "get_nav_labels";
export const GET_NAV_FOLDER = "get_nav_folder";
export const GET_READ_MAIL = "get_read_mail";
export const GET_STARRED_MAIL = "get_starred_mail";
export const GET_UNIMPORTANT_MAIL = "get_unimportant_mail";
export const GET_UNREAD_MAIL = "get_unread_mail";
export const GET_UNSELECTED_ALL_MAIL = "get_unselected_all_mail";
export const GET_UNSTARRED_MAIL = "get_unstarred_mail";
export const ON_ALL_MAIL_SELECT = "on_all_mail_select";
export const ON_FOLDER_MENU_ITEM_SELECT = "on_folder_menu_item_select";
export const ON_FOLDER_SELECT = "on_folder_select";
export const ON_IMPORTANT_SELECT = "on_important_select";
export const ON_LABEL_MENU_ITEM_SELECT = "on_label_menu_item_select";
export const GET_MARK_AS_START = "get_mark_as_start";
export const ON_LABEL_SELECT = "on_label_select";
export const ON_MAIL_CHECKED = "on_mail_checked";
export const ON_MAIL_SELECT = "on_mail_select";
export const ON_MAIL_SEND = "on_mail_send";
export const ON_OPTION_MENU_ITEM_SELECT = "on_option_menu_item_select";
export const ON_OPTION_MENU_SELECT = "on_option_menu_select";
export const ON_START_SELECT = "on_start_select";
export const SEARCH_MAIL = "search_mail";
export const ON_DELETE_MAIL = "on_delete_mail";
export const SET_CURRENT_MAIL_NULL = "set_current_mail_null";
export const ON_COMPOSE_MAIL = "on_compose_mail";

//// TO-DO Module const

export const ON_SORTEND = "on_sortend";
export const FETCH_ALL_TODO = "fetch_all_todo";
export const FETCH_ALL_TODO_SUCCESS = "fetch_all_todo-success";
export const FETCH_ALL_TODO_CONVERSATION = "fetch_all_todo_conversation";
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  "fetch_all_todo_conversation_success";
export const SELECT_ALL_TODO = "select_all_todo";
export const GET_ALL_TODO = "get_all_todo";
export const GET_UNSELECTED_ALL_TODO = "get_unselected_all_todo";
export const GET_STARRED_TODO = "get_starred_todo";
export const GET_UNSTARRED_TODO = "get_unstarred_todo";
export const GET_IMPORTANT_TODO = "get_important_todo";
export const GET_UNIMPORTANT_TODO = "get_unimportant_todo";
export const ON_LABEL_UPDATE = "on_label_update";
export const ON_TODO_UPDATE = "on_todo_update";
export const ON_DELETE_TODO = "on_delete_todo";
export const SEARCH_TODO = "search_todo";
export const SHOW_TODOS = "show_todos";
export const GET_TODO_CONVERSATION = "get_todo_conversation";
export const ON_TODO_CHECKED = "on_todo_checked";
export const ON_TODO_ADD = "on_todo_add";
export const ON_TODO_SELECT = "on_todo_select";
export const SET_CURRENT_TODO_NULL = "set_current_todo_null";
export const REMOVE_LABEL = "remove_label";
export const UPDATE_SEARCH = "update_search";

// Registration const
export const USER_REGISTRATION = "USER_REGISTTRATION";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTTRATION_SUCCESS";
export const USER_REGISTRATION_ERROR_MESSAGE =
  "USER_REGISTRATION_ERROR_MESSAGE";
export const HIDE_REGISTRATION_ERROR_MESSAGE =
  "HIDE_REGISTRATION_ERROR_MESSAGE";
export const ON_SHOW_REGISTRATION_LOADER = "ON_SHOW_REGISTRATION_LOADER";

// Register new claim

export const REGISTER_NEW_CLAIM_DATA = "REGISTER_NEW_CLAIM_DATA";
export const POST_REGISTER_NEW_CLAIM_DATA = "POST_REGISTER_NEW_CLAIM_DATA";

// Get reporter details

export const GET_REPORTER_DETAILS = "GET_REPORTER_DETAILS";

// Get kyc status

export const GET_KYC_STATUS = "GET_KYC_STATUS";

// Get User kyc document

export const GET_KYC_DOC_PREVIEW = "GET_KYC_DOC_PREVIEW";
export const RESET_KYC_DOCUMENT = "RESET_KYC_DOCUMENT";
export const KYC_PREVIEW_STATUS = "KYC_PREVIEW_STATUS";

// Get Admin kyc document

export const GET_ADMIN_KYC_DOC_PREVIEW = "GET_ADMIN_KYC_DOC_PREVIEW";
export const RESET_ADMIN_KYC_DOCUMENT = "RESET_ADMIN_KYC_DOCUMENT";

//Admin Compare with theto
export const ADMIN_COMPARE_REQUEST = "ADMIN_COMPARE_REQUEST";
export const ADMIN_COMPARE_SUCCESS = "ADMIN_COMPARE_SUCCESS";
export const ADMIN_COMPARE_ERROR_MESSAGE = "ADMIN_COMPARE_ERROR_MESSAGE";
export const ADMIN_COMPARE_LOADER = "ADMIN_COMPARE_LOADER";
export const HIDE_ADMIN_COMPARE_ERROR_MESSAGE =
  "HIDE_ADMIN_COMPARE_ERROR_MESSAGE";

//Registration OTP

export const REGISTRATION_OTP = "REGISTRATION_OTP";
export const REGISTRATION_OTP_LOADER = "REGISTRATION_OTP_LOADER";
export const REGISTRATION_OTP_ERROR_MESSAGE = "REGISTRATION_OTP_ERROR_MESSAGE";
export const HIDE_REGISTRATION_OTP_ERROR_MESSAGE =
  "HIDE_REGISTRATION_OTP_ERROR_MESSAGE";
export const VERIFY_REGISTRATION_OTP_SUCCESS =
  "VERIFY_REGISTRATION_OTP_SUCCESS";

// Registration ResendOTP

export const REGISTRATION_RESEND_OTP = "REGISTRATION_RESEND_OTP";
export const REGISTRATION_RESEND_OTP_LOADER = "REGISTRATION_RESEND_OTP_LOADER";
export const REGISTRATION_RESEND_OTP_ERROR_MESSAGE =
  "REGISTRATION_RESEND_OTP_ERROR_MESSAGE";
export const HIDE_REGISTRATION_RESEND_OTP_ERROR_MESSAGE =
  "HIDE_REGISTRATION_RESEND_OTP_ERROR_MESSAGE";
export const VERIFY_REGISTRATION_RESEND_OTP_SUCCESS =
  "VERIFY_REGISTRATION_RESEND_OTP_SUCCESS";

//Admin Approval
export const ADMIN_USER_REVIEW_REQUEST = "ADMIN_USER_APPROVAL_REQUEST";
export const ADMIN_USER_APPROVAL_SUCCESS = "ADMIN_USER_APPROVAL_SUCCESS";
export const ADMIN_USER_REVIEW_ERROR_MESSAGE =
  "ADMIN_USER_APPROVAL_ERROR_MESSAGE";
export const ADMIN_USER_APPROVAL_LOADER = "ADMIN_USER_APPROVAL_LOADER";
export const HIDE_ADMIN_USER_APPROVAL_ERROR_MESSAGE =
  "HIDE_ADMIN_USER_APPROVAL_ERROR_MESSAGE";

//Admin KYC Approval/REJECT
export const ADMIN_KYC_REVIEW_REQUEST = "ADMIN_KYC_REVIEW_REQUEST";
export const ADMIN_KYC_APPROVAL_SUCCESS = "ADMIN_KYC_APPROVAL_SUCCESS";
export const ADMIN_KYC_REVIEW_ERROR_MESSAGE = "ADMIN_KYC_REVIEW_ERROR_MESSAGE";
export const ADMIN_KYC_APPROVAL_LOADER = "ADMIN_KYC_APPROVAL_LOADER";
export const HIDE_ADMIN_KYC_APPROVAL_ERROR_MESSAGE =
  "HIDE_ADMIN_KYC_APPROVAL_ERROR_MESSAGE";

// Registration Request const

export const USER_REGISTRATION_REQUEST = "USER_REGISTRATION_REQUEST";
export const USER_REQUEST_REGISTRATION_SUCCESS =
  "USER_REQUEST_REGISTRATION_SUCCESS";
export const HIDE_REQUEST_REGISTRATION_ERROR_MESSAGE =
  "HIDE_REQUEST_REGISTRATION_ERROR_MESSAGE";
export const ON_REQUEST_SHOW_REGISTRATION_LOADER =
  "ON_REQUEST_SHOW_REGISTRATION_LOADER";
export const USER_REQUEST_REGISTRATION_ERROR_MESSAGE =
  "USER_REQUEST_REGISTRATION_ERROR_MESSAGE";
export const USER_REGISTRATION_REQUEST_RESET =
  "USER_REGISTRATION_REQUEST_RESET";

//KYC Request const

export const GET_KYC_REQUEST = "GET_KYC_REQUEST";
export const KYC_REQUEST_SUCCESS = "KYC_REQUEST_SUCCESS";
export const HIDE_KYC_REQUEST_ERROR_MESSAGE = "HIDE_KYC_REQUEST_ERROR_MESSAGE";
export const ON_REQUEST_SHOW_KYC_LOADER = "ON_REQUEST_SHOW_KYC_LOADER";
export const KYC_REQUEST_ERROR_MESSAGE = "KYC_REQUEST_ERROR_MESSAGE";
export const KYC_REQUEST_RESET = "KYC_REQUEST_RESET";

//DPO Payment

export const GET_DPO_REQUEST = "GET_DPO_REQUEST";
export const DPO_REQUEST_SUCCESS = "DPO_REQUEST_SUCCESS";
export const DPO_REQUEST_SUCCESS_MESSAGE = "DPO_REQUEST_SUCCESS_MESSAGE";
export const HIDE_DPO_REQUEST_ERROR_MESSAGE = "HIDE_DPO_REQUEST_ERROR_MESSAGE";
export const HIDE_DPO_REQUEST_SUCCESS_MESSAGE = "HIDE_DPO_REQUEST_SUCCESS_MESSAGE"
export const DPO_REQUEST_LOADER = "DPO_REQUEST_LOADER";
export const DPO_REQUEST_ERROR_MESSAGE = "DPO_REQUEST_ERROR_MESSAGE";
export const DPO_REQUEST_RESET = "DPO_REQUEST_RESET";

//DPO Token

export const GET_DPO_TOKEN = "GET_DPO_TOKEN";
export const DPO_TOKEN_SUCCESS = "DPO_TOKEN_SUCCESS";
export const DPO_TOKEN_LOADER = "DPO_TOKEN_LOADER";
export const HIDE_DPO_TOKEN_LOADER = "HIDE_DPO_TOKEN_LOADER"
export const DPO_TOKEN_RESET = "DPO_TOKEN_RESET";
export const DPO_TOKEN_STATUS = "DPO_TOKEN_STATUS";
export const HIDE_DPO_TOKEN_ERROR_MESSAGE = 'HIDE_DPO_TOKEN_ERROR_MESSAGE';
export const DPO_TOKEN_ERROR_MESSAGE = 'DPO_TOKEN_ERROR_MESSAGE';

//forgot password
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR_MESSAGE = "FORGOT_PASSWORD_ERROR_MESSAGE";
export const HIDE_PASSWORD_ERROR_MESSAGE = "HIDE_PASSWORD_ERROR_MESSAGE";
export const ON_SHOW_PASSWORD_LOADER = "ON_SHOW_PASSWORD_LOADER";

//Report download pdf/csv
export const REPORT_DOWNLOAD = "REPORT_DOWNLOAD";
export const REPORT_DOWNLOAD_SUCCESS = "REPORT_DOWNLOAD_SUCCESS";
export const REPORT_DOWNLOAD_ERROR_MESSAGE = "REPORT_DOWNLOAD_ERROR_MESSAGE";
export const HIDE_REPORT_DOWNLOAD_ERROR_MESSAGE =
  "HIDE_REPORT_DOWNLOAD_ERROR_MESSAGE";
export const ON_SHOW_REPORT_DOWNLOAD_LOADER = "ON_SHOW_REPORT_DOWNLOAD_LOADER";

// forgot mobile or email otp
export const POST_MOBILE_EMAIL_OTP = "POST_MOBILE_EMAIL_OTP";
export const POST_MOBILE_EMAIL_OTP_SUCCESS = "POST_MOBILE_EMAIL_OTP_SUCCESS";
export const POST_MOBILE_EMAIL_OTP_ERROR_MESSAGE =
  "POST_MOBILE_EMAIL_OTP_ERROR_MESSAGE";
export const HIDE_OTP_ERROR_MESSAGE = "HIDE_OTP_ERROR_MESSAGE";
export const ON_SHOW_OTP_LOADER = "ON_SHOW_OTP_LOADER";

// forgot reset password
export const FORGOT_RESET_PASSWORD = "FORGOT_RESET_PASSWORD";
export const FORGOT_RESET_PASSWORD_SUCCESS = "FORGOT_RESET_PASSWORD_SUCCESS";
export const FORGOT_RESET_PASSWORD_ERROR_MESSAGE =
  "FORGOT_RESET_PASSWORD_ERROR_MESSAGE";
export const HIDE_RESET_PASSWORD_ERROR_MESSAGE =
  "HIDE_RESET_PASSWORD_ERROR_MESSAGE";
export const ON_SHOW_RESET_PASSWORD_LOADER = "ON_SHOW_RESET_PASSWORD_LOADER";

// create password
export const CREATE_PASSWORD = "CREATE_PASSWORD";
export const CREATE_PASSWORD_SUCCESS = "CREATE_PASSWORD_SUCCESS";
export const CREATE_PASSWORD_ERROR_MESSAGE = "CREATE_PASSWORD_ERROR_MESSAGE";
export const HIDE_CREATE_PASSWORD_ERROR_MESSAGE =
  "HIDE_CREATE_PASSWORD_ERROR_MESSAGE";
export const ON_SHOW_CREATE_PASSWORD_LOADER = "ON_SHOW_CREATE_PASSWORD_LOADER";

//change password
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const UPDATE_PASSWORD_ERROR_MESSAGE = "UPDATE_PASSWORD_ERROR_MESSAGE";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const ON_SHOW_UPDATE_PASSWORD_LOADER = "ON_SHOW_UPDATE_PASSWORD_LOADER";
export const HIDE_UPDATE_PASSWORD_ERROR_MESSAGE =
  "HIDE_UPDATE_PASSWORD_ERROR_MESSAGE";

//my policies details
export const FETCH_ALL_MY_POLICIES = "FETCH_ALL_MY_POLICIES";
export const FETCH_ALL_MY_POLICIES_ERROR_MESSAGE =
  "FETCH_ALL_MY_POLICIES_ERROR_MESSAGE";
export const FETCH_ALL_MY_POLICIES_SUCCESS = "FETCH_ALL_MY_POLICIES_SUCCESS";
export const ON_SHOW_ALL_MY_POLICIES_LOADER = "ON_SHOW_ALL_MY_POLICIES_LOADER";
export const HIDE_ALL_MY_POLICIES_ERROR_MESSAGE =
  " HIDE_ALL_MY_POLICIES_ERROR_MESSAGE";

//common constants
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const SHOW_TEST_RESULT = "SHOW_TEST_RESULT";

//policy details
export const BASIC_INFO_DATA = "BASIC_INFO_DATA";
export const AGENT_INFO_DATA = "AGENT_INFO_DATA";
export const CUSTOMER_SERVICE_INFO_DATA = "CUSTOMER_SERVICE_INFO_DATA";
export const BENIFIT_INFO_DATA = "BENIFIT_INFO_DATA";
export const FINANCIAL_INFO_DATA = "FINANCIAL_INFO_DATA";
export const PARTY_INFO_DATA = "PARTY_INFO_DATA";
export const CLAIM_INFO_DATA = "CLAIM_INFO_DATA";
export const CONTACT_INFO_DATA = "CONTACT_INFO_DATA";
export const PRODUCTS_DATA = "PRODUCTS_DATA";
export const GET_PAYMENT_INFO = "GET_PAYMENT_INFO";
export const RESET_POLICY_DETAILS = "RESET_POLICY_DETAILS";

// signin mobile otp
export const POST_SIGNIN_MOBILE_OTP = "POST_SIGNIN_MOBILE_OTP";
export const POST_SIGNIN_MOBILE_OTP_SUCCESS = "POST_SIGNIN_MOBILE_OTP_SUCCESS";
export const POST_SIGNIN_MOBILE_OTP_ERROR_MESSAGE =
  "POST_SIGNIN_MOBILE_OTP_ERROR_MESSAGE";
export const HIDE_SIGNIN_MOBILE_OTP_MESSAGE = "HIDE_SIGNIN_MOBILE_OTP_MESSAGE";
export const ON_SHOW_SIGNIN_MOBILE_OTP_LOADER =
  "ON_SHOW_SIGNIN_MOBILE_OTP_LOADER";

// signin ResendOTP

export const POST_SIGNIN_MOBILE_RESEND_OTP = "POST_SIGNIN_MOBILE_RESEND_OTP";
export const POST_SIGNIN_MOBILE_RESEND_OTP_LOADER =
  "POST_SIGNIN_MOBILE_RESEND_OTP_LOADER";
export const POST_SIGNIN_MOBILE_RESEND_OTP_ERROR_MESSAGE =
  "POST_SIGNIN_MOBILE_RESEND_OTP_ERROR_MESSAGE";
export const HIDE_SIGNIN_MOBILE_RESEND_ERROR_MESSAGE =
  "HIDE_REGISTRATION_RESEND_OTP_ERROR_MESSAGE";
export const VERIFY_SIGNIN_MOBILE_RESEND_OTP_SUCCESS =
  "VERIFY_SIGNIN_MOBILE_RESEND_OTP_SUCCESS";

// Claim Status

export const GET_CLAIM_STATUS_DATA = "GET_CLAIM_STATUS_DATA";
export const RESET_CLAIM_STATUS_DATA = "RESET_CLAIM_STATUS";

// Cashback

export const GET_CASHBACK_STATUS_DATA = "GET_CASHBACK_STATUS_DATA";
export const RESET_CASHBACK_STATUS_DATA = "RESET_CLAIM_STATUS_DATA";

// Maturity

export const GET_MATURITY_STATUS_DATA = "GET_MATURITY_STATUS_DATA";
export const RESET_MATURITY_STATUS_DATA = "RESET_MATURITY_STATUS_DATA";

// Claim Info

export const GET_CLAIM_INFO_DATA = "GET_CLAIM_INFO_DATA";

// KYC Check

export const KYC_CHECK = "KYC_CHECK";
export const RESET_KYC_CHECK = "RESET_KYC_CHECK";
export const KYC_CHECK_MESSAGE = "KYC_CHECK_MESSAGE";
export const KYC_CHECK_CODE = "KYC_CHECK_CODE";

// KYC Details

export const GET_KYC_DATA = "GET_KYC_DATA";
export const POST_KYC_DATA = "POST_KYC_DATA";
export const RESET_KYC_DATA = "RESET_KYC_DATA";
export const SET_KYC_LOADER = "SET_KYC_LOADER";
export const RESET_KYC_LOADER = "RESET_KYC_LOADER";

// Admin KYC
export const GET_ALL_KYC_ADMIN_DATA = "GET_ALL_KYC_ADMIN_DATA";

//Claims documnet upload
export const CLAIMS_DOCS_UPLOAD = "CLAIMS_DOCS_UPLOAD";
export const CLAIMS_DOCS_UPLOAD_SUCCESS = "CLAIMS_DOCS_UPLOAD_SUCCESS";
export const CLAIMS_DOCS_UPLOAD_ERROR_MESSAGE =
  "CLAIMS_DOCS_UPLOAD_ERROR_MESSAGE";
export const HIDE_CLAIMS_DOCS_UPLOAD_ERROR_MESSAGE =
  "HIDE_CLAIMS_DOCS_UPLOAD_ERROR_MESSAGE";
export const ON_SHOW_CLAIMS_DOCS_UPLOAD_LOADER =
  "ON_SHOW_CLAIMS_DOCS_UPLOAD_LOADER";

//EDRMS files upload
export const EDRMS_DOCS_PUSH = "EDRMS_DOCS_PUSH";
export const RESET_EDRMS_DOCS_PUSH = "RESET_EDRMS_DOCS_PUSH";

//Booking Appointment
export const BOOKING_GET_BRANCH_REQUEST = "BOOKING_GET_BRANCH_REQUEST";
export const BOOKING_GET_BRANCH_SUCCESS = "BOOKING_GET_BRANCH_SUCCESS";
export const BOOKING_GET_BRANCH_ERROR_MESSAGE =
  "BOOKING_GET_BRANCH_ERROR_MESSAGE";
export const BOOKING_GET_BRANCH_LOADER = "BOOKING_GET_BRANCH_LOADER";
export const HIDE_BOOKING_GET_BRANCH_ERROR_MESSAGE =
  "HIDE_BOOKING_GET_BRANCH__ERROR_MESSAGE";
export const BOOKING_GET_BRANCH_RESET = "BOOKING_GET_BRANCH_RESET";

//Appointment Services
export const BOOKING_GET_SERVICES_REQUEST = "BOOKING_GET_SERVICES_REQUEST";
export const BOOKING_GET_SERVICES_SUCCESS = "BOOKING_GET_SERVICES_SUCCESS";
export const BOOKING_GET_SERVICES_ERROR_MESSAGE =
  "BOOKING_GET_SERVICES_ERROR_MESSAGE";
export const BOOKING_GET_SERVICES_LOADER = "BOOKING_GET_SERVICES_LOADER";
export const HIDE_BOOKING_GET_SERVICES_ERROR_MESSAGE =
  "HIDE_BOOKING_GET_SERVICES__ERROR_MESSAGE";
export const BOOKING_GET_SERVICES_RESET = "BOOKING_GET_SERVICES_RESET";

//Appointments
export const BRANCHES_DATA = "BRANCHES_DATA";
export const SERVICES_DATA = "SERVICES_DATA";
export const AVAILABLE_DAYS_DATA = "AVAILABLE_DAYS_DATA";
export const AVAILABLE_TIME_DATA = "AVAILABLE_TIME_DATA";
export const TAKE_APPOINTMENT_DATA = "TAKE_APPOINTMENT_DATA";
export const APPOINTMENT_LOADER = "APPOINTMENT_LOADER";
export const APPOINTMENT_MESSAGE = "APPOINTMENT_MESSAGE";
export const APPOINTMENT_ERROR_MESSAGE = "APPOINTMENT_MESSAGE";
export const RESET_APPOINTMENT_LOADER = "RESET_APPOINTMENT_LOADER";
export const RESET_APPOINTMENT_DETAILS = "RESET_APPOINMENT_DETAILS";
export const APPOINTMENT_STATUS = "APPOINTMENT_STATUS";
export const NEAREST_TIME = "NEAREST_TIME";
export const APPOINTMENT_HISTORY = "APPOINTMENT_HISTORY";
export const APPOINTMENT_HISTORY_STATUS = "APPOINTMENT_HISTORY_STATUS";

//Feedback
export const FEEDBACK_QUESTION_DATA = "FEEDBACK_QUESTION_DATA";
export const AVAILABLE_FEEDBACK_DATA = "AVAILABLE_FEEDBACK_DATA";
export const FEEDBACK_QUESTION_FAILURE = "FEEDBACK_QUESTION_FAILURE";
export const FEEDBACK_STATUS = "FEEDBACK_STATUS";
export const FEEDBACK_ERROR = "FEEDBACK_ERROR";
export const SUBMIT_STATUS = "SUBMIT_STATUS";
export const FEEDBACK_LOADER = "FEEDBACK_LOADER";
export const RESET_FEEDBACK = "RESET_FEEDBACK";

//Bank Details
export const BANK_DETAIL_DATA = "BANK_DETAIL_DATA";
export const BANK_DETAIL_ERROR = "BANK_DETAIL_ERROR";
export const HIDE_BANK_DETAIL_ERROR = "HIDE_BANK_DETAIL_ERROR";
export const BANK_DETAIL_STATUS = "BANK_DETAIL_STATUS";
export const BANK_DETAIL_LOADER = "BANK_DETAIL_LOADER";
export const RESET_BANK_DETAILS = "RESET_BANK_DETAILS";

//Reward Details
export const REWARD_DETAIL_DATA = "REWARD_DETAIL_DATA";
export const REWARD_DETAIL_LOADER = "REWARD_DETAIL_LOADER";
export const REWARD_DETAIL_ERROR = "REWARD_DETAIL_ERROR";
