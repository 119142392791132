import { httpGet, httpPost } from "util/REST";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  KYC_CHECK,
  KYC_CHECK_MESSAGE,
  RESET_KYC_CHECK,
  KYC_CHECK_CODE,
} from "constants/ActionTypes";

export const checkKyc = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("checkKYCRequestExist")
      .then((data) => {
        console.log("kyc check print: ", data);
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: KYC_CHECK, payload: data.response });
          dispatch({ type: KYC_CHECK_MESSAGE, payload: data.message });
          dispatch({ type: KYC_CHECK_CODE, payload: data.status });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
          dispatch({ type: KYC_CHECK_MESSAGE, payload: data.message });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: RESET_KYC_CHECK });
        // eslint-disable-next-line no-console
        console.log("Error****:", error.message);
      });
  };
};
