/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { httpPost } from "util/REST";
import {
  reportDownloadSuccess,
  reportDownloadError,
} from "actions/ReportAction";
import { REPORT_DOWNLOAD } from "constants/ActionTypes";

const postPdfReportDownloadApi = async (data) =>
  await httpPost("download-reports", data)
    .then((data) => data)
    .catch((error) => error);

function* PostPdfReportDownloadReq({ payload }) {
  try {
    let body = payload;
    const PdfReportData = yield call(postPdfReportDownloadApi, body);
    if (PdfReportData && PdfReportData.status >= 400) {
      yield put(reportDownloadError(PdfReportData.message));
    } else {
      yield put(reportDownloadSuccess(PdfReportData.response));
    }
  } catch (error) {
    yield put(reportDownloadError(error));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(REPORT_DOWNLOAD, PostPdfReportDownloadReq)]);
}
