import {
  BOOKING_GET_SERVICES_REQUEST,
  BOOKING_GET_SERVICES_SUCCESS,
  BOOKING_GET_SERVICES_ERROR_MESSAGE,
  BOOKING_GET_SERVICES_LOADER,
  HIDE_BOOKING_GET_SERVICES_ERROR_MESSAGE,
  BOOKING_GET_SERVICES_RESET,
} from "constants/ActionTypes";

export const getBookingServices = (data) => {
  return {
    type: BOOKING_GET_SERVICES_REQUEST,
    payload: data,
  };
};

export const getBookingServicesSuccess = (data) => {
  return {
    type: BOOKING_GET_SERVICES_SUCCESS,
    payload: data,
  };
};

export const showBookingServicesError = (data) => {
  return {
    type: BOOKING_GET_SERVICES_ERROR_MESSAGE,
    payload: data,
  };
};

export const showBookingServicesLoader = () => {
  return {
    type: BOOKING_GET_SERVICES_LOADER,
  };
};

export const hideBookingServicesError = () => {
  return {
    type: HIDE_BOOKING_GET_SERVICES_ERROR_MESSAGE,
  };
};

export const getServicesReset = () => {
  return {
    type: BOOKING_GET_SERVICES_RESET,
  };
};
