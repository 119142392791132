import React from "react";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { Card, CardBody, CardText } from "reactstrap";

class AddPolicy extends React.Component {
  state = {
    age: "",
    name: "",
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    return (
      <>
        {/* <div className="row">
            <div className="col-lg-3 col-sm-6 col-12">
            <FormControl className="w-100 mb-2">
              <InputLabel htmlFor="age-native-simple">Relationship</InputLabel>
              <Select
                native
                value={this.state.age}
                onChange={this.handleChange('age')}
                input={<Input id="age-native-simple"/>}
              >
                <option value={10}>Self</option>
                <option value={20}>Spouse</option>
                <option value={30}>Child</option>
              </Select>
            </FormControl>
            
          </div>
          </div> */}
        <Card
          style={{
            boxShadow:
              "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
            width: "50%",
          }}
        >
          <CardBody>
            <CardText>
              <div className="row">
                <div className="col-md-9 col-sm-12">
                  <Input
                    placeholder="Enter Policy Number"
                    className="w-100 mb-3"
                    inputProps={{
                      "aria-label": "Description",
                    }}
                  />
                </div>
                <div
                  className="col-md-3 col-sm-12"
                  style={{ textAlign: "right" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn text-white"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </CardText>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default AddPolicy;
