/* eslint-disable no-unused-vars */
import React from "react";
import moment from "moment";
import { currencyConverter } from "util/currencyConverter";

const BenefitInformation = ({ data }) => {
  const {
    benefitStatus = null,
    coverageDesc = null,
    coveragePeriod = null,
    expiryDate = null,
    planName = null,
    policyNumber = null,
    premium = null,
    term = null,
  } = data;

  return (
    <>
      <div className="row coustom-claim">
        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Benefit Status:</h5>
          <p>{benefitStatus}</p>
        </div>

        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Coverage Desc:</h5>
          <p> {coverageDesc}</p>
        </div>

        {/* <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
                <h5 className="mb-1 ">Coverage Period:</h5>
                <p>{coveragePeriod}</p>
            </div> */}

        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Expiry Date:</h5>
          <p>{expiryDate ? moment(expiryDate).format("DD/MM/YYYY") : null}</p>
        </div>

        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Plan Name:</h5>
          <p> {planName}</p>
        </div>

        {/* <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
                <h5 className="mb-1 ">Policy Number:</h5>
                <p>{policyNumber}</p>
            </div> */}

        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Premium:</h5>
          <p> {premium ? currencyConverter(premium) : null}</p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Term:</h5>
          <p> {term}</p>
        </div>
      </div>
    </>
  );
};

export default BenefitInformation;
