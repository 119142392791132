/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardText } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ContainerHeader from "components/ContainerHeader";
import Widget from "../../../../../components/Widget";

export const PoeloCreditLife = ({ productId, productName }) => {
  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile custom-kyp-fonts">
        <div className="card-header">
          <h4 className="card-title mb-0 text-uppercase">{productName}</h4>
        </div>
        <div className="jr-tabs-classic">
          <div className="jr-tabs-content jr-task-list pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <h3 className="mb-2">Product Code</h3>
                  <p>PSTA-1</p>
                  <h3 className="mb-2">Product Summary</h3>
                  <p>
                    This product is designed to assure the life of the
                    policyholder in the event of death. The duration of the loan
                    must be less than 6 years.
                  </p>
                </div>
                <div>
                  <h3 className="mb-2">Age Limits</h3>
                  <ul>
                    <li>Minimum age at entry for life cover : 21 years </li>
                    <li>Maximum age at entry for life cover : 65 years </li>
                    <li>
                      Termination age for life cover and accidental death : 70
                      years{" "}
                    </li>
                    <li>
                      Maximum age at entry for capital disability : 65 years{" "}
                    </li>
                    <li>
                      Maximum termination age for capital disability : 70 years{" "}
                    </li>
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2">Sum Assured</h3>
                  <ul>
                    <li>Minimum sum assured: P 50000. </li>
                    <li>Maximum sum assured: P 1,000,000</li>
                    <li>
                      The chosen sum assured cannot exceed the loan amount at
                      policy commencement.{" "}
                    </li>
                    <li>
                      On death or disability, the full sum assured is paid.{" "}
                    </li>
                    <li>
                      The bank deducts the outstanding balance and the
                      difference is paid to the beneficiaries (upon death) or to
                      the policyholder (upon disability).{" "}
                    </li>
                    <li>
                      Cover on the capital disability benefit cannot exceed the
                      life cover sum assured.{" "}
                    </li>
                    <li>
                      Sum assured on accidental death cannot exceed the death
                      cover or P 400,000.{" "}
                    </li>
                  </ul>
                  <h3 className="mb-2">Premium Limits</h3>
                  <p>
                    <b>Standard Poelo premium rate</b> x 1.35
                  </p>
                </div>
                <div>
                  <h3 className="mb-2">Policy Term</h3>
                  <ul>
                    <li>
                      As chosen by policyholder but subject to the minimum of
                      loan term and limited by the termination age.
                    </li>
                  </ul>
                </div>
                <div>
                  <Button
                    className="custom-btn-ach"
                    component={Link}
                    to="/home/know-your-products"
                    variant="contained"
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </>
  );
};
