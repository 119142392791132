/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
// import Isago from '../../../../assets/images/know your products images/Isago.png';
// import LoreMasterPlan from '../../../../assets/images/know your products images/LoreMasterPlan.png';
// import MasikaOtlhe from '../../../../assets/images/know your products images/MasikaOtlhe.png';
// import Mmoloki from '../../../../assets/images/know your products images/Mmoloki.png';
// import Mosako from '../../../../assets/images/know your products images/Mosako.png';
// import PoeloCredit from '../../../../assets/images/know your products images/PoeloCredit.png';
// import PoeloExclusive from '../../../../assets/images/know your products images/PoeloExclusive.png';
// import PoeloMortgage from '../../../../assets/images/know your products images/PoeloMortgage.png';
// import PoeloStandard from '../../../../assets/images/know your products images/PoeloStandard.png';
// import PoeloWholeOfLife from '../../../../assets/images/know your products images/PoeloWholeOfLife.png';
// import Tapologo from '../../../../assets/images/know your products images/Tapologo.png';

// import Isago from '../../../../assets/images/know your products images/Isago1.jpg';
// import LoreMasterPlan from '../../../../assets/images/know your products images/LoreMasterPlan1.jpg';
// import MasikaOtlhe from '../../../../assets/images/know your products images/MasikaOtlhe1.jpg';
// import Mmoloki from '../../../../assets/images/know your products images/Mmoloki1.jpg';
// import Mosako from '../../../../assets/images/know your products images/Mosako1.jpg';
// import PoeloCredit from '../../../../assets/images/know your products images/PoeloCredit1.jpg';
// import PoeloExclusive from '../../../../assets/images/know your products images/PoeloExclusive1.jpg';
// import PoeloMortgage from '../../../../assets/images/know your products images/PoeloMortgage1.jpg';
// import PoeloStandard from '../../../../assets/images/know your products images/PoeloStandard1.jpg';
// import PoeloWholeOfLife from '../../../../assets/images/know your products images/PoeloWholeOfLife1.jpg';

// import Tapologo from '../../../../assets/images/know your products images/Tapologo1.jpg';
import Isago from "../../../../assets/images/know your products images/banners/Isago.jpg";

// import Isago from '../../../../assets/images/know your products images/Resized banners/Life and More Product Online Banners 17032021-1.jpg';
import LoreMasterPlan from "../../../../assets/images/know your products images/banners/LoreMasterPlan.jpg";
import MasikaOtlhe from "../../../../assets/images/know your products images/banners/MasikaOtlhe.jpg";
import Mmoloki from "../../../../assets/images/know your products images/banners/Mmoloki.jpg";
import Mosako from "../../../../assets/images/know your products images/banners/Mosako.jpg";
import PoeloCredit from "../../../../assets/images/know your products images/banners/PoeloCredit.jpg";
import PoeloExclusive from "../../../../assets/images/know your products images/banners/PoeloExclusive.jpg";
import PoeloMortgage from "../../../../assets/images/know your products images/banners/PoeloMortgage.jpg";
import PoeloStandard from "../../../../assets/images/know your products images/banners/PoeloStandard.jpg";
import PoeloWholeOfLife from "../../../../assets/images/know your products images/banners/PoeloWholeOfLife.jpg";

import Tapologo from "../../../../assets/images/know your products images/banners/Tapologo.jpg";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import '../routes/ProductDetailsPages/slideshowdemo.css';

const ProductGridItem = ({ product }) => {
  const { internalId = null, productId = null, productName = null } = product;

  let backgroundImg = {
    backgroundImage: `url(${PoeloExclusive})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  };

  // var images = [
  //   { img: Isago },
  //   { img: LoreMasterPlan },
  //   { img: Mmoloki },
  //   { img: Mosako },
  //   { img: PoeloCredit },
  //   { img: PoeloExclusive },
  //   { img: PoeloMortgage },
  //   { img: PoeloStandard },

  //   { img: PoeloWholeOfLife },
  //   { img: Tapologo },
  //   { img: MasikaOtlhe },

  // ];
  // var imgSlides = () =>
  //   images.map(num => (
  //     <div className="imgpad">
  //       <Link to={"/home/know-your-products/product-details", product}>

  //         <img className="imgdetails" src={num.img} width="100%" />
  //       </Link>
  //     </div>
  //   ));

  if (productName == "ISAGO - MULTI FUND INVESTMENT PLAN") {
    backgroundImg = {
      backgroundImage: `url(${Isago})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (productName == "ENHANCED LORE") {
  /*
  else if (productName == 'LORE') {
    backgroundImg = {
      backgroundImage: `url(${LoreMasterPlan})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative'

    }
  }
  */
    backgroundImg = {
      backgroundImage: `url(${LoreMasterPlan})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (productName == "MASIKA OTLHE") {
    backgroundImg = {
      backgroundImage: `url(${MasikaOtlhe})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (productName == "BOKAMOSO MMOLOKI FAMILY WEALTH PROTECTOR") {
    backgroundImg = {
      backgroundImage: `url(${Mmoloki})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (productName == "MOSAKO") {
    backgroundImg = {
      backgroundImage: `url(${Mosako})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (
    productName == "POELO - SINGLE LIFE TERM ASSURANCE - WITH RETURN OF PREMIUM"
  ) {
    backgroundImg = {
      backgroundImage: `url(${PoeloCredit})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (productName == "") {
    backgroundImg = {
      backgroundImage: `url(${PoeloExclusive})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (productName == "POELO - MORTGAGE") {
    backgroundImg = {
      backgroundImage: `url(${PoeloMortgage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (productName == "POELO - SINGLE LIFE TERM ASSURANCE") {
    backgroundImg = {
      backgroundImage: `url(${PoeloStandard})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (productName == "POELO -WHOLE LIFE") {
    backgroundImg = {
      backgroundImage: `url(${PoeloWholeOfLife})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  } else if (productName == "TAPOLOGO - MULTI FUND RETIREMENT ANNUITY") {
    backgroundImg = {
      backgroundImage: `url(${Tapologo})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
    };
  }

  // return (

  //   <div className="App">
  //     <div class='container-fluid'>
  //       <div className="row title" style={{ marginBottom: "20px" }} >
  //         {/* <div class="col-sm-12 btn btn-info">
  //                           Image Slider In React Application
  //           </div> */}
  //       </div>
  //     </div>
  //     <Slider
  //       dots={true}
  //       slidesToShow={3}
  //       slidesToScroll={3}
  //       autoplay={false}
  //       // arrows={true}
  //       autoplaySpeed={3000}>{imgSlides()}
  //     </Slider>
  //   </div>

  return (
    <>
      {productName === "LORE" ? null : (
        <div className="col-xl-4 col-md-4 col-sm-6 col-12">
          <div className="card product-item">
            <div className="card-header border-0 p-0">
              {/* <div className="card-image">
            <div className="grid-thumb-equal">
              <span className="grid-thumb-100% 100% jr-link">
                <img alt="Remy Sharp" src={productName} alt={productName} />
              </span>
            </div>
          </div> */}
            </div>
            <div>
              <div className="card-body">
                <div className="product-details">
                  <h3
                    className="card-title fw-regular text-uppercase"
                    style={{ minHeight: 50 }}
                  >
                    {productName}
                    {/* style={{minHeight:58}} */}
                  </h3>
                  {/* <div className="d-flex ">
                <h3 className="card-title">{price} </h3>
                <h5 className="text-muted px-2">
                  <del>{mrp}</del>
                </h5>
                <h5 className="text-success">{offer} off</h5>
              </div>
//custom-btn-ach
              <p>{description}</p> */}
                </div>
                <div>
                  <Button
                    class="btn btn-primary"
                    color="primary"
                    to={{
                      pathname: "/home/know-your-products/product-details",
                      product,
                    }}
                    component={Link}
                    variant="100% 100%ed"
                  >
                    View More Details
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductGridItem;
