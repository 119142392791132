import React, { useEffect, useState } from "react";
import ControlledOpenSelect from "./DropDown";
import ProductGridItemProps from "./ProductGridItemProps";
import ContainerHeader from "../../../../components/ContainerHeader/index";
import { useDispatch, useSelector } from "react-redux";
import { getProductsData } from "actions/Products";
import { ToastContainer } from "react-toastify";
import { ErrorToaster } from "util/ReactToastify";

import CircularProgress from "@material-ui/core/CircularProgress";
import { hideErrorMessage } from "actions/Common";
// import 'containers/fontStyle.scss';

const KYP = ({ match }) => {
  const dispatch = useDispatch();

  const { productsData } = useSelector(({ products }) => products);
  const { loading, error } = useSelector(({ commonData }) => commonData);
  const [productTypeId, setProductTypeId] = useState("all");

  useEffect(() => {
    dispatch(getProductsData(productTypeId));
  }, [productTypeId, dispatch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
      }, 300);
    }
  }, [error, dispatch]);

  const handleChange = (event) => {
    setProductTypeId(event.target.value);
  };

  return (
    <div>
      <ContainerHeader match={match} />

      <ControlledOpenSelect
        productTypeId={productTypeId}
        handleChange={handleChange}
      />
      <ProductGridItemProps data={productsData} loading={loading} />

      {loading && (
        <div className="loading-view">
          <CircularProgress />
        </div>
      )}

      {error && <ErrorToaster message={error} />}
      <ToastContainer />
    </div>
  );
};

export default KYP;
