/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import { CircularProgress } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  signInMobileOtp,
  showSignInMobileOtpLoader,
  hideSignInMobileOtpErrorMessage,
} from "../actions/SignInOTP";
import { useHistory } from "react-router-dom";

import {
  signInResendOtp,
  hideSignInResendOtpErrorMessage,
  signInResendOtpLoader,
} from "../actions/SignInResendOTP";

import { forgotMobileOtpValidations } from "../constants/Validations";
import {
  ErrorToaster,
  SuccessToaster,
  toastMsgs,
} from "util/ReactToastify/index.js";

const SignInMobileOTP = (props) => {
  let history = useHistory();

  // console.log(props, 'propsprops');
  const [otpData, setOtpData] = useState();

  const { otpLoader, otpAlertMessage, otpShowMessage, otpStatus } = useSelector(
    ({ signInMobileOtpReducer }) => signInMobileOtpReducer
  );
  const {
    resendOtpLoader,
    resendOtpStatus,
    resendOtpAlertMessage,
    resendOtpShowMessage,
  } = useSelector(
    ({ signInMobileResendOtpReducer }) => signInMobileResendOtpReducer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (otpShowMessage) {
      setTimeout(() => {
        dispatch(hideSignInMobileOtpErrorMessage());
      }, 100);
    }
  }, [otpShowMessage, dispatch]);

  useEffect(() => {
    if (otpStatus === 200) {
      history.push("/");
    }
    return () => dispatch(hideSignInMobileOtpErrorMessage());
  }, [dispatch, otpStatus, props.history]);

  useEffect(() => {
    if (resendOtpShowMessage) {
      setTimeout(() => {
        dispatch(hideSignInResendOtpErrorMessage());
      }, 100);
    }

    if (resendOtpStatus === 200) {
      {
        toastMsgs.success(forgotMobileOtpValidations.otpResendSuccessMsg);
      }
      setTimeout(() => {
        dispatch(hideSignInResendOtpErrorMessage());
      }, 100);
    }
    return () => dispatch(hideSignInResendOtpErrorMessage());
  }, [resendOtpShowMessage, resendOtpStatus]);

  const handleResendOtp = () => {
    setSeconds(120);
    setIsActive(true);
    const contactNumber =
      props && props.mobileNumber ? props.mobileNumber : null;

    const countryCode = props && props.countryCode ? props.countryCode : null;

    const email = "";
    let otpResendData = {
      contactNumber,
      countryCode,
      email,
    };

    dispatch(signInResendOtpLoader());
    dispatch(signInResendOtp(otpResendData));
  };

  const [seconds, setSeconds] = useState(120);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isActive && seconds !== 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (isActive && seconds === 0) {
      setIsActive(false);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const minutes = Math.floor(seconds / 60);
  const secondsRem = seconds % 60;
  return (
    <>
      <Formik
        initialValues={initialValues}
        // validationSchema={SignupSchema}
        onSubmit={(values) => {
          const { otpNumber } = values;
          let contactNumber =
            props && props.mobileNumber ? props.mobileNumber : null;
          let countryCode =
            props && props.countryCode ? props.countryCode : null;
          let password = props && props.password ? props.password : null;
          let otpData = {
            contactNumber,
            otpNumber,
            countryCode,
            password,
          };
          setOtpData(otpData);
          dispatch(showSignInMobileOtpLoader());
          dispatch(signInMobileOtp(otpData));
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <div
              style={{ margin: "0 auto" }}
              className="custom-forgot-password"
            >
              <div className="login-container otp-holder align-center d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="login-content ">
                  <div className="login-header mb-4">
                    <Link className="app-logo" to="/" title="Jambo">
                      <img
                        src={require("assets/images/logo.png")}
                        alt="jambo"
                        title="jambo"
                      />
                    </Link>
                  </div>

                  <div className="login-form">
                    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <h4>
                        <IntlMessages id="appModule.OTPForEmailAndMobileText" />
                      </h4>
                      <TextField
                        error={errors.otpNumber && touched.otpNumber}
                        className="form-textfield form-textfield-label"
                        id="otpNumber"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.otpNumber}
                        onChange={handleChange}
                        placeholder="Enter OTP Number"
                        margin="normal"
                        required
                        onBlur={handleBlur}
                        fullWidth
                        helperText={
                          errors.otpNumber &&
                          touched.otpNumber &&
                          errors.otpNumber
                        }
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {minutes}:
                          {secondsRem < 10 ? `0${secondsRem}` : secondsRem}
                        </div>
                        <div className="remove-btn-padding">
                          <Button
                            className="jr-btn"
                            color="primary"
                            onClick={handleResendOtp}
                            disabled={isActive}
                          >
                            Resend OTP
                          </Button>
                        </div>
                      </div>
                      <div className="mt-1 mb-2 d-flex justify-content-center align-items-center custom-verify-btn">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Verify
                        </Button>
                      </div>
                    </form>
                  </div>
                  {(otpLoader || resendOtpLoader) && (
                    <div className="loader-view">
                      <CircularProgress />
                    </div>
                  )}
                  {resendOtpShowMessage && (
                    <ErrorToaster message={resendOtpAlertMessage} />
                  )}
                  {otpShowMessage && <ErrorToaster message={otpAlertMessage} />}
                  <ToastContainer />
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

const initialValues = {
  otpNumber: "",
};

// const SignupSchema = Yup.object().shape({
//   otpNumber: Yup.string()

//     .matches(/^[0-9]+$/, forgotMobileOtpValidations.OtpLengthMsg)
//     .min(6, forgotMobileOtpValidations.OtpLengthMsg)
//     .max(6, forgotMobileOtpValidations.OtpLengthMsg)
//     .required(forgotMobileOtpValidations.OtpRequired),
// });

export default SignInMobileOTP;
