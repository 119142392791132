import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const IdExpiryMessage = ({ open, closeExpiry, updateKYC }) => {
  return (
    <>
      <Dialog fullWidth open={open} className="custom-confirm-dialog">
        <DialogTitle className="custom-dialog-title">
          {"Notification"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="custom-details">
            <div
              className="table-responsive-material markt-table"
              style={{ border: "none" }}
            >
              <table className="table default-table custom-details-table table-sm full-table remove-table-border table-hover mb-0">
                <tbody>
                  <tr>
                    <td className="text-dark">
                      Your ID has expired. Please renew your ID and update your
                      KYC documents.
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirm-btns">
          <Button onClick={() => updateKYC()} className="no">
            Update KYC
          </Button>
          <Button onClick={() => closeExpiry()} className="yes">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IdExpiryMessage;
