/* eslint-disable no-unused-vars */
import { httpGet, httpPost } from "util/REST";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_KYC_DATA,
  POST_KYC_DATA,
  RESET_KYC_DATA,
  SET_KYC_LOADER,
  RESET_KYC_LOADER,
  SHOW_SUCCESS_MESSAGE,
} from "../constants/ActionTypes";
import { showSuccessMessage } from "./Common";
import { kycSubMissionButtonHandler } from "../app/routes/Kyc/index";

export const getKycDetails = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getKycDetails")
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_KYC_DATA, payload: data.response });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
          dispatch({ type: RESET_KYC_DATA });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // eslint-disable-next-line no-console
        console.log("Error****:", error.message);
      });
  };
};

export const postKycDetails = (details) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("userKyc", details)
      .then((data) => {
        if (data.status === 200) {
          kycSubMissionButtonHandler(data.response);
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: POST_KYC_DATA, payload: data.status });
          dispatch(showSuccessMessage(data.response));
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // console.log("Error****:", error.message);
      });
  };
};

export const resetKycDetails = () => {
  return {
    type: RESET_KYC_DATA,
  };
};

export const setKycLoader = () => {
  return {
    type: SET_KYC_LOADER,
  };
};

export const resetKycLoader = () => {
  return {
    type: RESET_KYC_LOADER,
  };
};
