import {
  BOOKING_GET_BRANCH_REQUEST,
  BOOKING_GET_BRANCH_SUCCESS,
  BOOKING_GET_BRANCH_ERROR_MESSAGE,
  BOOKING_GET_BRANCH_LOADER,
  HIDE_BOOKING_GET_BRANCH_ERROR_MESSAGE,
  BOOKING_GET_BRANCH_RESET,
} from "constants/ActionTypes";

export const getBranches = (data) => {
  return {
    type: BOOKING_GET_BRANCH_REQUEST,
    payload: data,
  };
};

export const getBranchesSuccess = (data) => {
  return {
    type: BOOKING_GET_BRANCH_SUCCESS,
    payload: data.response.branches ? data.response.branches : [],
  };
};

export const showBranchesError = (data) => {
  return {
    type: BOOKING_GET_BRANCH_ERROR_MESSAGE,
    payload: data,
  };
};

export const showBranchesLoader = () => {
  return {
    type: BOOKING_GET_BRANCH_LOADER,
  };
};

export const hideBranchesError = () => {
  return {
    type: HIDE_BOOKING_GET_BRANCH_ERROR_MESSAGE,
  };
};

export const getBranchReset = () => {
  return {
    type: BOOKING_GET_BRANCH_RESET,
  };
};
