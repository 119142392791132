import {
  FORGOT_RESET_PASSWORD_ERROR_MESSAGE,
  FORGOT_RESET_PASSWORD_SUCCESS,
  ON_SHOW_RESET_PASSWORD_LOADER,
  HIDE_RESET_PASSWORD_ERROR_MESSAGE,
} from "../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  forgotResetPasswordStatus: "",
  alertMessage: "",
  showMessage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FORGOT_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        forgotResetPasswordStatus: action.payload,
      };
    }
    case FORGOT_RESET_PASSWORD_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        loader: false,
        showMessage: true,
      };
    }
    case HIDE_RESET_PASSWORD_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
        forgotResetPasswordStatus: "",
      };
    }
    case ON_SHOW_RESET_PASSWORD_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    default:
      return state;
  }
};
