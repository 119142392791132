/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PolicySection from './PolicySection'
import ContainerHeader from '../../../../components/ContainerHeader/index'
import { useDispatch, useSelector } from 'react-redux'
import { myPoliciesDetails, onShowPoliciesDetailsLoader, hidePoliciesDetailsErrorMessage } from 'actions/MyPoliciesDetails'
import { CircularProgress } from "@material-ui/core";
import { ToastContainer } from 'react-toastify';
import { SuccessToaster, ErrorToaster, toastMsgs } from 'util/ReactToastify';
import { userSignOut } from "actions";
import { httpGet, httpPost } from "util/REST";
import { hideDpoTokenError } from "actions/DPOToken";
import { hideRequestDpoMessage, hideRequestDpoSuccessMessage, requestDpoLoader, getDpoRequestData, requestDpoReset, showRequestDpoError } from "actions/DPOPayment";
import { useLocation } from 'react-router-dom';
//import { createDPOToken, createDPOTokenLoader } from 'actions/CreateDPOTokenAction';
//import { httpGet, httpPost } from "util/REST";


const getData = (data) => {
  let dropDown = [];
  let policyList = data && data.policyListByOmang ? data.policyListByOmang : undefined;
  if (policyList !== undefined) {
    policyList.map((policy) => {
      let policyNumber = policy.policyNumber;
      let policyName = policy.policyName;
      return dropDown.push({ policyName, policyNumber });
    });
  }
  return dropDown
}
const MyPolicies = ({ match }) => {

  const location = useLocation();

  const { loader, myPoliciesData, alertMessage, showMessage, statusCode } = useSelector(({ myPoliciesDetails }) => myPoliciesDetails);
  const { dpoLoader, dpoAlertMessage, dpoShowSuccess, dpoShowMessage, dpoPayData, dpoPayStatus } = useSelector(({ dpoPaymentReducer }) => dpoPaymentReducer)
  const { dpoTokenLoader, showTokenMessage,  dpoTokenAlertMessage, dpoTokenStatus } = useSelector(({ dpoTokenReducer }) => dpoTokenReducer)

  const dispatch = useDispatch();

  const [loaderOpen, setLoaderOpen] = useState(false);
  const [paySuccess, setPaySuccess] = useState(false);
  const [payError, setPayError] = useState(false);
  const [userPolicy, setUserPolicy] = useState('');

  const omang = localStorage.getItem('omangNumber') ? JSON.parse(localStorage.getItem('omangNumber')) : null


  const userRole = JSON.parse(localStorage.getItem("role"));
  console.log("User Role: ", userRole);

  let dropDownData = getData(myPoliciesData)
  console.log("policy data: ", dropDownData);

  useEffect(() => {
    // Extract query parameters from the URL
    const params = new URLSearchParams(location.search);
    const transID = params.get('TransID'); // Get TransID from URL
    const ccdApproval = params.get('CCDapproval'); // Get CCDapproval from URL
    const policyCode = params.get('PnrID');
    // Call your backend API with the parameters if they exist
    if (transID && policyCode) {
      
      console.log("policyNumber: ",policyCode)

      let data = {
        policyNumber: policyCode,
        transToken: transID
      }
      dispatch(requestDpoLoader())
      dispatch(getDpoRequestData(data))

    }
  }, [location]);


  useEffect(() => {
    if (myPoliciesData) {
      console.log("policies data: ", myPoliciesData)
      console.log("policies data array: ", myPoliciesData.policyListByOmang)

    }
  }, [myPoliciesData])

  useEffect(() => {
    if (userPolicy) {
      console.log("selected user policy: ", userPolicy)
    }
  }, [])

  useEffect(() => {

    if (showMessage) {
      setTimeout(() => {
        dispatch(hidePoliciesDetailsErrorMessage());
      }, 100);
    }
  }, [showMessage])

  useEffect(() => {

    if (showTokenMessage) {
      setTimeout(() => {
        dispatch(hideDpoTokenError());
      }, 100);
    }
  }, [showTokenMessage])

  useEffect(() => {

    if (dpoShowSuccess) {
      setTimeout(() => {
        dispatch(hideRequestDpoSuccessMessage());
      }, 100);
    }

    return () => dispatch(requestDpoReset())

  }, [dpoShowSuccess])

  useEffect(() => {

    if (dpoShowMessage) {
      setTimeout(() => {
        dispatch(hideRequestDpoMessage());
      }, 300);
    }

    return () => dispatch(requestDpoReset())

  }, [dpoShowMessage])

  useEffect(() => {
    if (statusCode === 401) {
      toastMsgs.error(alertMessage);
      setTimeout(() => dispatch(userSignOut()), 300)
    }
  }, [statusCode])

  useEffect(() => {
    console.log("Omang number: ", omang)
    dispatch(onShowPoliciesDetailsLoader())
    dispatch(myPoliciesDetails(localStorage.getItem('omangNumber') ? JSON.parse(localStorage.getItem('omangNumber')) : null))
    return () => dispatch(hidePoliciesDetailsErrorMessage());

  }, [dispatch]);

  /*
  useEffect(() => {

    if (dpoPayStatus > 200) {
      //setPaySuccess(true)
      setTimeout(() => {
        dispatch(showRequestDpoError())
      }, 300)
    }
    else {
      console.log("dpo success: ",dpoAlertMessage)
    }

    return () => dispatch(requestDpoReset())

  }, [dpoPayStatus])
*/

  return (
    <>
      <ContainerHeader match={match} />

      <div className='col-md-12'>
        <div className="d-flex flex-row justify-content-between align-items-center policies-head-text">
          <h2 className="mr-2">My Policies</h2>
        </div>

        <div className="">

          {
            loaderOpen &&
            <div className="loader-view">
              <CircularProgress />
            </div>
          }

          {
            dpoTokenLoader &&
            <div className="loader-view">
              <CircularProgress />
            </div>
          }

          {dpoLoader &&
            <div className="loader-view">
              <CircularProgress />
            </div>
          }

          {dpoShowMessage && <ErrorToaster message={dpoAlertMessage} />}

          {dpoShowSuccess && <SuccessToaster message={dpoPayData} />}

          {showTokenMessage && <ErrorToaster message={dpoTokenAlertMessage} />}

          <>
            {myPoliciesData &&
              myPoliciesData.policyListByOmang &&
              myPoliciesData.policyListByOmang.length < 1 &&
              !loader ?
              <div>No data found</div> :
              myPoliciesData && myPoliciesData.policyListByOmang.map((data, index) =>
                <>
                  <PolicySection key={index} dropDownData={dropDownData} data={data} omang={omang} openLoader={() => setLoaderOpen(true)}
                    closeLoader={() => setLoaderOpen(false)}
                  />
                </>
              )}

          </>

        </div>

        {loader &&
          <div className="loader-view">
            <CircularProgress />
          </div>
        }

        {showMessage && <ErrorToaster message={alertMessage} />}

        <ToastContainer />
      </div>
    </>
  );
}

export default MyPolicies;
