import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const KYCNotification = ({ message }) => {
  const history = useHistory();

  return (
    <>
      <Dialog fullWidth open={true} className="custom-confirm-dialog">
        <DialogContent style={{ alignContent: "center" }}>
          <DialogContentText className="custom-details">
            <div
              className="table-responsive-material markt-table"
              style={{ border: "none" }}
            >
              <table className="table default-table custom-details-table table-sm full-table remove-table-border table-hover mb-0">
                <tbody>
                  <tr>
                    <td className="text-dark">{message}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirm-btns">
          <Button
            onClick={() => history.push("/home/my-policies")}
            className="no"
          >
            Go to My Policies
          </Button>
        </DialogActions>
      </Dialog>
      {/*  
      <Card className='notification'>
        <CardContent>
            <Typography>{message}</Typography>
        </CardContent>
      </Card>
      */}
    </>
  );
};

export default KYCNotification;
