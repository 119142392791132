import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import { Link } from "react-router-dom";

const UserInfo = () => {
  const dispatch = useDispatch();

  const [anchorE1] = useState(null);
  const [open, setOpen] = useState(false);

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div className="logo-con d-flex flex-row align-items-center justify-content-center">
      {/* <Avatar
        alt='...'
        src={"https://via.placeholder.com/150x150"}
        className="user-avatar "
      /> */}
      <div className="user-detail mt-2">
        {/* <h4 className="user-name" onClick={handleClick}>Robert Johnson <i
          className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
        </h4> */}

        <Link className="app-logo mr-2 d-sm-block" to="/">
          <img
            src={require("assets/images/botswana-logo1.jpg")}
            alt="SAis-BLIL"
            title="SAis-BLIL"
          />
        </Link>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>
        <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.setting" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRequestClose();
            dispatch(userSignOut());
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;
