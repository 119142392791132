/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, PropTypes, useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getContactInfo } from "actions/MyProfile";
import { hideErrorMessage } from "actions/Common";
import ScrollToTop from "util/ScrollToTop";
import { isMobile } from "react-device-detect";
import { CircularProgress } from "@material-ui/core";
import { history } from "store";
import autoTable from "jspdf-autotable";

// download html2canvas and jsPDF and save the files in app/ext, or somewhere else
// the built versions are directly consumable
// import {html2canvas, jsPDF} from 'app/ext';

export default function SamplePdf(props) {
  const dispatch = useDispatch();
  const [loadingPdf, setloadingPdf] = useState(false);
  const { loading, error } = useSelector(({ commonData }) => commonData);

  const { contactInfoData } = useSelector(({ myProfile }) => myProfile);

  const {
    location: { data, year, policyNumber, dropDownData },
  } = props;

  // useEffect(() => () => { // <-- Now we return the useEffect teardown effect, which will be fired only after the path/search change for the first time
  //   try {
  //     // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth',
  //     });
  //   } catch (error) {
  //     // just a fallback for older browsers
  //     window.scrollTo(0, 0);
  //   }
  // }, [props.pathname]);

  useEffect(() => {
    dispatch(
      getContactInfo(
        localStorage.getItem("omangNumber")
          ? JSON.parse(localStorage.getItem("omangNumber"))
          : null
      )
    );
    // return () => {
    //   cleanup
    // }
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
      }, 300);
    }
  }, [error]);

  const printDocument = () => {
    const pdf = new jsPDF({
      orientation: "portrait",
      compress: true,
    });
    const input = document.getElementById("divToPrint1");

    setloadingPdf(true);

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.5);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      var elem = document.getElementById("table");
      var data = pdf.autoTableHtmlToJson(elem);
      // console.log(data.columns,"data.columns")
      var option = [];
      option = data.columns.map((i) => {
        return i.header && i.header.content && i.header.content === "Month"
          ? { headStyles: { halign: "center" } }
          : i.header &&
            i.header.content &&
            i.header.content === "Expected Premium (P)"
          ? { headStyles: { halign: "right" } }
          : i.header &&
            i.header.content &&
            i.header.content === "Paid Amount (P)"
          ? { headStyles: { halign: "right" } }
          : i.header && i.header.content && i.header.content === "Paid Date"
          ? { headStyles: { halign: "center" } }
          : i.header && i.header.content && i.header.content === "Pay Mode"
          ? { headStyles: { halign: "left" } }
          : i.header &&
            i.header.content &&
            i.header.content === "Payment Status"
          ? { headStyles: { halign: "left" } }
          : null;
      });

      pdf.autoTable(
        data.columns,
        data.rows,

        {
          theme: "grid",
          styles: {
            fontSize: 8,
          },
          columnStyles: {
            0: { halign: "center" },
            1: { halign: "right" },
            2: { halign: "right" },
            3: { halign: "center" },
            4: { halign: "left" },
          },
          5: { halign: "left" },
          headStyles: {
            fillColor: "#36aed3",
            fontColor: "black",
            lineWidth: 0.1,
            lineColor: "gray",
            // textColor:'black',
            halign: "center",
          },
          startY: 100,
        }
      );
      pdf.save(`PS_${policyNumber}_${moment().format("YYYYMMDDhhmmss")}.pdf`);
      setloadingPdf(false);

      // var blob = pdf.output("blob");
      // blob.name = `SAiS_${moment().format("MMMM_Do_YYYY_h_mm_ss")}.pdf`;
      // window.open(URL.createObjectURL(blob));
    });

    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF({ compress: true });
    //   pdf.addImage(imgData, "JPEG", 0, 0);
    //   // pdf.output('dataurlnewwindow');
    //   setloadingPdf(false);
    //   pdf.save(`SAiS_${moment().format("MMMM_Do_YYYY_h_mm_ss")}.pdf`);
    // });
  };

  let sum = data ? (a) => a.reduce((x, y) => x + y) : 0;

  useEffect(() => {
    if (!data) {
      props.history.push("/home/my-policies");
    }
  }, [props.history]);

  let totalP = data ? sum(data.map((x) => Number(x.amount))) : 0;

  function getCommaSeparated(number) {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const printpage = () => {
    var printButton = document.getElementById("printbtn");
    printButton.style.visibility = "hidden";
    window.print();
    printButton.style.visibility = "visible";
  };
  return (
    <ScrollToTop>
      <div style={{ overflow: "auto" }} className="download-btns">
        <Button
          className="ml-4"
          onClick={() =>
            history.push("/home/my-policies/policy-details", {
              policyNumber,
              dropDownData,
            })
          }
          id="back"
          color="primary"
          variant="contained"
        >
          Back
        </Button>
        {isMobile ? (
          <div className="mt-2 ml-4">
            <Button
              disabled={loadingPdf}
              id="printbtn"
              color="primary"
              variant="contained"
              onClick={printDocument}
            >
              Download PDF
            </Button>
          </div>
        ) : (
          <div className="mb5 mt-2 ml-4">
            <Button
              color="primary"
              variant="contained"
              disabled={loadingPdf}
              onClick={printDocument}
            >
              Download PDF
            </Button>
          </div>
        )}
        <div
          id="divToPrint1"
          className="mt4"
          style={{
            backgroundColor: "#fff",
            width: "210mm",
            minHeight: "290mm",
            margin: "30px",
            padding: "20px 50px",
            position: "relative",
          }}
        >
          <div className="custom-pdf-header">
            <div className="custom-pdf-logo">
              <img
                src={require("assets/images/botswana-logo1.jpg")}
                alt="SAis-BLIL"
                title="SAis-BLIL"
              />
            </div>
            <div className="custom-pdf-address" style={{ marginTop: 40 }}>
              <p style={{ color: "#289bca", marginRight: 15, fontSize: 12 }}>
                Plot 66458 Fairground Office Park
                <br />
                Private Bag 00296 Gaborone Botswana
                <br />
              </p>
              <p style={{ color: "#289bca", marginRight: 15, fontSize: 12 }}>
                T +267 3645100/3951791
                <br />
                F +267 3905884
                <br />
                www.botswanalife.co.bw
              </p>
            </div>
          </div>
          <h3
            style={{ textAlign: "center" }}
          >{`PREMIUM PAID STATEMENT FOR THE YEAR ${
            year ? year : moment().format("DD/MM/YYYY")
          }`}</h3>

          <table
            style={{ width: "94%", margin: "8px auto", textAlign: "center" }}
          >
            <thead>
              <tr>
                <th style={{ color: "black" }}>Policy Number:</th>
                <th style={{ color: "black" }}>Customer Name:</th>
                <th style={{ color: "black" }}>Commencement Date:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{policyNumber ? policyNumber : null}</td>
                <td>{contactInfoData && contactInfoData.name}</td>
                <td>
                  {data && data[0].doc
                    ? moment(data[0].doc).format("DD/MM/YYYY")
                    : null}
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ margin: "8px 24px" }}>
            All the below payments are subject to cheque's realisation, where
            applicable.
          </p>
          <p style={{ margin: "8px 24px" }}>
            Total amount received toward premium for the policies listed above
            is P {getCommaSeparated(totalP)} for the year {year}.
          </p>
          <p style={{ margin: "8px 24px" }}>{`Date of issue ${moment().format(
            "DD/MM/YYYY"
          )}.`}</p>
          <footer className="custom-pdf-footer">
            <p style={{ marginLeft: "61px" }}>
              A wholly owned subsidiary of Botswana Insurance Holdings Limited
              (BIHL)
            </p>
            <p style={{ marginLeft: "61px" }}>
              {" "}
              DIRECTORS: **John Hinchliffe (Chairman), Catherine
              Lesetedi-Letegele (CEO), Patient Thuto, Thapelo Lippe,*, *Lizelle
              Nel, *Heinie Werth (* South African **British) Incorporated in the
              Republic of Botswana, Company Registration No: Co.1644 VAT No.
              C00796301112
            </p>
          </footer>
        </div>
      </div>
      <div
        style={{ overflow: "auto", position: "relative", top: "-736px" }}
        className="custom-download-pdf-table"
      >
        <table
          id="table"
          border="1"
          style={{ width: "60%", margin: "0px 70px" }}
        >
          <thead>
            <tr>
              <th style={{ textAlign: "center", color: "black" }}>Month</th>

              <th
                style={{
                  textAlign: "right",
                  color: "black",
                  padding: "0px 6px",
                  width: 100,
                }}
              >
                Expected Premium (P)
              </th>

              <th
                style={{
                  textAlign: "right",
                  color: "black",
                  padding: "0px 6px",
                }}
              >
                Paid Amount (P)
              </th>

              <th
                style={{
                  textAlign: "center",
                  color: "black",
                }}
              >
                Paid Date
              </th>

              <th
                style={{
                  textAlign: "left",
                  color: "black",
                  padding: "0px 6px",
                }}
              >
                Payment Mode
              </th>

              <th
                style={{
                  textAlign: "left",
                  color: "black",
                  padding: "0px 6px",
                }}
              >
                Payment Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              data
                .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
                .map((i) => {
                  return (
                    <tr key={i.receivedDate} style={{ color: "black" }}>
                      <td style={{ textAlign: "center" }}>
                        {moment(i.dueDate).format("MMM-YYYY")}
                      </td>

                      <td style={{ textAlign: "right", padding: "0px 6px" }}>
                        {getCommaSeparated(i.expectedPremium)}
                      </td>

                      <td style={{ textAlign: "right", padding: "0px 6px" }}>
                        {getCommaSeparated(i.amount)}
                      </td>

                      <td style={{ textAlign: "center" }}>
                        {i.amount !== "0"
                          ? moment(i.receivedDate).format("DD/MM/YYYY")
                          : "N/A"}
                      </td>

                      <td style={{ textAlign: "left", padding: "0px 6px" }}>
                        {i.amount !== "0" ? i.paymode : "N/A"}
                      </td>

                      <td style={{ textAlign: "left", padding: "0px 6px" }}>
                        {i.amount !== "0" ? i.status : "N/A"}
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div>
        {(loading || loadingPdf) && (
          <div className="loading-view">
            <CircularProgress />
          </div>
        )}
      </div>
    </ScrollToTop>
  );
}
