import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  AVAILABLE_FEEDBACK_DATA,
  FEEDBACK_QUESTION_DATA,
  FEEDBACK_QUESTION_FAILURE,
  FEEDBACK_STATUS,
  SUBMIT_STATUS,
  FEEDBACK_LOADER,
  RESET_FEEDBACK,
  FEEDBACK_ERROR,
} from "constants/ActionTypes";
import { httpGet, httpPost } from "util/REST";

export const getFeedbackReset = () => {
  return {
    type: RESET_FEEDBACK,
  };
};

export const getFeedbackInfo = (data) => {
  return async function (dispatch) {
    dispatch({ type: FETCH_START });
    httpPost("getEligibleCustomersForFeedback_V2", data)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: AVAILABLE_FEEDBACK_DATA,
            payload: data.response.eligibleFeedback
              ? data.response.eligibleFeedback
              : [],
          });
        } else if (data.status > 200) {
          dispatch({ type: FEEDBACK_STATUS, payload: data.status });
          dispatch({ type: FEEDBACK_QUESTION_FAILURE });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.response });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getFeedbackQuestions = (
  VisitFeedback,
  MOBILE,
  customerID,
  CustomerID,
  contactNumber,
  en,
  typeVisitFeedback,
  visitToken
) => {
  return async function (dispatch) {
    dispatch({ type: FETCH_START });
    httpGet(
      `getFeedbackQuestions?feedBackType=${VisitFeedback}&channel=${MOBILE}&customerID=${customerID}&customerIDType=${CustomerID}&contactNumber=${contactNumber}&languagePrefix=${en}&type=${typeVisitFeedback}&visitToken=${visitToken}`
    )
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: FEEDBACK_QUESTION_DATA,
            payload: data.response.feedbackquestions
              ? data.response.feedbackquestions
              : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: FEEDBACK_QUESTION_FAILURE });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const submitFeedback = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("submitFeedback", data).then((data) => {
      if (data.status === 200) {
        dispatch({ type: FETCH_SUCCESS, payload: data.response });
        dispatch({ type: SUBMIT_STATUS, payload: data.status });
      } else {
        dispatch({ type: SUBMIT_STATUS, payload: data.status });
        dispatch({ type: FEEDBACK_ERROR, payload: data.response.Description });
      }
    });
  };
};

export const showFeedbackLoader = () => {
  return {
    type: FEEDBACK_LOADER,
  };
};
