import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import ContainerHeader from "../../../components/ContainerHeader/index";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DatePickers from "../../../containers/DatePickers";
import CardBox from "components/CardBox";
import {
  CircularProgress,
  Select,
  MenuItem,
  Input,
  Grid,
  Tooltip,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PaymentDetail from "./PaymentDetail";
import RewardCards from "./RewardCards";
import {
  getBankDetails,
  resetBankDetails,
  getBankDetailsLoader,
} from "actions/BankDetailsHolder";
import {
  getRewardDetails,
  getRewardDetailsLoader,
} from "actions/LifeRewardDetails";
import {
  getReporterDetails,
  resetReporterDetailsData,
} from "actions/GetReporterDetailsAction";
import {
  myPoliciesDetails,
  onShowPoliciesDetailsLoader,
  hidePoliciesDetailsErrorMessage,
} from "actions/MyPoliciesDetails";

const PaymentInfo = ({ match }) => {
  const dispatch = useDispatch();

  const { bankLoader, bankDetailsHolder } = useSelector(
    ({ bankDetails }) => bankDetails
  );

  const { rewardDetailsLoader, rewardDetailsHolder } = useSelector(
    ({ rewardDetails }) => rewardDetails
  );

  const { loader, myPoliciesData, alertMessage, showMessage, statusCode } =
    useSelector(({ myPoliciesDetails }) => myPoliciesDetails);

  const omang = JSON.parse(localStorage.getItem("omangNumber"));

  const [accountName, setAccountName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyCode, setPolicyCode] = useState("");

  useEffect(() => {
    dispatch(myPoliciesDetails(omang));
    dispatch(resetBankDetails());
  }, []);

  useEffect(() => {
    if (myPoliciesData.length > 0) {
      console("policies: ", myPoliciesData);
    }
  }, []);

  useEffect(() => {
    dispatch(getRewardDetailsLoader());
    dispatch(getRewardDetails(omang));
  }, []);

  useEffect(() => {
    if (bankDetailsHolder) {
      console.log("bank details: ", bankDetailsHolder);
    }
  }, []);

  const getBank = (event) => {
    setPolicyCode(event.target.value);
    dispatch(getBankDetailsLoader());
    console.log("policy number: ", policyCode);
    dispatch(getBankDetails(event.target.value, omang));
  };

  return (
    <>
      <ContainerHeader match={match} />

      <h3 className="card-title">Bank Details</h3>

      <div
        className="col-md-6 col-sm-12"
        style={{ padding: "10px 0px 20px 0px" }}
        required
      >
        <span>Select Policy</span>
        <Select
          id="idType"
          name="idType"
          style={{ width: "100%" }}
          onChange={getBank}
          value={policyCode}
          // defaultValue="none"
          disabled={
            myPoliciesData && myPoliciesData.policyListByOmang.length > 0
              ? false
              : true
          }
        >
          {myPoliciesData &&
            myPoliciesData.policyListByOmang.map((policy) => (
              <MenuItem value={policy.policyNumber}>
                {policy.policyNumber}
              </MenuItem>
            ))}
        </Select>
      </div>

      {bankDetailsHolder.length > 0 ? (
        bankDetailsHolder
          .filter((item) => item.accType !== "Prepaid Card")
          .map((bankItem) => (
            <PaymentDetail
              accName={accountName}
              name={`${bankItem.accName}`}
              handleChange={(event) => setAccountName(event.target.value)}
              accType={accountType}
              type={`${bankItem.accType}`}
              //handleTypeChange={(event) => setAccountType(event.target.value)}
              bankAccount={bankAccount}
              bank={`${bankItem.bankAccount}`}
              //handleBankChange={(event) => setBankAccount(event.target.value)}
              bankName={bankName}
              nameBank={`${bankItem.bankName}`}
              //handleNameChange={(event) => setBankName(event.target.value)}
              bankBranch={branchName}
              branchName={`${bankItem.branch}`}
              //handleBranchChange={(event) => setBranchName(event.target.value)}
              policyNumber={policyNumber}
              policyCode={`${bankItem.policyCode}`}
              //handlePolicyChange={(event) => setPolicyNumber(event.target.value)}
              handleBlur={""}
            />
          ))
      ) : (
        <div>There are no bank details available</div>
      )}

      <h3 className="card-title" style={{ marginTop: "15px" }}>
        Life Rewards Cards
      </h3>

      {rewardDetailsHolder.length > 0 ? (
        rewardDetailsHolder.map((reward) => (
          <RewardCards
            accName={""}
            name={`${reward.accHolderName}`}
            accStatus={""}
            type={`${reward.accountStatus}`}
            cardUserNumber={""}
            userCardNumber={`${reward.cardNumber}`}
            idUserNumber={""}
            userIDNumber={`${reward.idNumber}`}
            bankName={""}
            nameBank={`${reward.bankName}`}
            bankBranch={""}
            branchCode={`${reward.branchCode}`}
            idCustomer={""}
            customerID={reward.idNumber}
            expiryDate={""}
            dateExpiry={`${reward.expiryDate}`}
          />
        ))
      ) : (
        <div>Life reward cards are not available</div>
      )}

      {bankLoader && (
        <div className="appointment-loading-view">
          <CircularProgress />
        </div>
      )}

      {rewardDetailsLoader && (
        <div className="appointment-loading-view">
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default PaymentInfo;
