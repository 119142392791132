import {
  USER_REGISTRATION_REQUEST,
  USER_REQUEST_REGISTRATION_SUCCESS,
  HIDE_REQUEST_REGISTRATION_ERROR_MESSAGE,
  ON_REQUEST_SHOW_REGISTRATION_LOADER,
  USER_REQUEST_REGISTRATION_ERROR_MESSAGE,
  USER_REGISTRATION_REQUEST_RESET,
} from "constants/ActionTypes";

export const registrationRequestData = (data) => {
  return {
    type: USER_REGISTRATION_REQUEST,
    payload: data,
  };
};

export const requestRegistrationSuccess = (data) => {
  return {
    type: USER_REQUEST_REGISTRATION_SUCCESS,
    payload: data,
  };
};

export const showRequestRegistrationError = (message) => {
  return {
    type: USER_REQUEST_REGISTRATION_ERROR_MESSAGE,
    payload: message,
  };
};

export const hideRequestRegistrationMessage = () => {
  return {
    type: HIDE_REQUEST_REGISTRATION_ERROR_MESSAGE,
  };
};

export const requestRegistrationLoader = () => {
  return {
    type: ON_REQUEST_SHOW_REGISTRATION_LOADER,
  };
};
export const requestRegistrationReset = () => {
  return {
    type: USER_REGISTRATION_REQUEST_RESET,
  };
};
