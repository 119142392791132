import React, { Component } from "react";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // localStorage.removeItem('user_id');
    // localStorage.removeItem('token');
    // localStorage.removeItem('role');
    // this.props.postErrorLog(error, info); to add client-log
  }

  handleClick = () => {
    window.location.replace("/signin");
    localStorage.removeItem("user_id");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
  };
  render() {
    const { errorMessage = "System is under maintenance." } = this.props;
    if (this.state.hasError) {
      return (
        <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
          <div style={{ marginTop: "100px" }} className="page-error-content">
            {/*<div className="error-code mb-4 animated zoomInDown">500</div>*/}
            <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
              {errorMessage}
            </h2>
            <p
              className="text-center zoomIn animation-delay-20 animated"
              onClick={() => this.handleClick()}
            >
              Sign In Again
            </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
