import React from "react";
import AdminKYCTable from "./AdminKYCTable";
import ContainerHeader from "../../../components/ContainerHeader/index";

const AdminKYCPage = ({ match }) => {
  return (
    <>
      <ContainerHeader match={match} />
      <div className="row mb-md-3 custom-validate-toolbar">
        <AdminKYCTable />
      </div>
    </>
  );
};

export default AdminKYCPage;
