import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";

const getDisplayString = (sub) => {
  const arr = sub.split("-");
  if (arr.length > 2) {
    return (
      arr[0].charAt(0).toUpperCase() +
      arr[0].slice(1) +
      " " +
      arr[1].charAt(0).toUpperCase() +
      arr[1].slice(1) +
      " " +
      arr[2].charAt(0).toUpperCase() +
      arr[2].slice(1)
    );
  } else if (arr.length > 1) {
    return (
      arr[0].charAt(0).toUpperCase() +
      arr[0].slice(1) +
      " " +
      arr[1].charAt(0).toUpperCase() +
      arr[1].slice(1)
    );
  } else {
    return sub.charAt(0).toUpperCase() + sub.slice(1);
  }
};
const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return null;
  } else {
    return "/home/" + sub;
  }
};

const ContainerHeader = ({ title, match }) => {
  const path = match.path.substr(1);
  const subPath = path.split("/");
  return (
    <div className="page-heading custom-page-heading">
      <h2 className="title mb-3 mb-sm-0">{title}</h2>

      <Breadcrumb className="mb-0 custom-nav" tag="nav">
        {subPath.map((sub, index) => {
          return (
            <BreadcrumbItem active={subPath.length === index + 1} key={index}>
              {subPath.length === index + 1 ? (
                <span>{getDisplayString(sub)}</span>
              ) : getUrlString(path, sub, index) ? (
                <Link to={getUrlString(path, sub, index)}>
                  <span>{getDisplayString(sub)}</span>
                </Link>
              ) : (
                <span>{getDisplayString(sub)}</span>
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default ContainerHeader;
