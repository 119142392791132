import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const SetupPasswordDialog = (props) => {
  const { open } = props;

  const handleRequestClose = () => {
    props.handleRequestClose();
  };

  const handleSubmit = () => {
    window.location.replace("/signin");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleRequestClose}
        className="custom-confirm-dialog"
      >
        <DialogTitle className="custom-dialog-title">
          {"Congratulations!"}
        </DialogTitle>
        <DialogContent className="custom-dialog-content">
          <DialogContentText className="custom-dialog-text">
            You have successfully set the password for your account. Please sign
            in with the new password.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirm-btns">
          <Button
            variant="contained"
            color="primary"
            className="text-white"
            onClick={handleSubmit}
          >
            Sign In
          </Button>
        </DialogActions>
        {/* <DialogActions>
                        <Button variant="contained" color="primary" className="text-white">
                            <a href="/signin" target="_blank"><span style={{ color: '#fff' }} className="d-inline-block">Login</span></a>
                        </Button>
                    </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default SetupPasswordDialog;
