import {
  BOOKING_GET_SERVICES_SUCCESS,
  BOOKING_GET_SERVICES_ERROR_MESSAGE,
  BOOKING_GET_SERVICES_LOADER,
  HIDE_BOOKING_GET_SERVICES_ERROR_MESSAGE,
  BOOKING_GET_SERVICES_RESET,
} from "constants/ActionTypes";

const INIT_STATE = {
  getServicesLoader: false,
  getServicesAlertMessage: "",
  getServicesShowMesssage: false,
  getServicesInfo: [],
  getServicesStatusCode: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BOOKING_GET_SERVICES_SUCCESS: {
      return {
        ...state,
        getServicesLoader: false,
        getServicesInfo: action.payload,
      };
    }

    case BOOKING_GET_SERVICES_ERROR_MESSAGE: {
      return {
        ...state,
        getServicesAlertMessage: action.payload.message
          ? action.payload.message
          : "",
        getServicesShowMesssage: true,
        getServicesLoader: false,
        getServicesStatusCode: action.payload.status
          ? action.payload.status
          : "",
      };
    }

    case HIDE_BOOKING_GET_SERVICES_ERROR_MESSAGE: {
      return {
        ...state,
        getServicesAlertMessage: "",
        getServicesShowMesssage: false,
        getServicesLoader: false,
        getServicesStatusCode: null,
      };
    }

    case BOOKING_GET_SERVICES_LOADER: {
      return {
        ...state,
        getServicesLoader: true,
      };
    }

    case BOOKING_GET_SERVICES_RESET: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case "resetLoader": {
      return {
        ...state,
        loader: false,
      };
    }

    default:
      return state;
  }
};
