import React from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormHelperText, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  helper3: {
    flexDirection: "row",
  },
  radio: {
    "&$checked": {
      color: "#0066ab",
    },
  },
  checked: {},
  helper4: {
    paddingRight: 0,
  },
  helper5: {
    paddingRight: 20,
  },
});

const FormikRadioGroup = ({
  field,
  form: { touched, errors },
  name,
  options,
  ...props
}) => {
  const classes = useStyles();
  const fieldName = name || field.name;

  return (
    <React.Fragment>
      <RadioGroup
        className={classes.helper3}
        {...field}
        {...props}
        name={fieldName}
      >
        {options.map((option) => (
          <FormControlLabel
            classes={{
              root: classes.formControlLabelRoot,
              label: classes.formControlLabel,
            }}
            className={classes.helper5}
            value={option}
            control={
              <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
                className={classes.helper4}
              />
            }
            label={option}
          />
        ))}
      </RadioGroup>

      {touched[fieldName] && errors[fieldName] && (
        <FormHelperText style={{ color: "red" }}>
          {errors[fieldName]}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};
export default FormikRadioGroup;
