import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContainerHeader from "../../../components/ContainerHeader/index";
import { ErrorToaster, toastMsgs, SuccessToaster } from "util/ReactToastify";
import dayjs from "dayjs";
import moment from "moment";
import {
  DialogTitle,
  Dialog,
  Tooltip,
  CircularProgress,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
/*import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';;
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';*/
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
//import {getBookingBranches, showBookingBranchesLoader, getBranchReset} from "actions/BookingGetBranches"
import {
  getBookingBranches,
  getBookingServices,
  getAvailableDays,
  getAvailableTimes,
  showAppointmentLoader,
  getAppointment,
  resetAppointmentLoader,
  getAppointmentHistory,
  getAppointmentReset,
} from "actions/MakeBooking";
import { getContactInfo } from "actions/MyProfile";
import BookingHistory from "./BookingHistory";

const isWeekend = (date) => {
  const day = date.day();

  return day === 0 || day === 6;
};

const Appointments = ({ match }) => {
  const dispatch = useDispatch();

  const {
    bookingData,
    servicesData,
    availableDaysData,
    availableTimesData,
    appointmentLoader,
    appointmentData,
    appointmentStatus,
    appointmentMessage,
    appointmentHistory,
    nearestAvailableTimes,
  } = useSelector(({ getBookingAppoinments }) => getBookingAppoinments);

  const { contactInfoData } = useSelector(({ myProfile }) => myProfile);

  const [value, setValue] = React.useState(dayjs());
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const mobileNumber = JSON.parse(localStorage.getItem("user_id"));
  const omangNumber = JSON.parse(localStorage.getItem("omangNumber"));
  console.log("mobile number: ", mobileNumber);

  const handleBranchSelect = (event) => {
    setSelectedBranch(event.target.value);
    console.log("selectd branch: ", selectedBranch);
    dispatch(showAppointmentLoader());
    dispatch(
      getBookingServices({
        languagePrefix: "en",
        segmentIdentification: " ",
        serviceGroupID: 0,
        mustBeAvailableforAppointment: false,
        mustBeAvailableforWalkin: false,
        departmentID: 0,
        branchID: event.target.value,
        services: null,
      })
    );
  };

  const handleServiceSelect = (event) => {
    setSelectedService(event.target.value);
    console.log("selectd branch: ", selectedBranch);
    dispatch(showAppointmentLoader());
    dispatch(
      getAvailableDays({
        branchID: selectedBranch,
        serviceID: event.target.value,
        availableAppointmentDays: null,
      })
    );
  };

  const handleDateSelect = () => {
    //setSelectedDate(event.target.value)
    //console.log("selectd branch: ",selectedDate)
    dispatch(showAppointmentLoader());
    dispatch(
      getAvailableTimes({
        branchID: selectedBranch,
        serviceID: selectedService,
        appointmentDay: selectedDate.toLocaleDateString("en-GB").slice(0, 10),
        validDayAppointmentTimes: null,
      })
    );
  };

  const handleTimeSelect = (event) => {
    setSelectedTime(event.target.value);
    console.log("selected time: ", event.target.value);
  };

  const handleAppointment = () => {
    dispatch(showAppointmentLoader());
    dispatch(
      getAppointment({
        languagePrefix: "en",
        branchID: selectedBranch,
        serviceID: selectedService,
        segmentIdentification: "",
        appointmentDay: selectedDate.toLocaleDateString("en-GB").slice(0, 10),
        appointmentTime: selectedTime,
        customerInfo: {
          additionalInfo: null,
          ID: contactInfoData.idNum,
          MobileNo: mobileNumber,
          Name: contactInfoData.name,
        },
        appointmentTicketInfo: null,
        nearestAppointmentTimes: null,
      })
    );
  };

  const dates = ["08/10/2022", "11/10/2022", "19/10/2022"];

  const customDayRenderer = (date, pickersDayProps) => {
    //console.log("picker date: ",date)
    const stringifiedDate = date.toLocaleDateString("en-GB").slice(0, 10);
    //console.log("stringified date: ",stringifiedDate)
    if (
      availableDaysData.length > 0 &&
      availableDaysData.includes(stringifiedDate)
    ) {
      //return <PickersDay {...pickersDayProps} />
      return false;
    } else {
      //return <PickersDay {...pickersDayProps} disabled />
      return true;
    }
  };

  const resetAppointment = () => {
    setSelectedBranch("");
    setSelectedService("");
    setSelectedDate("");
    setSelectedTime("");
  };

  useEffect(() => {
    dispatch(showAppointmentLoader());
    dispatch(
      getAppointmentHistory({
        languagePrefix: "en",
        branchId: 216,
        customerId: omangNumber,
        customerMobileNo: mobileNumber,
        appointmentTicketInfo: null,
      })
    );
    dispatch(
      getBookingBranches({
        languagePrefix: "en",
        cityId: 0,
        departmentId: 0,
        currentLongitude: 0,
        currentLatitude: 0,
        maxNumberOfBranchesToReturn: 0,
        maxDistanceOfBarnchesToReturn: 0,
        serviceIdForWakin: 0,
        serviceIdForAppointment: 0,
        branches: null,
        mustBeAvailableForAppointment: false,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getContactInfo(
        localStorage.getItem("omangNumber")
          ? JSON.parse(localStorage.getItem("omangNumber"))
          : null
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (contactInfoData) {
      console.log("policy holder info: ", contactInfoData.idNum);
      console.log("policy holder: ", contactInfoData.name);
    }
  });

  useEffect(() => {
    if (appointmentData.Code === 0) {
      toastMsgs.success("Your appointment has been made");
      resetAppointment();
    } else if (appointmentData.Code === -3) {
      toastMsgs.error(
        `Appointment time is not available. Available times are: ${nearestAvailableTimes}`
      );
    } else if (appointmentData.Code === -46) {
      console.log("Appointment status: ", appointmentData);
      toastMsgs.error(appointmentMessage);
      resetAppointment();
    }
  }, [appointmentData]);

  useEffect(() => {
    if (appointmentData.Code === 0 || appointmentData.Code === -46) {
      setTimeout(() => {
        dispatch(
          getAppointmentHistory({
            languagePrefix: "en",
            branchId: 216,
            customerId: omangNumber,
            customerMobileNo: mobileNumber,
            appointmentTicketInfo: null,
          })
        );
        dispatch(showAppointmentLoader());
        dispatch(
          getBookingBranches({
            languagePrefix: "en",
            cityId: 0,
            departmentId: 0,
            currentLongitude: 0,
            currentLatitude: 0,
            maxNumberOfBranchesToReturn: 0,
            maxDistanceOfBarnchesToReturn: 0,
            serviceIdForWakin: 0,
            serviceIdForAppointment: 0,
            branches: null,
            mustBeAvailableForAppointment: false,
          })
        );
      }, 300);
    }

    return () => {
      dispatch(getAppointmentReset());
    };
  }, [dispatch, appointmentData]);

  /*
    useEffect(() => {
      if(selectedDate) {
        dispatch(getAvailableTimes({
          "branchID": selectedBranch,
          "serviceID": selectedService,
          "appointmentDay":selectedDate,
          "validDayAppointmentTimes":null
      }))
      }
    })
*/

  return (
    <>
      <ContainerHeader match={match} />

      <div className="date-picker-container">
        <div className="branch-picker">
          <InputLabel htmlFor="age-native-simple">Select Branch</InputLabel>
          <Select
            style={{ width: "100%" }}
            className="custom-dropdown-kyp"
            value={selectedBranch}
            onChange={handleBranchSelect}
          >
            {bookingData.length > 0
              ? bookingData.map((booking) => (
                  <MenuItem key={booking} value={booking.ID}>
                    {booking.Name}
                  </MenuItem>
                ))
              : null}
          </Select>

          <InputLabel style={{ marginTop: "50px" }} htmlFor="age-native-simple">
            Services
          </InputLabel>
          <Select
            disabled={servicesData.length > 0 ? false : true}
            style={{ width: "100%" }}
            className="custom-dropdown-kyp"
            value={selectedService}
            onChange={handleServiceSelect}
          >
            {servicesData.length > 0
              ? servicesData.map((service) => (
                  <MenuItem value={service.ID}>{service.Name}</MenuItem>
                ))
              : null}
          </Select>
        </div>

        <div className="appointments">
          {/*<LocalizationProvider dateAdapter={AdapterMoment}>
       <StaticDatePicker
        orientation="landscape"
        displayStaticWrapperAs="desktop"
        openTo="day"
        //shouldDisableDate={isWeekend}
        value={selectedDate}
        onChange={(value) => setSelectedDate(value)}
        //renderDay={customDayRenderer}
        shouldDisableDate={customDayRenderer}
        //renderInput={(params) => <TextField {...params} />}
        inputFormat="dd/MM/yyyy"
       />
      </LocalizationProvider>*/}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              color="#30302f"
              disabled={availableDaysData.length > 0 ? false : true}
              orientation="landscape"
              variant="static"
              openTo="date"
              format="dd/MM/yyyy"
              fullWidth
              value={selectedDate}
              onChange={(value) => setSelectedDate(value)}
              shouldDisableDate={customDayRenderer}
            />
          </MuiPickersUtilsProvider>

          <div
            style={{
              position: "relative",
              marginTop: "25px",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "25px",
            }}
          >
            <Button
              disabled={availableDaysData.length > 0 ? false : true}
              variant="contained"
              color="primary"
              onClick={handleDateSelect}
            >
              Set Date
            </Button>
          </div>
        </div>

        <div className="time-picker">
          <InputLabel htmlFor="age-native-simple">
            Available Time Slots
          </InputLabel>
          <Select
            disabled={availableTimesData.length > 0 ? false : true}
            style={{ width: "100%" }}
            className="custom-dropdown-kyp"
            value={selectedTime}
            onChange={handleTimeSelect}
          >
            {availableTimesData.length > 0
              ? availableTimesData.slice(96, 200).map((timeData) => (
                  <MenuItem key={timeData} value={timeData}>
                    {timeData}
                  </MenuItem>
                ))
              : null}
          </Select>

          <div
            style={{
              position: "relative",
              marginTop: "25px",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "25px",
            }}
          >
            <Button
              disabled={selectedTime ? false : true}
              variant="contained"
              color="primary"
              onClick={handleAppointment}
            >
              Set Appointment
            </Button>
          </div>
        </div>

        {appointmentLoader && (
          <div className="appointment-loading-view">
            <CircularProgress />
          </div>
        )}

        {appointmentStatus === 200 && appointmentData.Code === 0 && (
          <SuccessToaster message={"Your appointment has been made"} />
        )}

        {appointmentStatus === 200 && appointmentData.Code === -3 && (
          <ErrorToaster
            message={`Appointment time is not available. Available times are: ${nearestAvailableTimes}`}
          />
        )}

        {appointmentStatus === 200 && appointmentData.Code === -46 && (
          <ErrorToaster message={appointmentMessage} />
        )}
      </div>
      {appointmentHistory.length > 0 && (
        <div className="booking-history">
          <BookingHistory data={appointmentHistory} loading={""} />
        </div>
      )}
    </>
  );
};

export default Appointments;
