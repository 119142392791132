/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import ContainerHeader from "../../../components/ContainerHeader/index";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DatePickers from "../../../containers/DatePickers";
import CardBox from "components/CardBox";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomImageInput from "../MyClaims/CustomImageInput";
import * as Yup from "yup";
import { kycValidationMsgs } from "../../../constants/Validations";
import {
  claimsDocsUpload,
  edrmsFilesPush,
  restEdrmsFiles,
} from "actions/ClaimsDocsUpload";
import {
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  OutlinedInput,
  Input,
  Grid,
  Tooltip,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { ToastContainer } from "react-toastify";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import {
  getKycDetails,
  postKycDetails,
  resetKycDetails,
  setKycLoader,
  resetKycLoader,
} from "../../../actions/KycDetails";
import { hideErrorMessage } from "actions/Common";
import { ErrorToaster, SuccessToaster, toastMsgs } from "util/ReactToastify";
import { size, values } from "lodash";
import { withStyles } from "@material-ui/styles";
import PdfPreview from "../AdminPdfPage/PdfPreview";
import JpgPreview from "../AdminPdfPage/JPGPreview";
import { userSignOut } from "actions";
import {
  getKYCDocument,
  ResetKYCDocument,
} from "actions/KYCDocumentsPreviewAction";
import { httpGet, httpPost } from "util/REST";
import UploadFiles from "../MyClaims/UploadFile";
import { KycValidationSchema } from "./KycValidation";
import { getContactInfo } from "actions/MyProfile";
import KYCNotification from "./KYCNotification";
import { checkKyc } from "actions/KycCheck";

let subMessage = null;
export const kycSubMissionButtonHandler = (message) => {
  subMessage = message;
};

const Kyc = ({ props, match }) => {
  let countrycode = getCountries().sort(function (a, b) {
    if (en[a] < en[b]) return -1;
    else if (en[a] > en[b]) return 1;
    return 0;
  });
  const DarkerDisabledTextField = withStyles({
    root: {
      marginRight: 8,
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
      },
    },
  })(TextField);

  const CustomToolTip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 420,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const dispatch = useDispatch();

  const { getKycData, loader, postKycData } = useSelector(
    ({ kycData }) => kycData
  );
  console.log("dispatch inital values used in kyc page for kycData ");
  console.log("getKycData {} : " + getKycData);
  console.log("loader {} : " + loader);
  console.log("postKycData {} : " + postKycData);

  const { KycDocument } = useSelector(
    ({ kYCDocumentsPreviewReducer }) => kYCDocumentsPreviewReducer
  );

  console.log(
    "dispatch inital values used in kyc page for kYCDocumentsPreviewReducer "
  );
  console.log("KycDocument {} : " + KycDocument);

  const { message, error, loading, statusCode } = useSelector(
    ({ commonData }) => commonData
  );

  const { contactInfoData } = useSelector(({ myProfile }) => myProfile);

  //const userName = contactInfoData.name
  //let nameArray = contactInfoData.name.split("")

  console.log("dispatch inital values used in kyc page for commonData ");
  console.log("message :{} " + message);
  console.log("error :{} " + error);
  console.log("loading :{} " + loading);
  console.log("statusCode :{} " + statusCode);

  const { edrmsData } = useSelector(({ claimsDocsUpload }) => claimsDocsUpload);

  console.log("dispatch inital values used in kyc page for claimsDocsUpload ");
  console.log("edrmsData {} :" + edrmsData);

  const { kycStatus } = useSelector(({ KycStatusReducer }) => KycStatusReducer);
  console.log("dispatch inital values used in kyc page for KycStatusReducer");
  console.log("kycStatus {} :" + kycStatus);

  const { kycCheckStatus, kycCheckCode, checkKycMessage } = useSelector(
    ({ kycCheckData }) => kycCheckData
  );

  const [userInformation, setUserInformation] = useState(false);
  const [contactInformation, setContactInformation] = useState(false);
  const [correspondenceAddress, setCorrespondenceAddress] = useState(true);
  const [residentialAddress, setResidentialAddress] = useState(true);
  const [idProof, setIdProof] = useState(true);
  const [addressProof, setAddressProof] = useState(true);
  const [bankProof, setBankProof] = useState(true);
  const [open, setOpen] = useState(false);
  const [pdf, setPdf] = useState("");
  const [jpg, setJpg] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  const [marital, setMarital] = useState("");
  const [email, setEmail] = useState("");
  const [maritalCode, setMaritalCode] = useState();

  const [fileData, setFileData] = React.useState([]);

  const [IDProofFile, setIDProofFile] = React.useState(null);
  const [AddressProofFile, setAddressProofFile] = React.useState(null);
  const [BankProofFile, setBankProofFile] = React.useState(null);

  const today = new Date();

  const omang = localStorage.getItem("omangNumber")
    ? JSON.parse(localStorage.getItem("omangNumber"))
    : null;
  const policy = localStorage.getItem("omangNumber")
    ? JSON.parse(localStorage.getItem("omangNumber"))
    : null;

  const editUserInformation = () => {
    setUserInformation(true);
  };
  const cancelUserInformation = () => {
    setUserInformation(false);
  };
  const editContactInformation = () => {
    setContactInformation(true);
  };
  const cancelContactInformation = () => {
    setContactInformation(false);
  };
  const editCorrespondenceAddress = () => {
    setCorrespondenceAddress(false);
  };
  const cancelCorrespondenceAddress = () => {
    setCorrespondenceAddress(true);
  };
  const editResidentialAddress = () => {
    setResidentialAddress(false);
  };
  const cancelResidentialAddress = () => {
    setResidentialAddress(true);
  };

  const handleClickOpen = (pdf) => {
    setPdf(pdf);
    console.log("pdf: ", pdf);
    setOpen(true);
  };

  const handleImageOpen = (jpg) => {
    setJpg(jpg);
    console.log("jpg: ", jpg);
    setImageOpen(true);
  };

  const handleChangeMarital = (event) => {
    setMarital(event.target.value);
    console.log("marital value: ", event.target.value);
  };

  useEffect(() => {
    dispatch(checkKyc());
  }, []);

  useEffect(() => {
    //if(kycCheckStatus){
    console.log("kyc check: ", kycCheckStatus);
    //}
  }, [kycCheckStatus]);

  useEffect(() => {
    dispatch(
      getContactInfo(
        localStorage.getItem("omangNumber")
          ? JSON.parse(localStorage.getItem("omangNumber"))
          : null
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (KycDocument) {
      const pdfString = KycDocument.replace(/[\[\]']+/g, "");
      console.log("string set");
      if (pdfString.startsWith("/", 0)) {
        handleImageOpen(pdfString);
        console.log("base jpg: ", pdfString);
      } else {
        handleClickOpen(pdfString);
        console.log("base pdf: ", pdfString);
        console.log("base 64: ", KycDocument);
      }
    }
    return () => {
      dispatch(ResetKYCDocument());
      dispatch(restEdrmsFiles());
    };
  }, [KycDocument]);

  const handlePDFPreview = (type) => {
    dispatch(getKYCDocument(type));
  };

  const handleRequestClosePopUP = () => {
    setOpen(false);
    setImageOpen(false);
  };
  const [userIdNumber, setuserIdNumber] = useState("");
  const handleFile = (data) => {
    subMessage = null;
    if (data && data !== null) {
      setFileData([...fileData, { ...data, policyOmangNumber: userIdNumber }]);
    }
  };
  const [kycDocValidation, setKycDocValidation] = React.useState({
    KYCIDPROOF: false,
    KYCADDRESSPROOF: false,
    KYCBANKPROOF: false,
  });

  const { KYCIDPROOF, KYCADDRESSPROOF, KYCBANKPROOF } = kycDocValidation;

  useEffect(() => {
    if (fileData.length) {
      const docTypes = ["KYCIDPROOF", "KYCADDRESSPROOF", "KYCBANKPROOF"];

      let tempKycDocValidation = {
        KYCIDPROOF: false,
        KYCADDRESSPROOF: false,
        KYCBANKPROOF: false,
      };

      const uploadedDocTypes = fileData.map((item) => item.fileType);

      docTypes.forEach((item) => {
        if (!uploadedDocTypes.includes(item)) {
          tempKycDocValidation[item] = true;
        }
      });

      setKycDocValidation({
        ...kycDocValidation,
        ...tempKycDocValidation,
      });
    }
  }, [fileData]);

  const removeDoc = (fileType) => {
    const data = fileData.filter((item) => item.fileType !== fileType);
    setFileData(data);
  };

  const handleResetPage = () => {
    if (
      kycStatus.statusName !== "Fully-Compliant" &&
      document.getElementById("file1")
    )
      document.getElementById("file1").value = null;
    if (
      kycStatus.statusName !== "Fully-Compliant" &&
      document.getElementById("file2")
    )
      document.getElementById("file2").value = null;

    if (
      kycStatus.statusName !== "Fully-Compliant" &&
      document.getElementById("file3")
    )
      document.getElementById("file3").value = null;

    setCorrespondenceAddress(true);
    setResidentialAddress(true);
    dispatch(restEdrmsFiles());
    setFileData([]);
    setKycDocValidation({
      KYCIDPROOF: false,
      KYCADDRESSPROOF: false,
      KYCBANKPROOF: false,
    });
    setIDProofFile(null);
    setAddressProofFile(null);
    setBankProofFile(null);
    setUserInformation(false);
    setContactInformation(false);
    setCorrespondenceAddress(true);
    setResidentialAddress(true);
  };

  let statusId = "";
  if (kycStatus.statusName === "Fully-Compliant") {
    statusId = "3";
  } else if (kycStatus.statusName === "Half-Compliant") {
    statusId = "2";
  } else if (kycStatus.statusName === "Non-Compliant") {
    statusId = "1";
  } else {
    statusId = "1";
  }
  const status = kycStatus.statusName;
  const kycInfo = getKycData;
  const idTypes = [
    { key: "none", value: "Select ID Type", disable: true },
    { key: "Omang", value: "Omang" },
    { key: "Passport", value: "Passport" },
    { key: "Others", value: "Others" },
  ];

  const maritalID = () => {
    if (kycInfo.maritalStatus) {
    }
  };

  useEffect(() => {
    if (statusCode === 401) {
      toastMsgs.error(error);
      setTimeout(() => dispatch(userSignOut()), 300);
    }
  }, [statusCode]);
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
        handleResetPage();
      }, 100);
    }
    if (message) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
        handleResetPage();
      }, 100);
    }
  }, [error, loader, loading, message, dispatch]);

  // useEffect(()=>{
  //   //
  //   if(postKycData === 200){
  //     //  setTimeout(() => dispatch(restEdrmsFiles()), 300);
  //      setTimeout(() => dispatch(resetKycDetails()), 300);
  //      setTimeout(() => dispatch(setKycLoader()), 300);
  //      setTimeout(() => dispatch(getKycDetails()), 300);
  //      handleResetPage()
  //   }
  // },[postKycData])

  useEffect(() => {
    if (error) {
      setTimeout(() => dispatch(hideErrorMessage()), 300);
    }
  }, [error, dispatch]);
  useEffect(() => {
    dispatch(setKycLoader());
    dispatch(getKycDetails());
    editIdProof();
    editAddressProof();
    editBankProof();
    return () => {
      dispatch(resetKycDetails());
      dispatch(resetKycLoader());
      dispatch(restEdrmsFiles());
    };
  }, []);
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
      }, 100);
    }
    if (message) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
      }, 100);
    }
  }, [error, loader, loading, message, dispatch]);

  useEffect(() => {
    if (statusCode) {
      console.log("error code: ", statusCode);
    }
  }, [statusCode]);

  useEffect(() => {
    if (kycInfo.maritalStatus === "Married") {
      setMaritalCode(1);
    } else if (kycInfo.maritalStatus === "Single") {
      setMaritalCode(2);
    } else if (kycInfo.maritalStatus === "Divorced") {
      setMaritalCode(3);
    } else if (kycInfo.maritalStatus === "Widowed") {
      setMaritalCode(4);
    } else if (kycInfo.maritalStatus === "Separated") {
      setMaritalCode(5);
    } else if (kycInfo.maritalStatus === "Other") {
      setMaritalCode(6);
    }
  }, [kycInfo.maritalStatus]);

  const initialValues = {
    idType: `${kycInfo.idType === "Omang" ? "Omang" : "Passport"}`,
    idNumber: kycInfo.idNumber,
    passportNumber: null,
    firstName: kycInfo.firstName,
    lastName: kycInfo.lastName,
    maritalStatus: null,
    expiryDate: kycInfo.expiryDate,
    mobileNumber: kycInfo.mobile,
    email: kycInfo.email,
    emailInput: email,
    correspondenceLine1: kycInfo.corresAddr1,
    correspondenceLine2: kycInfo.corresAddr2,
    correspondencePostal: kycInfo.corresAddrPostCode,
    correspondenceTown: kycInfo.corresAddrCity,
    residentialLine1: kycInfo.resAddr1,
    residentialLine2: kycInfo.resAddr2,
    residentialPostal: kycInfo.resAddrPostCode,
    residentialTown: kycInfo.resAddrCity,
    file1: null,
    file2: null,
    file3: null,
  };

  console.log("initial values: ", initialValues);

  const FILE_SIZE = 300 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "application/pdf"];

  const validationSchema = Yup.object().shape({
    idType: Yup.string()
      .required("Please select ID Type")
      .test((value) => value !== "none"),
    idNumber: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Omang/Passport should be alpha numeric")
      // .min(5, signUpValidation.omangNumberLengthMsg)
      .required("Omang/Passport is required")
      // .max(20, "Invalid Omang/Passport")

      .test("idNumber", "Invalid Omang number", function (value) {
        return (
          (this.parent.idType === "Omang" &&
            value?.length == 9 &&
            (value[4] == 1 || value[4] == 2)) ||
          this.parent.idType === "Passport" ||
          (this.parent.idType === "Others" &&
            this.parent.idType !== "omang" &&
            value?.length)
        );
      }),
    passportNumber: Yup.string().nullable(true),
    //  .matches(/^[a-zA-Z0-9]+$/, "Passport should be alpha numeric"),
    //,
    // email: Yup.string()
    //   .email("Please enter valid Email Address")
    //   .required("Email is required"),
    expiryDate: Yup.date()
      .min(new Date(), "Invalid Expiry Date")
      .typeError("Please enter valid Expiry Date")
      .required("Please enter Expiry Date"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number can be 0-9 digits only")
      .max(15, "Invalid Mobile Number")
      .required("Mobile Number is required"),
    correspondenceLine1: correspondenceAddress
      ? Yup.string()
      : Yup.string().required(kycValidationMsgs.CorrespondenceLine1),
    correspondenceLine2: correspondenceAddress ? Yup.string() : Yup.string(),
    /*.required(kycValidationMsgs.CorrespondenceLine2)*/
    correspondencePostal: correspondenceAddress
      ? Yup.string()
      : Yup.string().required(kycValidationMsgs.CorrespondencePostal),

    // correspondencePostal: Yup.string()
    //   .matches(/^[a-zA-Z0-9]+$/, "postal code can be alpha numeric")
    //   .max(10, "Invalid Postal Code and Postal Code allows maximum 10 Characters")
    //   .required(kycValidationMsgs.CorrespondencePostal),

    correspondenceTown: correspondenceAddress
      ? Yup.string()
      : Yup.string().required(kycValidationMsgs.CorrespondenceTown),
    residentialLine1: residentialAddress
      ? Yup.string()
      : Yup.string().required(kycValidationMsgs.ResidentialLine1),
    residentialLine2: residentialAddress ? Yup.string() : Yup.string(),
    /*.required(kycValidationMsgs.ResidentialLine2)*/
    // residentialPostal: Yup.string()
    //   .matches(/^[a-zA-Z0-9]+$/, "postal code can be alpha numeric")
    //   .max(10, "Invalid Postal Code and Postal Code allows maximum 10 Characters")
    //   .required(kycValidationMsgs.ResidentialPostal),

    residentialPostal: residentialAddress
      ? Yup.string()
      : Yup.string().required(kycValidationMsgs.ResidentialPostal),
    residentialTown: residentialAddress
      ? Yup.string()
      : Yup.string().required(kycValidationMsgs.ResidentialTown),

    // file1:
    //   kycStatus.statusName === 'Fully-Compliant'
    //     ? Yup.mixed()
    //     : Yup.mixed()
    //         .required('Please Upload Document')
    //         .test(
    //           'fileSize',
    //           'Uploaded files size should not exceed 300KB',
    //           (value) => value && value.size <= FILE_SIZE
    //         )
    //         .test(
    //           'fileFormat',
    //           'Unsupported Format',
    //           (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //         ),
    // file2:
    // file3:
    //   kycStatus.statusName === 'Fully-Compliant'
    //     ? Yup.mixed()
    //     : Yup.mixed()
    //         .required('Please Upload Document')
    //         .test(
    //           'fileSize',
    //           'Uploaded files size should not exceed 300KB',
    //           (value) => value && value.size <= FILE_SIZE
    //         )
    //         .test(
    //           'fileFormat',
    //           'Uploaded files size should not exceed 300KB',
    //           (value) => value && SUPPORTED_FORMATS.includes(value.type)
    //         ),
  });

  const editIdProof = () => {
    if (initialValues.file1 !== null) setIdProof(!idProof);
  };
  const cancelIdProof = () => {
    setIdProof(true);
  };
  const editAddressProof = () => {
    if (initialValues.file2 !== null) setAddressProof(false);
  };
  const cancelAddressProof = () => {
    setAddressProof(true);
  };
  const editBankProof = () => {
    if (initialValues.file3 !== null) setBankProofFile(false);
  };
  const cancelBankProof = () => {
    setBankProofFile(true);
  };
  return (
    <>
      {kycCheckStatus === "notExist" ? (
        <>
          <ContainerHeader match={match} />
          <div className="kyc-custom-card">
            <PdfPreview
              style={{ width: "100%", height: "100%" }}
              open={open}
              Pdf={pdf}
              handleRequestClose={handleRequestClosePopUP}
            />
            <JpgPreview
              style={{ width: "100%", height: "100%" }}
              open={imageOpen}
              Jpeg={jpg}
              handleRequestClose={handleRequestClosePopUP}
            />
            <div className="row" style={{ margin: "0px" }}>
              {/* <div className="col-lg-2 col-sm-2 col-xs-2"></div> */}
              <div className="col-lg-9 col-sm-8 col-xs-8 kyc-custom-card-content">
                <CardBox styleName="col-sm-12 custom-form-card mt-0">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onReset={(values) => {}}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      const {
                        idType,
                        idNumber,
                        passportNumber,
                        firstName,
                        lastName,
                        //maritalStatus,
                        maritalStatus,
                        expiryDate = moment(expiryDate).format("YYYY-MM-DD"),
                        mobileNumber,
                        email,
                        emailInput,
                        correspondenceLine1,
                        correspondenceLine2,
                        correspondencePostal,
                        correspondenceTown,
                        residentialLine1,
                        residentialLine2,
                        residentialPostal,
                        residentialTown,
                        file1,
                        file2,
                        file3,
                      } = values;
                      let idTypeCode = null;
                      if (values.idType === "Omang") {
                        idTypeCode = "34";
                      } else if (values.idType === "Passport") {
                        idTypeCode = "3";
                      } else {
                        idTypeCode = "9";
                      }

                      let userData = {
                        idInfo: {
                          idType: idTypeCode,
                          idNumber: idNumber,
                          expiryDate: moment(expiryDate).format("YYYY-MM-DD"),
                        },
                        correspondenceAddress: {
                          line1: correspondenceAddress
                            ? kycInfo.corresAddr1
                            : correspondenceLine1,
                          line2: correspondenceAddress
                            ? kycInfo.corresAddr2
                            : correspondenceLine2,
                          postalCode: correspondenceAddress
                            ? kycInfo.corresAddrPostCode
                            : correspondencePostal,
                          town: correspondenceAddress
                            ? kycInfo.corresAddrCity
                            : correspondenceTown,
                        },
                        residentialAddress: {
                          line1: residentialAddress
                            ? kycInfo.resAddr1
                            : residentialLine1,
                          line2: residentialAddress
                            ? kycInfo.resAddr2
                            : residentialLine2,
                          postalCode: residentialAddress
                            ? kycInfo.resAddrPostCode
                            : residentialPostal,
                          town: residentialAddress
                            ? kycInfo.resAddrCity
                            : residentialTown,
                        },
                        mobile: mobileNumber ? mobileNumber : kycInfo.mobile,
                        email: email ? email : kycInfo.email,
                        kycStatus: statusId,
                        userSelectionStatus: "submit",
                        lastName: lastName ? lastName : kycInfo.lastName,
                        maritalStatus: maritalStatus
                          ? maritalStatus
                          : maritalCode,
                        newIdNumber: passportNumber,
                        edrmsDetails: fileData,
                      };
                      setuserIdNumber(
                        userData && userData.idInfo && userData.idInfo.idNumber
                          ? userData.idInfo.idNumber
                          : JSON.parse(localStorage.getItem("omangNumber"))
                      );
                      console.log("userData {} :");
                      console.log(userData);
                      console.log("fileData.length");
                      console.log(fileData.length);
                      if (
                        fileData &&
                        fileData.length > 0 &&
                        (statusId === "1" || "2")
                      ) {
                        // dispatch(edrmsFilesPush(fileData));
                        console.log("marital status: ", maritalStatus);

                        dispatch(postKycDetails(userData));
                        console.log("user data: ", userData);
                      } else if (
                        status === "Fully-Compliant" &&
                        (!residentialAddress || !correspondenceAddress)
                      ) {
                        console.log("marital status: ", maritalStatus);
                        dispatch(postKycDetails(userData));
                        console.log("user data: ", userData);
                      }
                      // dispatch(setKycLoader());
                      setSubmitting(false);
                      //start
                      const validateFiles = () => {
                        const docTypes = [
                          "KYCIDPROOF",
                          "KYCADDRESSPROOF",
                          "KYCBANKPROOF",
                        ];

                        let tempKycDocValidation = {
                          KYCIDPROOF: false,
                          KYCADDRESSPROOF: false,
                          KYCBANKPROOF: false,
                        };

                        const uploadedDocTypes = fileData.map(
                          (item) => item.fileType
                        );

                        docTypes.forEach((item) => {
                          if (!uploadedDocTypes.includes(item)) {
                            tempKycDocValidation[item] = true;
                          }
                        });

                        setKycDocValidation({
                          ...kycDocValidation,
                          ...tempKycDocValidation,
                        });
                      };

                      validateFiles();
                      // end
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleReset,
                        handleSubmit,
                        setFieldValue,
                      } = props;
                      if (correspondenceAddress) {
                        values.correspondenceLine1 = kycInfo.corresAddr1;
                        values.correspondenceLine2 = kycInfo.corresAddr2;
                        values.correspondencePostal =
                          kycInfo.corresAddrPostCode;
                        values.correspondenceTown = kycInfo.corresAddrCity;
                      }
                      if (residentialAddress) {
                        values.residentialLine1 = kycInfo.resAddr1;
                        values.residentialLine2 = kycInfo.resAddr2;
                        values.residentialPostal = kycInfo.resAddrPostCode;
                        values.residentialTown = kycInfo.resAddrCity;
                      }

                      return (
                        <form
                          autoComplete="on"
                          noValidate
                          onSubmit={handleSubmit}
                          onReset={handleReset}
                        >
                          <div className="claim-card-header">
                            <h4 className="card-title mb-0">KYC Details</h4>
                          </div>
                          <div className="card-header address-header">
                            <h3 className="card-title">User Information</h3>
                            <Tooltip dis title="Edit">
                              <EditIcon onClick={editUserInformation} />
                            </Tooltip>
                            {userInformation ? (
                              <Tooltip title="Cancel">
                                <CancelIcon onClick={cancelUserInformation} />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <TextField
                                // readOnly="true"
                                error={errors.firstName && touched.firstName}
                                className="form-textfield form-textfield-label"
                                label="First Name"
                                name="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={true}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                fullWidth
                                margin="normal"
                                placeholder="First Name"
                                helperText={
                                  errors.firstName &&
                                  touched.firstName &&
                                  errors.firstName
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <TextField
                                // readOnly="true"
                                error={errors.lastName && touched.lastName}
                                className="form-textfield form-textfield-label"
                                label="Last Name"
                                name="lastName"
                                value={
                                  userInformation
                                    ? values.lastName
                                    : kycInfo.lastName
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={userInformation ? false : true}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                fullWidth
                                margin="normal"
                                placeholder="Last Name"
                                helperText={
                                  errors.lastName &&
                                  touched.lastName &&
                                  errors.lastName
                                }
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div
                              className="col-md-6 col-sm-12"
                              style={{ padding: "12px 13px 0px" }}
                              required
                            >
                              <span>ID Type</span>
                              <Select
                                id="idType"
                                name="idType"
                                style={{ width: "100%" }}
                                onChange={handleChange}
                                value={values.idType}
                                //defaultValue={kycInfo.idType}
                                disabled={!userInformation ? true : false}
                                input={<Input id="idType" />}
                                helperText={
                                  errors.idType &&
                                  touched.idType &&
                                  errors.idType
                                }
                                error={touched.idType && errors.idType}
                              >
                                {idTypes.map((type) => (
                                  <MenuItem
                                    value={type.key}
                                    disabled={type.disable}
                                  >
                                    {type.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>

                            <div className="col-md-6 col-sm-12">
                              <TextField
                                // readOnly="true"
                                error={errors.idNumber && touched.idNumber}
                                className="form-textfield form-textfield-label"
                                label={
                                  values.idType === "Passport"
                                    ? "Passport"
                                    : "ID Number"
                                }
                                name="idNumber"
                                value={values.idNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={true}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                fullWidth
                                margin="normal"
                                placeholder={
                                  values.idType === "Passport"
                                    ? "Passport Number"
                                    : "ID Number"
                                }
                                helperText={
                                  errors.idNumber &&
                                  touched.idNumber &&
                                  errors.idNumber
                                }
                              />
                            </div>

                            {values.idType === "Passport" && (
                              <div className="col-md-6 col-sm-12">
                                <TextField
                                  // readOnly="true"
                                  error={
                                    errors.passportNumber &&
                                    touched.passportNumber
                                  }
                                  className="form-textfield form-textfield-label"
                                  label={"New Passport Number"}
                                  name="passportNumber"
                                  value={values.passportNumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={!userInformation ? true : false}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  required
                                  fullWidth
                                  margin="normal"
                                  placeholder={"Passport Number"}
                                  helperText={
                                    errors.newIdNumber &&
                                    touched.newIdNumber &&
                                    errors.newIdNumber
                                  }
                                />
                              </div>
                            )}

                            <div
                              className="col-md-6 col-sm-12 form-textfield form-textfield-label"
                              style={{ marginTop: "8px" }}
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container>
                                  <KeyboardDatePicker
                                    error={
                                      errors.expiryDate && touched.expiryDate
                                    }
                                    name="expiryDate"
                                    autoOk={true}
                                    fullWidth
                                    minDate={new Date()}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    placeholder="DD/MM/YYYY"
                                    margin="normal"
                                    label="Expiry Date"
                                    value={values.expiryDate}
                                    disabled={
                                      userInformation &&
                                      (moment(values.expiryDate).isSameOrBefore(
                                        today
                                      ) ||
                                        kycInfo.expiryDate == null)
                                        ? false
                                        : true
                                    }
                                    onChange={(val) => {
                                      setFieldValue("expiryDate", val);
                                    }}
                                    handleBlur={handleBlur}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    helperText={
                                      errors.expiryDate &&
                                      touched.expiryDate &&
                                      errors.expiryDate
                                    }
                                  />
                                </Grid>
                              </MuiPickersUtilsProvider>
                              {/* <DatePickers
                                                        handleDateChange={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        expiryDate={values.expiryDate}
                                                        label= "Expiry Date"
                                                    /> */}
                            </div>

                            <div className="col-md-6 col-sm-12">
                              {/*!userInformation?
                                <TextField
                                // readOnly="true"
                                //error={errors.lastName && touched.lastName}
                                className="form-textfield form-textfield-label"
                                label="Marital Status"
                                name="maritalStatus"
                                value={kycInfo.maritalStatus}
                                //onChange={handleChange("maritalStatus")}
                                onBlur={handleBlur}
                                disabled={true}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                required
                                fullWidth
                                margin="normal"
                                placeholder="Marital Status"
                                helperText={
                                errors.maritalStatus &&
                                touched.maritalStatus &&
                                errors.maritalStatus
                                }
                              />
                              :
                              <>*/}
                              <div>Select Marital Status</div>
                              <FormControl
                                fullWidth
                                color="warning"
                                size="small"
                              >
                                <InputLabel id="demo-simple-select-label">
                                  {kycInfo.maritalStatus}
                                </InputLabel>
                                <Select
                                  className="form-textfield form-textfield-label"
                                  // setFieldValue={reporterData.email ? reporterData.email : ""}
                                  style={{ width: "100%" }}
                                  disabled={!userInformation}
                                  value={values.maritalStatus}
                                  onChange={handleChange("maritalStatus")}
                                  onBlur={handleBlur}
                                  fullWidth
                                  margin="normal"
                                  placeholder="Select Marital Status"
                                >
                                  <MenuItem value={1}>Married</MenuItem>
                                  <MenuItem value={2}>Single</MenuItem>
                                  <MenuItem value={3}>Divorced</MenuItem>
                                  <MenuItem value={4}>Widowed</MenuItem>
                                  <MenuItem value={5}>Separated</MenuItem>
                                  <MenuItem value={6}>Other</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>

                          <div className="card-header address-header">
                            <h3 className="card-title">Contact Information</h3>
                            <Tooltip dis title="Edit">
                              <EditIcon onClick={editContactInformation} />
                            </Tooltip>
                            {contactInformation ? (
                              <Tooltip title="Cancel">
                                <CancelIcon
                                  onClick={cancelContactInformation}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <TextField
                                error={
                                  errors.mobileNumber && touched.mobileNumber
                                }
                                readOnly="true"
                                className="form-textfield form-textfield-label"
                                id="mobileNumber"
                                label="Mobile Number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={values.mobileNumber}
                                /*InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        +
                                      </InputAdornment>
                                    ),
                                  }}*/
                                onChange={handleChange}
                                placeholder="XXXXXXXX"
                                margin="normal"
                                onBlur={handleBlur}
                                fullWidth
                                //readOnly="true"
                                disabled={contactInformation ? false : true}
                                helperText={
                                  errors.mobileNumber &&
                                  touched.mobileNumber &&
                                  errors.mobileNumber
                                }
                              />
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <TextField
                                error={errors.email && touched.email}
                                readOnly="true"
                                className="form-textfield form-textfield-label"
                                id="email"
                                label="Email Address"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={values.email}
                                onChange={handleChange}
                                placeholder="XXXXXXXX"
                                margin="normal"
                                onBlur={handleBlur}
                                fullWidth
                                disabled={contactInformation ? false : true}
                                helperText={
                                  errors.email && touched.email && errors.email
                                }
                              />
                            </div>
                          </div>
                          <div className="card-header address-header">
                            <h3 className="card-title">
                              Correspondence Address
                            </h3>
                            <Tooltip title="Edit">
                              <EditIcon onClick={editCorrespondenceAddress} />
                            </Tooltip>
                            {!correspondenceAddress ? (
                              <Tooltip title="Cancel">
                                <CancelIcon
                                  onClick={cancelCorrespondenceAddress}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-sm-12">
                              <TextField
                                error={
                                  errors.correspondenceLine1 &&
                                  touched.correspondenceLine1 &&
                                  !correspondenceAddress
                                }
                                className="form-textfield form-textfield-label"
                                label="Line 1"
                                name="correspondenceLine1"
                                required
                                value={
                                  correspondenceAddress
                                    ? kycInfo.corresAddr1
                                    : values.correspondenceLine1
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  !correspondenceAddress
                                    ? errors.correspondenceLine1 &&
                                      touched.correspondenceLine1 &&
                                      errors.correspondenceLine1
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                margin="normal"
                                placeholder="XXXXXXXXX"
                                disabled={correspondenceAddress}
                              />
                            </div>
                            <div className="col-md-12 col-sm-12">
                              <TextField
                                error={
                                  errors.correspondenceLine2 &&
                                  touched.correspondenceLine2 &&
                                  !correspondenceAddress
                                }
                                className="form-textfield form-textfield-label"
                                label="Line 2"
                                name="correspondenceLine2"
                                required
                                value={
                                  correspondenceAddress
                                    ? kycInfo.corresAddr2
                                    : values.correspondenceLine2
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  !correspondenceAddress
                                    ? errors.correspondenceLine2 &&
                                      touched.correspondenceLine2 &&
                                      errors.correspondenceLine2
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  required: false,
                                }}
                                fullWidth
                                margin="normal"
                                placeholder="XXXXXXXXX"
                                disabled={correspondenceAddress}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <TextField
                                error={
                                  errors.correspondencePostal &&
                                  touched.correspondencePostal &&
                                  !correspondenceAddress
                                }
                                className="form-textfield form-textfield-label"
                                label="Postal Code"
                                name="correspondencePostal"
                                required
                                value={
                                  correspondenceAddress
                                    ? kycInfo.corresAddrPostCode === "N/A"
                                      ? ""
                                      : kycInfo.corresAddrPostCode
                                    : values.correspondencePostal
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  !correspondenceAddress
                                    ? errors.correspondencePostal &&
                                      touched.correspondencePostal &&
                                      errors.correspondencePostal
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                margin="normal"
                                placeholder="XXXXXXXXX"
                                disabled={correspondenceAddress}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <TextField
                                error={
                                  errors.correspondenceTown &&
                                  touched.correspondenceTown &&
                                  !correspondenceAddress
                                }
                                className="form-textfield form-textfield-label"
                                label="Town/City"
                                name="correspondenceTown"
                                required
                                value={
                                  correspondenceAddress
                                    ? kycInfo.corresAddrCity
                                    : values.correspondenceTown
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  !correspondenceAddress
                                    ? errors.correspondenceTown &&
                                      touched.correspondenceTown &&
                                      errors.correspondenceTown
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                margin="normal"
                                placeholder="XXXXXXXXX"
                                disabled={correspondenceAddress}
                              />
                            </div>
                          </div>

                          <div className="card-header address-header">
                            <h3 className="card-title">Residential Address</h3>

                            <Tooltip title="Edit">
                              <EditIcon onClick={editResidentialAddress} />
                            </Tooltip>
                            {!residentialAddress ? (
                              <Tooltip title="Cancel">
                                <CancelIcon
                                  onClick={cancelResidentialAddress}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-sm-12">
                              <TextField
                                error={
                                  errors.residentialLine1 &&
                                  touched.residentialLine1 &&
                                  !residentialAddress
                                }
                                className="form-textfield form-textfield-label"
                                label="Line 1"
                                name="residentialLine1"
                                required
                                value={
                                  residentialAddress
                                    ? kycInfo.resAddr1
                                    : values.residentialLine1
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  !residentialAddress
                                    ? errors.residentialLine1 &&
                                      touched.residentialLine1 &&
                                      errors.residentialLine1
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                margin="normal"
                                placeholder="XXXXXXXXX"
                                disabled={residentialAddress}
                              />
                            </div>
                            <div className="col-md-12 col-sm-12">
                              <TextField
                                error={
                                  errors.residentialLine2 &&
                                  touched.residentialLine2 &&
                                  !residentialAddress
                                }
                                className="form-textfield form-textfield-label"
                                label="Line 2"
                                name="residentialLine2"
                                required
                                value={
                                  residentialAddress
                                    ? kycInfo.resAddr2
                                    : values.residentialLine2
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  !residentialAddress
                                    ? errors.residentialLine2 &&
                                      touched.residentialLine2 &&
                                      errors.residentialLine2
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  required: false,
                                }}
                                fullWidth
                                margin="normal"
                                placeholder="XXXXXXXXX"
                                disabled={residentialAddress}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <TextField
                                error={
                                  errors.residentialPostal &&
                                  touched.residentialPostal &&
                                  !residentialAddress
                                }
                                className="form-textfield form-textfield-label"
                                label="Postal Code"
                                name="residentialPostal"
                                required
                                value={
                                  residentialAddress
                                    ? kycInfo.resAddrPostCode === "N/A"
                                      ? ""
                                      : kycInfo.resAddrPostCode
                                    : values.residentialPostal
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  !residentialAddress
                                    ? errors.residentialPostal &&
                                      touched.residentialPostal &&
                                      errors.residentialPostal
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                margin="normal"
                                placeholder="XXXXXXXXX"
                                disabled={residentialAddress}
                              />
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <TextField
                                error={
                                  errors.residentialTown &&
                                  touched.residentialTown &&
                                  !residentialAddress
                                }
                                className="form-textfield form-textfield-label"
                                label="Town/City"
                                name="residentialTown"
                                required
                                value={
                                  residentialAddress
                                    ? kycInfo.resAddrCity
                                    : values.residentialTown
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  !residentialAddress
                                    ? errors.residentialTown &&
                                      touched.residentialTown &&
                                      errors.residentialTown
                                    : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                margin="normal"
                                placeholder="XXXXXXXXX"
                                disabled={residentialAddress}
                              />
                            </div>
                          </div>
                          <div className="card-header">
                            {status !== "Fully-Compliant" ? (
                              <h3 className="card-title">Upload Document</h3>
                            ) : (
                              <h3 className="card-title">Documents</h3>
                            )}
                            {status !== "Fully-Compliant" ? (
                              <p
                                style={{
                                  margin: "20px 0px -10px 0px",
                                  fontSize: "14px",
                                }}
                              >
                                <b>Note</b>: Only PDF/JPG files supported and
                                files size should not exceed 5MB
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div>
                                {/*-----------------1. Document Upload ---------------*/}

                                <div
                                  className="custom-upload-label"
                                  style={{
                                    marginBottom: "15px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  <p> 1) ID proof *</p>
                                  {status !== "Fully-Compliant" ||
                                  status !== "Half-Compliant" ||
                                  (idProof && status !== "Fully-Compliant") ||
                                  (userInformation &&
                                    status === "Fully-Compliant") ? (
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          <Typography
                                            color="inherit"
                                            variant="subtitle2"
                                          >
                                            Please Upload Certified
                                            Omang/Passport/Marraige Certificate
                                            Document. If you are a foreign
                                            national, kindly upload a certified
                                            copy of a valid passport, work
                                            permit and residence permit.
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    >
                                      <div className="custom-upload-myclaims">
                                        <UploadFiles
                                          id="file1"
                                          fileData={fileData}
                                          fileType="KYCIDPROOF"
                                          thePolicyCode={omang}
                                          insurredId={policy}
                                          handleFile={handleFile}
                                          removeDoc={removeDoc}
                                          handleChooseFile={setIDProofFile}
                                          selectedFile={IDProofFile}
                                          setSelectedFile={setIDProofFile}
                                        />
                                        {KYCIDPROOF ? (
                                          <div className="ml-3 claim-type-validation-text-file">
                                            Please upload ID proof
                                          </div>
                                        ) : null}

                                        <div style={{ marginBottom: "15px" }}>
                                          <p
                                            style={{
                                              margin: "10px 0px 10px 0px",
                                              fontSize: "10px",
                                            }}
                                          >
                                            <b>Note</b>: If you are a foreign
                                            national, kindly upload a certified
                                            copy of a valid passport, work
                                            permit and residence permit
                                          </p>
                                        </div>
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <span className="custom-upload-myclaims">
                                      <Button
                                        onClick={() =>
                                          handlePDFPreview("KYCIDPROOF")
                                        }
                                        className={`badge mr-0 text-capitalize text-white bg-blue admin-preview-btn kyc-preview-btn`}
                                      >
                                        <i className="zmdi zmdi-eye text-capitalize text-white">
                                          {" "}
                                          <span className="roboto">
                                            Preview
                                          </span>
                                        </i>
                                      </Button>
                                      {status !== "Fully-Compliant" ? (
                                        <Tooltip title="Edit">
                                          <EditIcon
                                            onClick={editIdProof}
                                            className="edit-icon"
                                          />
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </div>
                                {/*-----------------2. Document Upload ---------------*/}
                                <div
                                  className="custom-upload-label"
                                  style={{ marginTop: "35px" }}
                                >
                                  <p> 2) Address proof *</p>
                                  {status !== "Fully-Compliant" ||
                                  status !== "Half-Compliant" ||
                                  (addressProof &&
                                    status !== "Fully-Compliant") ||
                                  (!correspondenceAddress &&
                                    status === "Fully-Compliant") ? (
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          <div>
                                            <Typography
                                              color="inherit"
                                              variant="subtitle2"
                                            >
                                              Proof of residence
                                            </Typography>
                                          </div>
                                          <div>
                                            <Typography
                                              color="inherit"
                                              variant="subtitle2"
                                            >
                                              1. Utility Bill(not older than 3
                                              months.)
                                            </Typography>
                                          </div>
                                          <div>
                                            <Typography
                                              color="inherit"
                                              variant="subtitle2"
                                            >
                                              2. Lease agreement/ title deed/
                                              letter from employer{" "}
                                            </Typography>
                                          </div>
                                          <div>
                                            <Typography
                                              color="inherit"
                                              variant="subtitle2"
                                            >
                                              3. Affidavit from Commissioner of
                                              Oath
                                            </Typography>
                                          </div>
                                        </React.Fragment>
                                      }
                                    >
                                      <div className="custom-upload-myclaims">
                                        <UploadFiles
                                          id="file2"
                                          fileData={fileData}
                                          fileType="KYCADDRESSPROOF"
                                          thePolicyCode={omang}
                                          insurredId={policy}
                                          handleFile={handleFile}
                                          removeDoc={removeDoc}
                                          handleChooseFile={setAddressProofFile}
                                          selectedFile={AddressProofFile}
                                          setSelectedFile={setAddressProofFile}
                                        />
                                        {KYCADDRESSPROOF ? (
                                          <div className="ml-3 claim-type-validation-text-file">
                                            Please upload Address proof
                                          </div>
                                        ) : null}
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <span className="custom-upload-myclaims">
                                      <Button
                                        onClick={() =>
                                          handlePDFPreview("KYCADDRESSPROOF")
                                        }
                                        className={`badge mr-0 text-capitalize text-white bg-blue admin-preview-btn kyc-preview-btn`}
                                      >
                                        <i className="zmdi zmdi-eye text-capitalize text-white">
                                          {" "}
                                          <span className="roboto">
                                            Preview
                                          </span>
                                        </i>
                                      </Button>
                                      {status !== "Fully-Compliant" ? (
                                        <Tooltip title="Edit">
                                          <EditIcon
                                            onClick={editAddressProof}
                                            className="edit-icon"
                                          />
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </div>

                                {/*-----------------3. Document Upload ---------------*/}

                                <div
                                  className="custom-upload-label"
                                  style={{ marginTop: 26 }}
                                >
                                  <p> 3) Bank proof *</p>
                                  {status !== "Fully-Compliant" ||
                                  status !== "Half-Compliant" ||
                                  (bankProof && status !== "Fully-Compliant") ||
                                  (!residentialAddress &&
                                    status === "Fully-Compliant") ? (
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          <div>
                                            <Typography
                                              color="inherit"
                                              variant="subtitle2"
                                            >
                                              1. Valid Pay-slip (3months
                                              validity)
                                            </Typography>
                                          </div>
                                          <div>
                                            <Typography
                                              color="inherit"
                                              variant="subtitle2"
                                            >
                                              2. Three months Bank Statement for
                                              Business owners (Mostly applicable
                                              to Self Employed people)
                                            </Typography>
                                          </div>
                                          <div>
                                            <Typography
                                              color="inherit"
                                              variant="subtitle2"
                                            >
                                              3. Employment Letter reflecting
                                              one’s monthly income.
                                            </Typography>
                                          </div>
                                          <div>
                                            <Typography
                                              color="inherit"
                                              variant="subtitle2"
                                            >
                                              4. If the person is unemployed or
                                              lives through Piece jobs then they
                                              submit an affidavit explaining
                                              such. (This includes Hawkers as
                                              well)
                                            </Typography>
                                          </div>
                                        </React.Fragment>
                                      }
                                    >
                                      <div className="custom-upload-myclaims">
                                        <UploadFiles
                                          id="file3"
                                          fileData={fileData}
                                          fileType="KYCBANKPROOF"
                                          thePolicyCode={omang}
                                          insurredId={policy}
                                          handleFile={handleFile}
                                          removeDoc={removeDoc}
                                          handleChooseFile={setBankProofFile}
                                          selectedFile={BankProofFile}
                                          setSelectedFile={setBankProofFile}
                                        />
                                        {KYCBANKPROOF ? (
                                          <div className="ml-3 claim-type-validation-text-file">
                                            Please upload Bank Deatils proof
                                          </div>
                                        ) : null}
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <span className="custom-upload-myclaims">
                                      <Button
                                        onClick={() =>
                                          handlePDFPreview("KYCBANKPROOF")
                                        }
                                        className={`badge mr-0 text-capitalize text-white bg-blue admin-preview-btn kyc-preview-btn`}
                                      >
                                        <i className="zmdi zmdi-eye text-capitalize text-white">
                                          {" "}
                                          <span className="roboto">
                                            Preview
                                          </span>
                                        </i>
                                      </Button>
                                      {status !== "Fully-Compliant" ? (
                                        <Tooltip title="Edit">
                                          <EditIcon
                                            onClick={editBankProof}
                                            className="edit-icon"
                                          />
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row proceed-custom-btn">
                            <Tooltip
                              title={
                                <React.Fragment>
                                  <div>
                                    <Typography
                                      color="inherit"
                                      variant="subtitle2"
                                    >
                                      1. Please make sure you've clicked on the
                                      Pen Icon <EditIcon /> next to each section
                                      header before trying to submit.
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      color="inherit"
                                      variant="subtitle2"
                                    >
                                      2. Please make sure document is uploaded
                                      before trying to submit.
                                    </Typography>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <div className="mt-1 mb-2 d-flex justify-content-center align-items-center">
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  disabled={
                                    (status === "Fully-Compliant" &&
                                      ((!residentialAddress &&
                                        BankProofFile &&
                                        fileData.length > 0) ||
                                        (!correspondenceAddress &&
                                          AddressProofFile &&
                                          fileData.length > 0) ||
                                        (userInformation &&
                                          IDProofFile &&
                                          fileData.length > 0))) ||
                                    (status === "Half-Compliant" &&
                                      ((!residentialAddress &&
                                        BankProofFile &&
                                        fileData.length > 0) ||
                                        (!correspondenceAddress &&
                                          AddressProofFile &&
                                          fileData.length > 0) ||
                                        (userInformation &&
                                          IDProofFile &&
                                          fileData.length > 0))) ||
                                    (!(
                                      status === "Fully-Compliant" ||
                                      status === "Half-Compliant"
                                    ) &&
                                      !residentialAddress &&
                                      BankProofFile &&
                                      !correspondenceAddress &&
                                      AddressProofFile &&
                                      userInformation &&
                                      IDProofFile &&
                                      fileData.length === 3)
                                      ? false
                                      : true
                                  }
                                >
                                  Submit
                                </Button>
                              </div>
                            </Tooltip>
                            {"   "}
                            <div className="mt-1 mb-2 d-flex justify-content-center align-items-center">
                              <Button
                                type="reset"
                                onClick={handleResetPage}
                                variant="contained"
                                color="primary"
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </CardBox>
              </div>
            </div>
            {loader && (
              <div className="kyc-loader kyc-loader-view">
                <CircularProgress />
              </div>
            )}
            {loading && (
              <div className="kyc-loader kyc-loader-view">
                <CircularProgress />
              </div>
            )}
            {/* {showMessage && <ErrorToaster message={alertMessage} />}  */}
            {error && toastMsgs.error(error)}
            {message && toastMsgs.success(message)}
          </div>
        </>
      ) : (
        <>
          {kycCheckStatus === "Exist" && !(kycCheckCode > 200) ? (
            <KYCNotification
              message={
                "You have a KYC application that is pending admin verification, please wait for approval before you can submit a new KYC request."
              }
            />
          ) : (
            <KYCNotification
              message={"Something went wrong, please try gain later."}
            />
          )}
        </>
      )}
    </>
  );
};

export default Kyc;
