import {
  BRANCHES_DATA,
  SERVICES_DATA,
  AVAILABLE_DAYS_DATA,
  AVAILABLE_TIME_DATA,
  TAKE_APPOINTMENT_DATA,
  APPOINTMENT_LOADER,
  RESET_APPOINTMENT_LOADER,
  RESET_APPOINTMENT_DETAILS,
  APPOINTMENT_MESSAGE,
  APPOINTMENT_ERROR_MESSAGE,
  APPOINTMENT_STATUS,
  NEAREST_TIME,
  APPOINTMENT_HISTORY,
  APPOINTMENT_HISTORY_STATUS,
} from "constants/ActionTypes";

const INIT_STATE = {
  bookingData: [],
  servicesData: [],
  availableDaysData: [],
  availableTimesData: [],
  appointmentData: {},
  appointmentLoader: false,
  appointmentMessage: "",
  appointmentError: "",
  appointmentStatus: 0,
  nearestAvailableTimes: "",
  appointmentHistory: [],
  appointmentHistoryStatus: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BRANCHES_DATA: {
      return {
        ...state,
        bookingData: action.payload,
        appointmentLoader: false,
      };
    }
    case SERVICES_DATA: {
      return {
        ...state,
        servicesData: action.payload,
        appointmentLoader: false,
      };
    }
    case AVAILABLE_DAYS_DATA: {
      return {
        ...state,
        availableDaysData: action.payload,
        appointmentLoader: false,
      };
    }
    case AVAILABLE_TIME_DATA: {
      return {
        ...state,
        availableTimesData: action.payload,
        appointmentLoader: false,
      };
    }
    case TAKE_APPOINTMENT_DATA: {
      return {
        ...state,
        appointmentData: action.payload,
        appointmentLoader: false,
      };
    }

    case APPOINTMENT_MESSAGE: {
      return {
        ...state,
        appointmentMessage: action.payload,
        appointmentLoader: false,
      };
    }

    case APPOINTMENT_ERROR_MESSAGE: {
      return {
        ...state,
        appointmentError: action.payload,
        appointmentLoader: false,
      };
    }

    case APPOINTMENT_STATUS: {
      return {
        ...state,
        appointmentStatus: action.payload,
      };
    }

    case NEAREST_TIME: {
      return {
        ...state,
        nearestAvailableTimes: action.payload,
      };
    }

    case APPOINTMENT_LOADER: {
      return {
        ...state,
        appointmentLoader: true,
      };
    }

    case APPOINTMENT_HISTORY: {
      return {
        ...state,
        appointmentHistory: action.payload,
        appointmentLoader: false,
      };
    }

    case APPOINTMENT_HISTORY_STATUS: {
      return {
        ...state,
        appointmentHistoryStatus: action.payload,
      };
    }

    case RESET_APPOINTMENT_LOADER: {
      return {
        ...state,
        appointmentLoader: false,
      };
    }

    case RESET_APPOINTMENT_DETAILS: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};
