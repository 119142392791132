import { CONTACT_INFO_DATA } from "constants/ActionTypes";

const INIT_STATE = {
  contactInfoData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONTACT_INFO_DATA: {
      return {
        ...state,
        contactInfoData: action.payload,
      };
    }
    default:
      return state;
  }
};
