/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import Widget from "components/Widget";
//import { contactList } from "./data";
//import AboutItem from "./AboutItem";
import Auxiliary from "util/Auxiliary";
import { Tooltip, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const ContactDetails = ({ data }) => {
  const { email = null, address = null, landLine = null, mobile = null } = data;
  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <div className="card-header" style={{ display: "flex" }}>
        <h4 className="card-title mb-0">Contact Details</h4>
        <Link style={{ position: "absolute", right: "10px" }} to="/home/KYC">
          <Tooltip
            title={
              <React.Fragment>
                <Typography color="inherit" variant="subtitle2">
                  Go to KYC screen to update your contact details
                </Typography>
              </React.Fragment>
            }
          >
            <EditIcon fontSize="small" />
          </Tooltip>
        </Link>
      </div>
      <div className="jr-tabs-classic">
        <div className="jr-tabs-content jr-task-list pt-3">
          <div className="row">
            <div className="col-md-6">
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-email icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">Email Address</h6>
                    <p className="mb-0 custom-fields">{email}</p>
                  </div>
                </div>
              </Auxiliary>

              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-pin icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">Address</h6>
                    <p className="mb-0 custom-fields">{address}</p>
                  </div>
                </div>
              </Auxiliary>
            </div>
            <div className="col-md-6">
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-phone icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">LandLine</h6>
                    <p className="mb-0 custom-fields">{landLine}</p>
                  </div>
                </div>
              </Auxiliary>
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-phone icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">Mobile Number</h6>
                    <p className="mb-0 custom-fields">{mobile}</p>
                  </div>
                </div>
              </Auxiliary>
            </div>
          </div>
        </div>
      </div>
    </Widget>
  );
};

export default ContactDetails;
