import React from "react";
import AdminTable from "./AdminTable";
import ContainerHeader from "../../../components/ContainerHeader/index";

const AdminPage = ({ match }) => {
  return (
    <>
      <ContainerHeader match={match} />
      <div className="row mb-md-3 custom-validate-toolbar">
        <AdminTable />
      </div>
    </>
  );
};

export default AdminPage;
