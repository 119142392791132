/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Pdf from "./SAiS.pdf";
// import ReactPDF from 'react-pdf'
import { JPEGViewer, JPGs } from "./PdfFrame";
import { withStyles } from "@material-ui/styles";

const styles = {
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "86vh",
  },
};

// let Pdf = "http://www.africau.edu/images/default/sample.pdf"

const JpgPreview = (props) => {
  // console.log(props, "props");
  const { open, handleRequestClose, Jpeg } = props;
  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        paperFullWidth
        classes={{ paper: props.classes.dialogPaper }}
        open={open}
      >
        <DialogContent>
          {/* <ReactPDF
            file={{
              url: "./SAiS.pdf",
            }}
          /> */}
          {/* <iframe src={ Pdf } style="width: 100%;height: 100%;border: none;"></iframe> */}
          <JPEGViewer backend={JPGs} src={Jpeg} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRequestClose}
            className="badge text-white mr-3 bg-blue admin-preview-btn"
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(JpgPreview);
