/* eslint-disable no-unused-vars */
import React, { lazy } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";
import AddPolicy from "./MyPolicies/routes/PolicyDetails/AddPolicy";
import AdminPage from "../../app/routes/AdminPage";
//import AdminPdfPage from "../../app/routes/AdminPdfPage";
//import ClaimRegistration from "../../app/routes/ClaimRegistration";
import AllProduct from "./KnowYourProducts/index";
import ErrorPage from "util/Errorpage";
import ChangePassword from "./ChangePassword";
import Errorpage from "../../util/Errorpage";
import MyPolicies from "./MyPolicies";
import MyProfile from "./MyProfile";
import SamplePdf from "../routes/PdfFiles/sample";
import AdminKYCPage from "./AdminKYCPage";
import MyClaims from "../routes/MyClaims";
import ClaimsCaseDetails from "../routes/MyClaims/claimsCaseDetails";
//import ClaimsCaseDetails1 from "../routes/MyClaims/claimCaseDetails1";
import RedirectUrl from "../routes/Url/index";

import Kyc from "./Kyc";
import Downloads from "../routes/Downloads";
import TaxCertificate from "./Downloads/TaxCertificate";
import Appointments from "../routes/Appointments";
import Feedback from "../routes/Feedback";
import CustomerService from "./CustomerService";
//import { AdminKYCPage } from "app/routes/AdminKYCPage/index";

const Routes = ({ match }) => (
  <div className="app-wrapper h-100 custom-breadcrumb">
    <Switch>
      <Route
        path={`${match.url}/my-profile`}
        component={
          // JSON.parse(localStorage.getItem("role")) !== "admin" ||
          JSON.parse(localStorage.getItem("role")) === "customer"
            ? MyProfile
            : Errorpage
        }
      />
      <Route path={`${match.url}/know-your-products`} component={AllProduct} />
      <Route path={`${match.url}/my-policies`} component={MyPolicies} />
      <Route path={`${match.url}/change-password`} component={ChangePassword} />
      {/* <Route path={`${match.url}/kyc-requests`} component={AdminKYCPage} /> */}

      {/* <Route path={`${match.url}/my-policies`} component={AllPolicies}/>  */}
      {/* <Route path={`${match.url}/productdetails`} component={ProductDetails}/>*/}
      <Route path={`${match.url}/add-policy`} component={AddPolicy} />

      <Route path={`${match.url}/appointments`} component={Appointments} />

      <Route path={`${match.url}/feedback`} component={Feedback} />

      <Route path={`${match.url}/KYC`} component={Kyc} />

      <Route
        path={`${match.url}/customer-service`}
        component={CustomerService}
      />

      <Route path={`${match.url}/my-claims`} component={MyClaims} />
      {/* <Route path={`${match.url}/home/kYC-requests`} component={AdminKYCPage} /> */}
      <Route path={`${match.url}/statements`} component={Downloads} />

      <Route
        path={`${match.url}/my-claims/claim-case-status-details`}
        component={ClaimsCaseDetails}
      />

      <Route
        path={`${match.url}/my-claims/claim-case-status-details`}
        component={ClaimsCaseDetails}
      />

      <Route
        path={`${match.url}/admin-dashboard`}
        component={
          JSON.parse(localStorage.getItem("role")) === "admin"
            ? AdminPage
            : Errorpage
        }
      />
      <Route
        path={`${match.url}/kYC-requests`}
        component={
          JSON.parse(localStorage.getItem("role")) === "KYCadmin"
            ? AdminKYCPage
            : Errorpage
        }
      />

      <Route
        path={`${match.url}/pdf-page`}
        component={asyncComponent(() => import("app/routes/PdfFiles/sample"))}
      />

      <Route
        path={`${match.url}/:shortLink`}
        // path={`${window.location.hostname}/:shortLink`}
        // path ="/:shortLink"
        component={RedirectUrl}
      />

      <Route component={ErrorPage} />

      {/*<Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>*/}
    </Switch>
  </div>
);

export default withRouter(Routes);
