import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpAuthPost } from "util/REST";
import {
  userRegistrationSuccess,
  showUserRegistrationError,
} from "actions/Registration";
import { USER_REGISTRATION } from "constants/ActionTypes";

const postUserRegistrationapi = async (data) =>
  await httpAuthPost("registerUser", data)
    .then((data) => data)
    .catch((error) => error);

function* PostUserRegistrationReq({ payload }) {
  try {
    let body = payload;
    const userRegistration = yield call(postUserRegistrationapi, body);
    if (userRegistration && userRegistration.status >= 400) {
      yield put(showUserRegistrationError(userRegistration.message));
    } else {
      yield put(userRegistrationSuccess(userRegistration));
    }
  } catch (error) {
    yield put(showUserRegistrationError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(USER_REGISTRATION, PostUserRegistrationReq)]);
}
