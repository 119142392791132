/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
import { toggleCollapsedNav } from "actions/Setting";
import { getContactInfo } from "actions/MyProfile";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import PersonIcon from "@material-ui/icons/Person";
import { after } from "lodash";
import { Tooltip } from "@material-ui/core";
import { getKycStatus } from "actions/KYCStatusAction";
import { ErrorToaster, toastMsgs } from "util/ReactToastify";
import { ToastContainer } from "react-toastify";
import { hideErrorMessage } from "actions/Common";
import { userSignOut } from "actions";
import IdExpiryMessage from "./IdExpiryMessage";
import moment from "moment";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const Index = (props) => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { loading, error, statusCode } = useSelector(
    ({ commonData }) => commonData
  );
  const { drawerType } = useSelector(({ settings }) => settings);
  const { contactInfoData } = useSelector(({ myProfile }) => myProfile);
  const { kycStatus } = useSelector(({ KycStatusReducer }) => KycStatusReducer);
  const { name = null } = contactInfoData;

  const closeExpiry = () => {
    setOpen(false);
  };

  const updateKYC = () => {
    setOpen(false);
    history.push("/home/KYC");
  };

  useEffect(() => {
    dispatch(
      getContactInfo(
        localStorage.getItem("omangNumber")
          ? JSON.parse(localStorage.getItem("omangNumber"))
          : null
      )
    );
    dispatch(getKycStatus());
  }, [dispatch]);

  const onToggleCollapsedNav = () => {
    const val = !props.navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
      }, 300);
    }
  }, [error]);

  useEffect(() => {
    if (statusCode === 408) {
      toastMsgs.error(error);
      setTimeout(() => {
        dispatch(userSignOut());
      }, 3000);
    }
  }, [statusCode]);

  useEffect(() => {
    if (kycStatus) {
      localStorage.setItem("kycStatus", JSON.stringify(kycStatus.statusName));
    }
  });

  useEffect(() => {
    if (kycStatus.expiryDate) {
      const expiry = moment(kycStatus.expiryDate).format("MMM-YYYY");
      const today = moment().format("MMM-YYYY");
      console.log("expiry date: ", expiry);
      console.log("today date: ", today);
      if (moment(kycStatus.expiryDate).isSameOrBefore(today)) {
        setOpen(true);
        console.log("expiry not passed!");
      }
    }

    console.log("Show KYC status info");
  }, [kycStatus.expiryDate]);

  const getStatusName = (status) => {
    if (status === "Fully-Compliant") {
      return "Fully Compliant";
    } else if (status === "Half-Compliant") {
      return "Half Compliant";
    } else if (status === "Non-Compliant") {
      return "Non Compliant";
    } else if (status === null) {
      return "Please submit KYC";
    }
  };

  console.log("kycStatusName ", kycStatus.statusName);

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "d-block d-xl-none"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? "d-block"
    : "d-none";

  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: 12,
    },
  }))(Tooltip);
  return (
    <>
      <AppBar className="app-main-header">
        <Toolbar className="app-toolbar" disableGutters={false}>
          <IconButton
            className={`jr-menu-icon mr-3 ${drawerStyle}`}
            aria-label="Menu"
            onClick={onToggleCollapsedNav}
          >
            <span className="menu-icon" />
          </IconButton>

          <ul className="header-notifications list-inline ml-auto">
            <li className="list-inline-item mail-tour">
              <div className="user-detail right">
                <div>
                  <h4
                    className="user-name"
                    style={{ color: "#fff", textTransform: "capitalize " }}
                  >
                    {name ? name : ""}
                  </h4>
                  {kycStatus.statusName &&
                  JSON.parse(localStorage.getItem("role")) === "customer" ? (
                    <Tooltip
                      title={
                        kycStatus.statusName === "Fully-Compliant"
                          ? ""
                          : `${kycStatus.kycReason}`
                      }
                    >
                      <div key={kycStatus.idNumber} className="ribbon">
                        <span className={`ribbon3 ${kycStatus.statusName}`}>
                          <b> KYC Status - </b>
                          {kycStatus.statusName === "Fully-Compliant" ? (
                            <u style={{ textDecoration: "none" }}>
                              {getStatusName(kycStatus.statusName)}
                            </u>
                          ) : (
                            // <Tooltip title="Please upload ID proof and Address proof in KYC">
                            <Link to="/home/KYC">
                              <u>{getStatusName(kycStatus.statusName)}</u>
                            </Link>
                            //</Tooltip>
                          )}
                        </span>
                      </div>
                    </Tooltip>
                  ) : (
                    <>
                      {JSON.parse(localStorage.getItem("role")) ===
                      "customer" ? (
                        <Tooltip
                          title={
                            "Please upload ID proof and Address proof in KYC"
                          }
                        >
                          <Link to="/home/KYC">
                            <u>{"No KYC available"}</u>
                          </Link>
                        </Tooltip>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </li>
          </ul>
        </Toolbar>
        {error && <ErrorToaster message={error} />}
        <ToastContainer />
      </AppBar>

      {
        // eslint-disable-next-line no-mixed-operators
        JSON.parse(localStorage.getItem("role")) === "customer" && (
          <IdExpiryMessage
            open={open}
            closeExpiry={closeExpiry}
            updateKYC={updateKYC}
          />
        )
      }
    </>
  );
};

export default withRouter(Index);
