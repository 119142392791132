import {
  POST_MOBILE_EMAIL_OTP,
  POST_MOBILE_EMAIL_OTP_ERROR_MESSAGE,
  POST_MOBILE_EMAIL_OTP_SUCCESS,
  ON_SHOW_OTP_LOADER,
  HIDE_OTP_ERROR_MESSAGE,
} from "../constants/ActionTypes";

export const mobileOrEmailOtp = (data) => {
  return {
    type: POST_MOBILE_EMAIL_OTP,
    payload: data,
  };
};
export const mobileOrEmailOtpSuccess = (data) => {
  return {
    type: POST_MOBILE_EMAIL_OTP_SUCCESS,
    payload: data,
  };
};
export const mobileOrEmailOtpErrorMessage = (data) => {
  return {
    type: POST_MOBILE_EMAIL_OTP_ERROR_MESSAGE,
    payload: data,
  };
};

export const hideOtpErrorMessage = () => {
  return {
    type: HIDE_OTP_ERROR_MESSAGE,
  };
};

export const showOtpLoader = () => {
  return {
    type: ON_SHOW_OTP_LOADER,
  };
};
