import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  PRODUCTS_DATA,
} from "../constants/ActionTypes";
import { httpGet } from "util/REST";

export const getProductsData = (productTypeId) => {
  const apiUrl =
    productTypeId === "all"
      ? "getListOfAllProducts"
      : `getListOfProducts?productTypeId=${productTypeId}`;

  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet(apiUrl)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: PRODUCTS_DATA,
            payload: data.response.productInfo ? data.response.productInfo : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};
