import {
  USER_REGISTRATION,
  USER_REGISTRATION_SUCCESS,
  HIDE_REGISTRATION_ERROR_MESSAGE,
  ON_SHOW_REGISTRATION_LOADER,
  USER_REGISTRATION_ERROR_MESSAGE,
} from "constants/ActionTypes";

export const userRegistration = (data) => {
  return {
    type: USER_REGISTRATION,
    payload: data,
  };
};

export const userRegistrationSuccess = (data) => {
  return {
    type: USER_REGISTRATION_SUCCESS,
    payload: data,
  };
};

export const showUserRegistrationError = (message) => {
  return {
    type: USER_REGISTRATION_ERROR_MESSAGE,
    payload: message,
  };
};

export const hideUserRegistrationMessage = () => {
  return {
    type: HIDE_REGISTRATION_ERROR_MESSAGE,
  };
};

export const showRegistrationLoader = () => {
  return {
    type: ON_SHOW_REGISTRATION_LOADER,
  };
};
