/* eslint-disable no-useless-concat */
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  CONTACT_INFO_DATA,
} from "../constants/ActionTypes";
import { httpGet } from "util/REST";
import registerNew from "../app/routes/MyClaims/registerNew";

export const getContactInfo = (omangId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getContactInfoCustCarePortal" + "?IDNumber=" + omangId)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: CONTACT_INFO_DATA, payload: data.response });

          // <registerNew data={data.response} />
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};
