import { GET_CLAIM_INFO_DATA } from "constants/ActionTypes";

const INIT_STATE = {
  getClaimInfoData: {},
  loader: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLAIM_INFO_DATA: {
      return {
        ...state,
        getClaimInfoData: action.payload,
        loader: false,
      };
    }
    default:
      return state;
  }
};
