import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR_MESSAGE,
  FORGOT_PASSWORD_SUCCESS,
  HIDE_PASSWORD_ERROR_MESSAGE,
  ON_SHOW_PASSWORD_LOADER,
} from "constants/ActionTypes";

export const forgotPassword = (data) => {
  return {
    type: FORGOT_PASSWORD,
    payload: data,
  };
};
export const forgotPasswordSuccess = (data) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: data,
  };
};
export const forgotPasswordError = (data) => {
  return {
    type: FORGOT_PASSWORD_ERROR_MESSAGE,
    payload: data,
  };
};
export const hidePasswordErrorMessage = (data) => {
  return {
    type: HIDE_PASSWORD_ERROR_MESSAGE,
  };
};
export const showPasswordLoader = (data) => {
  return {
    type: ON_SHOW_PASSWORD_LOADER,
  };
};
