import {
  BANK_DETAIL_DATA,
  BANK_DETAIL_LOADER,
  BANK_DETAIL_ERROR,
  RESET_BANK_DETAILS,
} from "../constants/ActionTypes";

const INIT_STATE = {
  bankLoader: false,
  bankDetailsHolder: [],
  bankDetailsMessage: "",
  bankDetailsShowMessage: false,
  bankDetailsStatus: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BANK_DETAIL_DATA: {
      return {
        ...state,
        bankLoader: false,
        bankDetailsHolder: action.payload,
      };
    }
    case BANK_DETAIL_LOADER: {
      return {
        ...state,
        bankLoader: true,
      };
    }

    case BANK_DETAIL_ERROR: {
      return {
        ...state,
        bankLoader: false,
        bankDetailsMessage: action.payload,
      };
    }

    case RESET_BANK_DETAILS: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    default:
      return state;
  }
};
