/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  hideResetPasswordErrorMessage,
  onShowResetPasswordLoader,
} from "actions/ForgotResetPassword";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { ErrorToaster, SuccessToaster, toastMsgs } from "util/ReactToastify";

import ContainerHeader from "../../../components/ContainerHeader/index";
import { changePasswordValidations } from "../../../constants/Validations";
// import 'containers/fontStyle.scss';
const ChangePassword = ({ props, match }) => {
  const { loader, alertMessage, showMessage, forgotResetPasswordStatus } =
    useSelector(({ forgotResetPassword }) => forgotResetPassword);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideResetPasswordErrorMessage());
      }, 100);
    }
  }, [showMessage]);

  useEffect(() => {
    if (forgotResetPasswordStatus === 200) {
      {
        toastMsgs.success(changePasswordValidations.passwordResetSuccess);
      }
      dispatch(hideResetPasswordErrorMessage());
    }
    return () => dispatch(hideResetPasswordErrorMessage());
  }, [forgotResetPasswordStatus]);

  return (
    <>
      <ContainerHeader match={match} />
      <div className="login-container custom-login-container d-flex">
        <div className="login-content">
          <div className="login-form">
            <Formik
              initialValues={initialValues}
              validationSchema={passwordSchema}
              onSubmit={(
                values,
                { setSubmitting, setErrors, setStatus, resetForm }
              ) => {
                const { oldPassword, newPassword, confirmNewPassword } = values;
                dispatch(onShowResetPasswordLoader());
                dispatch(
                  changePassword({
                    oldPassword,
                    newPassword,
                    confirmNewPassword,
                  })
                );
                resetForm({});
                setSubmitting(false);
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;

                return (
                  <form
                    autoComplete="on"
                    noValidate
                    onReset={handleReset}
                    onSubmit={handleSubmit}
                  >
                    <TextField
                      error={errors.oldPassword && touched.oldPassword}
                      className="form-textfield form-textfield-label"
                      id="oldPassword"
                      type="password"
                      label="Current Password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.oldPassword || ""}
                      onChange={handleChange}
                      placeholder="*******"
                      margin="normal"
                      required
                      onBlur={handleBlur}
                      fullWidth
                      helperText={
                        errors.oldPassword &&
                        touched.oldPassword &&
                        errors.oldPassword
                      }
                    />

                    <TextField
                      error={errors.newPassword && touched.newPassword}
                      className="form-textfield form-textfield-label"
                      id="newPassword"
                      type="password"
                      label="New Password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.newPassword || ""}
                      onChange={handleChange}
                      placeholder="*******"
                      margin="normal"
                      required
                      onBlur={handleBlur}
                      fullWidth
                      helperText={
                        errors.newPassword &&
                        touched.newPassword &&
                        errors.newPassword
                      }
                    />

                    <TextField
                      error={
                        errors.confirmNewPassword && touched.confirmNewPassword
                      }
                      className="form-textfield form-textfield-label"
                      id="confirmNewPassword"
                      type="password"
                      label="Re-type New Password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.confirmNewPassword || ""}
                      onChange={handleChange}
                      placeholder="*******"
                      margin="normal"
                      required
                      onBlur={handleBlur}
                      fullWidth
                      helperText={
                        errors.confirmNewPassword &&
                        touched.confirmNewPassword &&
                        errors.confirmNewPassword
                      }
                    />

                    <div className="mt-3 mb-2 d-flex justify-content-center align-items-center reset-pwd-btn">
                      <Button type="submit" variant="contained" color="primary">
                        Reset Password
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
            {/*  */}
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && <ErrorToaster message={alertMessage} />}
          <ToastContainer />
        </div>
      </div>
    </>
  );
};
const initialValues = {
  newPassword: "",
  confirmNewPassword: "",
  oldPassword: "",
};
const passwordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(
      /^(?=[A-Z])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      changePasswordValidations.passwordMustContain
    )
    .required(changePasswordValidations.passwordRequired),
  newPassword: Yup.string()
    .matches(
      /^(?=[A-Z])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      changePasswordValidations.passwordMustContain
    )
    .required(changePasswordValidations.passwordRequired)
    .test("", changePasswordValidations.newAndOldSameError, function (value) {
      return this.parent.oldPassword !== value;
    }),
  confirmNewPassword: Yup.string()
    .matches(
      /^(?=[A-Z])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      changePasswordValidations.passwordMustContain
    )
    .required(changePasswordValidations.passwordRequired)
    .test("", changePasswordValidations.passwordMatchError, function (value) {
      return this.parent.newPassword === value;
    }),
});

export default ChangePassword;
