/* eslint-disable no-unused-vars */
import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpAuthPost } from "util/REST";
import {
  signInMobileOtpSuccess,
  signInMobileOtpErrorMessage,
} from "actions/SignInOTP";
import { POST_SIGNIN_MOBILE_OTP } from "constants/ActionTypes";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const postMobileOtpApi = async (data) =>
  await httpAuthPost("verifyOTPOfMobile", data)
    .then((data) => data)
    .catch((error) => error);

function* PostMobileOtpReq({ payload }) {
  try {
    let body = payload;
    const otpResponse = yield call(postMobileOtpApi, payload);
    if (otpResponse && otpResponse.status >= 400) {
      yield put(signInMobileOtpErrorMessage(otpResponse.message));
    } else {
      localStorage.setItem(
        "user_id",
        JSON.stringify(otpResponse.response.contactNumber)
      );
      localStorage.setItem(
        "token",
        JSON.stringify(otpResponse.response.accessToken)
      );
      localStorage.setItem("role", JSON.stringify(otpResponse.response.role));
      localStorage.setItem(
        "omangNumber",
        JSON.stringify(otpResponse.response.omangNumber)
      );
      yield put(signInMobileOtpSuccess(otpResponse.status));

      cookies.set("token", JSON.stringify(otpResponse.response.accessToken), {
        expires: 1000 * 60 * 5,
      });
    }
  } catch (error) {
    yield put(signInMobileOtpErrorMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(POST_SIGNIN_MOBILE_OTP, PostMobileOtpReq)]);
}
