import {
  CHANGE_PASSWORD,
  FORGOT_RESET_PASSWORD,
  FORGOT_RESET_PASSWORD_ERROR_MESSAGE,
  FORGOT_RESET_PASSWORD_SUCCESS,
  ON_SHOW_RESET_PASSWORD_LOADER,
  HIDE_RESET_PASSWORD_ERROR_MESSAGE,
} from "../constants/ActionTypes";

export const changePassword = (data) => {
  return {
    type: CHANGE_PASSWORD,
    payload: data,
  };
};

export const forgotResetPassword = (data) => {
  return {
    type: FORGOT_RESET_PASSWORD,
    payload: data,
  };
};

export const forgotResetPasswordSuccess = (data) => {
  return {
    type: FORGOT_RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const forgotResetPasswordError = (data) => {
  return {
    type: FORGOT_RESET_PASSWORD_ERROR_MESSAGE,
    payload: data,
  };
};
export const onShowResetPasswordLoader = () => {
  return {
    type: ON_SHOW_RESET_PASSWORD_LOADER,
  };
};
export const hideResetPasswordErrorMessage = () => {
  return {
    type: HIDE_RESET_PASSWORD_ERROR_MESSAGE,
  };
};
