import {
  POST_MOBILE_EMAIL_OTP_ERROR_MESSAGE,
  POST_MOBILE_EMAIL_OTP_SUCCESS,
  ON_SHOW_OTP_LOADER,
  HIDE_OTP_ERROR_MESSAGE,
} from "../constants/ActionTypes";

const INIT_STATE = {
  otpLoader: false,
  otpStatus: "",
  otpAlertMessage: "",
  otpShowMessage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_MOBILE_EMAIL_OTP_SUCCESS: {
      return {
        ...state,
        otpLoader: false,
        otpStatus: action.payload,
      };
    }
    case POST_MOBILE_EMAIL_OTP_ERROR_MESSAGE: {
      return {
        ...state,
        otpAlertMessage: action.payload,
        otpLoader: false,
        otpShowMessage: true,
      };
    }
    case HIDE_OTP_ERROR_MESSAGE: {
      return {
        ...state,
        otpAlertMessage: "",
        otpShowMessage: false,
        otpLoader: false,
        otpStatus: "",
      };
    }
    case ON_SHOW_OTP_LOADER: {
      return {
        ...state,
        otpLoader: true,
      };
    }
    default:
      return state;
  }
};
