import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpPost } from "util/REST";
import {
  requestRegistrationSuccess,
  showRequestRegistrationError,
} from "actions/RegistrationRequest";
import { USER_REGISTRATION_REQUEST } from "constants/ActionTypes";

const getAllusersApiRequest = async (data) =>
  await httpPost("usersByStatus", data)
    .then((data) => data)
    .catch((error) => error);

function* getAllusers({ payload }) {
  try {
    let body = payload;
    const usersData = yield call(getAllusersApiRequest, body);
    if (usersData && usersData.status >= 400) {
      yield put(showRequestRegistrationError(usersData));
    } else {
      yield put(
        requestRegistrationSuccess(usersData.response.userAndPolicyDetails)
      );
    }
  } catch (error) {
    yield put(showRequestRegistrationError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(USER_REGISTRATION_REQUEST, getAllusers)]);
}
