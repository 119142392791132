import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";

const PaymentDialog = ({
  open,
  closePay,
  policyName,
  policyHolderName,
  policyNumber,
  pushPayment,
  handlePaymentClose,
  premiumAmount,
  changeAmount,
  currency,
}) => {
  return (
    <>
      <Dialog
        overlayStyle={{ backgroundColor: "transparent" }}
        fullWidth
        open={open}
        className="custom-confirm-dialog"
      >
        <DialogTitle className="custom-dialog-title">
          {"Add payment details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="custom-details">
            <div
              className="table-responsive-material markt-table"
              style={{ border: "none" }}
            >
              <table className="table default-table custom-details-table table-sm full-table remove-table-border table-hover mb-0">
                <tbody>
                  <tr>
                    <td className="text-dark">Policy Name</td>
                    <td>:</td>
                    <td>{policyName}</td>
                  </tr>
                  <tr>
                    <td className="text-dark">Policy Holder</td>
                    <td>:</td>
                    <td>{policyHolderName}</td>
                  </tr>
                  <tr>
                    <td className="text-dark">Policy Number</td>
                    <td>:</td>
                    <td>{policyNumber}</td>
                  </tr>
                  <tr>
                    <td className="text-dark">Currency</td>
                    <td>:</td>
                    <td>{currency}</td>
                  </tr>
                  <tr>
                    <td className="text-dark">Premium Amount</td>
                    <td>:</td>
                    <td>
                      <input
                        value={premiumAmount}
                        onChange={changeAmount}
                      ></input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirm-btns">
          <Button onClick={handlePaymentClose} className="no">
            Cancel
          </Button>
          <Button onClick={pushPayment} className="yes">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentDialog;
