/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Card } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import {
  CircularProgress,
  Select,
  MenuItem,
  Input,
  Button,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { signUpValidation } from "../../../constants/Validations";
import moment from "moment";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Radio from "@material-ui/core/Radio";
import ContainerHeader from "../../../components/ContainerHeader/index";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomImageInput from "./CustomImageInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegisterNewClaimData,
  resetRegisterNewClaimData,
  postRegisterNewClaimData,
} from "actions/RegisterNewClaimAction";
import {
  getReporterDetails,
  resetReporterDetailsData,
} from "actions/GetReporterDetailsAction";
import { hideErrorMessage } from "actions/Common";
import { hideMessage } from "actions/Auth";
import { ToastContainer } from "react-toastify";
import { withStyles } from "@material-ui/styles";
import { ErrorToaster, toastMsgs, SuccessToaster } from "util/ReactToastify";
import { userSignOut } from "actions";
import {
  claimsDocsUpload,
  edrmsFilesPush,
  restEdrmsFiles,
} from "actions/ClaimsDocsUpload";
import UploadFiles from "./UploadFile";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { httpGet, httpPost } from "util/REST";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import ClaimKYCNotification from "./ClaimKYCNotification";

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
    },
  },
})(TextField);

const useStyles = makeStyles(() => ({
  accordionRoot: {
    width: "100%",
  },
  accordionHeading: {
    fontWeight: 500,
    fontFamily: "Roboto Helvetica Arial sans-serif",
    fontSize: "0.9375rem",
  },
}));
//const [responseMessage, setMessageResponse] = React.useState("");
let responseMessage = null;
export const handleResponseMessage = (message) => {
  // setMessageResponse(message);
  responseMessage = message;
};

const DeathMsgNotify = (props) => {
  // console.log("DeathMsgNotify {} :" + props.statusClaimtype);
  if (props.statusClaimtype) {
    return (
      <React.StrictMode>
        <ErrorToaster
          message={"Functionality under development. Contact 362 7000"}
        />
      </React.StrictMode>
    );
  } else {
    return "";
  }
};
const CustomToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function RegisterCase({ match, data }) {
  // console.log("RegisterCase started");

  // console.log(data)
  const dispatch = useDispatch();
  const { loading, error, message, statusCode } = useSelector(
    ({ commonData }) => commonData
  );
  const { claimsDocsData, edrmsData } = useSelector(
    ({ claimsDocsUpload }) => claimsDocsUpload
  );

  const { registerNewClaimData, regClaimsStatus } = useSelector(
    ({ registerNewClaimReducer }) => registerNewClaimReducer
  );
  const { reporterData } = useSelector(
    ({ getReporterDetailsReducer }) => getReporterDetailsReducer
  );
  const { kycStatus } = useSelector(({ KycStatusReducer }) => KycStatusReducer);
  const [policyNumber, setPolicyNumber] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const [fileData, setFileData] = React.useState([]);
  const [insurredId, setInsurredId] = React.useState([]);
  const [thePolicyCode, setThePolicyCode] = React.useState([]);
  const [claimSubmit, setClaimSubmit] = React.useState("");

  const [IDProofFile, setIDProofFile] = React.useState(null);
  const [bankProofFile, setBankProofFile] = React.useState(null);
  const [deathProofFile, setDeathProofFile] = React.useState(null);

  const [claimTypeDeath, setClaimTypeDeath] = React.useState(false);

  const [FullyComplientKycToast, setFullyComplientKycToast] =
    React.useState(false);
  const [disableKyc, setDisableKyc] = React.useState(false);

  const [reportCheck, setReportCheck] = React.useState(false);
  const [reportCheckToast, setReportCheckToast] = React.useState(false);
  const [radioLoader, SetRadioLoader] = React.useState(false);
  const [relationshipToast, setRelationshipToast] = useState(false);
  const [claimNatureToast, setClaimNatureToast] = React.useState(false);
  const [eventDateToast, setEventDateToast] = React.useState(false);

  const [reporterResponse, SetReporterResponse] = React.useState("");
  const [paymentEFT, setPaymentEFT] = React.useState(false);
  const [lifeRewardCardPayment, setLifeRewardCardPayment] =
    React.useState(false);
  const [payment, setpayment] = React.useState("");
  const [_claimType, _setClaimType] = React.useState("none");
  const [_policyNumber, _setPolicyNumber] = React.useState("none");
  const [policyList, setPolicyList] = React.useState([]);

  const [policyListFilterElmnts, setPolicyListFilterElmnts] = React.useState(
    []
  );
  const [insurredPolicySame, setInsurredPolicySame] = React.useState([]);
  const [selectedPolicyListSame, setSelectedPolicyListSame] = React.useState(
    []
  );
  const [insurredPolicyList, setInsurredPolicyList] = React.useState([]);
  const [selectPolicyListByCategoryName, setSelectPolicyListByCategoryName] =
    React.useState([]);
  const [statusOfKyc, setStatusOfKyc] = useState("");
  const [kycLoader, setKycLoader] = useState(false);

  const [uniquePolicyList, setUniquePolicyList] = React.useState([]);
  const [branchLists, setBranchLists] = React.useState([]);
  const [branch, setBranch] = React.useState(0);

  //console.log("KYC status: ", JSON.parse(localStorage.getItem("kycStatus")))

  const [claimDocValidation, setClaimDocValidation] = React.useState({
    CLAIMSIDPROOF: false,
    CLAIMSBANKPROOF: false,
    CLAIMSDEATHPROOF: false,
  });

  const [selectedValue, setSelectedValue] = React.useState("");
  var idNumber = JSON.parse(localStorage.getItem("omangNumber"));
  //const KYCstatus = JSON.parse(localStorage.getItem("kycStatus"));

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  useEffect(() => {
    const getUserKyc = () => {
      setKycLoader(true);

      httpGet(`getKycStatus`)
        .then((data) => {
          const kycStatusResponse = data.response;
          console.log("kycStatusResponse ", kycStatusResponse);

          setStatusOfKyc(kycStatusResponse.statusName);

          console.log("kycStatus : ", statusOfKyc);

          setKycLoader(false);
        })
        .catch((error) => {
          setKycLoader(false);
          console.log(error);
        });
    };

    getUserKyc();
  }, [statusOfKyc]);

  useEffect(() => {
    if (idNumber !== null) {
      dispatch(getReporterDetails(idNumber));
    }
  }, [idNumber]);

  useEffect(() => {
    dispatch(getRegisterNewClaimData());
    return () => {
      dispatch(resetRegisterNewClaimData());
      dispatch(restEdrmsFiles());
    };
  }, []);
  useEffect(() => {
    if (statusCode === 401) {
      toastMsgs.error(error);
      setTimeout(() => dispatch(userSignOut()), 300);
    }
  }, [statusCode]);

  // useEffect(()=>{
  //   //
  //   if(edrmsData.status === 200){
  //     toastMsgs.success(edrmsData.response)
  //      setTimeout(() => dispatch(restEdrmsFiles()), 300);
  //   }
  // },[edrmsData, dispatch])

  useEffect(() => {
    //
    if (statusCode === 200) {
      toastMsgs.success(edrmsData.response);
      setTimeout(() => dispatch(resetRegisterNewClaimData()), 300);
      setTimeout(() => dispatch(getRegisterNewClaimData()), 300);
      setTimeout(() => dispatch(restEdrmsFiles()), 300);
      handleResetPage();
    }
  }, [statusCode, regClaimsStatus, dispatch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => dispatch(hideErrorMessage()), 300);
    }
  }, [error, dispatch]);

  const handleResetPage = () => {
    // setPolicyNumber("");
    setFileData([]);
    setClaimDocValidation({
      CLAIMSIDPROOF: false,
      CLAIMSBANKPROOF: false,
      CLAIMSDEATHPROOF: false,
    });

    setIDProofFile(null);
    setBankProofFile(null);
    setDeathProofFile(null);

    // document.getElementById("file1").value = null;
    // document.getElementById("file2").value = null;
    // document.getElementById("file3").value = null;
    dispatch(resetRegisterNewClaimData());
    dispatch(resetReporterDetailsData());
    dispatch(restEdrmsFiles());
    handleResetPolicyDropDown();
    handleResetClaimType();

    // dispatch(getRegisterNewClaimData());
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
      }, 100);
    }
    if (message) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
      }, 100);
    }
  }, [error, loading, message, dispatch]);

  // const tablehandle = (event, claimpolicy) => {
  //   reportIdChecking(claimpolicy);
  // };
  let insuredId = null;
  let InsuredName = null;
  for (let i = 0; i < registerNewClaimData.length; i++) {
    // console.log("registerNewClaimData {} :");
    // console.log(registerNewClaimData);
    // console.log(policyNumber);
    // console.log(registerNewClaimData[i].policyNumber);
    // console.log(
    //   registerNewClaimData[i].policyNumber === policyNumber.toString()
    // );
    if (
      policyNumber &&
      registerNewClaimData[i].policyNumber === policyNumber.toString()
    ) {
      // console.log("inside policy number ");
      insuredId = registerNewClaimData[i].insuredId;
      InsuredName = registerNewClaimData[i].insuredName;
      break;
    }
  }

  // checking self insured person is claiming record
  //reporterData.length>0?(reporterData.reporterId) :''

  const FILE_SIZE = 300 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "application/pdf"];
  const initialValues = {
    reporterName: `${
      reporterData.reporterName ? reporterData.reporterName : ""
    }`,
    // relationshipof: `${
    //   reporterData.reporterName ? reporterData.relationToPH : "none"
    // }`,
    relationshipof: `${"none"}`,
    mobileNumber: `${reporterData.mobile ? `${reporterData.mobile}` : ""}`,
    telephoneNumber: `${reporterData.mobile ? `${reporterData.mobile}` : ""}`,
    email: `${
      reporterData.email
        ? reporterData.email === "N/A"
          ? ""
          : reporterData.email
        : ""
    }`,
    address: `${reporterData.address ? reporterData.address : ""}`,
    claimsType: _claimType,
    eventDate: null,
    eventLocation: "",
    eventDetails: "",
    claimsNature: "none",
    file1: undefined,
    file2: undefined,
    file3: undefined,
  };

  const [noReporter, setNoReporter] = useState(false);
  const [selectItemId, setSelectItemId] = useState("");
  const [selectproductId, setSelectproductId] = useState("");
  const [selectedcatagoryName, setSelectedCategoryName] = useState("");
  const [selectedClaimTypeDesc, setSelectedClaimTypeDesc] = useState("");
  const [selectedClaimType, setSelectedClaimType] = useState("");

  // useEffect(() => {
  // }, [selectedValue, selectItemId, selectproductId]);

  useEffect(() => {}, [selectedValue], [selectItemId], [selectproductId]);

  //////////////////////////////////////////////////////////

  const reportIdChecking = async (e, claimpolicy) => {
    responseMessage = null;
    setInsurredId(claimpolicy.insuredId);
    setThePolicyCode(claimpolicy.policyCode);
    console.log(claimpolicy.insuredId);
    setSelectedValue(claimpolicy.insuredId);
    setSelectItemId(claimpolicy.itemId);
    setSelectproductId(claimpolicy.productId);

    setSelectedCategoryName(claimpolicy.categoryName);
    setSelectedClaimTypeDesc(claimpolicy.claimTypeDesc);
    setSelectedClaimType(claimpolicy.claimType);
    console.log(registerNewClaimData);

    console.log("selected value from table");
    console.log(claimpolicy);
    SetRadioLoader(true);

    // console.log("reporterDetails", reporterDetails);

    ///getReporterDetails

    // let idNumber = JSON.parse(localStorage.getItem("omangNumber"));

    // await httpGet(
    //   "getReporterDetails" + "?policyNumber=" + claimpolicy.policyCode
    // )

    // await httpPost(
    //   "documentStaging" + "?fileType="+ fileType + "?policyNo=" +claimpolicy.policyCode+ "?insuredId"+insurredId
    // )

    // console.log("kycStatus", kycStatus);
    await httpGet(
      "getReporterDetails" + "?policyNumber=" + idNumber,
      //"getReporterDetails" + "?policyNumber=" + claimpolicy.policyCode

      console.log(claimpolicy.policyCode)
    )
      .then((data) => {
        const reporterDetailsResponseObj = data.response;
        reporterData.reporterName = reporterDetailsResponseObj.reporterName;
        reporterData.mobile = reporterDetailsResponseObj.mobile;
        reporterData.email = reporterDetailsResponseObj.email;
        reporterData.address = reporterDetailsResponseObj.address;
        reporterData.gender = reporterDetailsResponseObj.gender;
        reporterData.policyCode = policyNumber;
        reporterData.relationToPH = reporterDetailsResponseObj.relationToPH;
        reporterData.reporterId = reporterDetailsResponseObj.reporterId;

        console.log("data reporter");

        if (data.message === "No reporter details found for that policy") {
          // let idNumber = JSON.parse(localStorage.getItem("omangNumber"));

          httpGet("getContactInfoCustCarePortal" + "?IDNumber=" + idNumber)
            .then((data) => {
              console.log(data);
              let contactCustPortalResponseData = data.response;
              console.log(contactCustPortalResponseData);
              if (
                contactCustPortalResponseData.name == null &&
                contactCustPortalResponseData.mobile == null &&
                contactCustPortalResponseData.email == null &&
                contactCustPortalResponseData.address == null
              ) {
                // //  reporterData.reporterName = contactCustPortalResponseData.reporterName
                // reporterData.reporterName = 'Mia Tawana'
                // reporterData.mobile = '982525522'
                // //  reporterData.mobile = contactCustPortalResponseData.mobile
                // reporterData.email = 'miatawana@gmail.com'
                // // reporterData.email = contactCustPortalResponseData.email
                // // reporterData.address =contactCustPortalResponseData.address
                // reporterData.address = 'botswana'
              } else {
                reporterData.reporterName = contactCustPortalResponseData.name;
                reporterData.mobile = contactCustPortalResponseData.mobile;
                reporterData.email = contactCustPortalResponseData.email;
                reporterData.address = contactCustPortalResponseData.address;
                reporterData.gender = contactCustPortalResponseData.gender;
                reporterData.policyCode = policyNumber;
                reporterData.reporterId = contactCustPortalResponseData.idNum;
              }
            })
            .catch(function (error) {
              console.log("Error****:", error.message);
            });

          SetRadioLoader(false);
          setNoReporter(true);
          setReportCheck(true);
        }

        if (data.status == 200) {
          console.log(claimpolicy);
          console.log(data);
          let reportedmember = data.response.reporterId;
          let reporterResponse = data.response;
          console.log(reportedmember);
          console.log(claimpolicy.insuredId);
          console.log(claimpolicy.insuredId.toString() === reportedmember);

          // reporterData.reporterName = reporterResponse.reporterName
          // reporterData.mobile = reporterResponse.mobile
          // reporterData.email = reporterResponse.email
          // reporterData.address = reporterResponse.address

          if (claimpolicy.insuredId.toString() !== reportedmember) {
            setReportCheck(true);
            //setReportCheck(false);
            //setReportCheckToast(true);
          } else {
            //setReportCheck(true);
            setReportCheck(false);
            setReportCheckToast(true);
          }
          SetRadioLoader(false);
        }
      })
      .catch(function (error) {
        SetRadioLoader(false);
        console.log("Error****:", error.message);
      });

    await httpGet("branch")
      .then((data) => {
        console.log(data);
        setBranchLists(data);
        console.log("This is the branch list: ", branchLists);
      })
      .catch(function (error) {
        console.log("Error****:", error.message);
      });

    if (disableKyc) {
      setFullyComplientKycToast(true);
      setReportCheck(false);
    }
  };

  /////////////////////////////////////////////////////

  const validationSchema = Yup.object().shape({
    reporterName: Yup.string().required("Reporter Name is required"),
    email: Yup.string().email("Please enter valid Email Address"),
    telephoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Telephone Number can be 0-9 digits only")
      .max(15, "Invalid Telephone Number")
      .required("Telephone Number is required"),
    relationshipof: Yup.string()
      .required("Please select relationship")
      .test((value) => value !== "none"),
    claimsType: Yup.string()
      .required("Please enter Claims Type")
      .test((value) => value !== "none"),
    eventDate: Yup.date()
      .max(new Date(), "Cannot be future date")
      .typeError("Please enter valid Event Date")
      .required("Please enter Event Date"),
    claimsNature: Yup.string()
      .required("Please enter Claims Nature")
      .test((value) => value !== "none"),
  });

  useEffect(() => {
    if (policyNumber) {
      if (document.getElementById("file1")) {
        document.getElementById("file1").value = null;
      }
      if (document.getElementById("file2")) {
        document.getElementById("file2").value = null;
      }
      if (document.getElementById("file3")) {
        document.getElementById("file3").value = null;
      }
    }
  }, [policyNumber]);

  const claimNature = [
    { key: "none", value: "Select Claim Nature", disable: true },
    { key: 2, value: "Accident" },
    // { key: 1, value: "Illness" },
    { key: 1, value: "Non-accidental" },
    { key: 3, value: "Suicide" },
  ];

  const claimRelation = [
    { key: "none", value: "Select Relationship with insured", disable: true },
    { key: 18, value: "Administrator" },
    { key: 15, value: "Assignee" },
    { key: 2, value: "Child" },
    { key: 8, value: "Employee" },
    { key: 7, value: "Employer" },
    { key: 27, value: "Executor" },
    { key: 26, value: "Executrix" },
    { key: 9, value: "Heir" },
    // {
    //   key: `${reporterData.relationToPH}`,
    //   value: `${reporterData.relationToPH}`,
    // },
    { key: 14, value: "Niece/Nephew" },
    { key: 11, value: "Official Assignee" },
    { key: 6, value: "Other" },
    { key: 3, value: "Parent" },
    { key: 12, value: "Public Trustee" },
    { key: 4, value: "Relative" },
    { key: 1001, value: "Self" },
    { key: 13, value: "Siblings" },
    { key: 1, value: "Spouse" },
    { key: 10, value: "Trustee" },
  ];

  const handleFile = (data) => {
    if (data && data !== null) {
      setFileData([...fileData, { ...data, policyCode: insuredId }]);
    }
  };

  const { CLAIMSIDPROOF, CLAIMSBANKPROOF, CLAIMSDEATHPROOF } =
    claimDocValidation;
  // console.log(claimDocValidation, "claimDocValidation");

  useEffect(() => {
    if (fileData.length) {
      const docTypes = ["CLAIMSIDPROOF", "CLAIMSBANKPROOF", "CLAIMSDEATHPROOF"];

      let tempClaimDocValidation = {
        CLAIMSIDPROOF: false,
        CLAIMSBANKPROOF: false,
        CLAIMSDEATHPROOF: false,
      };

      const uploadedDocTypes = fileData.map((item) => item.fileType);

      docTypes.forEach((item) => {
        if (!uploadedDocTypes.includes(item)) {
          tempClaimDocValidation[item] = true;
        }
      });

      setClaimDocValidation({
        ...claimDocValidation,
        ...tempClaimDocValidation,
      });
    }
  }, [fileData]);

  const removeDoc = (fileType) => {
    const data = fileData.filter((item) => item.fileType !== fileType);
    setFileData(data);
  };

  const classesAccordin = useStyles();

  // nmethods used for handling claimtype api
  const [deathClaimTypeError, setDeathClaimTypeError] = useState(false);
  const [policyByOmangNumError, setPolicyByOmangNumError] = useState(false);
  const [policyByOmangNum, setPolicyByOmangNum] = useState(false);
  const [claimTypeAPiLoader, setClaimTypeAPiLoader] = useState(false);
  const [panel1, setPanel1] = React.useState(false);
  const [panel2, setPanel2] = React.useState(false);
  const [panel3, setPanel3] = React.useState(false);
  const [center, setCenter] = React.useState({
    lat: 59.95,
    lng: 30.33,
  });
  const [zoom, setZoom] = React.useState({
    zoom: 11,
  });

  const _onChangeClaimType = async (event) => {
    // used for resetting all set values
    let idNumber = JSON.parse(localStorage.getItem("omangNumber"));
    //let statusOfKyc = null;
    console.log(localStorage.getItem("omangNumber"));
    console.log(idNumber);
    handleResetClaimType();
    await httpGet(`getKycStatus`)
      .then((data) => {
        const kycStatusResponse = data.response;
        console.log("kycStatusResponse ", kycStatusResponse);

        setStatusOfKyc(kycStatusResponse.statusName);

        console.log("kycStatus : ", statusOfKyc);

        if (data.response === undefined) {
          setClaimTypeAPiLoader(false);
          console.log(error);
        }
      })
      .catch((error) => {
        setClaimTypeAPiLoader(false);
        console.log(error);
      });

    if (statusOfKyc !== "Fully-Compliant") {
      await httpGet(`isKycRequestExists?omangNumber=` + idNumber)
        .then((data) => {
          console.log(data);

          const kycSubmitted = data;

          console.log("kycStatus", statusOfKyc);
          if (data === 0 || data === undefined) {
            setFullyComplientKycToast(true);
            setDisableKyc(true);
          }
        })
        .catch((error) => {
          setClaimTypeAPiLoader(false);
          console.log(error);
        });
    }
    _setClaimType(event.target.value);
    if (event.target.value === "3") {
      setDeathClaimTypeError(true);
      return;
    }
    _setClaimType(event.target.value);
    if (event.target.value === "4") {
      setDeathClaimTypeError(true);
      return;
    }
    _setClaimType(event.target.value);
    if (event.target.value === "5") {
      setDeathClaimTypeError(true);
      return;
    }

    let claimType = event.target.value;

    //getInvestmentReports?policyCode=${4003832615}&fromDate=${2019}&toDate=${2020}
    setClaimTypeAPiLoader(true);
    await httpGet(
      `getInsuredListByClaimType?claimType=${claimType}&idNumber=${idNumber}`
    )
      .then((data) => {
        setClaimTypeAPiLoader(false);
        console.log(data);
        console.log(data.response);
        let responseForClaimType = data.response;
        //console.log(responseArray.length)
        if (data.response.length === undefined) {
          console.log("no polices found by using omangNumber");
          setPolicyByOmangNum(false);
          setPolicyByOmangNumError(true);
          console.log("error status: ", policyByOmangNumError);
          return;
        } else if (data.response === null) {
          console.log("no polices found by using omangNumber");
          setPolicyByOmangNum(false);
          setPolicyByOmangNumError(true);
          console.log("error status: ", policyByOmangNumError);
          return;
        }
        setPolicyByOmangNum(true);
        let count = 1;
        var addedUniqueForClaimTypeResponse = responseForClaimType.map(
          function (el) {
            var o = Object.assign({}, el);
            o.uniqueId = count;
            count++;
            return o;
          }
        );
        // console.log(responseForClaimType);
        // console.log(addedUniqueForClaimTypeResponse);
        claimTypelistResult(addedUniqueForClaimTypeResponse);
      })
      .catch((error) => {
        setClaimTypeAPiLoader(false);
        console.log("no polices found by using omangNumber");
        setPolicyByOmangNum(false);
        setPolicyByOmangNumError(true);
        console.log("error status: ", policyByOmangNumError);
        console.log(error);
      });
  };
  //final api response data (policyList)
  const claimTypelistResult = async (value) => {
    await setPolicyList(value);
    console.log(policyList);
  };

  const policyFilter = () => {
    let policyListByCategoryName = null;
    //policyListByCategoryName = policyList
    policyListByCategoryName = policyList.filter(
      (ph) => ph.categoryName === "Funeral"
    );
    console.log(policyListByCategoryName);
    const uniquePolicyNum = [
      ...new Set(policyListByCategoryName.map((item) => item.policyCode)),
    ];
    //policyListByCategoryName = uniquePolicyNum.filter((ph) => (ph.categoryName == "Funeral"));

    setPolicyListFilterElmnts(uniquePolicyNum);
    setSelectPolicyListByCategoryName(policyListByCategoryName);
  };

  useEffect(() => {
    if (policyList.length > 0) {
      policyFilter();
    }
  }, [policyList]);
  useEffect(() => {}, [insurredPolicySame]);

  //console.log(selectPolicyListByCategoryName)

  const handlePolicyNum = async (event) => {
    await handleResetPolicyDropDown();
    let policyValue = event.target.value;
    setPolicyNumber(policyValue);

    let insurredList = [];

    setInsurredPolicySame(insurredList);

    insurredList = selectPolicyListByCategoryName.filter(
      (ph) => ph.policyCode === policyValue
    );

    setInsurredPolicySame(insurredList);

    console.log("insurredList : " + insurredList);
    /// productId
    let uniqueItemData = Array.from(
      new Set(insurredList.map((a) => a.itemId))
    ).map((id) => {
      return insurredList.find((a) => a.itemId === id);
    });

    let uniqueInsurredIdData = Array.from(
      new Set(uniqueItemData.map((a) => a.insuredId))
    ).map((id) => {
      return uniqueItemData.find((a) => a.insuredId === id);
    });

    setUniquePolicyList(uniqueInsurredIdData);

    console.log("UNIQUE DATA", uniqueInsurredIdData);

    await setInsurredPolicySame(() => {
      setInsurredPolicySame(insurredList);
    });
  };

  const handlePanel1 = () => (event, isExpanded) => {
    setPanel1(isExpanded);
    setPanel2(false);
    setPanel3(false);
  };
  //setInsurredPolicySame(() => setInsurredPolicySame(result))
  const handlePanel2 = () => (event, isExpanded) => {
    setPanel2(isExpanded);
    setPanel1(false);
    setPanel3(false);
  };
  const handlePanel3 = () => (event, isExpanded) => {
    setPanel3(isExpanded);
    setPanel1(false);
    setPanel2(false);
  };

  const reporterInfoTabHandle = () => {
    _setClaimType(_claimType);
    setPanel1(true);
    setPanel2(false);
    setPanel3(false);
  };
  const claimEventTabHandle = () => {
    setPanel1(false);
    setPanel2(true);
    setPanel3(true);
  };

  // const claimTypeHandle = (claimNature, eventDate) => {
  //   console.log(claimNature);
  //   console.log(eventDate);
  //   if (claimNature !== "none" && eventDate !== null) {
  //     claimTypeChange();
  //   }
  // };

  useEffect(() => {
    console.log("trying to set the claimType");
  }, [_claimType]);

  // const successRegisterClaimHandler = (message) => {
  //   if (message == 'New claim is successfully registered') {

  //     setPolicyNumber("");
  //     setInsurredPolicySame([]);
  //     setSelectedPolicyListSame([]);
  //     setSelectPolicyListByCategoryName([]);
  //     setUniquePolicyList([]);
  //     setReportCheck(false);
  //   }
  // }

  const handleResetClaimType = () => {
    setPolicyByOmangNum(false);
    setPolicyNumber("");
    setInsurredPolicySame([]);
    setSelectedPolicyListSame([]);
    setSelectPolicyListByCategoryName([]);
    setUniquePolicyList([]);
    setReportCheck(false);
  };

  const handleResetPolicyDropDown = () => {
    setPolicyNumber("");
    setInsurredPolicySame([]);
    setSelectedValue("");
    setSelectedPolicyListSame([]);
    setSelectPolicyListByCategoryName([]);
    setUniquePolicyList([]);
    setReportCheck(false);
  };

  return (
    <>
      <ContainerHeader match={match} />
      {kycLoader ? (
        <div className="loader-view">
          <CircularProgress />
        </div>
      ) : (
        <>
          {statusOfKyc === "Fully-Compliant" ? (
            <>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onReset={(values) => {}}
                onSubmit={(values, { setSubmitting }) => {
                  const {
                    reporterName,
                    relationshipof,
                    mobileNumber,
                    email,
                    address,
                    claimsType,
                    eventDate,
                    eventLocation,
                    eventDetails,
                    claimsNature,
                    file1,
                    file2,
                    file3,
                  } = values;
                  let data = {
                    accidentTime: moment(eventDate).format("DD/MM/YYYY"),
                    accierGender: reporterData.gender,
                    accierIdNumber: kycStatus.idNumber,
                    accierIdType: claimsNature.toString(),
                    accierName: InsuredName,
                    address1: address,
                    claimType: claimsType.toString(),
                    insuredId: insurredId,
                    policyCode: reporterData.policyCode,
                    reporter: reporterData.reporterId,
                    rptrEmail: email,
                    rptrMp: mobileNumber,
                    rptrName: reporterName,
                    rptrRelation: relationshipof.toString(),
                    rptrTel: mobileNumber,
                    rptrZip: "",
                    edrmsDetails: fileData,
                    causeOfDeath: eventDetails,
                    eventLocation: eventLocation,
                    claimNature: claimsNature,
                    branchId: branch,
                  };

                  // if (fileData && fileData.length === 3) {
                  //   // dispatch(edrmsFilesPush(fileData));
                  //   dispatch(postRegisterNewClaimData(data));
                  // }
                  // setSubmitting(false);
                  if (paymentEFT) {
                    if (fileData && fileData.length === 3) {
                      // dispatch(edrmsFilesPush(fileData));
                      dispatch(postRegisterNewClaimData(data));
                    }
                  } else if (!paymentEFT) {
                    if (fileData && fileData.length === 2) {
                      dispatch(postRegisterNewClaimData(data));
                    }
                  }

                  //logging insurredId and Policy Code
                  console.log(
                    "insurredId",
                    insurredId,
                    "policyCode",
                    reporterData.policyCode
                  );

                  console.log(setSubmitting);
                  setSubmitting(false);

                  //start

                  const validateFiles = () => {
                    const docTypes = [
                      "CLAIMSIDPROOF",
                      "CLAIMSBANKPROOF",
                      "CLAIMSDEATHPROOF",
                    ];

                    let tempClaimDocValidation = {
                      CLAIMSIDPROOF: false,
                      CLAIMSBANKPROOF: false,
                      CLAIMSDEATHPROOF: false,
                    };

                    const uploadedDocTypes = fileData.map(
                      (item) => item.fileType
                    );

                    docTypes.forEach((item) => {
                      if (!uploadedDocTypes.includes(item)) {
                        tempClaimDocValidation[item] = true;
                      }
                    });

                    setClaimDocValidation({
                      ...claimDocValidation,
                      ...tempClaimDocValidation,
                    });
                  };

                  validateFiles();

                  // end
                }}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    setFieldValue,
                  } = props;

                  return (
                    <>
                      <Card className="register-new-claims">
                        <div
                          className="claim-card-header"
                          style={{ textAlign: "start" }}
                        >
                          <h4 className="card-title mb-0">
                            Register New Claim
                          </h4>
                        </div>

                        <div class="container">
                          <div className="row">
                            <div
                              className="col-md-2 col-lg-2 col-xl-2 col-sm-12"
                              style={{ marginTop: "30px", textAlign: "start" }}
                            >
                              <span>Claim Type :</span>
                            </div>
                            <div
                              className="col-md-8 col-lg-4 col-xl-4 col-sm-12"
                              style={{ textAlign: "start", marginTop: "30px" }}
                            >
                              <Select
                                className="custom-country-code"
                                name="_claimType"
                                style={{ width: "60%", textAlign: "center" }}
                                onChange={_onChangeClaimType}
                                value={_claimType}
                              >
                                <MenuItem value="none" disabled>
                                  Select Claim Type
                                </MenuItem>
                                {/*
                        <MenuItem value="3">Cashback</MenuItem>
                        <MenuItem value="4">Maturity</MenuItem>
                        */}
                                <MenuItem value="5">Encashment</MenuItem>
                                <MenuItem value="15">Funeral</MenuItem>
                              </Select>
                            </div>
                            <div
                              className="col-md-2 col-lg-2 col-xl-2 col-sm-12"
                              style={{}}
                            ></div>
                          </div>
                        </div>
                        <div className="register-new-table-loader-view">
                          {claimTypeAPiLoader && <CircularProgress />}
                        </div>
                        {_claimType !== "none" &&
                        _claimType == "15" &&
                        policyByOmangNum ? (
                          <div className="container">
                            <div className="row">
                              <div
                                className="col-md-2 col-lg-2 col-xl-2 col-sm-12"
                                style={{
                                  marginTop: "30px",
                                  textAlign: "start",
                                }}
                              >
                                <span>Policy Number :</span>
                              </div>

                              <div
                                className="col-md-8 col-lg-8 col-xl-8 col-sm-12"
                                style={{
                                  textAlign: "start",
                                  marginTop: "30px",
                                }}
                              >
                                <Select
                                  className="custom-country-code"
                                  name="_policyNumber"
                                  style={{ width: "28%", textAlign: "center" }}
                                  onChange={handlePolicyNum}
                                  value={policyNumber}
                                >
                                  <MenuItem value="none" disabled>
                                    Select policy number
                                  </MenuItem>
                                  {policyListFilterElmnts.length > 0
                                    ? policyListFilterElmnts.map(
                                        (ph, index) => (
                                          <MenuItem value={ph} key={index}>
                                            {ph}
                                          </MenuItem>
                                        )
                                      )
                                    : ""}
                                </Select>
                              </div>

                              <div
                                className="col-md-2 col-lg-2 col-xl-2 col-sm-12"
                                style={{}}
                              ></div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {policyNumber !== "" ? (
                          <div className="register-claim-table  custom-responsive-border">
                            <Table
                              className="custom-page-table table-bordered"
                              style={{ marginLeft: "25px", marginTop: "50px" }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">
                                    Policy Number
                                  </TableCell>
                                  <TableCell align="center">
                                    Insured Name
                                  </TableCell>
                                  <TableCell align="center">Select</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {uniquePolicyList &&
                                uniquePolicyList.length > 0 ? (
                                  uniquePolicyList.map((item, index) => (
                                    <TableRow key={item.policyCode} key={index}>
                                      {setLoader(true)}
                                      <TableCell align="center" style={{}}>
                                        {item.policyCode}
                                      </TableCell>
                                      <TableCell align="left">
                                        {item.insuredName}
                                      </TableCell>
                                      <TableCell align="center">
                                        {" "}
                                        <Radio
                                          className="pb-0 pt-0 pl-0 pr-0"
                                          onChange={(e) => {
                                            reportIdChecking(e, item);
                                          }}
                                          value={item.policyCode}
                                          name="radio-button-demo"
                                          color="default"
                                          inputProps={{ "aria-label": "A" }}
                                          checked={
                                            selectedValue === item.insuredId &&
                                            selectItemId === item.itemId &&
                                            selectproductId ===
                                              item.productId &&
                                            selectedcatagoryName ===
                                              item.categoryName &&
                                            selectedClaimTypeDesc ===
                                              item.claimTypeDesc &&
                                            selectedClaimType === item.claimType
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan="3">
                                      {loading || registerNewClaimData ? (
                                        <div className="register-new-table-loader-view">
                                          <CircularProgress />
                                          {setLoader(false)}
                                        </div>
                                      ) : (
                                        !registerNewClaimData && (
                                          <div className="register-new-table-loader-view">
                                            No data found
                                          </div>
                                        )
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>

                            {!policyNumber &&
                            registerNewClaimData &&
                            registerNewClaimData.length > 0 ? (
                              <div
                                className="ml-4"
                                style={{ textAlign: "center" }}
                              >
                                {" "}
                                Please select a Policy Number
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {loading && loader && (
                    <div className="loading-view reporter-details-loader-view">
                      <CircularProgress />
                    </div>
                  )} */}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="register-new-table-loader-view">
                          {radioLoader ? <CircularProgress /> : ""}
                        </div>

                        {reportCheck && (
                          <React.Fragment>
                            <form
                              autoComplete="on"
                              noValidate
                              onReset={handleReset}
                              onSubmit={handleSubmit}
                            >
                              <div className={classesAccordin.root}>
                                <Accordion
                                  onChange={handlePanel1()}
                                  expanded={panel1}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography
                                      className={classesAccordin.heading}
                                    >
                                      Reporter Info
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div>
                                      <div className="row">
                                        <div
                                          className="col-md-6 col-sm-12"
                                          style={{ padding: "12px 13px 0px" }}
                                        >
                                          <DarkerDisabledTextField
                                            error={
                                              errors.reporterName &&
                                              touched.reporterName &&
                                              !reporterData.reporterName
                                            }
                                            className="form-textfield form-textfield-label"
                                            label="Reporter Name *"
                                            name="reporterName"
                                            //variant="filled"
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled
                                            value={values.reporterName}
                                            helperText={
                                              !reporterData.reporterName
                                                ? errors.reporterName &&
                                                  touched.reporterName &&
                                                  errors.reporterName
                                                : ""
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            fullWidth
                                            margin="normal"
                                            placeholder="Enter reporter name"
                                          />
                                        </div>
                                        <div
                                          className="col-md-6 col-sm-12"
                                          style={{ padding: "12px 13px 0px" }}
                                        >
                                          <span>
                                            Relationship with Insured *
                                          </span>
                                          <Select
                                            className="custom-country-code"
                                            name="relationshipof"
                                            style={{ width: "100%" }}
                                            // onChange={handleChange("relationshipof")}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "relationshipof",
                                                e.target.value
                                              );

                                              // {
                                              //   reporterChange();
                                              // }
                                            }}
                                            onBlur={handleBlur}
                                            input={
                                              <Input id="relationshipof" />
                                            }
                                            defaultValue="relationToPH"
                                            value={values.relationshipof}
                                            helperText={
                                              errors.relationshipof &&
                                              touched.relationshipof &&
                                              errors.relationshipof
                                            }
                                            error={
                                              touched.relationshipof &&
                                              Boolean(errors.relationshipof) &&
                                              !reporterData.relationToPH
                                            }
                                          >
                                            {claimRelation.map((relation) => (
                                              <MenuItem
                                                value={relation.key}
                                                disabled={relation.disable}
                                              >
                                                {relation.value}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                          {errors.relationshipof &&
                                            touched.relationshipof && (
                                              <div className="claim-type-validation-text">
                                                Please select relationship
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div
                                          className="col-md-6 col-sm-12"
                                          style={{ padding: "12px 13px 0px" }}
                                        >
                                          <DarkerDisabledTextField
                                            error={
                                              errors.mobileNumber &&
                                              touched.mobileNumber &&
                                              !reporterData.mobile
                                            }
                                            className="form-textfield form-textfield-label"
                                            label="Mobile Number"
                                            name="mobileNumber"
                                            disabled
                                            //variant="filled"
                                            style={{ width: "100%" }}
                                            value={values.mobileNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={
                                              errors.mobileNumber &&
                                              touched.mobileNumber &&
                                              errors.mobileNumber
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  +
                                                </InputAdornment>
                                              ),
                                            }}
                                            fullWidth
                                            margin="normal"
                                            placeholder="XXXXXXXXX"
                                          />
                                        </div>
                                        <div
                                          className="col-md-6 col-sm-12"
                                          style={{ padding: "12px 13px 0px" }}
                                        >
                                          <TextField
                                            error={
                                              errors.telephoneNumber &&
                                              touched.telephoneNumber &&
                                              !reporterData.mobileNumber
                                            }
                                            className="form-textfield form-textfield-label"
                                            label="Telephone Number *"
                                            name="telephoneNumber"
                                            style={{ width: "100%" }}
                                            value={values.telephoneNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={
                                              errors.telephoneNumber &&
                                              touched.telephoneNumber &&
                                              errors.telephoneNumber
                                            }
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  +
                                                </InputAdornment>
                                              ),
                                              shrink: true,
                                            }}
                                            // InputProps={{
                                            //   startAdornment: <InputAdornment position="start">+</InputAdornment>,
                                            // }}
                                            fullWidth
                                            margin="normal"
                                            placeholder="XXXXXXXXX"
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div
                                          className="col-md-6 col-sm-12"
                                          style={{ padding: "12px 13px 0px" }}
                                        >
                                          <TextField
                                            error={
                                              errors.email && touched.email
                                            }
                                            className="form-textfield form-textfield-label"
                                            label="Email Address"
                                            name="email"
                                            // setFieldValue={reporterData.email ? reporterData.email : ""}
                                            style={{ width: "100%" }}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={
                                              errors.email &&
                                              touched.email &&
                                              errors.email
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            fullWidth
                                            margin="normal"
                                            placeholder="user@gmail.com"
                                          />
                                        </div>

                                        {/* <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6"> */}
                                        {
                                          <div
                                            className="col-md-6 col-sm-12"
                                            style={{ padding: "12px 13px 0px" }}
                                          >
                                            <div>BLIL Branch</div>
                                            <Select
                                              className="custom-country-code"
                                              name="branches"
                                              // setFieldValue={reporterData.email ? reporterData.email : ""}
                                              style={{ width: "100%" }}
                                              value={values.branch}
                                              onChange={handleBranchChange}
                                              onBlur={handleBlur}
                                              fullWidth
                                              margin="normal"
                                              placeholder="Please Select BLIL Branch Location"
                                            >
                                              {branchLists.map((branchList) => (
                                                <MenuItem
                                                  value={branchList.id}
                                                  // disabled={relation.disable}
                                                >
                                                  {branchList.value}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </div>
                                        }
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                          <TextField
                                            error={
                                              errors.address && touched.address
                                            }
                                            className="form-textfield form-textfield-label"
                                            label="Address"
                                            name="address"
                                            value={values.address}
                                            style={{ width: "100%" }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={
                                              errors.address &&
                                              touched.address &&
                                              errors.address
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            fullWidth
                                            margin="normal"
                                            placeholder="Enter address"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion
                                  expanded={panel2}
                                  onChange={handlePanel2()}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                  >
                                    <Typography
                                      className={classesAccordin.heading}
                                    >
                                      Claim Event
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                      <div>
                                        <div className="row">
                                          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                                            <div>Claim Type *</div>
                                            <Select
                                              className="custom-country-code"
                                              style={{ width: "100%" }}
                                              name="claimsType"
                                              disabled
                                              // onChange={handleChange("claimsType")}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "claimsType",
                                                  e.target.value
                                                );
                                                {
                                                  if (e.target.value === "3") {
                                                    setClaimTypeDeath(true);
                                                    console.log(
                                                      "claim type death true or false {} :" +
                                                        claimTypeDeath
                                                    );
                                                  }
                                                  if (e.target.value === "3") {
                                                    setClaimTypeDeath(true);
                                                    console.log(
                                                      "claim type death true or false {} :" +
                                                        claimTypeDeath
                                                    );
                                                  }
                                                  if (e.target.value === "5") {
                                                    setClaimTypeDeath(true);
                                                    console.log(
                                                      "claim type death true or false {} :" +
                                                        claimTypeDeath
                                                    );
                                                  } else {
                                                    setClaimTypeDeath(false);
                                                  }
                                                  // } else {
                                                  //
                                                  // }
                                                }
                                              }}
                                              onBlur={handleBlur}
                                              input={<Input id="claimsType" />}
                                              // defaultValue="none"
                                              value={values.claimsType}
                                              helperText={
                                                errors.claimsType &&
                                                touched.claimsType &&
                                                errors.claimsType
                                              }
                                              error={
                                                touched.claimsType &&
                                                Boolean(errors.claimsType)
                                              }
                                            >
                                              <MenuItem value="none" disabled>
                                                Select Claim Type
                                              </MenuItem>
                                              <MenuItem value="2">
                                                Death
                                              </MenuItem>
                                              <MenuItem value="15">
                                                Funeral
                                              </MenuItem>
                                              {/* <MenuItem value="3">3</MenuItem>
                                        <MenuItem value="4">4</MenuItem> */}
                                            </Select>
                                            {errors.claimsType &&
                                            touched.claimsType ? (
                                              <div className="claim-type-validation-text">
                                                Please select Claim Type
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>

                                          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                                            <div>Claim Nature *</div>
                                            <Select
                                              className="custom-country-code"
                                              style={{ width: "100%" }}
                                              name="claimsNature"
                                              value={
                                                values.claimsType == 15
                                                  ? values.claimsNature
                                                  : "none"
                                              }
                                              //onChange={handleChange}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "claimsNature",
                                                  e.target.value
                                                );

                                                {
                                                  // claimTypeHandle(
                                                  //   setFieldValue(
                                                  //     "claimsNature",
                                                  //     e.target.value
                                                  //   ),
                                                  //   values.eventDate
                                                  // );
                                                }
                                              }}
                                              onBlur={handleBlur}
                                              input={
                                                <Input id="claimsNature" />
                                              }
                                              defaultValue="none"
                                              error={
                                                errors.claimsNature &&
                                                touched.claimsNature
                                              }
                                            >
                                              {claimNature.map((nature) => (
                                                <MenuItem
                                                  value={nature.key}
                                                  disabled={nature.disable}
                                                >
                                                  {nature.value}
                                                </MenuItem>
                                              ))}
                                            </Select>
                                            {errors.claimsNature &&
                                            touched.claimsNature ? (
                                              <div className="claim-type-validation-text">
                                                Please select Claim Nature
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row"></div>

                                      <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <Grid container>
                                              <KeyboardDatePicker
                                                className="form-textfield form-textfield-label"
                                                error={
                                                  errors.eventDate &&
                                                  touched.eventDate
                                                }
                                                id="eventDate"
                                                name="eventDate"
                                                autoOk={true}
                                                required
                                                fullWidth
                                                maxDate={new Date()}
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                placeholder="DD/MM/YYYY"
                                                margin="normal"
                                                label="Event Date"
                                                value={values.eventDate}
                                                // onChange={(val) => {
                                                //   setFieldValue("eventDate", val);
                                                // }}
                                                onChange={(val) => {
                                                  setFieldValue(
                                                    "eventDate",
                                                    val
                                                  );

                                                  // claimTypeHandle(
                                                  //   values.claimsNature,
                                                  //   val
                                                  // );
                                                }}
                                                handleBlur={handleBlur}
                                                KeyboardButtonProps={{
                                                  "aria-label": "change date",
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                helperText={
                                                  errors.eventDate &&
                                                  touched.eventDate &&
                                                  errors.eventDate
                                                }
                                              />
                                            </Grid>
                                          </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="col-md-6">
                                          <TextField
                                            className="form-textfield form-textfield-label"
                                            label="Event location"
                                            name="eventLocation"
                                            value={values.eventLocation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            fullWidth
                                            margin="normal"
                                            placeholder="Please enter Incident location"
                                          />
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-md-12">
                                          <Tooltip title="Please enter the cause of death from Death Certificate">
                                            <TextField
                                              className="form-textfield form-textfield-label"
                                              label="Cause of death"
                                              name="eventDetails"
                                              value={values.eventDetails}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              rows="2"
                                              rowsMax="5"
                                              multiline
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              fullWidth
                                              margin="normal"
                                              placeholder={
                                                values.claimsType == 15
                                                  ? "Please enter 'Cause of death' from death certificate"
                                                  : "Enter event details"
                                              }
                                            />
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                                <Accordion
                                  expanded={panel3}
                                  onChange={handlePanel3()}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography
                                      className={classesAccordin.heading}
                                    >
                                      Upload Document
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                      <p>
                                        <b>Note</b>: Only PDF/JPG files
                                        supported and files size should not
                                        exceed 5MB
                                      </p>

                                      <div className="container">
                                        <div className="row">
                                          <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                            1) ID proof *
                                          </div>
                                          <CustomToolTip
                                            title={
                                              <React.Fragment>
                                                <Typography
                                                  color="inherit"
                                                  variant="subtitle2"
                                                >
                                                  Please Upload Certified
                                                  Omang/Passport Document
                                                </Typography>
                                              </React.Fragment>
                                            }
                                          >
                                            <div
                                              className="col-sm-7 col-md-7 col-lg-7 col-xl-7"
                                              style={{}}
                                            >
                                              <UploadFiles
                                                id="file1"
                                                fileData={fileData}
                                                thePolicyCode={thePolicyCode}
                                                insurredId={insurredId}
                                                fileType="CLAIMSIDPROOF"
                                                handleFile={handleFile}
                                                removeDoc={removeDoc}
                                                handleChooseFile={
                                                  setIDProofFile
                                                }
                                                selectedFile={IDProofFile}
                                                setSelectedFile={setIDProofFile}
                                              />
                                              {CLAIMSIDPROOF ? (
                                                <div className="ml-3 claim-type-validation-text-file">
                                                  Please upload ID Proof
                                                </div>
                                              ) : null}
                                            </div>
                                          </CustomToolTip>
                                        </div>
                                      </div>

                                      <div className="container">
                                        <div
                                          className="row"
                                          style={{
                                            marginTop: 26,
                                          }}
                                        >
                                          <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                            2) Bank Account proof *
                                          </div>
                                          <div
                                            className="col-sm-7 col-md-7 col-lg-7 col-xl-7"
                                            style={{}}
                                          >
                                            <FormControl
                                              style={{ marginLeft: "10px" }}
                                            >
                                              {/* <InputLabel id="demo-simple-select-helper-label">
                                          Payment Mode
                                        </InputLabel> */}
                                              <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={payment}
                                                onChange={(e) => {
                                                  console.log(e.target.value);
                                                  setpayment(e.target.value);
                                                  {
                                                    if (e.target.value === 10) {
                                                      console.log(
                                                        e.target.value
                                                      );

                                                      setPaymentEFT(true);
                                                    } else {
                                                      setPaymentEFT(false);
                                                    }

                                                    if (e.target.value === 20) {
                                                      console.log(
                                                        e.target.value
                                                      );

                                                      setLifeRewardCardPayment(
                                                        true
                                                      );
                                                    } else {
                                                      setLifeRewardCardPayment(
                                                        false
                                                      );
                                                    }
                                                  }
                                                }}
                                              >
                                                <MenuItem value={10}>
                                                  EFT
                                                </MenuItem>
                                                <MenuItem value={20}>
                                                  Life Rewards Card
                                                </MenuItem>
                                              </Select>
                                              <FormHelperText>
                                                Please Select Payment Mode
                                              </FormHelperText>
                                            </FormControl>
                                          </div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{
                                            marginTop: 26,
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>
                                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                            {paymentEFT ? (
                                              <div className="container">
                                                <div className="col-xs-12 row col-md-12 col-lg-12 col-xl-12">
                                                  <div className="custom-upload-myclaims">
                                                    <CustomToolTip
                                                      title={
                                                        <React.Fragment>
                                                          <div>
                                                            <Typography
                                                              color="inherit"
                                                              variant="subtitle2"
                                                            >
                                                              1. Valid Pay-slip
                                                              (3months validity)
                                                              (OR){" "}
                                                            </Typography>
                                                          </div>
                                                          <div>
                                                            <Typography
                                                              color="inherit"
                                                              variant="subtitle2"
                                                            >
                                                              2. Three months
                                                              Bank Statement for
                                                              Business owners
                                                              (Mostly applicable
                                                              to Self Employed
                                                              people) (OR){" "}
                                                            </Typography>
                                                          </div>
                                                          <div>
                                                            <Typography
                                                              color="inherit"
                                                              variant="subtitle2"
                                                            >
                                                              3. Employment
                                                              Letter reflecting
                                                              one’s monthly
                                                              income. (OR){" "}
                                                            </Typography>
                                                          </div>
                                                          <div>
                                                            <Typography
                                                              color="inherit"
                                                              variant="subtitle2"
                                                            >
                                                              4. If the person
                                                              is unemployed or
                                                              lives through
                                                              Piece jobs then
                                                              they submit an
                                                              affidavit
                                                              explaining such.
                                                              (This includes
                                                              Hawkers as well)
                                                            </Typography>
                                                          </div>
                                                        </React.Fragment>
                                                      }
                                                    >
                                                      <div className="custom-upload-myclaims">
                                                        <UploadFiles
                                                          id="file2"
                                                          fileData={fileData}
                                                          thePolicyCode={
                                                            thePolicyCode
                                                          }
                                                          insurredId={
                                                            insurredId
                                                          }
                                                          fileType="CLAIMSBANKPROOF"
                                                          handleFile={
                                                            handleFile
                                                          }
                                                          removeDoc={removeDoc}
                                                          handleChooseFile={
                                                            setBankProofFile
                                                          }
                                                          selectedFile={
                                                            bankProofFile
                                                          }
                                                          setSelectedFile={
                                                            setBankProofFile
                                                          }
                                                        />
                                                      </div>
                                                    </CustomToolTip>

                                                    {CLAIMSBANKPROOF ? (
                                                      <div className="ml-3 claim-type-validation-text-file">
                                                        Please upload Bank
                                                        Account Proof
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="container">
                                        <div
                                          className="row"
                                          style={{
                                            marginTop: 26,
                                          }}
                                        >
                                          <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5">
                                            3) Death Certificate *
                                          </div>
                                          <CustomToolTip
                                            title={
                                              <React.Fragment>
                                                <Typography
                                                  color="inherit"
                                                  variant="subtitle2"
                                                >
                                                  Please Upload Certified Death
                                                  Certificate
                                                </Typography>
                                              </React.Fragment>
                                            }
                                          >
                                            <div
                                              className="col-sm-7 col-md-7 col-lg-7 col-xl-7"
                                              style={{}}
                                            >
                                              <UploadFiles
                                                id="file3"
                                                fileData={fileData}
                                                thePolicyCode={thePolicyCode}
                                                insurredId={insurredId}
                                                fileType="CLAIMSDEATHPROOF"
                                                handleFile={handleFile}
                                                removeDoc={removeDoc}
                                                handleChooseFile={
                                                  setDeathProofFile
                                                }
                                                selectedFile={deathProofFile}
                                                setSelectedFile={
                                                  setDeathProofFile
                                                }
                                              />
                                              {CLAIMSDEATHPROOF ? (
                                                <div className="ml-3 claim-type-validation-text-file">
                                                  Please upload Death
                                                  Certificate Proof
                                                </div>
                                              ) : null}
                                            </div>
                                          </CustomToolTip>
                                        </div>
                                      </div>

                                      {/*loading && fileData.length === 3 && (
                                <div className="loading-view">
                                  <CircularProgress />
                                </div>
                              )*/}
                                      {loading && (
                                        <div className="loading-view">
                                          <CircularProgress />
                                        </div>
                                      )}
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                              {/* <GoogleMapReact
                        bootstrapURLKeys={{ key: "googleMap" }}
                        defaultCenter={center}
                        defaultZoom={zoom}
                      >

                        <AnyReactComponent
                          lat={59.955413}
                          lng={30.337844}
                          text="My Marker"
                        />
                      </GoogleMapReact> */}

                              {/* <h3
                      className="claim-header"
                      style={{ marginTop: 20, marginBottom: 0 }}
                    >
                      Reporter Info
                    </h3> */}

                              {/* <h3 className="claim-header" style={{ marginTop: 20 }}>
                      Claim Event
                    </h3> */}

                              {/* <h3 className="claim-header" style={{ marginTop: 20 }}>
                      Upload Document
                    </h3> */}

                              <div className="proceed-claim-btn">
                                <div className="mt-1 mb-2 d-flex justify-content-center align-items-center">
                                  <Button
                                    disabled={
                                      values.claimsType !== null
                                        ? values.claimsType === "2"
                                          ? true
                                          : false
                                        : false &&
                                          responseMessage ===
                                            "New claim is successfully registered"
                                        ? true
                                        : false
                                    }
                                    // responseMessage="";

                                    onClick={() => {
                                      if (values.relationshipof == "none") {
                                        reporterInfoTabHandle();
                                        setRelationshipToast(true);
                                      } else if (
                                        values.claimsNature == "none"
                                      ) {
                                        claimEventTabHandle();
                                        setClaimNatureToast(true);
                                      } else if (values.eventDate == null) {
                                        claimEventTabHandle();
                                        setEventDateToast(true);
                                      }
                                    }}
                                    type="submit"
                                    variant="contained"
                                    id="submitclaim"
                                  >
                                    Submit
                                  </Button>

                                  <Button
                                    onClick={handleResetPage}
                                    type="reset"
                                    variant="contained"
                                    color="primary"
                                    id="cancelclaim"
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </div>
                            </form>
                          </React.Fragment>
                        )}
                      </Card>

                      {FullyComplientKycToast && (
                        <ErrorToaster
                          message={
                            "Please complete & submit KYC before submitting claim"
                          }
                        />
                      )}
                      {setFullyComplientKycToast(false)}

                      {reportCheckToast && (
                        <ErrorToaster
                          message={
                            "Self Insured Person Cannot Register his own Claim"
                          }
                        />
                      )}
                      {setReportCheckToast(false)}
                      {setClaimNatureToast(false)}
                      {relationshipToast && (
                        <ErrorToaster
                          message={"Plase select Relationship With Insured"}
                        />
                      )}
                      {setRelationshipToast(false)}
                      {claimNatureToast && (
                        <ErrorToaster message={"Plase select ClaimNature"} />
                      )}
                      {setEventDateToast(false)}
                      {eventDateToast && (
                        <ErrorToaster message={"Plase select Event Date"} />
                      )}
                      {setEventDateToast(false)}
                      {/* {noReporter && (
                <ErrorToaster
                  message={"No reporter details found for that policy"}
                />
              )}
              {setNoReporter(false)} */}

                      {deathClaimTypeError && (
                        <ErrorToaster
                          message={
                            "Functionality under development. Contact 362 7000"
                          }
                        />
                      )}

                      {/*
                policyByOmangNumError && (
                  <ErrorToaster message={"No Policies Found "} />
                )
              */}
                      {policyByOmangNumError && (
                        <ErrorToaster message={"No Eligible Policy."} />
                      )}
                      {/*setPolicyByOmangNumError(false)*/}
                      {setDeathClaimTypeError(false)}
                      {error && <ErrorToaster message={error} />}
                      {message && <SuccessToaster message={message} />}

                      {edrmsData.response && (
                        <SuccessToaster message={edrmsData.response} />
                      )}
                      {console.log(edrmsData.message)}
                      {edrmsData.message && (
                        <ErrorToaster message={edrmsData.message} />
                      )}
                      {console.log(edrmsData.message)}
                      {/* {claimTypeDeath && (
                <ErrorToaster
                  message={
                    "Death claim cannot be submitted from cutomer portal.Please contact help desk (+267)3623700"
                  }
                />
              )} */}
                      {claimTypeDeath ? (
                        <DeathMsgNotify statusClaimtype={claimTypeDeath} />
                      ) : (
                        ""
                      )}
                      {setClaimTypeDeath(false)}
                    </>
                  );
                }}
              </Formik>
            </>
          ) : (
            <ClaimKYCNotification />
          )}
        </>
      )}
    </>
  );
}
