/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, CardBody, CardHeader, p } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ContainerHeader from "components/ContainerHeader";
import Widget from "../../../../../components/Widget";

export const MasikaOltheExtendedFamilyCover = ({ productName, productId }) => {
  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile custom-kyp-fonts">
        {/* <div
                style={{ background- image: 'url(' + Image + ')', background-size: 'auto' }}
        >Background Image
               </div> */}

        <div
          style={{
            backgroundImage: "url(" + Image + ")",
            backgroundSize: "auto",
          }}
        ></div>
        <div className="card-header">
          {" "}
          <div
            style={{
              backgroundImage: "url(" + Image + ")",
              backgroundSize: "auto",
            }}
          ></div>
          <h4 className="card-title mb-0 text-uppercase">{productName}</h4>
        </div>
        <div className="jr-tabs-classic">
          <div className="jr-tabs-content jr-task-list pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <h3 className="mb-2 ">Product Code</h3>
                  <p>MSKO-1</p>
                  <h3 className="mb-2">Product Summary</h3>
                  <p>
                    This product is designed as a funeral cover for extended
                    family members without the option to add nuclear family
                    members. It is at the policyholder’s discretion whom they
                    want covered.The premium payer (policyholder) will be
                    covered for accidental death only in the policy.The policy
                    will pay out the sum assured in the event of death of the
                    life covered.There is option of a savings where the client
                    can save some monies independent of the funeral cover at no
                    extra administration costs. The savings commission may be
                    used for the funeral expenses for other family members that
                    may not have been included in the policy or for any other
                    contingency that a premium payer may see fit.Cover ceases on
                    death of the premium payer.
                  </p>
                </div>
                <div>
                  <h3 className="mb-2">Age Limits</h3>
                  <div className="table-responsive-material custom-responsive-border">
                    <Table className="custom-page-table table-bordered">
                      <TableHead>
                        <TableRow>
                          <TableCell>Category of member </TableCell>
                          <TableCell>Max number of lives </TableCell>
                          <TableCell>Min entry age next birthday </TableCell>
                          <TableCell>Max entry age next birthday </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{}}>
                            Extended Family including parents and parents in law
                            (excluding still born){" "}
                          </TableCell>
                          <TableCell align="left">19 </TableCell>
                          <TableCell align="left">1 </TableCell>
                          <TableCell align="left">85 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Premium payer </TableCell>
                          <TableCell align="left">1 </TableCell>
                          <TableCell align="left">18 </TableCell>
                          <TableCell align="left">70 </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
                <div>
                  <h3 className="mb-2">Sum Assured</h3>
                  <div className="table-responsive-material custom-responsive-border">
                    <Table className="custom-page-table table-bordered">
                      <TableHead>
                        <TableRow>
                          <TableCell>Policyholder </TableCell>
                          <TableCell>
                            Extended family (over 16) including parents and
                            parents in law{" "}
                          </TableCell>
                          <TableCell>
                            Extended family aged 16 years and younger{" "}
                          </TableCell>
                          <TableCell>Domestic workers </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{}}>5,000</TableCell>
                          <TableCell align="left">10,000 </TableCell>
                          <TableCell align="left"> 5,000 </TableCell>
                          <TableCell align="left">2,000 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>10,000 </TableCell>
                          <TableCell align="left">20,000</TableCell>
                          <TableCell align="left">10,000 </TableCell>
                          <TableCell align="left">5,000 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>15,000 </TableCell>
                          <TableCell align="left">30,000</TableCell>
                          <TableCell align="left">20,000 </TableCell>
                          <TableCell align="left">10,000 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>20,000 </TableCell>
                          <TableCell align="left">40,000</TableCell>
                          <TableCell align="left">20,000 </TableCell>
                          <TableCell align="left">10,000 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>25,000 </TableCell>
                          <TableCell align="left">50,000</TableCell>
                          <TableCell align="left">30,000 </TableCell>
                          <TableCell align="left">10,000 </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>

                  <h3 className="mb-2">Premium Limits</h3>
                  <div className="table-responsive-material custom-responsive-border">
                    <Table className="custom-page-table table-bordered">
                      <TableHead>
                        <TableRow>
                          <TableCell>Age next birthday </TableCell>
                          <TableCell>Option 1</TableCell>
                          <TableCell>Option 2</TableCell>
                          <TableCell>Option 3</TableCell>
                          <TableCell>Option 4</TableCell>
                          <TableCell>Option 5</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{}}>1-2</TableCell>
                          <TableCell>35 </TableCell>
                          <TableCell>36</TableCell>
                          <TableCell>37 </TableCell>
                          <TableCell>38 </TableCell>
                          <TableCell>40 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>3-18</TableCell>
                          <TableCell align="left">45 </TableCell>
                          <TableCell align="left">45</TableCell>
                          <TableCell align="left">47 </TableCell>
                          <TableCell align="left">49 </TableCell>
                          <TableCell align="left">51 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>19-25</TableCell>
                          <TableCell align="left">48 </TableCell>
                          <TableCell align="left">51</TableCell>
                          <TableCell align="left">55 </TableCell>
                          <TableCell align="left">60 </TableCell>
                          <TableCell align="left">65 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>26-30</TableCell>
                          <TableCell align="left">50 </TableCell>
                          <TableCell align="left">56</TableCell>
                          <TableCell align="left">63 </TableCell>
                          <TableCell align="left">69 </TableCell>
                          <TableCell align="left">75 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>31-35</TableCell>
                          <TableCell align="left">52 </TableCell>
                          <TableCell align="left">56</TableCell>
                          <TableCell align="left">69 </TableCell>
                          <TableCell align="left">78 </TableCell>
                          <TableCell align="left">87 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>36-40</TableCell>
                          <TableCell align="left">53 </TableCell>
                          <TableCell align="left">62</TableCell>
                          <TableCell align="left">70 </TableCell>
                          <TableCell align="left">80 </TableCell>
                          <TableCell align="left">92 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>41-45</TableCell>
                          <TableCell align="left">56 </TableCell>
                          <TableCell align="left">69</TableCell>
                          <TableCell align="left">81 </TableCell>
                          <TableCell align="left">93 </TableCell>
                          <TableCell align="left">105 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>46-50</TableCell>
                          <TableCell align="left">57 </TableCell>
                          <TableCell align="left">70</TableCell>
                          <TableCell align="left">82 </TableCell>
                          <TableCell align="left">96 </TableCell>
                          <TableCell align="left">112 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>51-55</TableCell>
                          <TableCell align="left">58 </TableCell>
                          <TableCell align="left">75</TableCell>
                          <TableCell align="left">95 </TableCell>
                          <TableCell align="left">113 </TableCell>
                          <TableCell align="left">135 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>56-60</TableCell>
                          <TableCell align="left">67</TableCell>
                          <TableCell align="left">91</TableCell>
                          <TableCell align="left">115</TableCell>
                          <TableCell align="left">140</TableCell>
                          <TableCell align="left">165 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>61-65 </TableCell>
                          <TableCell align="left">81</TableCell>
                          <TableCell align="left">113</TableCell>
                          <TableCell align="left">145 </TableCell>
                          <TableCell align="left">180</TableCell>
                          <TableCell align="left">215 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>66-70</TableCell>
                          <TableCell align="left">89</TableCell>
                          <TableCell align="left">133</TableCell>
                          <TableCell align="left">180 </TableCell>
                          <TableCell align="left">225</TableCell>
                          <TableCell align="left">265 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>71-75</TableCell>
                          <TableCell align="left">108</TableCell>
                          <TableCell align="left">165</TableCell>
                          <TableCell align="left">225 </TableCell>
                          <TableCell align="left">285 </TableCell>
                          <TableCell align="left"> 340 </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>76-85</TableCell>
                          <TableCell align="left">133 </TableCell>
                          <TableCell align="left">208</TableCell>
                          <TableCell align="left">290</TableCell>
                          <TableCell align="left">365</TableCell>
                          <TableCell align="left">450</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>
                            Domestic and farm workers
                          </TableCell>
                          <TableCell align="left">30 </TableCell>
                          <TableCell align="left">44</TableCell>
                          <TableCell align="left">55</TableCell>
                          <TableCell align="left">65</TableCell>
                          <TableCell align="left">65</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{}}>Policyholder</TableCell>
                          <TableCell align="left">5</TableCell>
                          <TableCell align="left">10</TableCell>
                          <TableCell align="left">15</TableCell>
                          <TableCell align="left">20</TableCell>
                          <TableCell align="left">25</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
                <div>
                  <h3 className="mb-2">Policy Term</h3>
                  <ul>
                    <li> Whole of life cover</li>
                  </ul>
                </div>
                <div>
                  <Button
                    className="custom-btn-ach"
                    component={Link}
                    to="/home/know-your-products"
                    variant="contained"
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </>
  );
};
