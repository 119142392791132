import {
  REGISTRATION_RESEND_OTP,
  REGISTRATION_RESEND_OTP_LOADER,
  REGISTRATION_RESEND_OTP_ERROR_MESSAGE,
  HIDE_REGISTRATION_RESEND_OTP_ERROR_MESSAGE,
  VERIFY_REGISTRATION_RESEND_OTP_SUCCESS,
} from "constants/ActionTypes";

export const registrationResendOtp = (data) => {
  return {
    type: REGISTRATION_RESEND_OTP,
    payload: data,
  };
};

export const verifyRegistrationResendOtpSuccess = (data) => {
  return {
    type: VERIFY_REGISTRATION_RESEND_OTP_SUCCESS,
    payload: data,
  };
};

export const showRegistrationResendOtpLoader = () => {
  return {
    type: REGISTRATION_RESEND_OTP_LOADER,
  };
};
export const hideRegistrationResendOtpErrorMessage = () => {
  return {
    type: HIDE_REGISTRATION_RESEND_OTP_ERROR_MESSAGE,
  };
};

export const verifyRegistrationResendOtpErrorMessage = (message) => {
  return {
    type: REGISTRATION_RESEND_OTP_ERROR_MESSAGE,
    payload: message,
  };
};
