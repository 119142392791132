import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Registration from "./Registration";
import RegistrationOtp from "./RegistrationOtp";
import RegistrationResendOtp from "./RegistrationResendOTP";
import AdminApproval from "./AdminApproval";
import AdminKycApproval from "./AdminKycApproval";
import RegistrationRequest from "./RegistrationRequest";
import ForgotPassword from "./ForgotPassword";
import MobileorEmailOtp from "./MobileorEmailOtp";
import ForgotResetPassword from "./ForgotResetPassword";
import CreatePassword from "./CreatePassword";
import MyPoliciesDetails from "./MyPoliciesDetails";
import PolicyDetails from "./PolicyDetails";
import Common from "./Common";
import MyProfile from "./MyProfile";
import Products from "./Products";
import AdminCompare from "./AdminCompare";
import SignInMobileOtpReducer from "./SignInMobileOtp";
import SignInMobileResendOtpReducer from "./SignInResendOtp";
import ReportDownloadReducer from "./ReportDownloadReducer";
import ClaimStatus from "./ClaimStatus";
import ClaimInfo from "./ClaimInfo";
import RegisterNewClaimReducer from "./RegisterNewClaimReducer";
import GetReporterDetailsReducer from "./GetReporterDetailsReducer";
import KycDetails from "./KycDetails";
import KYCStatusReducer from "./KYCStatusReducer";
import AdminAllKycRequests from "./AdminAllKycRequests";
import ClaimsDocsUpload from "./ClaimsDocsUpload";
import KYCDocumentsPreviewReducer from "./KYCDocumentsPreviewReducer";
import DpoPaymentReducer from "./DPOPayment";
import DpoTokenReducer from "./DPOToken";
//import DpoPayTokenReducer from "./DpoTokenReducer";
import BookingGetBranchesReducer from "./BookingGetBranches";
import BookingGetServicesReducer from "./BookingGetServices";
import MakeBookingReducer from "./MakeBooking";
import GetFeedbackReducer from "./GetFeedback";
import BankDetailsReducer from "./BankDetailsReducer";
import LifeRewardReducer from "./LifeRewardReducer";
import KycCheck from "./KycCheck";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    registration: Registration,
    registrationOtp: RegistrationOtp,
    registrationResendOtp: RegistrationResendOtp,
    adminApproval: AdminApproval,
    registrationRequest: RegistrationRequest,
    forgotPassword: ForgotPassword,
    mobileorEmailOtpData: MobileorEmailOtp,
    forgotResetPassword: ForgotResetPassword,
    createPasswordSetup: CreatePassword,
    myPoliciesDetails: MyPoliciesDetails,
    policyDetails: PolicyDetails,
    commonData: Common,
    myProfile: MyProfile,
    products: Products,
    adminCompare: AdminCompare,
    signInMobileOtpReducer: SignInMobileOtpReducer,
    signInMobileResendOtpReducer: SignInMobileResendOtpReducer,
    reportDownloadReducer: ReportDownloadReducer,
    claimStatus: ClaimStatus,
    claimInfo: ClaimInfo,
    registerNewClaimReducer: RegisterNewClaimReducer,
    getReporterDetailsReducer: GetReporterDetailsReducer,
    kycData: KycDetails,
    KycStatusReducer: KYCStatusReducer,
    adminAllKycRequests: AdminAllKycRequests,
    adminKycApproval: AdminKycApproval,
    claimsDocsUpload: ClaimsDocsUpload,
    kYCDocumentsPreviewReducer: KYCDocumentsPreviewReducer,
    dpoPaymentReducer: DpoPaymentReducer,
    dpoTokenReducer: DpoTokenReducer,
    getBookingBranch: BookingGetBranchesReducer,
    getBookingServices: BookingGetServicesReducer,
    getBookingAppoinments: MakeBookingReducer,
    getFeedback: GetFeedbackReducer,
    bankDetails: BankDetailsReducer,
    rewardDetails: LifeRewardReducer,
    kycCheckData: KycCheck,
  });
