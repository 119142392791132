import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpAuthPut } from "util/REST";
import {
  forgotResetPasswordSuccess,
  forgotResetPasswordError,
} from "actions/ForgotResetPassword";
import { FORGOT_RESET_PASSWORD } from "constants/ActionTypes";

const postForgotResetPasswordApi = async (data) =>
  await httpAuthPut("updatePwd", data)
    .then((data) => data)
    .catch((error) => error);

function* PostForgotResetPasswordReq({ payload }) {
  try {
    let body = payload;
    const forgetResetPwd = yield call(postForgotResetPasswordApi, body);
    if (forgetResetPwd && forgetResetPwd.status >= 400) {
      yield put(forgotResetPasswordError(forgetResetPwd.message));
    } else {
      yield put(forgotResetPasswordSuccess(forgetResetPwd.status));
    }
  } catch (error) {
    yield put(forgotResetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(FORGOT_RESET_PASSWORD, PostForgotResetPasswordReq)]);
}
