/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik } from "formik";
import * as Yup from "yup";

export default ({ open, handleRequestClose, handleRequestSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={remarksSchema}
      onSubmit={async (values, { resetForm }) => {
        const { remarks } = values;
        await handleRequestSubmit("reject", remarks);
        resetForm();
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <Dialog className="" open={open} className="custom-confirm-dialog">
            <form autoComplete="on" noValidate onSubmit={handleSubmit}>
              <DialogTitle className="custom-dialog-title">
                Confirmation
              </DialogTitle>
              <DialogContent className="custom-dialog-content">
                <DialogContentText className="custom-dialog-text">
                  Please enter reason for Rejection
                </DialogContentText>

                <TextField
                  className="custom-popup-label"
                  id="remarks"
                  placeholder="Reason"
                  label="Reason"
                  value={values.remarks}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  fullWidth
                  className="jr-wall-textarea"
                  margin="none"
                  variant="outlined"
                  rows="5"
                  required
                  onBlur={handleBlur}
                  error={errors.remarks && touched.remarks}
                  helperText={
                    errors.remarks && touched.remarks && errors.remarks
                  }
                />
              </DialogContent>
              <DialogActions className="confirm-btns">
                <Button
                  color="secondary"
                  onClick={() => {
                    handleReset();
                    handleRequestClose("reject");
                  }}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

const initialValues = {
  remarks: "",
};

const remarksSchema = Yup.object().shape({
  remarks: Yup.string()
    .max(200, "Remarks should not exceed more than 200 characters")
    .required("Please enter remarks"),
});
