/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotResetPassword,
  onShowResetPasswordLoader,
  hideResetPasswordErrorMessage,
} from "actions/ForgotResetPassword";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import ResetConfirmDialog from "../DialogBox/ResetConfirmDialog.js";
import { CircularProgress } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { ErrorToaster } from "util/ReactToastify";

import { resetPasswordMsgs } from "../../constants/Validations";
// import 'containers/fontStyle.scss';

const ResetPassword = (props) => {
  const [open, setDialog] = useState(false);
  const { loader, alertMessage, showMessage, forgotResetPasswordStatus } =
    useSelector(({ forgotResetPassword }) => forgotResetPassword);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideResetPasswordErrorMessage());
      }, 100);
    }
    if (forgotResetPasswordStatus === 200) {
      setDialog(true);
      dispatch(hideResetPasswordErrorMessage());
    }
    return () => dispatch(hideResetPasswordErrorMessage());
  }, [showMessage, forgotResetPasswordStatus]);

  return (
    <div style={{ margin: "0 auto" }} className="custom-forgot-password">
      <ResetConfirmDialog
        open={open}
        handleRequestClose={() => setDialog(true)}
      />
      <div className="login-container otp-holder d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div
          className="login-content"
          style={{
            width: "480px",
            margin: "12px 30px",
            boxShadow:
              "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
            padding: "35px 35px 20px",
          }}
        >
          <div className="login-header">
            <Link className="app-logo" to="/" title="Sais">
              <img
                src={require("assets/images/logo-color.png")}
                alt="sais"
                title="sais"
              />
            </Link>
          </div>

          <div className="mb-2">
            <h2>
              <IntlMessages id="appModule.resetPassword" />
            </h2>
          </div>

          <div className="login-form">
            <Formik
              initialValues={initialValues}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                const { newPassword, confirmNewPassword } = values;
                let mobileNumber = null;
                if (props && props.location && props.location.state) {
                  mobileNumber = props.location.state.mobileNumber;
                }
                let resetPassword = {
                  mobileNumber,
                  newPassword,
                  confirmNewPassword,
                };
                if (
                  resetPassword.newPassword === resetPassword.confirmNewPassword
                ) {
                  dispatch(onShowResetPasswordLoader());
                  dispatch(forgotResetPassword(resetPassword));
                }
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <TextField
                      error={errors.newPassword && touched.newPassword}
                      className="form-textfield form-textfield-label"
                      id="newPassword"
                      label="Enter your new password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.newPassword}
                      onChange={handleChange}
                      placeholder="*******"
                      margin="normal"
                      required
                      onBlur={handleBlur}
                      fullWidth
                      type="password"
                      helperText={
                        errors.newPassword &&
                        touched.newPassword &&
                        errors.newPassword
                      }
                    />

                    <TextField
                      error={
                        errors.confirmNewPassword && touched.confirmNewPassword
                      }
                      className="form-textfield form-textfield-label"
                      id="confirmNewPassword"
                      label="Re-type your new password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.confirmNewPassword}
                      onChange={handleChange}
                      placeholder="*******"
                      margin="normal"
                      type="password"
                      required
                      onBlur={handleBlur}
                      fullWidth
                      helperText={
                        errors.confirmNewPassword &&
                        touched.confirmNewPassword &&
                        errors.confirmNewPassword
                      }
                    />

                    <div className="mt-1 mb-2 d-flex justify-content-center align-items-center create-pwd-btn">
                      <Button type="submit" variant="contained" color="primary">
                        Submit
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && <ErrorToaster message={alertMessage} />}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

const initialValues = {
  newPassword: "",
  confirmNewPassword: "",
};
const SignupSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /^(?=[A-Z])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      resetPasswordMsgs.passwordMustContain
    )
    .required(resetPasswordMsgs.passwordRequired),
  confirmNewPassword: Yup.string()
    .matches(
      /^(?=[A-Z])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      resetPasswordMsgs.passwordMustContain
    )
    .required(resetPasswordMsgs.passwordRequired)
    .test("", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export default ResetPassword;
