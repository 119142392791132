import { GET_REPORTER_DETAILS } from "constants/ActionTypes";

const INIT_STATE = {
  reporterData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTER_DETAILS: {
      return {
        ...state,
        reporterData: action.payload,
      };
    }

    case "resetReporterDetailsData": {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    default:
      return state;
  }
};
