/* eslint-disable no-useless-concat */
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  BASIC_INFO_DATA,
  AGENT_INFO_DATA,
  BENIFIT_INFO_DATA,
  FINANCIAL_INFO_DATA,
  PARTY_INFO_DATA,
  CLAIM_INFO_DATA,
  CUSTOMER_SERVICE_INFO_DATA,
  GET_PAYMENT_INFO,
  RESET_POLICY_DETAILS,
} from "../constants/ActionTypes";
import { httpGet } from "util/REST";

export const resetPolicydetails = (data) => {
  return {
    type: RESET_POLICY_DETAILS,
    payload: data,
  };
};

export const getCustomerServiceInfo = (policyNumber) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getCustomerServiceInfo" + "?policyCode=" + policyNumber)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: CUSTOMER_SERVICE_INFO_DATA,
            payload: data.response.customerServiceInfo,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};
export const getAgentInfo = (policyNumber) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getAgentInfoByPolicyNumber" + "?policyCode=" + policyNumber)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: AGENT_INFO_DATA, payload: data.response });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getBasicInfo = (policyNumber) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getPolicyBasics" + "?policyNumber=" + policyNumber)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: BASIC_INFO_DATA, payload: data.response });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getBenifitInfo = (policyNumber) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getBenefitInfo" + "?policyNumber=" + policyNumber)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: BENIFIT_INFO_DATA,
            payload: data.response.benefitInfo ? data.response.benefitInfo : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getFinancialInfo = (policyNumber) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getFinancialInfo" + "?policyNumber=" + policyNumber)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: FINANCIAL_INFO_DATA, payload: data.response });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getPartyInfo = (policyNumber) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getPartyInfo" + "?policyNumber=" + policyNumber)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: PARTY_INFO_DATA, payload: data.response.partyInfo });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getClaimInfo = (policyNumber) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getClaimInfoCustCarePortal" + "?policyNumber=" + policyNumber)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: CLAIM_INFO_DATA,
            payload: data.response.claimInfo ? data.response.claimInfo : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getPaymentInfo = (policyNumber, year) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getPaymentInfo" + "?policyCode=" + policyNumber + "&date=" + year)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_PAYMENT_INFO,
            payload: data.response.paymentInfo,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};
