console.log(
  "checking wheather application is running locally or uat or producation"
);
console.log("process.env.NODE_ENV : {} " + process.env.NODE_ENV);

const envi = window.location.hostname;
console.log(envi, "hostname");

//uncomment the below 3 lines while deploying to prod and comment while deploying to UAT/dev
if (process.env.NODE_ENV === "production") {
  //production
  module.exports = require("../config.prod");
}

/*comment below while deploying to production
if (envi === "102.37.61.33") {
  //UAT
  module.exports = require("../config.uat");
} 
else if (
   envi ===
   "sais-blil-20200406112853-hostingbucket-develop.s3-website.ap-south-1.amazonaws.com"
) {
//   //development
   module.exports = require("../config.awsdev");
}
else if (envi === "localhost") {
  //localhost
  module.exports = require("../config.awsdev");
}
*/
