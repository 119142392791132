import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpGet } from "util/REST";
import {
  adminCompareSuccess,
  showAdminCompareErrorMsg,
  resetAdminLoader,
} from "actions/AdminCompare";
import { ADMIN_COMPARE_REQUEST } from "constants/ActionTypes";

const postAdminCompareapi = async (data) =>
  await httpGet(`userValidate?status=PENDING&policyNumberIDNumber=` + data)
    .then((data) => data)
    .catch((error) => error);

function* PostAdminCompareReq(data) {
  try {
    const { body, usersData, tData } = data.payload;
    const AdminApprovalData = yield call(postAdminCompareapi, body);
    if (AdminApprovalData && AdminApprovalData.status >= 400) {
      yield put(showAdminCompareErrorMsg(AdminApprovalData.message));
      yield put(resetAdminLoader());
    } else {
      yield put(
        adminCompareSuccess(AdminApprovalData.response, usersData, tData)
      );
    }
  } catch (error) {
    yield put(showAdminCompareErrorMsg(error));
    yield put(resetAdminLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ADMIN_COMPARE_REQUEST, PostAdminCompareReq)]);
}
