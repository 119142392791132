import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import ContainerHeader from "../../../components/ContainerHeader/index";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  CircularProgress,
  Select,
  MenuItem,
  Input,
  Grid,
  Tooltip,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { ToastContainer } from "react-toastify";

const PaymentDetail = ({
  accName,
  name,
  handleChange,
  accType,
  type,
  handleTypeChange,
  bankAccount,
  bank,
  handleBankChange,
  bankName,
  nameBank,
  handleNameChange,
  bankBranch,
  branchName,
  handleBranchChange,
  policyNumber,
  policyCode,
  handlePolicyChange,
  handleBlur,
}) => {
  return (
    <div className="row">
      <div className="col">
        <div className="policy-data">
          <div className="card policy-card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-7">
                  <div className="name">
                    <p className=" mr-2 policies-section label">
                      <b>Account Name :</b>
                    </p>
                    <p className=" policies-section">{name}</p>
                  </div>

                  <div className="name">
                    <p className=" mr-2 policies-section label">
                      <b>Account Type :</b>
                    </p>
                    <p className=" policies-section">{type}</p>
                  </div>

                  <div className="name">
                    <p className=" mr-2 policies-section label">
                      <b>Bank Account :</b>
                    </p>
                    <p className=" policies-section">{bank}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="name">
                    <p className=" mr-2 policies-section label">
                      <b>Policy Number :</b>
                    </p>
                    <p className=" policies-section">{policyCode}</p>
                  </div>

                  <div className="name">
                    <p className=" mr-2 policies-section label">
                      <b>Bank Name :</b>
                    </p>
                    <p className=" policies-section">{nameBank}</p>
                  </div>

                  <div className="name">
                    <p className=" mr-2 policies-section label">
                      <b>Bank Branch :</b>
                    </p>
                    <p className=" policies-section">{branchName}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetail;
