import {
  FEEDBACK_QUESTION_DATA,
  AVAILABLE_FEEDBACK_DATA,
  FEEDBACK_QUESTION_FAILURE,
  FEEDBACK_STATUS,
  SUBMIT_STATUS,
  FEEDBACK_LOADER,
  RESET_FEEDBACK,
  FEEDBACK_ERROR,
} from "constants/ActionTypes";

const INIT_STATE = {
  customerFeedbackInfo: [],
  feedbackQuestions: [],
  feedbackStatus: 0,
  submitStatus: 0,
  feedbackLoader: false,
  feedbackError: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AVAILABLE_FEEDBACK_DATA: {
      return {
        ...state,
        customerFeedbackInfo: action.payload,
        feedbackLoader: false,
      };
    }

    case FEEDBACK_QUESTION_DATA: {
      return {
        ...state,
        feedbackQuestions: action.payload,
        feedbackLoader: false,
      };
    }

    case FEEDBACK_QUESTION_FAILURE: {
      return {
        ...state,
        feedbackLoader: false,
      };
    }

    case FEEDBACK_ERROR: {
      return {
        ...state,
        feedbackError: action.payload,
        feedbackLoader: false,
      };
    }

    case SUBMIT_STATUS: {
      return {
        ...state,
        submitStatus: action.payload,
        feedbackLoader: false,
      };
    }

    case FEEDBACK_STATUS: {
      return {
        ...state,
        feedbackStatus: action.payload,
      };
    }

    case FEEDBACK_LOADER: {
      return {
        ...state,
        feedbackLoader: true,
      };
    }

    case RESET_FEEDBACK: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};
