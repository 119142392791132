import React from "react";

const ProfileHeader = ({ data }) => {
  const { name = null, address = null } = data;
  return (
    <div className="jr-profile-banner">
      <div className="jr-profile-container">
        <div className="jr-profile-banner-top">
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar">
              {/* <Avatar className="size-90" alt="..." src={"https://via.placeholder.com/150x150"}/> */}
            </div>
            <div className="jr-profile-banner-avatar-info">
              <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                {name}
              </h2>
              <p className="mb-0 jr-fs-lg">{address}</p>
            </div>
          </div>
        </div>
        {/* <div className="jr-profile-banner-bottom">
          <span className="jr-link jr-profile-setting">
            <Link style={{color: 'white'}} to="/app/edit-details">
            <i className="zmdi zmdi-edit mr-2"/>
            <span className="d-inline-flex align-middle ml-1 jr-ml-sm-0">Edit Details</span>
            </Link>
          </span> 
        </div>*/}
      </div>
    </div>
  );
};

export default ProfileHeader;
