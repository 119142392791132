import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpAuthPut } from "util/REST";
import {
  createPasswordSuccess,
  createPasswordError,
} from "actions/CreatePassword";
import { CREATE_PASSWORD } from "constants/ActionTypes";

const postCreatePasswordApi = async (data) =>
  await httpAuthPut("updatePwd", data)
    .then((data) => data)
    .catch((error) => error);

function* PostCreatePasswordReq({ payload }) {
  try {
    let body = payload;
    const createPasswordData = yield call(postCreatePasswordApi, body);
    if (createPasswordData && createPasswordData.status >= 400) {
      yield put(createPasswordError(createPasswordData.message));
    } else {
      yield put(createPasswordSuccess(createPasswordData.status));
    }
  } catch (error) {
    yield put(createPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(CREATE_PASSWORD, PostCreatePasswordReq)]);
}
