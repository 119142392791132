import React from "react";
import moment from "moment";
import { currencyConverter } from "util/currencyConverter";

const ClaimInformation = ({ data }) => {
  const {
    caseNumber = null,
    caseRegisterDate = null,
    caseSettleDate = null,
    caseStatus = null,
    claimAmount = null,
    claimType = null,
    policyNumber = null,
    totalClaims = null,
  } = data;

  return (
    <>
      <div className="row coustom-claim">
        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Case Number:</h5>
          <p>{caseNumber}</p>
        </div>

        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Case Register Date:</h5>
          <p>
            {caseRegisterDate
              ? moment(caseRegisterDate).format("DD/MM/YYYY")
              : null}
          </p>
        </div>

        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Case Settle Date:</h5>
          <p>
            {caseSettleDate
              ? moment(caseSettleDate).format("DD/MM/YYYY")
              : null}
          </p>
        </div>

        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Case Status:</h5>
          <p>{caseStatus}</p>
        </div>

        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Claim Amount:</h5>
          <p>{claimAmount ? currencyConverter(claimAmount) : "P 0.00"}</p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Claim Type:</h5>
          <p>{claimType}</p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Policy Number:</h5>
          <p>{policyNumber}</p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Total Claims:</h5>
          <p>{totalClaims}</p>
        </div>
      </div>
    </>
  );
};

export default ClaimInformation;
