import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  BANK_DETAIL_DATA,
  BANK_DETAIL_LOADER,
  RESET_BANK_DETAILS,
  BANK_DETAIL_ERROR,
} from "../constants/ActionTypes";
import { httpGet, httpPost } from "util/REST";

export const getBankDetails = (policyCode, idNumber) => {
  return async function (dispatch) {
    dispatch({ type: FETCH_START });
    httpGet(`getBankDetails?policyNumber=${policyCode}&idNumber=${idNumber}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: BANK_DETAIL_DATA,
            payload: data.response.bankDetails ? data.response.bankDetails : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: BANK_DETAIL_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: BANK_DETAIL_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const resetBankDetails = () => {
  return {
    type: RESET_BANK_DETAILS,
  };
};

export const getBankDetailsLoader = () => {
  return {
    type: BANK_DETAIL_LOADER,
  };
};
