/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ContainerHeader from "../../../components/ContainerHeader/index";
import ProfileHeader from "./ProfileComponents/ProfileHeader";
import PersonalDetails from "./ProfileComponents/PersonalDetails";
import ContactDetails from "./ProfileComponents/ContactDetails";
//import { RegisterCase } from './app/routes/MyClaims/registerNew'
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer } from "react-toastify";
import { ErrorToaster } from "util/ReactToastify";

import { hideErrorMessage } from "actions/Common";
import { getContactInfo } from "actions/MyProfile";

const MyProfile = ({ match }) => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector(({ commonData }) => commonData);
  const { contactInfoData } = useSelector(({ myProfile }) => myProfile);

  useEffect(() => {
    dispatch(
      getContactInfo(
        localStorage.getItem("omangNumber")
          ? JSON.parse(localStorage.getItem("omangNumber"))
          : null
      )
    );
    // return () => {
    //   cleanup
    // }
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
      }, 300);
    }
  }, [error]);

  return (
    <>
      <ContainerHeader match={match} />
      <div>
        <ProfileHeader data={contactInfoData} />
        <div className="jr-profile-content">
          <div className="row">
            <div className="col-xl-12 col-lg-8 col-md-12 col-12">
              <PersonalDetails data={contactInfoData} loading={loading} />
              <ContactDetails data={contactInfoData} />
              {/* <RegisterCase data={contactInfoData} /> */}
            </div>
          </div>
        </div>

        {error && <ErrorToaster message={error} />}
        <ToastContainer />
      </div>
    </>
  );
};

export default MyProfile;
