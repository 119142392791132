/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import moment from "moment";

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">
        Copyright &copy; Botswana Life, 2020-{moment(new Date()).format("YYYY")}
      </span>
      <a href="https://www.botswanalife.co.bw/contact-us" target="_blank">
        <span className="d-inline-block">Contact Us</span>
      </a>

      {/* <Button
          href="https://codecanyon.net/cart/configure_before_adding/20978545?license=regular&ref=phpbits&size=source&support=bundle_12month&_ga=2.172338659.1340179557.1515677375-467259501.1481606413"
          target="_blank"
          size="small"
          color="primary"
        ><IntlMessages id="eCommerce.buyNow"/></Button> */}
    </footer>
  );
};
export default Footer;
