import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpPut } from "util/REST";
import {
  showAdminUserApprovalError,
  adminUserApprovalSuccess,
} from "actions/AdminApproval";
import { ADMIN_USER_REVIEW_REQUEST } from "constants/ActionTypes";

const adminUserApprovalapi = async (data) =>
  await httpPut("updateStatus", data)
    .then((data) => data)
    .catch((error) => error);

function* UserApprovalStatus({ payload }) {
  try {
    let body = payload;
    const userApproval = yield call(adminUserApprovalapi, body);
    if (userApproval && userApproval.status >= 400) {
      yield put(showAdminUserApprovalError(userApproval.message));
    } else {
      yield put(adminUserApprovalSuccess(userApproval.response));
    }
  } catch (error) {
    yield put(showAdminUserApprovalError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ADMIN_USER_REVIEW_REQUEST, UserApprovalStatus)]);
}
