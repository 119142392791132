import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  REWARD_DETAIL_DATA,
  REWARD_DETAIL_LOADER,
  REWARD_DETAIL_ERROR,
} from "../constants/ActionTypes";
import { httpGet, httpPost } from "util/REST";

export const getRewardDetails = (idNumber) => {
  return async function (dispatch) {
    dispatch({ type: FETCH_START });
    httpGet(`getCardDetails?idNumber=${idNumber}`)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: REWARD_DETAIL_DATA,
            payload: data.response.cardDetailsByIDNumber
              ? data.response.cardDetailsByIDNumber
              : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: REWARD_DETAIL_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getRewardDetailsLoader = () => {
  return {
    type: REWARD_DETAIL_LOADER,
  };
};
