/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const OTPConirmDialog = (props) => {
  const { open } = props;

  const handleRequestClose = () => {
    props.handleRequestClose();
  };

  const handleSubmit = () => {
    window.location.replace("/signin");
  };

  return (
    <div>
      <Dialog open={open} className="custom-confirm-dialog">
        <DialogTitle className="custom-dialog-title">
          {"Congratulations!"}
        </DialogTitle>
        <DialogContent className="custom-dialog-content">
          <DialogContentText className="custom-dialog-text">
            Thank you for the registration request. Our Admin will get back to
            you shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirm-btns">
          <Button
            variant="contained"
            className="bg-primary text-white"
            onClick={handleSubmit}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OTPConirmDialog;
