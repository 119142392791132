import React from "react";

export class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    this.backend = new props.backend();
  }

  componentDidMount() {
    const { src } = this.props;
    const element = this.viewerRef.current;

    this.backend.init(src, element);
  }

  render() {
    return (
      <div
        ref={this.viewerRef}
        id="viewer"
        style={{ width: "100%", height: "67vh" }}
      ></div>
    );
  }
}

///////////////////////////////////////////////////////////////

export class JPEGViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    this.backend = new props.backend();
  }

  componentDidMount() {
    const { src } = this.props;
    const element = this.viewerRef.current;

    this.backend.init(src, element);
  }

  render() {
    return (
      <div
        ref={this.viewerRef}
        id="viewer"
        style={{ width: "100%", height: "67vh" }}
      ></div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////

export class PDFJs {
  init = (source, element) => {
    const iframe = document.createElement("iframe");
    console.log("source : ", source);
    var data = this.base64toPDF(source);
    console.log("data : ", data);
    iframe.src = `${data}`;
    iframe.width = "100%";
    iframe.height = "100%";

    element.appendChild(iframe);
  };

  base64toPDF(data) {
    var bufferArray = this.base64ToArrayBuffer(data);
    console.log("Buffer array: ", bufferArray.type);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    console.log("blob: ", blobStore);
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blobStore);
    //   return;
    // }
    var data = window.URL.createObjectURL(blobStore);
    return data;
    // var link = document.createElement('a');
    // document.body.appendChild(link);
    // link.href = data;
    // link.download = "file.pdf";
    // link.click();
    // window.URL.revokeObjectURL(data);
    // link.remove();
    // return data;
  }

  base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    //var bString = Buffer.from(data, 'base64').toString('base64')
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////

export class JPGs {
  init = (source, element) => {
    const iframe = document.createElement("iframe");
    console.log("source : ", source);
    var data = this.base64toJPG(source);
    console.log("data : ", data);
    iframe.src = `${data}`;
    iframe.width = "100%";
    iframe.height = "100%";

    element.appendChild(iframe);
  };

  base64toJPG(data) {
    var bufferArray = this.base64ToArrayBuffer(data);
    console.log("Buffer array: ", bufferArray.type);
    var imageblobStore = new Blob([bufferArray], { type: "image/jpg" });
    console.log("blob: ", imageblobStore);
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(blobStore);
    //   return;
    // }
    var data = window.URL.createObjectURL(imageblobStore);
    return data;
    // var link = document.createElement('a');
    // document.body.appendChild(link);
    // link.href = data;
    // link.download = "file.pdf";
    // link.click();
    // window.URL.revokeObjectURL(data);
    // link.remove();
    // return data;
  }

  base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    //var bString = Buffer.from(data, 'base64').toString('base64')
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
}
