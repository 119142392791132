import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  BRANCHES_DATA,
  SERVICES_DATA,
  AVAILABLE_DAYS_DATA,
  AVAILABLE_TIME_DATA,
  APPOINTMENT_MESSAGE,
  APPOINTMENT_ERROR_MESSAGE,
  APPOINTMENT_LOADER,
  RESET_APPOINTMENT_LOADER,
  TAKE_APPOINTMENT_DATA,
  RESET_APPOINTMENT_DETAILS,
  APPOINTMENT_STATUS,
  NEAREST_TIME,
  APPOINTMENT_HISTORY,
  APPOINTMENT_HISTORY_STATUS,
} from "constants/ActionTypes";
import { httpGet, httpPost } from "util/REST";

export const getAppointmentReset = () => {
  return {
    type: RESET_APPOINTMENT_DETAILS,
  };
};

export const getBookingBranches = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("getBranches", data)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: BRANCHES_DATA,
            payload: data.response.branches ? data.response.branches : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getBookingServices = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("getServices", data)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SERVICES_DATA,
            payload: data.response.services ? data.response.services : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getAvailableDays = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("getAvailableDaysToTakeAppointment", data)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: AVAILABLE_DAYS_DATA,
            payload: data.response.ValidAppointmentDays
              ? data.response.ValidAppointmentDays
              : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getAvailableTimes = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("getDayAvailableTimesToTakeAppointment", data)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: AVAILABLE_TIME_DATA,
            payload: data.response.ValidDayAppointmentTimes
              ? data.response.ValidDayAppointmentTimes
              : [],
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getAppointment = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("takeAppointment", data)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TAKE_APPOINTMENT_DATA,
            payload: data.response.TakeAppointmentResult,
          });
          dispatch({
            type: NEAREST_TIME,
            payload: data.response.nearestAppointmentTimes
              ? data.response.nearestAppointmentTimes
              : "",
          });
          dispatch({ type: APPOINTMENT_STATUS, payload: data.status });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({
            type: APPOINTMENT_MESSAGE,
            payload: data.response.TakeAppointmentResult.Description,
          });
          dispatch({ type: APPOINTMENT_STATUS, payload: data.status });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getAppointmentHistory = (data) => {
  return async function (dispatch) {
    dispatch({ type: FETCH_START });
    httpPost("getCustomerAppointments", data)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: APPOINTMENT_HISTORY,
            payload: data.response.appointmentTicketInfo
              ? data.response.appointmentTicketInfo
              : [],
          });
        } else {
          dispatch({
            type: APPOINTMENT_HISTORY_STATUS,
            payload: data.response.status,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const showAppointmentLoader = () => {
  return {
    type: APPOINTMENT_LOADER,
  };
};

export const resetAppointmentLoader = () => {
  return {
    type: RESET_APPOINTMENT_LOADER,
  };
};
