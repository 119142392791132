import {
  ADMIN_KYC_APPROVAL_SUCCESS,
  ADMIN_KYC_REVIEW_ERROR_MESSAGE,
  ADMIN_KYC_APPROVAL_LOADER,
  HIDE_ADMIN_KYC_APPROVAL_ERROR_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  verifyKycLoader: false,
  verifyKycalertMessage: "",
  verifyKycShowMesssage: false,
  verifyKycAdminApprovalStatus: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADMIN_KYC_APPROVAL_SUCCESS:
      return {
        ...state,
        verifyKycLoader: false,
        verifyKycAdminApprovalStatus: action.payload,
      };
    case ADMIN_KYC_REVIEW_ERROR_MESSAGE:
      return {
        ...state,
        verifyKycalertMessage: action.payload,
        verifyKycShowMesssage: true,
        verifyKycLoader: false,
      };
    case ADMIN_KYC_APPROVAL_LOADER:
      return {
        ...state,
        verifyKycLoader: true,
      };
    case HIDE_ADMIN_KYC_APPROVAL_ERROR_MESSAGE:
      return {
        ...state,
        verifyKycalertMessage: "",
        verifyKycShowMesssage: false,
        verifyKycLoader: false,
        verifyKycAdminApprovalStatus: "",
      };

    default:
      return state;
  }
};
