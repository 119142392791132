/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import AcceptPopUp from "../AdminPage/AcceptPopUp";
import RejectPopUp from "../AdminPage/RejectPopUp";
import DownloadPopUp from "../AdminPage/DownloadPopUp";
import { ErrorToaster, toastMsgs, SuccessToaster } from "util/ReactToastify";
import {
  requestKycLoader,
  getKycRequestData,
  hideRequestKycMessage,
  requestKycReset,
} from "actions/AdminAllKycRequests";
import ErrorBoundary from "util/ErrorBoundary";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CardBox from "components/CardBox/index";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { adminTableMsgs } from "constants/Validations";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "@material-table/core";
import PopOver from "../AdminPage/PopOver";
import {
  showAdminKycApprovalLoader,
  adminKycApprovalRequest,
  hideAdminKycApprovalError,
} from "actions/AdminKycApproval";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import {
  DialogTitle,
  Dialog,
  Tooltip,
  CircularProgress,
  createMuiTheme,
  MuiThemeProvider,
  Checkbox,
} from "@material-ui/core";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PdfPreview from "../AdminPdfPage/PdfPreview";
import JpgPreview from "../AdminPdfPage/JPGPreview";
import { hideErrorMessage } from "actions/Common";
import { userSignOut } from "actions";
import {
  getAdminKYCDocument,
  ResetAdminKYCDocument,
} from "actions/KYCDocumentsPreviewAction";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import HoldPopUp from "../AdminPage/HoldPopUp";
//import KycReportSample from "../PdfFiles/KycReportSample"

const AdminHomePage = () => {
  console.log("KYC page rendered");

  const [openAcceptDialog, setAcceptDialog] = useState(false);
  const [openRejectDialog, setRejectDialog] = useState(false);
  const [openHoldDialog, setHoldDialog] = useState(false);
  const [externalId, setExternalId] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [openDownloadDialog, setDownloadDialog] = useState(false);
  const [isComments, setComments] = useState(false);

  const dispatch = useDispatch();
  const { loading, error } = useSelector(({ commonData }) => commonData);
  const { adminKycDocument } = useSelector(
    ({ kYCDocumentsPreviewReducer }) => kYCDocumentsPreviewReducer
  );
  const {
    loader,
    alertMessage,
    showMessage,
    kycRequestData,

    statusCode,
  } = useSelector(({ adminAllKycRequests }) => adminAllKycRequests);
  const {
    verifyKycLoader,
    verifyKycalertMessage,
    verifyKycShowMesssage,
    verifyKycAdminApprovalStatus,
  } = useSelector(({ adminKycApproval }) => adminKycApproval);
  const [startDate, setStartDate] = useState(moment().subtract(6, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [startDatenull, setstartDatenull] = useState(null);
  const [endDatenull, setendDatenull] = useState(null);
  const [compareDate, setcompareDate] = useState(null);
  const [bothDatenull, setbothDatenull] = useState(null);
  const [status, setStatus] = useState("ALL");
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [documentStatus, setDocumentStatus] = useState({});
  const [pdf, setPdf] = useState("");
  const [jpg, setJpg] = useState("");
  const [kycPreviewError, setKYCPreviewError] = useState(false);

  const pdfEx1 = "http://www.africau.edu/images/default/sample.pdf";
  const pdfEx2 =
    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";

  const handleDocumentCheckbox = (event, type, externalId) => {
    let documentData = { ...documentStatus };
    let documentName;
    if (type === "bankDetails") {
      documentName = "bankProof";
    } else {
      documentName = type === "id" ? "idProof" : "addressProof";
    }

    if (
      documentData[externalId] &&
      documentData[externalId].hasOwnProperty(documentName)
    ) {
      documentData[externalId][documentName] = event.target.checked;
    } else {
      documentData[externalId] = {
        ...documentData[externalId],
        [[documentName]]: event.target.checked,
      };
    }

    console.log("value to be set for documentData");
    console.log(documentData);
    setDocumentStatus(documentData);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
        dispatch(hideRequestKycMessage());
        dispatch(hideAdminKycApprovalError());
      }, 300);
    }
  }, [error, showMessage, verifyKycShowMesssage]);

  useEffect(() => {
    if (verifyKycAdminApprovalStatus && verifyKycAdminApprovalStatus.response) {
      toastMsgs.success(verifyKycAdminApprovalStatus.response);
    }
  }, [verifyKycAdminApprovalStatus]);

  useEffect(() => {
    if (statusCode === 401) {
      toastMsgs.error(alertMessage);
      setTimeout(() => dispatch(userSignOut()), 300);
    }
  }, [statusCode]);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideRequestKycMessage());
      }, 100);
    }

    return () => {
      dispatch(hideRequestKycMessage());
    };
  }, [showMessage, dispatch]);

  useEffect(() => {
    dispatch(requestKycLoader());
    dispatch(
      getKycRequestData({
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
        status: status,
      })
    );

    return () => {
      dispatch(requestKycReset());
    };
  }, [dispatch]);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#0066ab",
      },
      secondary: {
        main: "#0066ab",
      },
    },
  });

  const handleDownloadPopup = (externalId) => {
    setExternalId(externalId);
    setDownloadDialog(true);
  };
  const handleRequestClosePopUP = () => {
    setOpen(false);
    setOpenImage(false);
  };

  const handleImageOpen = (jpg) => {
    setJpg(jpg);
    setOpenImage(true);
  };

  const handleClickOpen = (pdf) => {
    setPdf(pdf);
    setOpen(true);
  };

  const requestLimitDate = moment("01/06/2022", "DD/MM/YYYY");

  useEffect(() => {
    if (adminKycDocument.response) {
      const base64Data = adminKycDocument.response;
      console.log("document: ", adminKycDocument);
      const baseString = base64Data.replace(/[\[\]']+/g, "");
      console.log("string set");

      if (baseString.startsWith("/", 0)) {
        handleImageOpen(baseString);
        console.log("base jpg: ", baseString);
      } else {
        handleClickOpen(baseString);
        console.log("base pdf: ", baseString);
      }
    } else {
      if (
        adminKycDocument.response === null &&
        adminKycDocument.message === null
      ) {
        console.log("status: ", adminKycDocument.status);
        setKYCPreviewError(true);
      }
    }

    return () => {
      dispatch(ResetAdminKYCDocument());
    };
  }, [adminKycDocument]);

  const handleAdminPDFPreview = (externalId, fileType) => {
    const data = {
      externalId,
      fileType,
    };
    console.log("before dispatch pdf data  {} :");
    console.log(data);
    dispatch(getAdminKYCDocument(data));
  };

  useEffect(() => {
    if (verifyKycShowMesssage) {
      setTimeout(() => {
        dispatch(hideAdminKycApprovalError());
      }, 100);
    }
    if (
      verifyKycAdminApprovalStatus &&
      verifyKycAdminApprovalStatus.status === 200
    ) {
      dispatch(hideAdminKycApprovalError());
      dispatch(showAdminKycApprovalLoader());
      dispatch(
        getKycRequestData({
          fromDate: moment(startDate).format("YYYY-MM-DD"),
          toDate: moment(endDate).format("YYYY-MM-DD"),
          status: status,
        })
      );
    }
    return () => dispatch(hideAdminKycApprovalError());
  }, [verifyKycShowMesssage, verifyKycAdminApprovalStatus, dispatch]);

  const columns = [
    {
      field: "name",
      title: "Name",
      headerStyle: { minWidth: 120, color: "#3e484f" },
      render: (rowData) => (rowData.lastName ? rowData.lastName : "N/A"),
    },
    {
      field: "idNumber",
      title: "Omang/Passport Number",
      headerStyle: { minWidth: 120, color: "#3e484f" },
      render: (rowData) => (rowData.idNumber ? rowData.idNumber : "N/A"),
    },

    {
      field: "kycStatus",
      title: "KYC Status",
      headerStyle: { minWidth: 130, color: "#3e484f" },
      render: (rowData) => (
        <span
          className={`badge text-capitalize ${
            rowData.kycStatus === "3"
              ? "text-white Fully-Compliant"
              : rowData.kycStatus === "2"
              ? "text-white Half-Compliant"
              : rowData.kycStatus === "1"
              ? "text-white Non-Compliant"
              : "text-white Non-Compliant"
          }`}
        >
          {rowData.kycStatus === "1"
            ? "Non Compliant"
            : rowData.kycStatus === "2"
            ? "Half Compliant"
            : rowData.kycStatus === "3"
            ? "Fully Compliant"
            : "Non Compliant"}
        </span>
      ),
    },
    {
      field: "createdAt",
      type: "date",
      title: "Request Date",
      headerStyle: { minWidth: 145, color: "#3e484f" },
      render: (rowData) =>
        moment.parseZone(rowData.createdAt).format("DD/MM/YYYY"),
    },
    {
      field: "mobile",
      title: "Mobile No",
      numeric: true,
      headerStyle: { minWidth: 125, color: "#3e484f" },
      render: (rowData) => (rowData.mobile ? rowData.mobile : "N/A"),
    },
    {
      field: "document1",
      // type: "number",
      title: "ID Proof",
      headerStyle: { minWidth: 176, color: "#3e484f" },
      render: (rowData) => (
        <>
          {moment(rowData.createdAt).isBefore(requestLimitDate) ? (
            <Button
              onClick={() =>
                handleAdminPDFPreview(rowData.externalId, "KYCIDPROOF")
              }
              className={`badge mr-0 text-capitalize text-white admin-preview-btn bg-blue`}
            >
              <i className="zmdi zmdi-eye text-capitalize text-white">
                {" "}
                <span className="roboto">Preview</span>
              </i>
            </Button>
          ) : (
            <Tooltip
              title={
                rowData.idProofStatus === true ? "" : "Document does not exist"
              }
            >
              <div style={{ display: "inline-block" }}>
                <Button
                  disabled={rowData.idProofStatus === true ? false : true}
                  onClick={() =>
                    handleAdminPDFPreview(rowData.externalId, "KYCIDPROOF")
                  }
                  className={
                    rowData.idProofStatus === true
                      ? `badge mr-0 text-capitalize text-white admin-preview-btn bg-blue`
                      : "badge mr-0 text-capitalize text-white admin-preview-btn disabled-blue"
                  }
                >
                  <i className="zmdi zmdi-eye text-capitalize text-white">
                    {" "}
                    <span className="roboto">Preview</span>
                  </i>
                </Button>
              </div>
            </Tooltip>
          )}

          {rowData.adminStatus === "NEW" && (
            <Checkbox
              disabled={
                moment(rowData.createdAt).isBefore(requestLimitDate)
                  ? false
                  : rowData.idProofStatus === true
                  ? false
                  : true
              }
              checked={
                documentStatus[rowData.externalId]
                  ? documentStatus[rowData.externalId]["idProof"]
                  : false
              }
              color="primary"
              onChange={(event) =>
                handleDocumentCheckbox(event, "id", rowData.externalId)
              }
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          )}
        </>
      ),
    },
    {
      field: "document2",
      // numeric: true,
      headerStyle: { minWidth: 168, color: "#3e484f" },
      // cellStyle: {textAlign: "center"}
      title: "Address Proof",
      render: (rowData) => (
        <>
          {moment(rowData.createdAt).isBefore(requestLimitDate) ? (
            <Button
              onClick={() =>
                handleAdminPDFPreview(rowData.externalId, "KYCADDRESSPROOF")
              }
              className={`badge mr-0 text-capitalize text-white admin-preview-btn bg-blue`}
            >
              <i className="zmdi zmdi-eye text-capitalize text-white">
                {" "}
                <span className="roboto">Preview</span>
              </i>
            </Button>
          ) : (
            <Tooltip
              title={
                rowData.addressProofStatus === true
                  ? ""
                  : "Document does not exist"
              }
            >
              <div style={{ display: "inline-block" }}>
                <Button
                  disabled={rowData.addressProofStatus === true ? false : true}
                  onClick={() =>
                    handleAdminPDFPreview(rowData.externalId, "KYCADDRESSPROOF")
                  }
                  className={
                    rowData.addressProofStatus === true
                      ? `badge mr-0 text-capitalize text-white admin-preview-btn bg-blue`
                      : "badge mr-0 text-capitalize text-white admin-preview-btn disabled-blue"
                  }
                >
                  <i className="zmdi zmdi-eye text-capitalize text-white">
                    {" "}
                    <span className="roboto">Preview</span>
                  </i>
                </Button>
              </div>
            </Tooltip>
          )}
          {rowData.adminStatus === "NEW" && (
            <Checkbox
              disabled={
                moment(rowData.createdAt).isBefore(requestLimitDate)
                  ? false
                  : rowData.addressProofStatus === true
                  ? false
                  : true
              }
              checked={
                documentStatus[rowData.externalId]
                  ? documentStatus[rowData.externalId]["addressProof"]
                  : false
              }
              onChange={(event) =>
                handleDocumentCheckbox(event, "address", rowData.externalId)
              }
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          )}
        </>
      ),
    },

    {
      field: "document3",
      // numeric: true,
      headerStyle: { minWidth: 168, color: "#3e484f" },
      // cellStyle: {textAlign: "center"}
      title: "Bank Proof",
      render: (rowData) => (
        <>
          {moment(rowData.createdAt).isBefore(requestLimitDate) ? (
            <Button
              onClick={() =>
                handleAdminPDFPreview(rowData.externalId, "KYCADDRESSPROOF")
              }
              className={`badge mr-0 text-capitalize text-white admin-preview-btn bg-blue`}
            >
              <i className="zmdi zmdi-eye text-capitalize text-white">
                {" "}
                <span className="roboto">Preview</span>
              </i>
            </Button>
          ) : (
            <Tooltip
              title={
                rowData.bankProofStatus === true
                  ? ""
                  : "Document does not exist"
              }
            >
              <div style={{ display: "inline-block" }}>
                <Button
                  disabled={rowData.bankProofStatus === true ? false : true}
                  onClick={() =>
                    handleAdminPDFPreview(rowData.externalId, "KYCBANKPROOF")
                  }
                  className={
                    rowData.bankProofStatus === true
                      ? `badge mr-0 text-capitalize text-white admin-preview-btn bg-blue`
                      : "badge mr-0 text-capitalize text-white admin-preview-btn disabled-blue"
                  }
                >
                  <i className="zmdi zmdi-eye text-capitalize text-white">
                    {" "}
                    <span className="roboto">Preview</span>
                  </i>
                </Button>
              </div>
            </Tooltip>
          )}
          {rowData.adminStatus === "NEW" && (
            <Checkbox
              disabled={
                moment(rowData.createdAt).isBefore(requestLimitDate)
                  ? false
                  : rowData.bankProofStatus === true
                  ? false
                  : true
              }
              checked={
                documentStatus[rowData.externalId]
                  ? documentStatus[rowData.externalId]["bankProof"]
                  : false
              }
              onChange={(event) =>
                handleDocumentCheckbox(event, "bankDetails", rowData.externalId)
              }
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          )}
        </>
      ),
    },

    {
      field: "adminStatus",
      title: "Status",
      headerStyle: { minWidth: 152, color: "#3e484f" },
      // cellStyle: { textAlign: "center" },
      render: (rowData) => (
        <span
          className={`badge text-capitalize ${
            rowData.adminStatus === "APPROVED"
              ? "text-white bg-success"
              : rowData.adminStatus === "REJECTED"
              ? "text-white bg-danger"
              : rowData.adminStatus === "NEW"
              ? "text-white bg-blue"
              : ""
          }`}
        >
          {rowData.adminStatus}
        </span>
      ),
    },
    {
      field: "finalDecision",
      title: "Final Decision",
      headerStyle: { minWidth: 148, color: "#3e484f" },
      render: (rowData) => (
        <div>
          <Tooltip title="Approve" aria-label="approve">
            <Button
              variant="contained"
              color="primary"
              className={`btn-5 jr-btn jr-btn-xs ${
                rowData.adminStatus === "APPROVED"
                  ? ""
                  : rowData.adminStatus === "NEW" &&
                    documentStatus[rowData.externalId]
                  ? rowData.adminStatus === "NEW" &&
                    documentStatus[rowData.externalId]["idProof"] &&
                    documentStatus[rowData.externalId]["addressProof"] &&
                    documentStatus[rowData.externalId]["bankProof"]
                  : false
                  ? "bg-blue"
                  : ""
              } text-white`}
              onClick={() => handleClickAccept(rowData.externalId)}
              disabled={
                !(documentStatus[rowData.externalId]
                  ? rowData.adminStatus === "NEW" &&
                    ((documentStatus[rowData.externalId]["idProof"] &&
                      documentStatus[rowData.externalId]["addressProof"] &&
                      documentStatus[rowData.externalId]["bankProof"]) ||
                      (rowData.adminStatus === "NEW" &&
                        documentStatus[rowData.externalId]["idProof"] &&
                        documentStatus[rowData.externalId]["addressProof"]) ||
                      (rowData.adminStatus === "NEW" &&
                        documentStatus[rowData.externalId]["addressProof"] &&
                        documentStatus[rowData.externalId]["bankProof"]) ||
                      (rowData.adminStatus === "NEW" &&
                        documentStatus[rowData.externalId]["idProof"] &&
                        documentStatus[rowData.externalId]["bankProof"]) ||
                      (rowData.adminStatus === "NEW" &&
                        (documentStatus[rowData.externalId]["idProof"] ||
                          documentStatus[rowData.externalId]["addressProof"] ||
                          documentStatus[rowData.externalId]["bankProof"])))
                  : false)
              }
            >
              <i className="zmdi zmdi-check"></i>
            </Button>
          </Tooltip>

          {/* <Tooltip title="Reject" aria-label="reject">
            <Button
              variant="contained"
              color="primary"
              className={`jr-btn jr-btn-xs ${rowData.adminStatus === "REJECTED"
                ? ""
                : rowData.adminStatus === "NEW"
                  ? "bg-danger"
                  : ""
                } text-white`}
              onClick={() => handleClickReject(rowData.externalId)}
              disabled={
                // rowData.tableData.checked ||
                rowData.adminStatus !== "NEW"
              }
            >
              <i className="zmdi zmdi-close"></i>
            </Button>
          </Tooltip> */}

          <Tooltip title="On Hold" aria-label="hold">
            <Button
              variant="contained"
              color="primary"
              className={`jr-btn jr-btn-xs 
                ${rowData.adminStatus === "NEW" ? "bg-warning" : ""} 
                text-white`}
              onClick={() => handleClickHold(rowData.externalId)}
              disabled={
                // rowData.tableData.checked ||
                rowData.adminStatus !== "NEW"
              }
            >
              <i className="zmdi zmdi-pause"></i>
            </Button>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "comments",
      title: "Comments",
      headerStyle: { minWidth: 140, color: "#3e484f" },
      render: (rowData) => (
        <span>
          {rowData.comments ? (
            <PopOver data={rowData.comments} popOverData={rowData.comments} />
          ) : (
            <> -- </>
          )}
        </span>
      ),
    },
  ];

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleClickAccept = (externalId) => {
    console.log("handleClickAccept used for pop up for approve");
    setAcceptDialog(true);
    setExternalId(externalId);
  };

  const handleClickReject = (externalId) => {
    console.log("handleClickReject used for pop up for  disapproved ");
    setExternalId(externalId);
    setRejectDialog(true);
  };

  const handleClickHold = (externalId) => {
    console.log("handleClickReject used for pop up for  disapproved ");
    setExternalId(externalId);
    setHoldDialog(true);
  };

  // Modal pop up functions
  const handleRequestClose = (type) => {
    if (type === "accept") {
      setAcceptDialog(false);
    } else if (type === "reject") {
      setRejectDialog(false);
    } else if (type === "hold") {
      setHoldDialog(false);
    } else {
      setDownloadDialog(false);
    }
  };

  const handleRequestSubmit = (type, remarks = null) => {
    dispatch(showAdminKycApprovalLoader());
    const data = {};
    if (type === "accept") {
      data["status"] = "APPROVED";
      data["externalId"] = externalId;
      data["comments"] = remarks;
      dispatch(adminKycApprovalRequest(data));
      setAcceptDialog(false);
    } else if (type === "reject") {
      data["status"] = "REJECTED";
      data["externalId"] = externalId;
      data["comments"] = remarks;
      dispatch(adminKycApprovalRequest(data));
      setRejectDialog(false);
    } else if (type === "hold") {
      data["externalId"] = externalId;
      data["status"] = "ON-HOLD";
      data["comments"] = remarks;
      dispatch(adminKycApprovalRequest(data));
      setHoldDialog(false);
    }
    setDownloadDialog(false);
  };

  const handleReportDownloadClose = () => {
    setDownloadDialog(false);
  };

  const handlleStartDatechange = (startDate) => {
    setStartDate(startDate);
  };
  const handlleEndDatechange = (endDate) => {
    setEndDate(endDate);
  };
  const handleDateSubmit = () => {
    if (startDate || endDate) {
      if (startDate === null) {
        setstartDatenull(adminTableMsgs.selectStartDate);
      } else if (endDate === null) {
        setendDatenull(adminTableMsgs.selectEndDate);
      } else if (startDate > endDate || endDate < startDate) {
        setcompareDate(adminTableMsgs.startLessThanEndDate);
      } else if (startDate > moment() || endDate > moment()) {
        return;
      } else {
        dispatch(requestKycLoader());
        setstartDatenull(null);
        setendDatenull(null);
        setcompareDate(null);
        setbothDatenull(null);
        dispatch(
          getKycRequestData({
            fromDate: moment(startDate).format("YYYY-MM-DD"),
            toDate: moment(endDate).format("YYYY-MM-DD"),
            status: status,
          })
        );
      }
    } else {
      setbothDatenull(adminTableMsgs.selectStartAndEndDate);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }));
  return (
    <ErrorBoundary>
      <PdfPreview
        style={{ width: "100%", height: "100%" }}
        open={open}
        Pdf={pdf}
        handleRequestClose={handleRequestClosePopUP}
      />

      <JpgPreview
        style={{ width: "100%", height: "100%" }}
        open={openImage}
        Jpeg={jpg}
        handleRequestClose={handleRequestClosePopUP}
      />

      <AcceptPopUp
        open={openAcceptDialog}
        handleRequestClose={handleRequestClose}
        handleRequestSubmit={handleRequestSubmit}
      />

      <RejectPopUp
        open={openRejectDialog}
        handleRequestClose={handleRequestClose}
        handleRequestSubmit={handleRequestSubmit}
      />

      <DownloadPopUp
        open={openDownloadDialog}
        KycRequestsData={kycRequestData}
        handleRequestClose={handleReportDownloadClose}
        handleRequestSubmit={handleReportDownloadClose}
        setComments={setComments}
        startDate={startDate}
        endDate={endDate}
        status={status}
        isAdmin={false}
      />

      <HoldPopUp
        open={openHoldDialog}
        handleRequestClose={handleRequestClose}
        handleRequestSubmit={handleRequestSubmit}
      />

      <CardBox
        styleName="col-12"
        cardStyle="p-0"
        heading="KYC Requests"
        headerOutside
      >
        <div className="row ">
          <div className="col">
            <div className="row custom-inputs">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid>
                  <div>
                    <KeyboardDatePicker
                      autoOk="true"
                      variant="inline"
                      required
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="From Date"
                      value={startDate}
                      maxDate={new Date()}
                      // onChange={(date) => setStartDate(date)}
                      onChange={(date) => handlleStartDatechange(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />{" "}
                    <KeyboardDatePicker
                      autoOk="true"
                      required
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="To Date"
                      value={endDate}
                      onChange={(date) => handlleEndDatechange(date)}
                      maxDate={new Date()}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    <div
                      className="error"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      <span>
                        {startDatenull ||
                          endDatenull ||
                          compareDate ||
                          bothDatenull}
                      </span>
                    </div>
                  </div>
                </Grid>
              </MuiPickersUtilsProvider>
              <div className="select-input-custom">
                <InputLabel
                  htmlFor="name-native-disabled"
                  className="MuiInputLabel-shrink"
                >
                  Status
                </InputLabel>
                <Select
                  native
                  value={status}
                  onChange={handleStatus}
                  input={<Input id="age-native-helper" />}
                >
                  <option value={"ALL"}>All</option>
                  <option value={"NEW"}>New</option>
                  <option value={"ON-HOLD"}>On-Hold</option>
                  <option value={"APPROVED"}>Approved</option>
                  {/* <option value={"REJECTED"}>Rejected</option> */}
                </Select>
              </div>

              <div
                style={{ position: "relative", top: "25px", left: "48px" }}
                className="custom-status-btn"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDateSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            title={""}
            data={kycRequestData}
            columns={columns}
            isLoading={loader || loading || verifyKycLoader}
            components={{
              OverlayLoading: () => (
                <div className="loading-view">
                  <CircularProgress />
                </div>
              ),
            }}
            options={{
              emptyRowsWhenPaging: false,
            }}
            detailPanel={({ rowData }) => {
              return (
                <div className="pl-4 admin-KC-details-text">
                  <div>
                    <div className="jr-tabs-content jr-task-list pt-3">
                      <div className="row col-md-12">
                        <div className="col-md-6">
                          <div className="media-body">
                            <h6 className="mb-1 text-label-blue">
                              ID Information:{" "}
                            </h6>

                            <div className="row col-md-12">
                              {/* <div className="col-md-2" /> */}
                              <div className="col-md-12">
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      ID Type:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.idType === "34"
                                        ? "Omang"
                                        : rowData.idType === "3"
                                        ? "Passport"
                                        : "Others"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    {rowData.idType === "34" && (
                                      <>
                                        <h6 className="mb-1 col-md-4 text-label-blue">
                                          ID Number:{" "}
                                        </h6>
                                        <p className="mb-0 col-md-6 custom-fields">
                                          {rowData.idNumber}
                                        </p>
                                      </>
                                    )}
                                    {rowData.idType === "3" && (
                                      <>
                                        <h6 className="mb-1 col-md-4 text-label-blue">
                                          New Passport Number:{" "}
                                        </h6>
                                        <p className="mb-0 col-md-6 custom-fields">
                                          {rowData.newIdNumber}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Expiry Date:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.expiryDate}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Marital Status:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.maritalStatus
                                        ? (rowData.maritalStatus === 1 &&
                                            "Single") ||
                                          (rowData.maritalStatus === 2 &&
                                            "Married") ||
                                          (rowData.maritalStatus === 3 &&
                                            "Divorced") ||
                                          (rowData.maritalStatus === 4 &&
                                            "Widowed") ||
                                          (rowData.maritalStatus === 5 &&
                                            "Seperated") ||
                                          (rowData.maritalStatus === 6 &&
                                            "Other")
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="media-body">
                            <h6 className="mb-1 text-label-blue">
                              Correspondence Address:{" "}
                            </h6>
                            <div className="row col-md-12">
                              {/* <div className="col-md-2" /> */}
                              <div className="col-md-12">
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Line 1:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.correspondanceAddressLine1}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Line 2:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.correspondanceAddressLine2}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Postal Code:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.correspondanceAddressPostal}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      City/Town:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.correspondanceAddressTown}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="jr-tabs-content jr-task-list pt-3">
                      <div className="row col-md-12">
                        <div className="col-md-6">
                          <div className="media-body">
                            <h6 className="mb-1 text-label-blue">
                              Contact Information:{" "}
                            </h6>
                            <div className="row col-md-12">
                              {/* <div className="col-md-2" /> */}
                              <div className="col-md-12">
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Mobile Number:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.mobile}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Email Address:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.email}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="media-body">
                            <h6 className="mb-1 text-label-blue">
                              Residential Address:{" "}
                            </h6>
                            <div className="row col-md-12">
                              {/* <div className="col-md-2" /> */}
                              <div className="col-md-12">
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Line 1:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.residentialAddressLine1}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Line 2:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.residentialAddressLine2}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      Postal Code:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.residentialAddressPostal}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="media-body row">
                                    <h6 className="mb-1 col-md-4 text-label-blue">
                                      City/Town:{" "}
                                    </h6>
                                    <p className="mb-0 col-md-6 custom-fields">
                                      {rowData.residentialAddressTown}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
            actions={[
              {
                icon: () => (
                  <Button
                    className={
                      //   usersData.length === 0
                      "ml-3 mr-3"
                      //: "validateButton ml-3 mr-3"
                    }
                    variant="contained"
                  >
                    Reports
                    <SaveAltIcon className="ml-1" />{" "}
                  </Button>
                ),
                // tooltip: usersData.length === 0 ? "No records to export" : "Export Records",
                isFreeAction: true,
                // disabled: usersData.length === 0 ? true : false,
                onClick: handleDownloadPopup,
              },
            ]}
          />
        </MuiThemeProvider>
      </CardBox>

      {/*verifyKycLoader &&
      <div className="loading-view">
       <CircularProgress />
      </div>*/}

      {showMessage && <ErrorToaster message={alertMessage} />}
      {error && <ErrorToaster message={error} />}
      {verifyKycShowMesssage && (
        <ErrorToaster message={verifyKycalertMessage} />
      )}
      {verifyKycAdminApprovalStatus && (
        <SuccessToaster message={verifyKycAdminApprovalStatus} />
      )}
      {kycPreviewError && <ErrorToaster message={"Document not available"} />}
    </ErrorBoundary>
  );
};

export default AdminHomePage;
