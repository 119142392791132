import React from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

const AgentInfo = ({ agentInfo, loading }) => {
  return (
    <div className="agent-info-data-table table-responsive-material">
      <Table className="table-bordered">
        <TableHead>
          <TableRow>
            <TableCell className="tablecell-heading" align="left">
              Name
            </TableCell>
            <TableCell className="tablecell-heading" align="left">
              Email Address
            </TableCell>
            <TableCell className="tablecell-heading" align="right">
              Mobile No
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agentInfo.agentInfo ? (
            agentInfo.agentInfo.map((agent) => {
              return (
                <TableRow key={agent.name}>
                  <TableCell align="left">{agent.name} </TableCell>
                  <TableCell align="left">
                    {agent.email === "n/a" ? (
                      <div className="text-capitalize">{agent.email}</div>
                    ) : (
                      agent.email
                    )}{" "}
                  </TableCell>
                  <TableCell align="right">{agent.mobile} </TableCell>
                </TableRow>
              );
            })
          ) : loading ? (
            <TableRow>
              <TableCell colSpan="4" align="center">
                Loading...
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan="4" align="center">
                No Record Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default AgentInfo;
