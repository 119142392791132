import {
  DPO_TOKEN_SUCCESS,
  DPO_TOKEN_LOADER,
  HIDE_DPO_TOKEN_LOADER,
  DPO_TOKEN_ERROR_MESSAGE,
  DPO_TOKEN_RESET,
  DPO_TOKEN_STATUS,
  HIDE_DPO_TOKEN_ERROR_MESSAGE
} from "constants/ActionTypes";
import { httpGet, httpPost } from 'util/REST';



export const getDpoTokenData = (policyNumber, amount) => {
  return async function (dispatch) {
    dispatch({ type: DPO_TOKEN_LOADER });
    httpPost(`createDpoToken/?policyNumber=${policyNumber}&amount=${amount}`)
      .then(data => {
        if (data.status === 200 && data.response.transToken) {
          console.log("status: ", data.status)
          //dispatch({ type: HIDE_DPO_TOKEN_LOADER })
          dispatch({ type: DPO_TOKEN_STATUS, payload: data.status })
          dispatch({ type: DPO_TOKEN_SUCCESS, payload: data.response.transToken });
        } else {
          if (data.response == null) {
            dispatch({ type: DPO_TOKEN_ERROR_MESSAGE, payload: data.message ? data.message : "" })
          } else {
            //dispatch({ type: HIDE_DPO_TOKEN_LOADER })
            console.log("explanation response: ",data.response)
            dispatch({ type: DPO_TOKEN_STATUS, payload: data.status })
            dispatch({ type: DPO_TOKEN_ERROR_MESSAGE, payload: data.response?.resultExplanation })
          }
        }
      })
      .catch(error => {
        //dispatch({ type: HIDE_DPO_TOKEN_LOADER })
        dispatch({ type: DPO_TOKEN_ERROR_MESSAGE, payload: error.message })
      })
  }
};

export const hideDpoTokenError = () => {
  return {
    type: HIDE_DPO_TOKEN_ERROR_MESSAGE,
  }
}

export const requestDpoTokenReset = () => {
  return {
    type: DPO_TOKEN_RESET,
  };
};