import {
  KYC_CHECK,
  RESET_KYC_CHECK,
  KYC_CHECK_MESSAGE,
  KYC_CHECK_CODE,
} from "constants/ActionTypes";

const INIT_STATE = {
  kycCheckStatus: "",
  kycCheckMessage: "",
  kycCheckCode: null,
  loader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case KYC_CHECK: {
      return {
        ...state,
        loader: false,
        kycCheckStatus: action.payload,
      };
    }

    case KYC_CHECK_MESSAGE: {
      return {
        ...state,
        loader: false,
        kycCheckMessage: action.payload,
      };
    }

    case KYC_CHECK_CODE: {
      return {
        ...state,
        loader: false,
        kycCheckCode: action.payload,
      };
    }

    case RESET_KYC_CHECK: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    default:
      return state;
  }
};
