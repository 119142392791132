import * as Yup from "yup";
import { kycValidationMsgs } from "../../../constants/Validations";
const FILE_SIZE = 300 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "application/pdf"];
export const KycValidationSchema = [
  Yup.object().shape({
    idType: Yup.string()
      .required("Please select ID Type")
      .test((value) => value !== "none"),
    idNumber: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Omang/Passport should be alpha numeric")
      // .min(5, signUpValidation.omangNumberLengthMsg)
      .required("Please enter valid Id number")
      // .max(20, "Invalid Omang/Passport")
      .test("idNumber", "Invalid Omang number", function (value) {
        return (
          (this.parent.idType === "Omang" &&
            value?.length == 9 &&
            (value[4] == 1 || value[4] == 2)) ||
          ((this.parent.idType === "Passport" ||
            this.parent.idType === "Other") &&
            this.parent.idType !== "omang" &&
            value?.length)
        );
      }),
    // email: Yup.string()
    //   .email("Please enter valid Email Address")
    //   .required("Email is required"),
    expiryDate: Yup.date()
      .min(new Date(), "Invalid Expiry Date")
      .typeError("Please enter valid Expiry Date")
      .required("Please enter Expiry Date"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number can be 0-9 digits only")
      .max(15, "Invalid Mobile Number")
      .required("Mobile Number is required"),
    correspondenceLine1: Yup.string().required(
      kycValidationMsgs.CorrespondenceLine1
    ),
    correspondenceLine2: Yup.string().required(
      kycValidationMsgs.CorrespondenceLine2
    ),
    correspondencePostal: Yup.string().required(
      kycValidationMsgs.CorrespondencePostal
    ),
    correspondenceTown: Yup.string().required(
      kycValidationMsgs.CorrespondenceTown
    ),
    residentialLine1: Yup.string().required(kycValidationMsgs.ResidentialLine1),
    residentialLine2: Yup.string().required(kycValidationMsgs.ResidentialLine2),
    residentialPostal: Yup.string().required(
      kycValidationMsgs.ResidentialPostal
    ),
    residentialTown: Yup.string().required(kycValidationMsgs.ResidentialTown),
    file1: Yup.mixed()
      .required("Please Upload Document")
      .test(
        "fileSize",
        "Uploaded files size should not exceed 300KB",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    file2: Yup.mixed()
      .required("Please Upload Document")
      .test(
        "fileSize",
        "Uploaded files size should not exceed 300KB",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Uploaded files size should not exceed 300KB",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  }),
  Yup.object().shape({
    idType: Yup.string()
      .required("Please select ID Type")
      .test((value) => value !== "none"),
    idNumber: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Omang/Passport should be alpha numeric")
      // .min(5, signUpValidation.omangNumberLengthMsg)
      .required("Please enter valid Id number")
      // .max(20, "Invalid Omang/Passport")
      .test("idNumber", "Invalid Omang number", function (value) {
        return (
          (this.parent.idType === "Omang" &&
            value?.length == 9 &&
            (value[4] == 1 || value[4] == 2)) ||
          ((this.parent.idType === "Passport" ||
            this.parent.idType === "Other") &&
            this.parent.idType !== "omang" &&
            value?.length)
        );
      }),
    // email: Yup.string()
    //   .email("Please enter valid Email Address")
    //   .required("Email is required"),
    expiryDate: Yup.date()
      .min(new Date(), "Invalid Expiry Date")
      .typeError("Please enter valid Expiry Date")
      .required("Please enter Expiry Date"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number can be 0-9 digits only")
      .max(15, "Invalid Mobile Number")
      .required("Mobile Number is required"),
    residentialLine1: Yup.string().required(kycValidationMsgs.ResidentialLine1),
    residentialLine2: Yup.string().required(kycValidationMsgs.ResidentialLine2),
    residentialPostal: Yup.string().required(
      kycValidationMsgs.ResidentialPostal
    ),
    residentialTown: Yup.string().required(kycValidationMsgs.ResidentialTown),
    file1: Yup.mixed()
      .required("Please Upload Document")
      .test(
        "fileSize",
        "Uploaded files size should not exceed 300KB",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    file2: Yup.mixed()
      .required("Please Upload Document")
      .test(
        "fileSize",
        "Uploaded files size should not exceed 300KB",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Uploaded files size should not exceed 300KB",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  }),
  Yup.object().shape({
    idType: Yup.string()
      .required("Please select ID Type")
      .test((value) => value !== "none"),
    idNumber: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Omang/Passport should be alpha numeric")
      // .min(5, signUpValidation.omangNumberLengthMsg)
      .required("Please enter valid Id number")
      // .max(20, "Invalid Omang/Passport")
      .test("idNumber", "Invalid Omang number", function (value) {
        return (
          (this.parent.idType === "Omang" &&
            value?.length == 9 &&
            (value[4] == 1 || value[4] == 2)) ||
          ((this.parent.idType === "Passport" ||
            this.parent.idType === "Other") &&
            this.parent.idType !== "omang" &&
            value?.length)
        );
      }),
    // email: Yup.string()
    //   .email("Please enter valid Email Address")
    //   .required("Email is required"),
    expiryDate: Yup.date()
      .min(new Date(), "Invalid Expiry Date")
      .typeError("Please enter valid Expiry Date")
      .required("Please enter Expiry Date"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number can be 0-9 digits only")
      .max(15, "Invalid Mobile Number")
      .required("Mobile Number is required"),
    correspondenceLine1: Yup.string().required(
      kycValidationMsgs.CorrespondenceLine1
    ),
    correspondenceLine2: Yup.string().required(
      kycValidationMsgs.CorrespondenceLine2
    ),
    correspondencePostal: Yup.string().required(
      kycValidationMsgs.CorrespondencePostal
    ),
    correspondenceTown: Yup.string().required(
      kycValidationMsgs.CorrespondenceTown
    ),
    file1: Yup.mixed()
      .required("Please Upload Document")
      .test(
        "fileSize",
        "Uploaded files size should not exceed 300KB",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    file2: Yup.mixed()
      .required("Please Upload Document")
      .test(
        "fileSize",
        "Uploaded files size should not exceed 300KB",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Uploaded files size should not exceed 300KB",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  }),
  Yup.object().shape({
    idType: Yup.string()
      .required("Please select ID Type")
      .test((value) => value !== "none"),
    idNumber: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Omang/Passport should be alpha numeric")
      // .min(5, signUpValidation.omangNumberLengthMsg)
      .required("Please enter valid Id number")
      // .max(20, "Invalid Omang/Passport")
      .test("idNumber", "Invalid Omang number", function (value) {
        return (
          (this.parent.idType === "Omang" &&
            value?.length == 9 &&
            (value[4] == 1 || value[4] == 2)) ||
          ((this.parent.idType === "Passport" ||
            this.parent.idType === "Other") &&
            this.parent.idType !== "omang" &&
            value?.length)
        );
      }),
    // email: Yup.string()
    //   .email("Please enter valid Email Address")
    //   .required("Email is required"),
    expiryDate: Yup.date()
      .min(new Date(), "Invalid Expiry Date")
      .typeError("Please enter valid Expiry Date")
      .required("Please enter Expiry Date"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number can be 0-9 digits only")
      .max(15, "Invalid Mobile Number")
      .required("Mobile Number is required"),
    file1: Yup.mixed()
      .required("Please Upload Document")
      .test(
        "fileSize",
        "Uploaded files size should not exceed 300KB",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    file2: Yup.mixed()
      .required("Please Upload Document")
      .test(
        "fileSize",
        "Uploaded files size should not exceed 300KB",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Uploaded files size should not exceed 300KB",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  }),
];
