import {
  REWARD_DETAIL_DATA,
  REWARD_DETAIL_LOADER,
  REWARD_DETAIL_ERROR,
} from "../constants/ActionTypes";

const INIT_STATE = {
  rewardDetailsLoader: false,
  rewardDetailsHolder: [],
  rewardDetailsMessage: "",
  rewardDetailsShowMessage: false,
  rewardDetailsStatus: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REWARD_DETAIL_DATA: {
      return {
        ...state,
        rewardDetailsLoader: false,
        rewardDetailsHolder: action.payload,
      };
    }
    case REWARD_DETAIL_LOADER: {
      return {
        ...state,
        rewardDetailsLoader: true,
      };
    }

    case REWARD_DETAIL_ERROR: {
      return {
        ...state,
        rewardDetailsLoader: false,
        rewardDetailsMessage: action.payload,
      };
    }

    default:
      return state;
  }
};
