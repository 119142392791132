/* eslint-disable no-unused-vars */
import React from "react";
import ProductGridItem from "./ProductGridItem";
//import productData from "./productData";

function ProductGridItemProps({ data, loading }) {
  return (
    <div className="row animated slideInUpTiny animation-duration-3">
      {data && data.length
        ? data.map((product, index) => (
            <ProductGridItem key={index} product={product} />
          ))
        : null}

      {!loading && !data.length ? <span>No Data</span> : null}
    </div>
  );
}

export default ProductGridItemProps;
