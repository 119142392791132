/* eslint-disable no-useless-concat */
import { httpGet, httpPost } from "util/REST";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_KYC_DOC_PREVIEW,
  RESET_KYC_DOCUMENT,
  GET_ADMIN_KYC_DOC_PREVIEW,
  RESET_ADMIN_KYC_DOCUMENT,
  KYC_PREVIEW_STATUS,
} from "../constants/ActionTypes";

export const getKYCDocument = (fileType) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("documentPreview" + "?fileType=" + fileType)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_KYC_DOC_PREVIEW, payload: data.response });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // eslint-disable-next-line no-console
        console.log("Error****:", error.message);
      });
  };
};

export const getAdminKYCDocument = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("adminDocumentPreview ", data)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_ADMIN_KYC_DOC_PREVIEW, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
          dispatch({ type: KYC_PREVIEW_STATUS, payload: data.status });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // eslint-disable-next-line no-console
        console.log("Error****:", error.message);
      });
  };
};

export const ResetKYCDocument = () => {
  return {
    type: RESET_KYC_DOCUMENT,
  };
};

export const ResetAdminKYCDocument = () => {
  return {
    type: RESET_ADMIN_KYC_DOCUMENT,
  };
};
