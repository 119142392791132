/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getBasicInfo } from "actions/PolicyDetails";
import { useDispatch } from "react-redux";
const ControlledOpenSelect = ({ data, policyNumber, handleChange }) => {
  return (
    <div className="row">
      <div className="col-lg-6 col-sm-6 col-12">
        <FormControl className="w-100 mb-2">
          <Select
            native
            className="custom-dropdown-kyp"
            value={policyNumber}
            onChange={handleChange}
            input={<Input id="age-native-simple" />}
          >
            {data !== [] ? (
              data.map((item) => {
                return (
                  <option key={Math.random()} value={item.policyNumber}>
                    {item.policyName} - {item.policyNumber}
                  </option>
                );
              })
            ) : (
              <div>no data found</div>
            )}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
export default ControlledOpenSelect;
