import React from "react";
import { Route, Switch } from "react-router-dom";
import KYP from "./routes";
import ProductDetails from "./routes/ProductDetails";

const AllProduct = ({ match }) => (
  <div>
    <Switch>
      {/* <Redirect  exact from={`${match.url}/`} to={`${match.url}/kyp`}/>  */}

      <Route path={`${match.url}/product-details`} component={ProductDetails} />
      <Route path={`${match.url}`} component={KYP} />
    </Switch>
  </div>
);

export default AllProduct;
