var config = {
  'default': {
      saisAPIEndPoint: 'customerportal.botswanalife.co.bw',
      saisAPIPort: null,
      saisAPILabel: 'api',
      saisAPIVersion: 'v1',
      sslEnabled: true,
  },
}

module.exports = { config }
// http://customerportal.botswanalife.co.bw:8000/
// http://customerportal.botswanalife.co.bw:8000
// https://customerportal.botswanalife.co.bw/api/v1/loginUser