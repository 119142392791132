import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  CLAIMS_DOCS_UPLOAD,
  EDRMS_DOCS_PUSH,
  RESET_EDRMS_DOCS_PUSH,
} from "../constants/ActionTypes";
import { httpPost } from "util/REST";

export const claimsDocsUpload = (file, Data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("documentStaging?fileType=" + file, { file: Data })
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: CLAIMS_DOCS_UPLOAD, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const edrmsFilesPush = (Data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("edrmsPush", Data)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: EDRMS_DOCS_PUSH, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const restEdrmsFiles = () => {
  return {
    type: RESET_EDRMS_DOCS_PUSH,
  };
};
