import { config } from './init/index'
// import cookie from 'react-cookie'

export const getConfig = (parameter) => {
  // var conf = cookie.load('Config');
  let conf = null; 
  if (conf == null)
    conf = config['default'];
  return conf[parameter]
}


// export const getConfig = (parameter) => {
//  // console.log(window.location.hostname)
//   var conf = window.location.hostname;
//   let type = null
//   if (conf && conf.toString() === 'chainlinkweb.fnzchain.com') {
//     type = require('./config.prod');
//   } 
//   else if (conf && conf.toString() === 'chainlink.demo.fnzchain.com') {
//     type = require('./config.demo');
//   } 
//   else if (conf && conf.toString() === 'chainlinkweb.dev.fnzchain.com') {
//     type = require('./config.benchmark');
//   }
//   else if (conf && conf.toString().includes('35.178.143.36')) {
//     type = require('./config.newdev');
//   }
//   else{
//     type = require('./config.dev')
//   }
//   if(type){
//     type = type['config'].default
//   }  
//   let confRes=type[parameter.toString()];
//   return confRes;
// }
