import {
  USER_REQUEST_REGISTRATION_SUCCESS,
  HIDE_REQUEST_REGISTRATION_ERROR_MESSAGE,
  ON_REQUEST_SHOW_REGISTRATION_LOADER,
  USER_REQUEST_REGISTRATION_ERROR_MESSAGE,
  USER_REGISTRATION_REQUEST_RESET,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  usersData: [],
  statusCode: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_REQUEST_REGISTRATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        usersData: action.payload,
      };
    }

    case USER_REQUEST_REGISTRATION_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload.message ? action.payload.message : "",
        showMessage: true,
        loader: false,
        statusCode: action.payload.status ? action.payload.status : "",
      };
    }
    case HIDE_REQUEST_REGISTRATION_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
        statusCode: null,
      };
    }

    case ON_REQUEST_SHOW_REGISTRATION_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case USER_REGISTRATION_REQUEST_RESET: {
      return {
        usersData: null,
        loader: false,
        showMessage: false,
      };
    }

    case "resetLoader": {
      return {
        ...state,
        loader: false,
      };
    }

    default:
      return state;
  }
};
