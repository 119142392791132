/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */
import { httpGet } from "util/REST";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CLAIM_INFO_DATA,
} from "../constants/ActionTypes";

export const getClaimInfo = (caseNumber, status) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getClaimInfo" + "?caseNumber=" + caseNumber + "&status=" + status)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_CLAIM_INFO_DATA, payload: data.response });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // eslint-disable-next-line no-console
        console.log("Error****:", error.message);
      });
  };
};
