/* eslint-disable no-useless-concat */
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_REPORTER_DETAILS,
} from "../constants/ActionTypes";
import { httpGet } from "util/REST";

export const getReporterDetails = (policyNumber) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("getReporterDetails" + "?policyNumber=" + policyNumber)
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_REPORTER_DETAILS, payload: data.response });
        } else {
          // dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const resetReporterDetailsData = () => {
  return {
    type: "resetReporterDetailsData",
  };
};
