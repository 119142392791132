import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  SHOW_TEST_RESULT,
} from "constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  message: "",
  statusCode: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: "", message: "", loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: "", message: "", loading: false };
    }
    case SHOW_SUCCESS_MESSAGE: {
      return { ...state, error: "", message: action.payload, loading: false };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload.message ? action.payload.message : "",
        statusCode: action.payload.status ? action.payload.status : null,
        message: "",
      };
    }
    case HIDE_ERROR_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        statusCode: null,
      };
    }
    case SHOW_TEST_RESULT: {
      return {
        ...state,
        loading: false,
        error: "",
        message: "Done running Test",
      };
    }
    default:
      return state;
  }
};
