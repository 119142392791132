import { REGISTER_NEW_CLAIM_DATA } from "constants/ActionTypes";

const INIT_STATE = {
  registerNewClaimData: [],
  regClaimsStatus: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REGISTER_NEW_CLAIM_DATA: {
      return {
        ...state,
        registerNewClaimData: action.payload.response,
        regClaimsStatus: action.payload.status,
      };
    }
    case "resetRegisterNewClaimData": {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};
