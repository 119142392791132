import React from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const ControlledOpenSelect = ({ productTypeId, handleChange }) => {
  return (
    <div className="row custom-select-head-drop">
      <div className="col-lg-3 col-sm-6 col-12">
        <FormControl className="w-100 mb-2">
          <InputLabel htmlFor="age-native-simple">Insurance Type</InputLabel>
          <Select
            native
            className="custom-dropdown-kyp"
            value={productTypeId}
            onChange={handleChange}
            input={<Input id="age-native-simple" />}
          >
            <option value={"all"}>All</option>
            <option value={41}>Investment</option>
            <option value={13}>Whole Life</option>
            <option value={14}>Term</option>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default ControlledOpenSelect;
