import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpPost } from "util/REST";
import {
  showAdminKycApprovalError,
  adminKycApprovalSuccess,
} from "actions/AdminKycApproval";
import { ADMIN_KYC_REVIEW_REQUEST } from "constants/ActionTypes";

const adminKycApprovalapi = async (data) =>
  await httpPost("adminKycVerification", data)
    .then((data) => data)
    .catch((error) => error);

function* UserKycApprovalStatus({ payload }) {
  try {
    let body = payload;
    const userKycApproval = yield call(adminKycApprovalapi, body);
    if (userKycApproval && userKycApproval.status >= 400) {
      yield put(showAdminKycApprovalError(userKycApproval.message));
    } else {
      yield put(adminKycApprovalSuccess(userKycApproval));
    }
  } catch (error) {
    yield put(showAdminKycApprovalError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ADMIN_KYC_REVIEW_REQUEST, UserKycApprovalStatus)]);
}
