import {
  GET_KYC_REQUEST,
  KYC_REQUEST_SUCCESS,
  HIDE_KYC_REQUEST_ERROR_MESSAGE,
  ON_REQUEST_SHOW_KYC_LOADER,
  KYC_REQUEST_ERROR_MESSAGE,
  KYC_REQUEST_RESET,
} from "constants/ActionTypes";

export const getKycRequestData = (data) => {
  return {
    type: GET_KYC_REQUEST,
    payload: data,
  };
};

export const requestKycSuccess = (data) => {
  return {
    type: KYC_REQUEST_SUCCESS,
    payload: data,
  };
};

export const showRequestKycError = (data) => {
  return {
    type: KYC_REQUEST_ERROR_MESSAGE,
    payload: data,
  };
};

export const hideRequestKycMessage = () => {
  return {
    type: HIDE_KYC_REQUEST_ERROR_MESSAGE,
  };
};

export const requestKycLoader = () => {
  return {
    type: ON_REQUEST_SHOW_KYC_LOADER,
  };
};

export const requestKycReset = () => {
  return {
    type: KYC_REQUEST_RESET,
  };
};
