import React, { useState } from "react";
import ContainerHeader from "../../../components/ContainerHeader/index";
import ClaimsCaseDetails from "./claimsCaseDetails";
import ClaimCaseHome from "./ClaimCaseHome";
export default function ClaimsCases(props) {
  const [state, setState] = useState(1);
  const [state1, setState1] = useState({});

  const handleCasedetails = (caseStatus, caseNumber, name, policyNumber) => {
    setState(2);
    setState1({ caseStatus, caseNumber, name, policyNumber });
  };

  return (
    <>
      <ContainerHeader match={props.match} />
      {state === 1 ? (
        <ClaimCaseHome handleCasedetails={handleCasedetails} />
      ) : (
        <ClaimsCaseDetails data={state1} handleBack={() => setState(1)} />
      )}
    </>
  );
}
