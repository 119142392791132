import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import registrationOtpSagas from "./RegistrationOTP";
import registrationResendOtpSagas from "./RegistrationResendOTP";
import adminUserApprovalSagas from "./AdminApproval";
import adminKycApprovalSagas from "./AdminKycApproval";
import registrationSagas from "./Registration";
import registrationRequestSagas from "./RegistrationRequest";
import forgotPasswordSagas from "./forgotPassword";
import mobileorEmailOtpSagas from "./mobileorEmailOtp";
import forgotResetPasswordSagas from "./forgotResetPassword";
import createPassword from "./CreatePassword";
import changePassword from "./changePassword";
import mypoliciesDetails from "./MypoliciesDetails";
import adminCompare from "./AdminCompare";
import signInMobileOtp from "./SignInMobileOtp";
import signInMobileResendOtp from "./SignInMobileResendOTP";
import reportDownloadSaga from "./ReportDownloadSaga";
import adminAllKycRequests from "./AdminAllKycRequest";
import dpoPaymentRequestSagas from "./DPOPayment";
import getBookingBranchesSagas from "./BookingGetBranches";
import getBookingServicesSagas from "./BookingGetServices";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    registrationSagas(),
    registrationOtpSagas(),
    registrationResendOtpSagas(),
    adminUserApprovalSagas(),
    registrationRequestSagas(),
    forgotPasswordSagas(),
    mobileorEmailOtpSagas(),
    forgotResetPasswordSagas(),
    changePassword(),
    createPassword(),
    mypoliciesDetails(),
    adminCompare(),
    signInMobileOtp(),
    signInMobileResendOtp(),
    reportDownloadSaga(),
    adminAllKycRequests(),
    adminKycApprovalSagas(),
    dpoPaymentRequestSagas(),
    getBookingBranchesSagas(),
    getBookingServicesSagas(),
  ]);
}
