import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpGet } from "util/REST";
import {
  myPoliciesDetailsSuccess,
  myPoliciesDetailsErrorMessage,
} from "actions/MyPoliciesDetails";
import { FETCH_ALL_MY_POLICIES } from "constants/ActionTypes";

const postMypoliciesapi = async (data) =>
  await httpGet("getPoliciesByOmangId?IDNumber=" + data)
    .then((data) => data)
    .catch((error) => error);

function* PostMypoliciesnReq({ payload }) {
  try {
    let body = payload;
    const Mypolicies = yield call(postMypoliciesapi, body);
    if (Mypolicies && Mypolicies.status >= 400) {
      yield put(myPoliciesDetailsErrorMessage(Mypolicies));
    } else {
      yield put(myPoliciesDetailsSuccess(Mypolicies.response));
    }
  } catch (error) {
    yield put(myPoliciesDetailsErrorMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(FETCH_ALL_MY_POLICIES, PostMypoliciesnReq)]);
}
