export const signUpValidation = {
  omangRequired: "Omang/Passport is required",
  alphaNumericOnly: "Omang/Passport should be alpha numeric",
  omangNumberLengthMsg: "Invalid Omang/Passport ",
  emailRequired: "Email is required",
  emailInvalid: "Please enter valid Email Address",
  policyNumberRequired: "Policy Number is required",
  policyNumberLengthMsg: "Invalid Policy Number",
  alphaNumeric: "Policy Number should be alpha numeric",
  phoneNumberRequired: "Mobile Number is required",
  phoneNumberLengthMsg: "Mobile number should be 8-digit long",
  dateOfBirthTypeError: "Please enter valid Date of birth",
  dateOfBirthMaxLimitError: "Cannot be future date",
  dateOfBirthRequired: "Please enter Date of birth",
  mobileNumberOnly: "Mobile Number can be 0-9 digits only",
};
export const signIn = {
  emailRequired: "Mobile Number is required",
  emailInvalid: "Please enter valid Email Address/Mobile Number",
  passwordRequired: "Please enter Password",
  passwordMustContain:
    "Password must contain one numeric, one special character and first letter must be capital",
  dateOfBirthRequired: "Please enter Date of birth",
  dateOfBirthTypeError: "Please enter valid Date of birth",
  dateOfBirthMaxLimitError: "Cannot be future date",
  recaptchaRequired: "Please select Recaptcha",
  phoneNumberLengthMsg: "Mobile number should be 8-digit long",
  mobileNumberOnly: "Mobile Number can be 0-9 digits only",
};

export const mobileAndEmailOtp = {
  emailOtpRequired: "Please enter OTP",
  emailOtpLengthMsg: "Invalid OTP",
  mobileOtpRequired: "Please enter OTP",
  mobileOtpLengthMsg: "Invalid OTP",
};

export const changePasswordOtp = {
  otpExpiredErrorMsg: "OTP expired",
  otpExpiredStatus: "OTP is Expired",
  otpInalidStatus: "OTP is invalid",
  otpInalidErrorMsg: "Password cannot be updated due to Invalid OTP",
  otpSuccessStatus: "OTP verified successfully",
  otpSuccessStatusMsg: "Password updated successfully",
  somethingWrongMsg: "Something went wrong",
  resendOtpMsg: "New OTP sent to registered Mobile Number",
  otpRequired: "Please enter OTP",
  otpLengthMsg: "Invalid OTP",
};

export const forgotMobileOtpValidations = {
  otpSuccessStatus: "OTP verified successfully",
  otpResendSuccessMsg: "New OTP sent to registered Mobile Number",
  OtpRequired: "Please enter OTP",
  OtpLengthMsg: "OTP must be 6 digits",
};

export const resetPasswordMsgs = {
  passwordRequired: "Please enter password",
  passwordMustContain:
    "Password must contain one numeric,special character and first letter should be capital",
  passwordMatchMsg: "Passwords do not match",
};

export const setPassword = {
  passwordRequired: "Please enter password",
  passwordMustContain:
    "Password must contain one numeric,special character and first letter should be capital",
  passwordMatchError: "Passwords do not match",
};

export const changePasswordValidations = {
  passwordRequired: "Please enter Password",
  passwordMustContain:
    "Password must contain one numeric,special character and first letter must be capital",
  passwordResetSuccess: "Password updated successfully",
  passwordMatchError: "Passwords do not match",
  newAndOldSameError: "New Password cannot be same as Old Password",
};

export const mobileOtpMsgs = {
  otpSendSuccessMsgToMobile:
    "OTP sent successfully to registered Mobile Number",
  otpSendSuccessMsgToBoth:
    "OTP sent successfully to registered Email Address/Mobile Number",
};

export const adminTableMsgs = {
  recordApproved: "Record approved successfully",
  recordRejected: "Record rejected successfully",
  recordOnHold: "Registration request is On-Hold for approval",
  startLessThanEndDate: "To date must be greater then from date",
  selectStartDate: "Select From Date ",
  selectEndDate: "Select To Date",
  selectStartAndEndDate: "Select From Date and To Date",
};

export const kycValidationMsgs = {
  CorrespondenceLine1: "Please enter Address",
  CorrespondenceLine2: "Please enter Address",
  CorrespondencePostal: "Please enter Postal Code",
  CorrespondenceTown: "Please enter Town/City",
  ResidentialLine1: "Please enter Address",
  ResidentialLine2: "Please enter Address",
  ResidentialPostal: "Please enter Postal Code",
  ResidentialTown: "Please enter Town/City",
  Occupation: "Please enter Occupation",
};
