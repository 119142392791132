import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Route, Switch, Redirect } from "react-router-dom";
import PolicySchedule from "./PolicySchedule";
import InvestmentDetailedReport from "./InvestmentDetailed";
import TaxCertificate from "./TaxCertificate";
import adminKycPage from "app/routes/AdminPage/index";
import PolicyContract from "./PolicyContract";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ match }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Switch>
        <Redirect
          exact
          from={`${match.url}/`}
          to={`${match.url}/policy-schedule`}
        />

        <Route
          path={`${match.url}/policy-schedule`}
          component={PolicySchedule}
        />
        <Route
          path={`${match.url}/investment-detailed-report`}
          component={InvestmentDetailedReport}
        />
        <Route
          path={`${match.url}/tax-certificate`}
          component={TaxCertificate}
        />
        <Route
          path={`${match.url}/policy-contract`}
          component={PolicyContract}
        />
      </Switch>
    </div>
  );
}

export const glossary = () => {
  return (
    <Table
      className="custom-page-table table-bordered"
      style={{ marginLeft: "25px", marginTop: "100px" }}
    >
      <TableHead>
        <TableRow>
          <TableCell align="center">Term</TableCell>
          <TableCell align="center">Meaning</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left" style={{}}>
            Active
          </TableCell>
          <TableCell align="left">
            This means the policy is in force and premium payments have been
            made
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Lapse
          </TableCell>
          <TableCell align="left">
            The termination of an insurance contract through non-payment of
            premiums
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Invested Amount
          </TableCell>
          <TableCell align="left">
            This is the balance of the premium after deduction of all policy
            charges that is used to purchase units
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Risk Premium
          </TableCell>
          <TableCell align="left">
            An amount to be retained by BLIL for assuming the risks under the
            policy insured
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Expected Premium Payments
          </TableCell>
          <TableCell align="left">
            due to the insurance company from the policy owner in exchange for
            benefits
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Unit Price
          </TableCell>
          <TableCell align="left">
            This is the investment fund price that determines the number of
            units purchased by the InvestedAmount, and also determines the
            policy value.
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Units
          </TableCell>
          <TableCell align="left">
            Units represent the way that each fund is split to identify a value
            that we can attribute to each policyholder's holding
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Partial Withdrawal
          </TableCell>
          <TableCell align="left">
            Part withdrawal of the value of the policy before maturity
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Policy Value
          </TableCell>
          <TableCell align="left">
            The value of the units purchased under a policy. Calculated as the
            number of units multiplied by the current unit price
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Risk Benefits
          </TableCell>
          <TableCell align="left">
            Benefits payable in the event of contingencies such as death or
            disability
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" style={{}}>
            Annual premium update
          </TableCell>
          <TableCell align="left">
            The annual increase in premium that occurs at each policy
            anniversary
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Waiver of Premiums
          </TableCell>
          <TableCell align="left">
            An additional benefit where future premiums due are ""waived"" (paid
            by the insurer) in the event of contingencies such as death or
            disability
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Paid Up (PUP)
          </TableCell>
          <TableCell align="left">
            When a policy is made "paid up" future premiums cease and cover will
            continue at a reduced level or for the remaining term
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Cover Level
          </TableCell>
          <TableCell align="left">
            The amount of benefit payable on the death of the insured during the
            term of the policy
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Arrears
          </TableCell>
          <TableCell align="left">
            These are the amounts of premium payments that have been missed
            under the policy
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Renewal expense
          </TableCell>
          <TableCell align="left">
            Cost of collecting premiums from pay points and banks. It also
            includes the policy fee which runs throughout the term of the policy
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Commission charge
          </TableCell>
          <TableCell align="left">
            Cost of procurement of business by an agent or broker
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell align="left" style={{}}>
            Insurance charge
          </TableCell>
          <TableCell align="left">
            Premium for risk cover (this is not invested) Maintenance charge
            Charge associated with maintaining policy
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
//Glossary of BLIL Insurance Terminology
