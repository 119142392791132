import {
    DPO_TOKEN_LOADER,
    DPO_TOKEN_SUCCESS,
    DPO_TOKEN_ERROR_MESSAGE,
    DPO_TOKEN_RESET,
    HIDE_DPO_TOKEN_LOADER,
    DPO_TOKEN_STATUS,
    HIDE_DPO_TOKEN_ERROR_MESSAGE
} from "constants/ActionTypes";



const INIT_STATE = {
    dpoTokenLoader: false,
    showTokenMessage: false,
    dpoTokenAlertMessage: '',
    dpoTokenData: '',
    dpoTokenStatus: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case DPO_TOKEN_LOADER: {
            return {
                ...state,
                dpoTokenLoader: true
            }
        }

        case HIDE_DPO_TOKEN_LOADER: {
            return {
                ...state,
                dpoTokenLoader: false,
            };
        }
        
        case DPO_TOKEN_SUCCESS: {
            return {
                ...state,
                dpoTokenData: action.payload,
            };
        }

        case DPO_TOKEN_ERROR_MESSAGE: {
            return {
                ...state,
                showTokenMessage: true,
                dpoTokenAlertMessage: action.payload,
            };
        }

        case HIDE_DPO_TOKEN_ERROR_MESSAGE: {
            return {
                ...state,
                INIT_STATE
            }
        }

        case DPO_TOKEN_STATUS: {
            return {
                ...state,
                dpoTokenLoader: false,
                dpoTokenStatus: action.payload.status
            }
        }

        case DPO_TOKEN_RESET: {
            return {
                ...state,
                ...INIT_STATE,
            };
        }


        default:
            return state;
    }
};