/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContainerHeader from "../../../components/ContainerHeader/index";
import { Card, CardTitle } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { getClaimInfo } from "../../../actions/ClaimInfo";
import moment from "moment";

export default function ClaimsCaseDetails({
  data: { caseStatus, caseNumber, name, policyNumber },
  handleBack,
}) {
  const dispatch = useDispatch();
  const { getClaimInfoData, loader } = useSelector(
    ({ claimInfo }) => claimInfo
  );
  // console.log(getClaimInfoData);
  useEffect(() => {
    dispatch(getClaimInfo(caseNumber, caseStatus));
  }, [caseNumber, dispatch]);

  return (
    <Card className="claim-details">
      <div className="claim-header">
        <h3 className="card-title">Claim Details</h3>
      </div>
      <div className="row">
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Case Number:</h5>
          <p>{caseNumber ? caseNumber : "N/A"}</p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1">Insured Name:</h5>
          <p>{name ? name : "N/A"}</p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Policy Number:</h5>
          <p>{policyNumber ? policyNumber : "N/A"}</p>
        </div>

        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Mobile Number:</h5>
          <p>
            {getClaimInfoData && getClaimInfoData.mobileNumber
              ? getClaimInfoData.mobileNumber
              : "N/A"}
          </p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Claim Status:</h5>
          <p>{caseStatus ? caseStatus : "N/A"}</p>
        </div>
      </div>
      <div className="row">
        <h4 className="claim-header" style={{ marginTop: 10, marginBottom: 0 }}>
          Reporter Info
        </h4>
      </div>
      <div className="row">
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Reporter Name:</h5>
          <p>
            {getClaimInfoData && getClaimInfoData.reporterName
              ? getClaimInfoData.reporterName
              : "N/A"}
          </p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1">Relationship with Insured:</h5>
          <p>
            {getClaimInfoData && getClaimInfoData.relationshipWithInsured
              ? getClaimInfoData.relationshipWithInsured
              : "N/A"}
          </p>
        </div>

        {/* <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Mobile Number:</h5>
          <p>{getClaimInfoData.mobileNumber}</p>
        </div> */}

        {/* <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <p className="mr-3 policies-section label">DOB:</p>
        <p className="policies-section">{"desc"}</p>
      </div> */}

        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Email Address:</h5>
          <p>
            {getClaimInfoData && getClaimInfoData.emailAddress
              ? getClaimInfoData.emailAddress
              : "N/A"}
          </p>
        </div>
      </div>
      <div className="row">
        <h4 className="claim-header" style={{ marginTop: 10, marginBottom: 0 }}>
          Claim Event Info
        </h4>
      </div>
      <div className="row">
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Claim Type:</h5>
          <p>
            {getClaimInfoData && getClaimInfoData.claimType
              ? getClaimInfoData.claimType
              : "N/A"}
          </p>
        </div>

        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Claim Nature:</h5>
          <p>
            {getClaimInfoData && getClaimInfoData.claimNature
              ? getClaimInfoData.claimNature
              : "N/A"}
          </p>
        </div>

        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Event Date:</h5>
          <p>
            {getClaimInfoData && getClaimInfoData.eventDate
              ? moment(getClaimInfoData.eventDate).format("DD/MM/YYYY")
              : "N/A"}
          </p>
        </div>
        {/* <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Event Location:</h5>
          <p>N/A</p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Event Details:</h5>
          <p>N/A</p>
        </div> */}
      </div>
      {/* <div className="row">
        <h4 className="claim-header" style={{ marginTop: 10, marginBottom: 0 }}>
          Uploaded Documents
        </h4>
      </div>
      <div className="row">
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">ID Proof: </h5>
          <p>N/A</p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Bank Account proof:</h5>
          <p>N/A</p>
        </div>
        <div className="col-xl-4 custom-info-details col-lg-4 col-md-4 col-sm-6 col-12">
          <h5 className="mb-1 ">Death Certificate:</h5>
          <p>N/A</p>
        </div>
      </div> */}
      <div className="row proceed-custom-btn">
        <div className="mt-1 mb-2 d-flex justify-content-center align-items-center">
          <Button
            type="submit"
            className="custom-btn-ach"
            variant="contained"
            color="primary"
            onClick={handleBack}
            // component={Link}
            // to="/home/my-claims"
          >
            Back
          </Button>
        </div>
      </div>
      {loader && (
        <div className="claim-details-loader claim-details-loader-view">
          <CircularProgress />
        </div>
      )}
    </Card>
  );
}
