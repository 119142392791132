/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import moment from "moment";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from "@material-ui/core";

const BookingHistory = ({ data, loading }) => {
  //setPayHistory(data.at(arrayIndex));
  //console.log("pay history: ",payHistory);

  return (
    <div
      className="custom-service-data-table table-responsive-material "
      style={{ width: "100%" }}
    >
      <Table className="table-bordered">
        <TableHead>
          <TableRow>
            {/* <TableCell align="center">Policy Code</TableCell> */}
            <TableCell className="tablecell-heading" align="center">
              Date
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              Branch
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              Service
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              Time
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              Ticket Number
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? (
            data
              .slice(0)
              .reverse()
              .map((i, index) => {
                return (
                  <TableRow>
                    <TableCell align="left">{i.AppointmentDay}</TableCell>
                    <TableCell align="center">{i.Branch.Name}</TableCell>
                    <TableCell align="center">{i.Service.Name}</TableCell>
                    <TableCell align="center">{i.AppointmentTime}</TableCell>
                    <TableCell align="center">{i.TicketNumber}</TableCell>
                  </TableRow>
                );
              })
          ) : loading ? (
            <TableRow>
              <TableCell colSpan="4" align="center">
                Loading...
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan="4" align="center">
                No record found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default BookingHistory;
