import React from "react";
import { Route, Switch } from "react-router-dom";

import Details from "./routes/PolicyDetails/index";
import MyPolicies from "../MyPolicies/routes/index";
import Errorpage from "../../../util/Errorpage";

const AllPolicies = ({ match }) => (
  <div>
    <Switch>
      {/* <Redirect  exact from={`${match.url}/`} to={`${match.url}`}/>  */}
      <Route path={`${match.url}/policy-details`} component={Details} />
      <Route
        path={`${match.url}`}
        component={
          JSON.parse(localStorage.getItem("role")) === "customer"
            ? MyPolicies
            : Errorpage
        }
      />
    </Switch>
  </div>
);

export default AllPolicies;
