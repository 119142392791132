import {
  BASIC_INFO_DATA,
  BENIFIT_INFO_DATA,
  FINANCIAL_INFO_DATA,
  PARTY_INFO_DATA,
  CLAIM_INFO_DATA,
  AGENT_INFO_DATA,
  CUSTOMER_SERVICE_INFO_DATA,
  GET_PAYMENT_INFO,
  RESET_POLICY_DETAILS,
} from "constants/ActionTypes";

const INIT_STATE = {
  agentInfoData: [],
  basicInfoData: {},
  benifitInfoData: [],
  financialInfoData: {},
  partyInfoData: [],
  claimInfoData: [],
  customerServiceInfoData: [],
  getPaymentInfoDetails: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BASIC_INFO_DATA: {
      return {
        ...state,
        basicInfoData: action.payload,
      };
    }
    case CUSTOMER_SERVICE_INFO_DATA: {
      return {
        ...state,
        customerServiceInfoData: action.payload,
      };
    }
    case AGENT_INFO_DATA: {
      return {
        ...state,
        agentInfoData: action.payload,
      };
    }
    case BENIFIT_INFO_DATA: {
      return {
        ...state,
        benifitInfoData: action.payload,
      };
    }
    case FINANCIAL_INFO_DATA: {
      return {
        ...state,
        financialInfoData: action.payload,
      };
    }
    case PARTY_INFO_DATA: {
      return {
        ...state,
        partyInfoData: action.payload,
      };
    }
    case CLAIM_INFO_DATA: {
      return {
        ...state,
        claimInfoData: action.payload,
      };
    }
    case GET_PAYMENT_INFO: {
      return {
        ...state,
        getPaymentInfoDetails: action.payload,
      };
    }
    case RESET_POLICY_DETAILS: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};
