/* eslint-disable no-unused-vars */
import React from "react";
import { currencyConverter } from "util/currencyConverter";

const BeneficiariesInfo = ({ data }) => {
  const { age, gender, name, policyNumber, roleType, shareRate } = data;

  return (
    <>
      <div className="row coustom-claim">
        <div className="col-xl-3 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Role:</h5>
          <p>{roleType}</p>
        </div>

        <div className="col-xl-5 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Name:</h5>
          <p>{name}</p>
        </div>
        <div className="col-xl-2 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Age :</h5>
          <p>{age}</p>
        </div>

        <div className="col-xl-2 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
          <h5 className="mb-1 ">Gender :</h5>
          <p>{gender}</p>
        </div>

        {/* <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
               <h5 className="mb-1 ">Policy Number :</h5>
               <p>{policyNumber}</p>
            </div> */}

        {roleType === "BENEFICIARY" || roleType === "ASSIGNEE" ? (
          <div className="col-xl-3 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
            <h5 className="mb-1 ">Share Rate:</h5>
            <p>{shareRate + "%"}</p>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BeneficiariesInfo;
