import {
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_ERROR_MESSAGE,
  HIDE_CREATE_PASSWORD_ERROR_MESSAGE,
  ON_SHOW_CREATE_PASSWORD_LOADER,
} from "../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  createPasswordStatus: "",
  alertMessage: "",
  showMessage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        alertMessage: "",
        loader: false,
        showMessage: false,
        createPasswordStatus: action.payload,
      };
    }

    case CREATE_PASSWORD_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        loader: false,
        showMessage: true,
      };
    }
    case HIDE_CREATE_PASSWORD_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
        createPasswordStatus: "",
      };
    }
    case ON_SHOW_CREATE_PASSWORD_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    default:
      return state;
  }
};
