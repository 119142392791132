/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import OTPConfirmDialog from "../DialogBox/OTPConfirmDialog.js";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
  registrationOtp,
  showRegistrationOtpLoader,
  hideRegistrationOtpErrorMessage,
} from "actions/RegistrationOTP";
import {
  registrationResendOtp,
  showRegistrationResendOtpLoader,
  hideRegistrationResendOtpErrorMessage,
} from "actions/RegistrationResendOTP";
import { toast, ToastContainer } from "react-toastify";
import { ErrorToaster, SuccessToaster, toastMsgs } from "util/ReactToastify";

import { CircularProgress } from "@material-ui/core";
import { mobileAndEmailOtp, mobileOtpMsgs } from "../../constants/Validations";
// import 'containers/fontStyle.scss';

//Compare imports
import { httpGet, httpPut } from "util/REST";

import {
  showAdminCompareLoader,
  adminCompareRequest,
  resetComapareData,
} from "actions/AdminCompare";
//Approval imports
import {
  showAdminApprovalLoader,
  adminUserApprovalRequest,
  hideAdminUserApprovalError,
} from "actions/AdminApproval";

const MobileOTP = ({ location: { state }, history }) => {
  const [open, setDialog] = useState(false);
  const [validateData, setValidateData] = useState();
  const [compareLoader, setCompareLoader] = useState(false);
  const dispatch = useDispatch();

  const remarks = "Auto-approved";

  const {
    verifyUseralertMessage,
    verifyUserShowMesssage,
    verifyUserAdminApprovalStatus,
  } = useSelector(({ adminApproval }) => adminApproval);

  const {
    alertCompareMessage,
    showCompareMessage,
    comparisionData,
    adminCompareloader,
  } = useSelector(({ adminCompare }) => adminCompare);

  const { loader, alertMessage, registrationOtpStatus, showMessage } =
    useSelector(({ registrationOtp }) => registrationOtp);

  const {
    resendLoader,
    resendAlertMessage,
    registrationResendOtpStatus,
    resendShowMessage,
  } = useSelector(({ registrationResendOtp }) => registrationResendOtp);
  const isUserDataAvailable = state && state.userData;
  const isExternalIdAvailable = state && state.externalId;

  // const isEmailAvailable = isUserDataAvailable && state.userData.email ;
  const isEmailAvailable = false;

  const verifyRegistrationOtpSchema = [
    Yup.object().shape({
      emailOtp: Yup.string()
        .matches(/^[0-9]+$/, mobileAndEmailOtp.emailOtpLengthMsg)
        .min(6, mobileAndEmailOtp.emailOtpLengthMsg)
        .max(6, mobileAndEmailOtp.emailOtpLengthMsg)
        .required(mobileAndEmailOtp.emailOtpRequired),
      mobileOtp: Yup.string()
        .matches(/^[0-9]+$/, mobileAndEmailOtp.mobileOtpLengthMsg)
        .min(6, mobileAndEmailOtp.mobileOtpLengthMsg)
        .max(6, mobileAndEmailOtp.mobileOtpLengthMsg)
        .required(mobileAndEmailOtp.mobileOtpRequired),
    }),
    Yup.object().shape({
      mobileOtp: Yup.string()
        .matches(/^[0-9]+$/, mobileAndEmailOtp.mobileOtpLengthMsg)
        .min(6, mobileAndEmailOtp.mobileOtpLengthMsg)
        .max(6, mobileAndEmailOtp.mobileOtpLengthMsg)
        .required(mobileAndEmailOtp.mobileOtpRequired),
    }),
  ];

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideRegistrationOtpErrorMessage());
      }, 100);
    }

    if (registrationOtpStatus == 200) {
      compareData();
      dispatch(hideRegistrationOtpErrorMessage());
    }

    if (!isUserDataAvailable && !isExternalIdAvailable) {
      history.push("/signup");
    }
  }, [showMessage, registrationOtpStatus, history]);

  useEffect(() => {
    if (resendShowMessage) {
      setTimeout(() => {
        dispatch(hideRegistrationResendOtpErrorMessage());
      }, 100);
    }

    if (registrationResendOtpStatus === 200) {
      {
        isEmailAvailable
          ? toastMsgs.success(mobileOtpMsgs.otpSendSuccessMsgToBoth)
          : toastMsgs.success(mobileOtpMsgs.otpSendSuccessMsgToMobile);
      }
      dispatch(hideRegistrationResendOtpErrorMessage());
    }

    return () => dispatch(hideRegistrationResendOtpErrorMessage());
  }, [resendShowMessage, registrationResendOtpStatus]);

  const compareData = async () => {
    setCompareLoader(true);

    const tempData = `${state.userData.policyNumber}-${state.userData.omangNumber}`;

    await httpGet(
      `userValidate?status=PENDING&policyNumberIDNumber=` + tempData
    )
      .then((data) => {
        const resultData = data.response;
        console.log("This is result data: ", resultData);
        console.log(
          "This is result data array: ",
          resultData.userDetailsValidation
        );
        console.log(
          "This is omang: ",
          resultData.userDetailsValidation[0].omangNumberValidation
        );
        console.log(
          "This is mobile: ",
          resultData.userDetailsValidation[0].mobileNumberValidation
        );
        console.log(
          "This is DOB: ",
          resultData.userDetailsValidation[0].dateOfBirthValidation
        );

        if (resultData.userDetailsValidation.length === 0) {
          setCompareLoader(false);
          setDialog(true);
        } else {
          if (
            resultData.userDetailsValidation[0].omangNumberValidation == 1 &&
            resultData.userDetailsValidation[0].mobileNumberValidation == 1 &&
            resultData.userDetailsValidation[0].dateOfBirthValidation == 1
          ) {
            setCompareLoader(true);
            const approvalData = {};
            approvalData["externalId"] = state.externalId;
            approvalData["registrationStatus"] = "APPROVED";
            approvalData["reason"] = remarks;
            console.log("approval data: ", approvalData);
            //dispatch(adminUserApprovalRequest(approvalData));
            httpPut("updateStatus_auto", approvalData).then((data) => {
              console.log("approval message: ", data.response);
              console.log("user is approved");
            });
          } else {
            console.log("user is not approved");
          }
        }
        setCompareLoader(false);
        setDialog(true);
      })
      .catch((error) => error);
  };

  const handleResend = () => {
    setSeconds(120);
    setIsActive(true);
    dispatch(showRegistrationResendOtpLoader());
    let userData = null;
    if (state && state.userData) {
      userData = state.userData;
    }

    let otpData = {
      contactNumber:
        userData && userData.contactNumber ? userData.contactNumber : "",
      email: "",
      countryCode: userData && userData.countryCode ? userData.countryCode : "",
    };

    dispatch(registrationResendOtp(otpData));
  };

  const [seconds, setSeconds] = useState(120);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isActive && seconds !== 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (isActive && seconds === 0) {
      setIsActive(false);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const minutes = Math.floor(seconds / 60);
  const secondsRem = seconds % 60;
  return (
    <div style={{ margin: "0 auto" }} className="custom-forgot-password">
      <OTPConfirmDialog
        open={open}
        handleRequestClose={() => setDialog(false)}
      />
      <div className="login-container otp-holder align-center d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="login-content ">
          <div className="login-header mb-4">
            <Link className="app-logo" to="/" title="Jambo">
              <img
                src={require("assets/images/logo-color.png")}
                alt="jambo"
                title="jambo"
              />
            </Link>
          </div>

          <div className="login-form">
            <Formik
              initialValues={initialValues}
              validationSchema={
                isEmailAvailable
                  ? verifyRegistrationOtpSchema[0]
                  : verifyRegistrationOtpSchema[1]
              }
              onSubmit={(values, { setSubmitting }) => {
                const { emailOtp, mobileOtp } = values;
                let userData = null;
                if (state) {
                  userData = state.userData;
                }
                const OTPData = {
                  contactNumber: userData ? userData.contactNumber : "",
                  countryCode: userData ? userData.countryCode : "",
                  email: "",
                  mobileOTP: mobileOtp,
                  emailOTP: emailOtp ? emailOtp : "",
                };
                // if(isEmailAvailable) {
                //   OTPData['email']= userData ? userData.email : '';
                //   OTPData['emailOTP']= userData ? userData.emailOTP : '';
                // }
                dispatch(showRegistrationOtpLoader());
                dispatch(registrationOtp(OTPData));
                setSubmitting(false);
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;
                return (
                  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <fieldset>
                      <h4>Enter OTP</h4>

                      <TextField
                        error={errors.mobileOtp && touched.mobileOtp}
                        className="form-textfield form-textfield-label"
                        label="Enter Mobile OTP"
                        name="mobileOtp"
                        value={values.mobileOtp}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.mobileOtp &&
                          touched.mobileOtp &&
                          errors.mobileOtp
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        required
                        margin="normal"
                        placeholder="xxxxxx"
                      />
                      {isEmailAvailable && (
                        <TextField
                          error={errors.emailOtp && touched.emailOtp}
                          className="form-textfield form-textfield-label"
                          label="Enter Email OTP"
                          name="emailOtp"
                          value={values.emailOtp}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.emailOtp &&
                            touched.emailOtp &&
                            errors.emailOtp
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          required
                          margin="normal"
                          placeholder="xxxxxx"
                        />
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {minutes}:
                          {secondsRem < 10 ? `0${secondsRem}` : secondsRem}
                        </div>
                        <div className="remove-btn-padding">
                          <Button
                            className="jr-btn"
                            color="primary"
                            onClick={handleResend}
                            disabled={isActive}
                          >
                            Resend OTP
                          </Button>
                        </div>
                      </div>

                      <div className="mt-1 mb-2 d-flex justify-content-center align-items-center custom-verify-btn">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Verify
                        </Button>
                      </div>
                    </fieldset>
                  </form>
                );
              }}
            </Formik>
          </div>
          {(loader || resendLoader) && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          {compareLoader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      {showMessage &&
        toast.error(alertMessage, {
          autoClose: 10000,
          hideProgressBar: true,
          className: "toaster-text",
        })}
      {resendShowMessage &&
        toast.error(resendAlertMessage, {
          autoClose: 10000,
          hideProgressBar: true,
          className: "toaster-text",
        })}
      <ToastContainer />
    </div>
  );
};

const initialValues = {
  mobileOtp: "",
  emailOtp: "",
};

export default MobileOTP;
