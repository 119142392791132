import {
  FETCH_ALL_MY_POLICIES_ERROR_MESSAGE,
  FETCH_ALL_MY_POLICIES_SUCCESS,
  HIDE_ALL_MY_POLICIES_ERROR_MESSAGE,
  ON_SHOW_ALL_MY_POLICIES_LOADER,
} from "../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  myPoliciesData: "",
  alertMessage: "",
  showMessage: false,
  statusCode: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_MY_POLICIES_SUCCESS: {
      return {
        ...state,
        alertMessage: "",
        loader: false,
        showMessage: false,
        myPoliciesData: action.payload,
      };
    }

    case FETCH_ALL_MY_POLICIES_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload.message ? action.payload.message : "",
        statusCode: action.payload.status ? action.payload.status : null,
        loader: false,
        showMessage: true,
      };
    }
    case HIDE_ALL_MY_POLICIES_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
        myPoliciesData: "",
      };
    }
    case ON_SHOW_ALL_MY_POLICIES_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    default:
      return state;
  }
};
