import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpPost } from "util/REST";
import {
  getBookingServicesSuccess,
  showBookingServicesError,
} from "actions/BookingGetServices";
import { BOOKING_GET_SERVICES_REQUEST } from "constants/ActionTypes";

const getBookingServicesRequest = async (data) =>
  await httpPost("getServices", data)
    .then((data) => data)
    .catch((error) => error);

function* getBookingServices({ payload }) {
  try {
    let body = payload;
    const servicesData = yield call(getBookingServicesRequest, body);
    if (servicesData && servicesData.status >= 400) {
      yield put(showBookingServicesError(servicesData));
    } else {
      yield put(getBookingServicesSuccess(servicesData.response.services));
    }
  } catch (error) {
    yield put(showBookingServicesError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(BOOKING_GET_SERVICES_REQUEST, getBookingServices)]);
}
