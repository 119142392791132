/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";

const YearsDropdown = ({ data, year, handlePremiumChange, startYear }) => {
  const [endDate, setEndDate] = useState(moment().format("YYYY"));

  const commenceYear = moment(startYear).format("YYYY");
  const numberOfYears = endDate - commenceYear;

  let items = [];
  for (let i = 0; i < numberOfYears; i++) {
    items.push(i + 1);
  }

  return (
    <div className="col-lg-2 col-sm-4 col-12">
      <FormControl className="w-100 mb-2">
        <Select
          native
          className="custom-dropdown-kyp"
          label="Select Year"
          required
          value={year}
          onChange={handlePremiumChange}
          input={<Input id="age-native-simple" />}
          placeholder="Select Year"
        >
          <option value={""}>Select Year</option>
          <option value={endDate}>{endDate}</option>
          {items.map((item, index) => (
            <option key={index} value={endDate - item}>
              {endDate - item}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default YearsDropdown;
