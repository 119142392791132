import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { httpAuthPost } from "util/REST";
import { SIGNIN_USER, SIGNOUT_USER } from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from "actions/Auth";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const signInUserWithEmailPasswordRequest = async (data) =>
  await httpAuthPost("loginUser", data)
    .then((authUser) => authUser)
    .catch((error) => error);

function* signInUserWithEmailPassword({ payload }) {
  try {
    console.log("log in initiated");
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);
    if (signInUser && signInUser.message) {
      console.log("user: ", signInUser.message);
      yield put(
        showAuthMessage(
          signInUser.message === "Network Error"
            ? "Something went wrong, please contact BLIL Contact Centre at (+267) 362 3700 for assistance."
            : signInUser.message
        )
      );
    } else {
      // localStorage.setItem('user_id', JSON.stringify(signInUser.response.contactNumber));
      // localStorage.setItem('token',JSON.stringify(signInUser.response.accessToken));
      // localStorage.setItem('role',JSON.stringify(signInUser.response.role));
      // localStorage.setItem('omangNumber',JSON.stringify(signInUser.response.omangNumber));
      yield put(userSignInSuccess(signInUser.status));
    }
  } catch (error) {
    console.log("log in unsuccessful");
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem("user_id");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    //cookies.remove('token');
    yield put(userSignOutSuccess());
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}
