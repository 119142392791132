/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import { hideMessage, showAuthLoader, userSignIn } from "actions/Auth";
import Captcha from "./Captcha";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorBoundary from "util/ErrorBoundary";
import { signIn } from "../constants/Validations";
import { ToastContainer } from "react-toastify";
import { ErrorToaster } from "util/ReactToastify";
import { Select, MenuItem, Input } from "@material-ui/core";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import SignInMobileOTP from "./SignInMobileOtp";

const SignIn = (props) => {
  const [infoData, setinfoData] = useState("");
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const { otpLoader, otpAlertMessage, otpShowMessage, otpStatus } = useSelector(
    ({ signInMobileOtpReducer }) => signInMobileOtpReducer
  );

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    // if (authUser === 200) {
    //    props.history.push("/");
    // }
    if (otpStatus === 200) {
      props.history.push("/");
    }
    return () => dispatch(hideMessage());
  }, [showMessage, authUser, props.history, dispatch]);

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  let countrycode = getCountries().sort(function (a, b) {
    if (en[a] < en[b]) return -1;
    else if (en[a] > en[b]) return 1;
    return 0;
  });
  const { history } = props;
  return (
    <ErrorBoundary>
      {authUser === 200 ? (
        <SignInMobileOTP
          mobileNumber={infoData.userId ? infoData.userId : null}
          countryCode={infoData.countryCode ? infoData.countryCode : null}
          password={infoData.password ? infoData.password : null}
        />
      ) : (
        <div className="app-login-container login-holder d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-login-content">
              <div className="app-login-form">
                <div className="logo-placement align-items-center justify-content-center">
                  <Link className="logo-lg" to="/" title="botswana-life">
                    {/* //signinlogo */}
                    <img
                      src={require("../assets/images/logo.png")}
                      alt="botswana-life"
                      title="botswana-life"
                    />
                  </Link>
                </div>
                <Formik
                  initialValues={initialValues}
                  validationSchema={SignInSchema}
                  onSubmit={(values) => {
                    const { email, password, /*recaptcha,*/ countryCode } =
                      values;

                    let data = {
                      userId: email,
                      password,
                      countryCode,
                      // dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
                      //captchaResponse: recaptcha,
                    };
                    setinfoData(data);
                    dispatch(showAuthLoader());
                    dispatch(userSignIn(data));
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      //touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    } = props;

                    return (
                      <form
                        className="custom-signin-form"
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        <div>
                          <label>Country Code</label>
                          <br />
                          <Select
                            placeholder="Country Code"
                            style={{ width: "100%" }}
                            countryCode={en[values.countryCode]}
                            onChange={handleChange}
                            input={
                              <Input name="countryCode" id="countryCode" />
                            }
                            defaultValue="+267"
                          >
                            {/* <option value="">
                                                                {en['ZZ']}
                                                            </option> */}
                            {/* <MenuItem value="empty" disabled>
                            Please select
                          </MenuItem> */}
                            {countrycode.map((countryCode) => (
                              <MenuItem
                                key={countryCode}
                                value={"+" + getCountryCallingCode(countryCode)}
                              >
                                {en[countryCode]} +
                                {getCountryCallingCode(countryCode)}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <fieldset>
                          <TextField
                            className="form-textfield custom-text-field form-textfield-label"
                            label={
                              <IntlMessages id="appModule.emailPhoneNumber" />
                            }
                            id="email"
                            fullWidth
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={errors.email /*&& touched.email*/}
                            helperText={
                              errors.email /*&& touched.email && errors.email*/
                            }
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <TextField
                            className="form-textfield custom-text-field form-textfield-label"
                            id="password"
                            type="password"
                            label={<IntlMessages id="appModule.password" />}
                            fullWidth
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={errors.password /*&& touched.password*/}
                            helperText={
                              errors.password /*&&
                              touched.password &&
                              errors.password*/
                            }
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />

                          {/* <DatePickers
                          handleDateChange={setFieldValue}
                          handleBlur={handleBlur}
                          dateOfBirth={values.dateOfBirth}
                          error={errors.dateOfBirth && touched.dateOfBirth}
                          helperText={errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
                        /> */}
                          {/*<div className="custom-captcha">
                            {<Captcha
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                          />}
                            </div>*/}

                          <div className="btn-con placement">
                            <div
                              style={{ width: "150px", margin: "0 auto" }}
                              className="custom-signin-page"
                            >
                              <a
                                href="https://www.botswanalife.co.bw/technology-use-and-privacy-policy"
                                className="signin-font"
                              >
                                <IntlMessages
                                  className="forgot-password"
                                  id="signIn.termsConditions"
                                />
                              </a>
                            </div>

                            <div className="row proceed-custom-btn">
                              <div
                                style={{ width: "100px", margin: "0 auto" }}
                                className="mt-1 mb-2 d-flex justify-content-center align-items-center"
                              >
                                <Button
                                  style={{
                                    margin: "0 auto",
                                    width: "100%",
                                    marginTop: "15px",
                                    marginBottom: "5px",
                                  }}
                                  type="submit"
                                  variant="contained"
                                >
                                  <IntlMessages id="appModule.signIn" />
                                </Button>
                              </div>
                            </div>

                            <div
                              style={{ width: "150px", margin: "0 auto" }}
                              className="custom-signin-page"
                            >
                              <Link
                                to="/forgot-password"
                                className="signin-font"
                              >
                                <IntlMessages
                                  className="forgot-password"
                                  id="signIn.forgotPasswordAndUid"
                                />
                              </Link>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>

            <div className="app-logo-content d-flex align-items-center justify-content-center">
              {/*<Link className="logo-lg" to="/" title="botswana-life">
                <img
                  src={require("assets/images/logo.png")}
                  alt="botswana-life"
                  title="botswana-life"
                />
              </Link>*/}
              <div className="btn-con login-button-placement">
                <div className="custom-signin-page sign-up-font">
                  <IntlMessages id="appModule.signUpMessage" />
                </div>

                <Button
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    border: "solid 1.5px #ffffff",
                  }}
                  onClick={() => history.push("/signup")}
                  type="submit"
                  variant="contained"
                >
                  <IntlMessages id="appModule.signUp" />
                </Button>
              </div>
            </div>
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && <ErrorToaster message={alertMessage} />}
          <ToastContainer />
        </div>
      )}
    </ErrorBoundary>
  );
};

const initialValues = {
  email: "",
  password: "",
  // dateOfBirth: new Date('2000-01-01'),
  //recaptcha: "",
  countryCode: "+267",
};

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .required(signIn.emailRequired)
    .matches(/^[0-9]+$/, signIn.mobileNumberOnly)
    .test("email", signIn.phoneNumberLengthMsg, function (value) {
      return (
        (this.parent.countryCode === "+267" && value?.length === 8) ||
        (this.parent.countryCode !== "+267" && value?.length > 1)
      );
    }),
  password: Yup.string()
    .matches(
      /^(?=[A-Z])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      signIn.passwordMustContain
    )
    .required(signIn.passwordRequired),
  /*recaptcha: Yup.string()
    .typeError(signIn.recaptchaRequired)
    .required(signIn.recaptchaRequired),*/
});

export default SignIn;
