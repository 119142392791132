import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch, Router } from 'react-router-dom';
import axios from "axios";
import configureStore, { history } from './store';
import App from './containers/App';
import ErrorBoundary from 'util/ErrorBoundary';
import RedirectUrl from './app/routes/Url/index'

  var currentLocation = window.location.href;
  var isAlreadyRun = "no";
  console.log(currentLocation)
  currentLocation = currentLocation.split("/");
  currentLocation = currentLocation[currentLocation.length - 1];
  //const url = 'http://localhost:8000/api/v1/'+currentLocation;
  //const url = 'http://102.37.61.33:8000/api/v1/'+currentLocation;
  //old UAT/ const url = 'http://52.168.14.3:8000/api/v1/'+currentLocation;
  //const url = 'http://13.92.251.108:8000/api/v1/'+currentLocation;
  const url = 'https://customerportal.botswanalife.co.bw/api/v1/'+currentLocation;


  function GetUrl(){
    
      console.log("Current location "+currentLocation.length);

      if(isAlreadyRun != "yes"){
        const rps = axios.get(url)
          .then(function(response){
            console.log(response.data)
            window.location.href = response.data
            return response.data;
          })
          .catch(err=>{console.log("Error: "+err)})
          isAlreadyRun = "yes";
          
      }
      
  }

  if(currentLocation.length >= 8 && currentLocation.length <= 10){
    console.log("Length is "+currentLocation.length);
    GetUrl();
  }
  
  export const store = configureStore();
const MainApp = () =>

  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <ErrorBoundary>
          <Route path="/" component={App} />
        </ErrorBoundary>
      </Switch>
    </Router>
  </Provider>

export default MainApp;