/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardText } from "reactstrap";
import Button from "@material-ui/core/Button";
// import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import ContainerHeader from "components/ContainerHeader";
import Widget from "../../../../../components/Widget";

export const ISAGOMultiFundInvestmentPlan = ({ productName, productId }) => {
  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile custom-kyp-fonts">
        <div className="card-header">
          <h4 className="card-title mb-0 text-uppercase">{productName}</h4>
        </div>
        <div className="jr-tabs-classic">
          <div className="jr-tabs-content jr-task-list pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <h3 className="mb-2">Product Code</h3>
                  <p>MFDS</p>
                  <h3 className="mb-2">Product Summary</h3>
                  <p>
                    Botswana Life has introduced yet another exciting offering
                    to its clientele base, Isago Investement Plan. The policy
                    aims to help our policyholders save for planned events, for
                    example children’s education, a dream wedding, a dream
                    holiday, starting a business etc. On maturity the client can
                    take all the investment value. Partial withdrawal of the
                    investment is allowed in case of an emergency that requires
                    cash that may be locked in the Isago investment.
                  </p>
                  <p>
                    There are options to choose level premiums, premiums
                    escalating at 5% and 10% as well as any ad hoc increases. If
                    the Isago policyholder passes on before policy maturity,
                    Botswana Life pays the greater of amount of investments made
                    to date and investment value at the time of death. Isago
                    policyholders are empowered to change their investment
                    allocations depending on their financial sophistication and
                    understanding of the market performance. While our
                    competitors charge a fee for each and every investment
                    switch, with Isago the policyholder may switch investments 3
                    times in a year at no cost to the client (“Mahala!”)
                  </p>
                  <p>
                    While our competitors demand up to 5% as an initial
                    investment fee, with Isago there is no initial fee and 100%
                    of the investment is invested. This ensures that the
                    client’s investment grows at a faster rate than it would
                    when invested with our competitors. Isago policyholders can
                    choose between local investment funds and offshore
                    investment funds. With offshore investments, the
                    policyholder may benefit from investment returns earned in
                    hard currency as well as from currency movements
                  </p>
                </div>
                <div>
                  <h3 className="mb-2">Age Limits</h3>
                  <ul>
                    <li>Minimum age at entry: - 0 years</li>
                    <li>Maximum age at entry: - 89 years</li>
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2">Sum Assured</h3>
                  <p> N/A</p>
                  <h3 className="mb-2">Premium Limits</h3>
                  <p> N/A</p>
                </div>
                <div>
                  <h3 className="mb-2">Policy Term</h3>
                  <ul>
                    <li>Minimum term: 10 years</li>
                    <li>Maximum term : 35 years</li>
                  </ul>
                </div>
                <div>
                  <Button
                    className="custom-btn-ach"
                    component={Link}
                    to="/home/know-your-products"
                    variant="contained"
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </>
  );
};
