import { Tooltip } from "@material-ui/core";
import React, { Component } from "react";
// import UploadService from "../services/upload-files.service";
import { CircularProgress } from "@material-ui/core";
import { httpPost } from "../../../util/REST";
import DeleteIcon from "@material-ui/icons/Delete";

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileFormatErrMessage: "",
      fileSizeErr: false,
      fileSizeZero: false,
      isfilecorrupted: false,
      loader: false,
      selectedFiles: null,
      //fileInfos: null
      //fileInfos define at line 17
    };
  }

  handleChooseFile = (event) => {
    let fileData = event.target.files;
    const FILE_SIZE = 5000 * 1024;
    const ZERO = 0;
    if (fileData && fileData.length && fileData[0].size >= FILE_SIZE) {
      this.setState({
        fileSizeErr: true,
        fileSizeZero: false,
      });
    } else if (fileData && fileData.length && fileData[0].size === ZERO) {
      this.setState({
        fileSizeZero: true,
        fileSizeErr: false,
      });
    } else {
      this.setState({
        fileSizeErr: false,
        fileSizeZero: false,
        selectedFiles: fileData && fileData[0] && fileData[0].name,
      });
      this.props.handleChooseFile(fileData);
    }
  };

  handleFileDelete(id, fileType) {
    document.getElementById(id).value = null;

    this.setState({
      selectedFiles: null,
      isfilecorrupted: false,
      fileSizeErr: false,
      fileSizeZero: false,
      fileFormatErrMessage: "",
    });
    this.props.setSelectedFile(null);

    this.props.removeDoc(fileType);
  }

  upload = () => {
    const { selectedFile } = this.props;
    // console.log(selectedFile, "selectedFile");

    if (selectedFile && selectedFile[0] && selectedFile[0].name) {
      let currentFile = selectedFile[0];
      this.setState({
        selectedFiles: selectedFile && selectedFile[0] && selectedFile[0].name,
      });

      let formData = new FormData();

      formData.append("file", currentFile);
      if (!this.state.fileSizeErr || !this.state.fileSizeZero) {
        this.setState({
          loader: true,
          // selectedFiles: selectedFile && selectedFile[0] && selectedFile[0].name,
        });

        return httpPost(
          `documentStaging?fileType=${this.props.fileType}&policyNo=${this.props.thePolicyCode}&insuredId=${this.props.insurredId}`,
          formData,

          //logging insurredId and Policy Code
          console.log(
            "policyCode",
            this.props.thePolicyCode,
            "insuredId",
            this.props.insurredId
          ),
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
          .then((files) => {
            if (files.response === null) {
              // console.log(files);
              this.setState({
                loader: false,
                isfilecorrupted: true,
                fileFormatErrMessage: files.message,
              });
            } else {
              this.setState({
                loader: false,
                isfilecorrupted: false,
                fileInfos: files && files.response ? files.response : null,
              });
              console.log(files);
              this.props.handleFile(this.state.fileInfos);
            }
          })
          .catch((e) => {
            this.setState({ loader: false });
            console.log("error....", e);
          });
      }
    }
  };

  checkFileuploadStatus = (fileData, fileType) => {
    let status = false;
    fileData.forEach((item) => {
      if (item.fileType === fileType) {
        status = true;
      }
    });
    return status;
  };

  render() {
    const {
      fileSizeErr,
      // isfilecorrupted,
      loader,
      // selectedFiles,
      fileSizeZero,
      fileFormatErrMessage,
    } = this.state;

    const { selectedFile } = this.props;

    return (
      <>
        <div className="upload-file-css">
          <div className="upload-file-css-custom">
            <label className="btn btn-default">
              <input
                type="file"
                id={this.props.id}
                disabled={this.checkFileuploadStatus(
                  this.props.fileData,
                  this.props.fileType
                )}
                style={{
                  whiteSpace: "nowrap",
                  width: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  // border: 1px solid #000000;
                }}
                onChange={this.handleChooseFile}
                accept="image/jpeg, image/jpg, application/pdf"
              />
            </label>
          </div>
          {/* {console.log(fileSizeErr,"fileSizeErrfileSizeErr")} */}
          {(fileSizeErr || fileSizeZero || selectedFile !== null) && (
            <Tooltip title="Delete">
              <DeleteIcon
                className="delete-icon"
                id="clear"
                onClick={() => {
                  this.handleFileDelete(this.props.id, this.props.fileType);
                }}
              />
            </Tooltip>
          )}
          {!this.checkFileuploadStatus(
            this.props.fileData,
            this.props.fileType
          ) &&
            selectedFile !== null &&
            !fileSizeErr &&
            !fileSizeZero && (
              <button
                // disabled={fileInfos && fileInfos.fileName !== ""}
                onClick={this.upload}
                variant="contained"
                type="button"
                // style={{backgroundColor:fileInfos && fileInfos.fileName !== ""?"#d3d3d3":"primary"}}
              >
                Upload
              </button>
            )}
        </div>
        {this.checkFileuploadStatus(this.props.fileData, this.props.fileType)
          ? `File uploaded`
          : null}

        {fileSizeErr && (
          <div className="ml-3 claim-type-validation-text">
            Uploaded files size should not exceed 5MB
          </div>
        )}

        {fileSizeZero && (
          <div className="ml-3 claim-type-validation-text">
            File is Corrupted please select another file
          </div>
        )}
        {/* {console.log(fileSizeZero,"fileSizeZero", fileSizeErr, "fileSizeErr", fileFormatErrMessage, 'fileFormatErrMessage')} */}
        {!fileSizeErr &&
          !fileSizeZero &&
          !this.checkFileuploadStatus(
            this.props.fileData,
            this.props.fileType
          ) &&
          fileFormatErrMessage !== "" && (
            <div className="ml-3 claim-type-validation-text">
              {fileFormatErrMessage}
            </div>
          )}

        {loader && (
          <div className="register-new-table-loader-view-upload">
            <CircularProgress />
          </div>
        )}
      </>
    );
  }
}
