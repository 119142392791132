import {
  BOOKING_GET_BRANCH_SUCCESS,
  BOOKING_GET_BRANCH_ERROR_MESSAGE,
  BOOKING_GET_BRANCH_LOADER,
  HIDE_BOOKING_GET_BRANCH_ERROR_MESSAGE,
  BOOKING_GET_BRANCH_RESET,
} from "constants/ActionTypes";

const INIT_STATE = {
  getBranchesLoader: false,
  getBranchesAlertMessage: "",
  getBranchesShowMesssage: false,
  getBranchesInfo: [],
  getBranchesStatusCode: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BOOKING_GET_BRANCH_SUCCESS: {
      return {
        ...state,
        getBranchesLoader: false,
        getBranchesInfo: action.payload.response,
      };
    }

    case BOOKING_GET_BRANCH_ERROR_MESSAGE: {
      return {
        ...state,
        getBranchesAlertMessage: action.payload.message
          ? action.payload.message
          : "",
        getBranchesShowMesssage: true,
        getBranchesLoader: false,
        getBranchesStatusCode: action.payload.status
          ? action.payload.status
          : "",
      };
    }

    case HIDE_BOOKING_GET_BRANCH_ERROR_MESSAGE: {
      return {
        ...state,
        getBranchesAlertMessage: "",
        getBranchesShowMesssage: false,
        getBranchesLoader: false,
        getBranchesStatusCode: null,
      };
    }

    case BOOKING_GET_BRANCH_LOADER: {
      return {
        ...state,
        getBranchesLoader: true,
      };
    }

    case BOOKING_GET_BRANCH_RESET: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case "resetLoader": {
      return {
        ...state,
        loader: false,
      };
    }

    default:
      return state;
  }
};
