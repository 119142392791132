import {
  POST_SIGNIN_MOBILE_RESEND_OTP,
  POST_SIGNIN_MOBILE_RESEND_OTP_LOADER,
  POST_SIGNIN_MOBILE_RESEND_OTP_ERROR_MESSAGE,
  HIDE_SIGNIN_MOBILE_RESEND_ERROR_MESSAGE,
  VERIFY_SIGNIN_MOBILE_RESEND_OTP_SUCCESS,
} from "constants/ActionTypes";

export const signInResendOtp = (data) => {
  return {
    type: POST_SIGNIN_MOBILE_RESEND_OTP,
    payload: data,
  };
};

export const verifySignInResendOtpSuccess = (data) => {
  return {
    type: VERIFY_SIGNIN_MOBILE_RESEND_OTP_SUCCESS,
    payload: data,
  };
};

export const signInResendOtpLoader = () => {
  return {
    type: POST_SIGNIN_MOBILE_RESEND_OTP_LOADER,
  };
};
export const hideSignInResendOtpErrorMessage = () => {
  return {
    type: HIDE_SIGNIN_MOBILE_RESEND_ERROR_MESSAGE,
  };
};

export const verifySignInResendOtpErrorMessage = (message) => {
  return {
    type: POST_SIGNIN_MOBILE_RESEND_OTP_ERROR_MESSAGE,
    payload: message,
  };
};
