import { all, call, put, takeEvery } from "redux-saga/effects"
import { httpPost } from 'util/REST'
import { requestDpoSuccess, showRequestDpoError, requestDpoSuccessMessage } from "actions/DPOPayment"
import { GET_DPO_REQUEST } from "constants/ActionTypes"

const getDPOApiRequest = async (data) =>
    await httpPost(`paymentCollection?policyNumber=${data.policyNumber}&transToken=${data.transToken}`)
        .then(data => data)
        .catch(error => error)

function* getDPOPaymentData({ payload }) {
    try {
        let body = payload;
        const dpoData = yield call(getDPOApiRequest, body);
        if (dpoData && dpoData.status >= 400) {
            yield put(showRequestDpoError(dpoData.message))
        } else {
            yield put(requestDpoSuccess(dpoData.status))
            yield put(requestDpoSuccessMessage(dpoData.message))
        }
    } catch (error) {
        yield put(showRequestDpoError(error))
    }

};

export default function* rootSaga() {
    yield all([takeEvery(GET_DPO_REQUEST, getDPOPaymentData)]);
}