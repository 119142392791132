import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContainerHeader from "../../../components/ContainerHeader/index";
import { ErrorToaster, toastMsgs, SuccessToaster } from "util/ReactToastify";
import dayjs from "dayjs";
import moment from "moment";
import {
  DialogTitle,
  FormLabel,
  Dialog,
  Tooltip,
  CircularProgress,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {
  getFeedbackInfo,
  getFeedbackQuestions,
  submitFeedback,
  showFeedbackLoader,
  getFeedbackReset,
} from "actions/GetFeedback";

const Feedback = ({ match }) => {
  const dispatch = useDispatch();

  const [feedback, setFeedback] = useState([]);
  const [answer, setAnswer] = useState({
    Question_ID: "",
    Answer_ID: "",
    RatingValue: "",
    AnswerText: "",
  });
  const [questionID, setQuestionID] = useState("");
  const [firstAnswerID, setFirstAnswerID] = useState("410");
  const [secondAnswerID, setSecondAnswerID] = useState("414");
  const [firstRating, setFirstRating] = useState("2");
  const [secondRating, setSecondRating] = useState("3");
  const [multichoice, setMultichoice] = useState("");
  const [rating, setRating] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [filterResult, setFilterResult] = useState();
  const [visitCode, setVisitCode] = useState("");
  const [validCustomer, setValidCustomer] = useState();
  console.log({ feedback });

  const {
    customerFeedbackInfo,
    feedbackQuestions,
    feedbackError,
    feedbackStatus,
    submitStatus,
    feedbackLoader,
  } = useSelector(({ getFeedback }) => getFeedback);

  const VisitFeedback = "VisitFeedback";
  const MOBILE = "MOBILE";
  const CustomerID = "CustomerID";
  const en = "en";
  const typeVisitFeedback = "VisitFeedback";
  const mobileNumber = JSON.parse(localStorage.getItem("user_id"));
  const userID = JSON.parse(localStorage.getItem("omangNumber"));
  console.log("user mobile number: ", mobileNumber);

  const submitSurvey = () => {
    dispatch(showFeedbackLoader());
    dispatch(
      submitFeedback({
        channel: "Mobile",
        type: "VisitFeedback",
        customerID: null,
        customerIDType: null,
        visitToken: visitCode,
        contactNumber: null,
        smsResponse: null,
        counterNumber: 0,
        branchIdentity: null,
        languagePrefix: "en",
        submitAnswerInfo: [
          {
            Question_ID: 409,
            Answer_ID: firstAnswerID,
            RatingValue: null,
            AnswerText: null,
          },
          {
            Question_ID: 418,
            Answer_ID: null,
            RatingValue: firstRating,
            AnswerText: null,
          },
          {
            Question_ID: 413,
            Answer_ID: secondAnswerID,
            RatingValue: null,
            AnswerText: null,
          },
          {
            Question_ID: 416,
            Answer_ID: null,
            RatingValue: secondRating,
            AnswerText: null,
          },
          {
            Question_ID: 417,
            Answer_ID: null,
            RatingValue: null,
            AnswerText: answerText,
          },
        ],
      })
    );
  };

  const checkUser = async () => {
    try {
      const members = customerFeedbackInfo.find(
        (member) => member.CustomerID === `${userID}`
      );

      if (members) {
        console.log("valid members: ", validCustomer);

        dispatch(showFeedbackLoader());
        dispatch(
          getFeedbackQuestions(
            VisitFeedback,
            MOBILE,
            members.CustomerID,
            CustomerID,
            mobileNumber,
            en,
            typeVisitFeedback,
            members.VisitToken
          )
        );
      } else {
        toastMsgs.error("You are not eligible to give feedback");
        console.log("You can't give feedback!!!!");
      }
    } catch (error) {
      console.log("nothing happens");
    }
  };

  ///const handleQuestion = () => {}

  useEffect(() => {
    dispatch(showFeedbackLoader());
    dispatch(
      getFeedbackInfo({
        channel: "MOBILE",
        mode: "1",
        minuteToGoBack: "1440",
        languagePrefix: "en",
        additionalInfo: [
          {
            Key: "SegmentIdentification",
            Value: "",
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    if (customerFeedbackInfo.length > 0) {
      checkUser();
      /*
        let arrayIndex = customerFeedbackInfo.findIndex(object => {

          return object.CustomerID === `${userID}`
          
        });
        console.log("user list: ",arrayIndex)

        let userDetail = customerFeedbackInfo[arrayIndex]

        console.log("data array: ",userDetail)

        if(userDetail.VisitToken){
         setVisitCode(userDetail.VisitToken)
        }
        
        if(member.CustomerID === `${userID}`){
          dispatch(showFeedbackLoader())
          dispatch(getFeedbackQuestions( VisitFeedback, MOBILE, member.CustomerID, CustomerID, mobileNumber, en, typeVisitFeedback, member.VisitToken))
        }
        */
    } else {
      toastMsgs.error("You are not eligible to give feedback");
      console.log("You can't give feedback!!!!");
    }
  }, [customerFeedbackInfo]);

  useEffect(() => {
    if (feedbackQuestions) {
      console.log("feedback questions: ", feedbackQuestions);
    }
  }, []);

  useEffect(() => {
    if (submitStatus === 200) {
      toastMsgs.success("Survey has been submitted successfully.");
    } else if (submitStatus > 200) {
      toastMsgs.error(feedbackError);
    }

    return () => dispatch(getFeedbackReset());
  }, []);

  return (
    <>
      <ContainerHeader match={match} />

      <div className="feedback-container">
        {/*
        customerFeedbackInfo.length > 0 ?
        <div>The array exists</div>
        :
        <div>The array does not exists</div>
        */}

        {feedbackQuestions.length > 0 ? (
          <>
            <div className="question-holder">
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                Did you visit any BLIL branch with the same request before?
              </div>
              <FormControl style={{ marginTop: "10px", marginBottom: "10px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={firstAnswerID}
                  onChange={(event) => setFirstAnswerID(event.target.value)}
                >
                  <FormControlLabel
                    value={"410"}
                    control={<Radio />}
                    label={"Yes"}
                  />
                  <FormControlLabel
                    value={"411"}
                    control={<Radio />}
                    label={"No"}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="question-holder">
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                How satisfied were you with the overall service provided by the
                branch?
              </div>
              <FormControl style={{ marginTop: "10px", marginBottom: "10px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={firstRating}
                  onChange={(event) => setFirstRating(event.target.value)}
                >
                  <FormControlLabel
                    value={"0"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"😡"}
                  />
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"🙁"}
                  />
                  <FormControlLabel
                    value={"2"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"😐"}
                  />
                  <FormControlLabel
                    value={"3"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"🙂"}
                  />
                  <FormControlLabel
                    value={"4"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"😃"}
                  />
                  <FormControlLabel
                    value={"5"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"😁"}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="question-holder">
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                Were you referred to anyone else within the branch?
              </div>
              <FormControl style={{ marginTop: "10px", marginBottom: "10px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={secondAnswerID}
                  onChange={(event) => setSecondAnswerID(event.target.value)}
                >
                  <FormControlLabel
                    value={"414"}
                    control={<Radio />}
                    label={"Yes"}
                  />
                  <FormControlLabel
                    value={"415"}
                    control={<Radio />}
                    label={"No"}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="question-holder">
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                How likely are you to recommend BOTSWANA LIFE INSURANCE to a
                friend or family?
              </div>
              <FormControl style={{ marginTop: "10px", marginBottom: "10px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={secondRating}
                  onChange={(event) => setSecondRating(event.target.value)}
                >
                  <FormControlLabel
                    value={"0"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"😡"}
                  />
                  <FormControlLabel
                    value={"1"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"🙁"}
                  />
                  <FormControlLabel
                    value={"2"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"😐"}
                  />
                  <FormControlLabel
                    value={"3"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"🙂"}
                  />
                  <FormControlLabel
                    value={"4"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"😃"}
                  />
                  <FormControlLabel
                    value={"5"}
                    control={<Radio />}
                    labelPlacement="top"
                    label={"😁"}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="question-holder">
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                Is there anything about your service experience that you would
                like us to improve?
              </div>
              <TextField
                fullWidth
                value={answerText}
                onChange={(event) => setAnswerText(event.target.value)}
              />
            </div>

            <div
              style={{
                position: "relative",
                marginTop: "25px",
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "25px",
              }}
            >
              <Button
                disabled={
                  firstAnswerID &&
                  firstRating &&
                  secondAnswerID &&
                  secondRating &&
                  answerText
                    ? false
                    : true
                }
                variant="contained"
                color="primary"
                onClick={submitSurvey}
              >
                Submit
              </Button>
            </div>
          </>
        ) : (
          <div
            style={{
              marginTop: "100px",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            You can only give feedback once you have honoured your appointment.
          </div>
        )}
      </div>

      {feedbackLoader && (
        <div className="appointment-loading-view">
          <CircularProgress />
        </div>
      )}

      {feedbackStatus > 200 && (
        <ErrorToaster message={"You are not eligible to give feedback"} />
      )}

      {submitStatus === 200 && (
        <SuccessToaster message={"Survey has been submitted successfully."} />
      )}

      {submitStatus > 200 && <ErrorToaster message={feedbackError} />}
    </>
  );
};

export default Feedback;
