/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardText } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ContainerHeader from "components/ContainerHeader";
import Widget from "../../../../../components/Widget";
// import { Image } from '@react-pdf/renderer';
// import Image from "assets/images/45yearsoflife.png";
//import ReactApp from 'react-native';
// import {
//     ImageBackground,
// } from 'react-native';

export const MmolokiFamilyWealthProtectorPlan = ({
  productId,
  productName,
}) => {
  // let { StyleSheet } = React;

  // let styles = StyleSheet.create({
  //     backgroundImage: {
  //         flex: 1,
  //         resizeMode: 'cover', // or 'stretch'
  //     }
  // });

  return (
    <>
      {/* <div style={{ position: 'relative', textAlign: 'center', color: 'white' }}> */}
      {/* // src={require("assets/images/45yearsoflife.png")} */}

      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile custom-kyp-fonts">
        {/* <image source={require('assets/images/45yearsoflife.png')} style={styles.backgroundImage} /> */}

        {/* <img src={require("assets/images/45yearsoflife.png")} alt="Snow" style="width:100%;" /> */}

        <div className="card-header">
          <h4 className="card-title mb-0 text-uppercase">{productName}</h4>
        </div>
        {/* <div style={{ backgroundImage: 'url(' + Image + ')', backgroundSize: 'auto' }}></div> */}
        <div className="jr-tabs-classic">
          <div className="jr-tabs-content jr-task-list pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <h3 className="mb-2">Product Code</h3>
                  <p>BMFW-1</p>
                  <h3 className="mb-2">Product Summary</h3>
                  <p>
                    Mmoloki Family Wealth Protector Plan will assist you to
                    minimise the financial impact of the death of a loved one.
                    Many of us have always understood the responsibilities of
                    taking care of the entire family, including the extended
                    family. Mmoloki allows cover of up to 27 family members. It
                    also has flexible levels of cover, with a minimum of P10,
                    000 for the main member.
                  </p>
                  <p>
                    It also has the following in-built benefits:
                    <br />
                    <br />
                    <b>Waiver of premium:</b> In the event that the policy
                    Premium Payer dies or is permanently and totally disabled,
                    Botswana Life will take over premium payment until the
                    policy terminates.
                    <br />
                    <br /> <b>Ownership Transferability:</b> When the main life
                    reaches the age of 50 but before they reach age 65, then
                    ownership of the policy can be transferred to another adult
                    who is covered under the policy with no change in premium.
                    <br />
                    <br />
                    <b>Cash back every five (5) years:</b> At the end of every
                    five (5) years, Botswana Life will pay you back 10% of your
                    total premiums that you have paid for the previous five (5)
                    years, as long as the policy remains active, even if you
                    have claimed.
                  </p>
                </div>
                <div>
                  <h3 className="mb-2">Age Limits</h3>
                  <ul>
                    <li> Policyholder : 64 years</li>
                    <li>Spouse : 64 years</li>
                    <li>Children: 20 years</li>
                    <li>Parents and parents in law: 85 years</li>
                    <li>Wider family members: 85 years</li>
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2">Sum Assured</h3>
                  <p>
                    The client can choose any sum assured between P 10,000 and P
                    100,000. This will be translated into number of units for
                    pricing the selected sum assured. The sum assured limits for
                    the member can be any number of units from 2 units to 20
                    units. The sum assured will be the number of units
                    multiplied by unit value. If the member chooses 10 units,
                    the member sum assured will be 10 x 5000= 50,000. If the
                    member selects 20 units, the sum assured will be 20 x 5000 =
                    100,000.
                  </p>
                  <h3 className="mb-2">Premium Limits</h3>
                  <p> N/A</p>
                </div>
                <div>
                  <h3 className="mb-2">Policy Term</h3>
                  <ul>
                    <li> Whole of life cover</li>
                  </ul>
                </div>
                <div>
                  <Button
                    className="custom-btn-ach"
                    component={Link}
                    to="/home/know-your-products"
                    variant="contained"
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
      {/* </div> */}
    </>
  );
};
