/* eslint-disable no-unused-vars */
import React from "react";
import moment from "moment";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from "@material-ui/core";

const CustomerServiceInfo = ({ data, loading }) => {
  return (
    <div
      className="custom-service-data-table table-responsive-material "
      style={{ width: "100%" }}
    >
      <Table className="table-bordered">
        <TableHead>
          <TableRow>
            {/* <TableCell align="center">Policy Code</TableCell> */}
            <TableCell className="tablecell-heading" align="left">
              Transaction Name
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              Registered Date
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              Completion Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 1 ? (
            data.map((i) => {
              return (
                <TableRow>
                  {/* <TableCell align="center">{i.policyCode} </TableCell> */}
                  <TableCell align="left">{i.serviceName} </TableCell>
                  <TableCell align="center">
                    {i.registeredDate
                      ? moment(i.registeredDate).format("DD/MM/YYYY")
                      : null}{" "}
                  </TableCell>
                  <TableCell align="center">
                    {i.completedDate
                      ? moment(i.completedDate).format("DD/MM/YYYY")
                      : null}{" "}
                  </TableCell>
                </TableRow>
              );
            })
          ) : loading ? (
            <TableRow>
              <TableCell colSpan="4" align="center">
                Loading...
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan="4" align="center">
                No record found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default CustomerServiceInfo;
