import {
  POST_SIGNIN_MOBILE_RESEND_OTP_LOADER,
  POST_SIGNIN_MOBILE_RESEND_OTP_ERROR_MESSAGE,
  HIDE_SIGNIN_MOBILE_RESEND_ERROR_MESSAGE,
  VERIFY_SIGNIN_MOBILE_RESEND_OTP_SUCCESS,
} from "../constants/ActionTypes";

const INIT_STATE = {
  resendOtpLoader: false,
  resendOtpStatus: "",
  resendOtpAlertMessage: "",
  resendOtpShowMessage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VERIFY_SIGNIN_MOBILE_RESEND_OTP_SUCCESS: {
      return {
        ...state,
        resendOtpLoader: false,
        resendOtpStatus: action.payload,
      };
    }
    case POST_SIGNIN_MOBILE_RESEND_OTP_ERROR_MESSAGE: {
      return {
        ...state,
        resendOtpAlertMessage: action.payload,
        resendOtpLoader: false,
        resendOtpShowMessage: true,
      };
    }
    case HIDE_SIGNIN_MOBILE_RESEND_ERROR_MESSAGE: {
      return {
        ...state,
        resendOtpAlertMessage: "",
        resendOtpShowMessage: false,
        resendOtpLoader: false,
        resendOtpStatus: "",
      };
    }
    case POST_SIGNIN_MOBILE_RESEND_OTP_LOADER: {
      return {
        ...state,
        resendOtpLoader: true,
      };
    }
    default:
      return state;
  }
};
