import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpAuthPost } from "util/REST";
import {
  verifyRegistrationResendOtpSuccess,
  verifyRegistrationResendOtpErrorMessage,
} from "actions/RegistrationResendOTP";
import { REGISTRATION_RESEND_OTP } from "constants/ActionTypes";

const registrationResendOtp = async (data) =>
  await httpAuthPost("resendOTPS", data)
    .then((data) => data)
    .catch((error) => error);

function* registrationResendOTPRequest({ payload }) {
  try {
    let body = payload;
    const otpVerification = yield call(registrationResendOtp, body);
    if (otpVerification && otpVerification.status >= 400) {
      yield put(
        verifyRegistrationResendOtpErrorMessage(otpVerification.message)
      );
    } else {
      yield put(verifyRegistrationResendOtpSuccess(otpVerification.status));
    }
  } catch (error) {
    yield put(verifyRegistrationResendOtpErrorMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(REGISTRATION_RESEND_OTP, registrationResendOTPRequest)]);
}
