import {
  POST_SIGNIN_MOBILE_OTP,
  POST_SIGNIN_MOBILE_OTP_SUCCESS,
  POST_SIGNIN_MOBILE_OTP_ERROR_MESSAGE,
  HIDE_SIGNIN_MOBILE_OTP_MESSAGE,
  ON_SHOW_SIGNIN_MOBILE_OTP_LOADER,
} from "../constants/ActionTypes";

export const signInMobileOtp = (data) => {
  return {
    type: POST_SIGNIN_MOBILE_OTP,
    payload: data,
  };
};
export const signInMobileOtpSuccess = (data) => {
  return {
    type: POST_SIGNIN_MOBILE_OTP_SUCCESS,
    payload: data,
  };
};
export const signInMobileOtpErrorMessage = (data) => {
  return {
    type: POST_SIGNIN_MOBILE_OTP_ERROR_MESSAGE,
    payload: data,
  };
};

export const hideSignInMobileOtpErrorMessage = () => {
  return {
    type: HIDE_SIGNIN_MOBILE_OTP_MESSAGE,
  };
};

export const showSignInMobileOtpLoader = () => {
  return {
    type: ON_SHOW_SIGNIN_MOBILE_OTP_LOADER,
  };
};
