import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const DatePickers = (props) => {
  const { helperText, error, dateOfBirth, handleDateChange, handleBlur } =
    props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <KeyboardDatePicker
          className="form-textfield form-textfield-label"
          id="date-picker-inline"
          name="dateOfBirth"
          autoOk={true}
          fullWidth
          required
          maxDate={new Date()}
          variant="inline"
          format="dd/MM/yyyy"
          placeholder="DD/MM/YYYY"
          margin="normal"
          label="Date Of Birth"
          value={dateOfBirth}
          onChange={(val) => {
            handleDateChange("dateOfBirth", val);
          }}
          handleBlur={handleBlur}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={helperText}
          error={error}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
export default DatePickers;
