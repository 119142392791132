import {
  ADMIN_KYC_REVIEW_REQUEST,
  ADMIN_KYC_APPROVAL_SUCCESS,
  ADMIN_KYC_REVIEW_ERROR_MESSAGE,
  ADMIN_KYC_APPROVAL_LOADER,
  HIDE_ADMIN_KYC_APPROVAL_ERROR_MESSAGE,
} from "constants/ActionTypes";

export const adminKycApprovalRequest = (data) => {
  return {
    type: ADMIN_KYC_REVIEW_REQUEST,
    payload: data,
  };
};

export const adminKycApprovalSuccess = (data) => {
  return {
    type: ADMIN_KYC_APPROVAL_SUCCESS,
    payload: data,
  };
};

export const showAdminKycApprovalError = (message) => {
  return {
    type: ADMIN_KYC_REVIEW_ERROR_MESSAGE,
    payload: message,
  };
};

export const showAdminKycApprovalLoader = () => {
  return {
    type: ADMIN_KYC_APPROVAL_LOADER,
  };
};

export const hideAdminKycApprovalError = () => {
  return {
    type: HIDE_ADMIN_KYC_APPROVAL_ERROR_MESSAGE,
  };
};
