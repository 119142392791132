import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpAuthPost } from "util/REST";
import {
  verifyRegistrationOtpSuccess,
  verifyRegistrationOtpErrorMessage,
} from "actions/RegistrationOTP";
import { REGISTRATION_OTP } from "constants/ActionTypes";

const registrationOtp = async (data) =>
  await httpAuthPost("verifyOTPSOfRegistration", data)
    .then((data) => data)
    .catch((error) => error);

function* registrationOTPRequest({ payload }) {
  try {
    let body = payload;
    const otpVerification = yield call(registrationOtp, body);
    if (otpVerification && otpVerification.status >= 400) {
      yield put(verifyRegistrationOtpErrorMessage(otpVerification.message));
    } else {
      yield put(verifyRegistrationOtpSuccess(otpVerification.status));
    }
  } catch (error) {
    yield put(verifyRegistrationOtpErrorMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(REGISTRATION_OTP, registrationOTPRequest)]);
}
