import { GET_KYC_STATUS } from "constants/ActionTypes";

const INIT_STATE = {
  kycStatus: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_KYC_STATUS: {
      return {
        ...state,
        kycStatus: action.payload,
      };
    }
    default:
      return state;
  }
};
