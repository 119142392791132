import {
  FETCH_ALL_MY_POLICIES,
  FETCH_ALL_MY_POLICIES_ERROR_MESSAGE,
  FETCH_ALL_MY_POLICIES_SUCCESS,
  HIDE_ALL_MY_POLICIES_ERROR_MESSAGE,
  ON_SHOW_ALL_MY_POLICIES_LOADER,
} from "../constants/ActionTypes";

export const myPoliciesDetails = (data) => {
  return {
    type: FETCH_ALL_MY_POLICIES,
    payload: data,
  };
};

export const myPoliciesDetailsErrorMessage = (data) => {
  return {
    type: FETCH_ALL_MY_POLICIES_ERROR_MESSAGE,
    payload: data,
  };
};

export const myPoliciesDetailsSuccess = (data) => {
  return {
    type: FETCH_ALL_MY_POLICIES_SUCCESS,
    payload: data,
  };
};

export const onShowPoliciesDetailsLoader = () => {
  return {
    type: ON_SHOW_ALL_MY_POLICIES_LOADER,
  };
};
export const hidePoliciesDetailsErrorMessage = () => {
  return {
    type: HIDE_ALL_MY_POLICIES_ERROR_MESSAGE,
  };
};
