import {
  ADMIN_USER_APPROVAL_SUCCESS,
  ADMIN_USER_REVIEW_ERROR_MESSAGE,
  ADMIN_USER_APPROVAL_LOADER,
  HIDE_ADMIN_USER_APPROVAL_ERROR_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  verifyUserLoader: false,
  verifyUseralertMessage: "",
  verifyUserShowMesssage: false,
  verifyUserAdminApprovalStatus: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADMIN_USER_APPROVAL_SUCCESS:
      return {
        ...state,
        verifyUserLoader: false,
        verifyUserAdminApprovalStatus: action.payload,
      };
    case ADMIN_USER_REVIEW_ERROR_MESSAGE:
      return {
        ...state,
        verifyUseralertMessage: action.payload,
        verifyUserShowMesssage: true,
        verifyUserLoader: false,
      };
    case ADMIN_USER_APPROVAL_LOADER:
      return {
        ...state,
        verifyUserLoader: true,
      };
    case HIDE_ADMIN_USER_APPROVAL_ERROR_MESSAGE:
      return {
        ...state,
        verifyUseralertMessage: "",
        verifyUserShowMesssage: false,
        verifyUserLoader: false,
        verifyUserAdminApprovalStatus: "",
      };

    default:
      return state;
  }
};
