import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpPost } from "util/REST";
import {
  getBranchesSuccess,
  showBranchesError,
} from "actions/BookingGetBranches";
import { BOOKING_GET_BRANCH_REQUEST } from "constants/ActionTypes";

const getBookingBranchRequest = async (data) =>
  await httpPost("getBranches", data)
    .then((data) => data)
    .catch((error) => error);

function* getAppointmentBranches({ payload }) {
  try {
    let body = payload;
    const branchData = yield call(getBookingBranchRequest, body);
    if (branchData && branchData.status >= 400) {
      yield put(showBranchesError(branchData.message));
    } else {
      yield put(getBranchesSuccess(branchData.response.branches));
    }
  } catch (error) {
    yield put(showBranchesError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(BOOKING_GET_BRANCH_REQUEST, getAppointmentBranches)]);
}
