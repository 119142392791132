import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  REGISTER_NEW_CLAIM_DATA,
  POST_REGISTER_NEW_CLAIM_DATA,
} from "../constants/ActionTypes";
import { httpGet, httpPost } from "util/REST";
import { showSuccessMessage } from "./Common";
import { handleResponseMessage } from "app/routes/MyClaims/registerNew";
// import { successRegisterClaimHandler } from "app/routes/MyClaims/registerNew";

// import {
//   getReporterDetails,
//   resetReporterDetailsData,
// } from "actions/GetReporterDetailsAction";

export const getRegisterNewClaimData = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpGet("policyAndInsured")
      .then((data) => {
        if (data.response) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: REGISTER_NEW_CLAIM_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const postRegisterNewClaimData = (details) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    httpPost("registerNewClaim", details)
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: POST_REGISTER_NEW_CLAIM_DATA,
            payload: data.status,
          });
          // dispatch(successRegisterClaimHandler(data.message));

          handleResponseMessage(data.message);
          dispatch(showSuccessMessage(data.message));
        } else {
          //handleResponseMessage(data.message);
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // console.log("Error****:", error.message);
      });
  };
};

export const resetRegisterNewClaimData = () => {
  return {
    type: "resetRegisterNewClaimData",
  };
};
