/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardText } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ContainerHeader from "components/ContainerHeader";
import Widget from "../../../../../components/Widget";

export const PoeloStandard = ({ productId, productName }) => {
  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile custom-kyp-fonts">
        <div className="card-header">
          <h4 className="card-title mb-0 text-uppercase">{productName}</h4>
        </div>
        <div className="jr-tabs-classic">
          <div className="jr-tabs-content jr-task-list pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <h3 className="mb-2">Product Code</h3>
                  <p>PSTA-1</p>
                  <h3 className="mb-2">Product Summary</h3>
                  <p>
                    This product is designed to assure the life of the
                    policyholder in the event of death. Upon survival of the
                    policyholder to the 15th policy year, BLIL will return the
                    total premiums paid up to that date, increased by 20%.
                  </p>
                </div>
                <div>
                  <h3 className="mb-2">Age Limits</h3>
                  <ul>
                    <li>Minimum age at entry for life cover – 21</li>
                    <li>Maximum age at entry for life cover – 55</li>
                    <li>
                      Termination age for life cover and accidental death – 70
                    </li>
                    <li>Maximum age at entry for capital disability – 55</li>
                    <li>Maximum termination age for capital disability - 70</li>
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2">Sum Assured</h3>
                  <ul>
                    {" "}
                    <li>
                      No minimum or maximum sum assured on the life cover,
                      provided that minimum premium of P200 is achieved.{" "}
                    </li>
                    <li>
                      Cover on the capital disability benefit cannot exceed P 3
                      million or 7.5 times the insured’s annual income{" "}
                    </li>
                    <li>
                      Sum assured on accidental death cannot exceed the death
                      cover{" "}
                    </li>
                  </ul>
                  <h3 className="mb-2">Premium Limits</h3>
                  <ul>
                    <li>
                      {" "}
                      Premium rates are calculated according to the basis below
                      with various add-ons for disability, dread disease and
                      travel benefits. A minimum margin of 10% premium was
                      achieved
                    </li>
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2">Policy Term</h3>
                  <ul>
                    <li>
                      As chosen by policyholder but subject to the minimum of 15
                      years and limited by the termination age
                    </li>
                  </ul>
                </div>
                <div>
                  <Button
                    className="custom-btn-ach"
                    component={Link}
                    to="/home/know-your-products"
                    variant="contained"
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </>
  );
};
