import {
  REGISTRATION_OTP,
  REGISTRATION_OTP_LOADER,
  REGISTRATION_OTP_ERROR_MESSAGE,
  HIDE_REGISTRATION_OTP_ERROR_MESSAGE,
  VERIFY_REGISTRATION_OTP_SUCCESS,
} from "constants/ActionTypes";

export const registrationOtp = (data) => {
  return {
    type: REGISTRATION_OTP,
    payload: data,
  };
};

export const verifyRegistrationOtpSuccess = (data) => {
  return {
    type: VERIFY_REGISTRATION_OTP_SUCCESS,
    payload: data,
  };
};

export const showRegistrationOtpLoader = () => {
  return {
    type: REGISTRATION_OTP_LOADER,
  };
};
export const hideRegistrationOtpErrorMessage = () => {
  return {
    type: HIDE_REGISTRATION_OTP_ERROR_MESSAGE,
  };
};

export const verifyRegistrationOtpErrorMessage = (message) => {
  return {
    type: REGISTRATION_OTP_ERROR_MESSAGE,
    payload: message,
  };
};
