/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Widget from "components/Widget";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Card, CardTitle } from "reactstrap";
import {
  getClaimStatus,
  getCashbackStatus,
  getMaturityStatus,
  resetClaimStatusData,
} from "../../../actions/ClaimStatus";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { ErrorToaster, toastMsgs, SuccessToaster } from "util/ReactToastify";
import { userSignOut } from "actions";
import { hideErrorMessage } from "actions/Common";

export default function ClaimCaseHome({ handleCasedetails }) {
  const dispatch = useDispatch();
  const { loading, error, statusCode } = useSelector(
    ({ commonData }) => commonData
  );
  const { getClaimStatusData, getCashbackStatusData, getMaturityStatusData } =
    useSelector(({ claimStatus }) => claimStatus);
  const omang = JSON.parse(localStorage.getItem("omangNumber"));
  const noClaims = "No Claims Found";
  const noCashback = "No Cashback Details Found";
  const noMaturity = "No Maturiy Details Found";

  useEffect(() => {
    dispatch(getClaimStatus(omang));
    dispatch(getCashbackStatus(omang));
    dispatch(getMaturityStatus(omang));

    return () => {
      dispatch(resetClaimStatusData());
    };
  }, []);

  useEffect(() => {
    if (statusCode === 401) {
      toastMsgs.error(error);
      setTimeout(() => dispatch(userSignOut()), 300);
    }
  }, [statusCode]);
  useEffect(() => {
    if (error) {
      setTimeout(() => dispatch(hideErrorMessage()), 300);
    }
  }, [error, dispatch]);
  const [loader, setLoader] = useState(true);
  // if (getClaimStatusData === "No claims Found") {
  //   return <h4> {getClaimStatusData}</h4>;
  // } else {
  // console.log(loading, "Loading");
  // console.log(getClaimStatusData.length, "Length");
  return (
    <Card className="claim-status">
      <div className="card-header">
        <h4 className="card-title mb-0">Claim Case Status</h4>
      </div>

      <div className="claim-status-table custom-responsive-border">
        <Table className="custom-page-table table-bordered">
          <TableHead>
            <TableRow>
              <TableCell align="center">Case Number</TableCell>
              <TableCell align="center">Policy Number</TableCell>
              <TableCell align="center">Insured Name </TableCell>
              <TableCell align="center">Claim Status</TableCell>
              <TableCell align="center">Claim Registration Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getClaimStatusData &&
            getClaimStatusData.length > 0 &&
            getClaimStatusData !== "No claims Found" ? (
              getClaimStatusData.map((data) => (
                <TableRow>
                  <TableCell
                    align="right"
                    style={{
                      color: "#0066AB",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() =>
                      handleCasedetails(
                        data.caseStatus,
                        data.claimNumber,
                        data.insuredName,
                        data.policyNumber
                      )
                    }
                  >
                    {data.claimNumber}
                  </TableCell>
                  <TableCell align="center">{data.policyNumber}</TableCell>
                  <TableCell align="center" style={{ fontSize: "12px" }}>
                    {data.insuredName}
                  </TableCell>
                  <TableCell align="center">{data.caseStatus}</TableCell>
                  <TableCell align="center">
                    {moment(data.caseRegisterDate, "YYYY-MM-DD").format(
                      "YYYY-MM-DD"
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : getClaimStatusData &&
              getClaimStatusData.length === 0 &&
              !loading ? (
              <TableRow>
                <TableCell colSpan="5" align="center">
                  {noClaims}
                  {/* {console.log(getClaimStatusData, "Datas")} */}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan="6">
                  {loading && (
                    <div className="register-new-table-loader-view">
                      <CircularProgress />
                      {/* {console.log(getClaimStatusData, "Data")} */}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* {loading && loader && (
          <div className="loading-view reporter-details-loader-view">
            <CircularProgress />
          </div>
        )} */}
      </div>

      <div className="card-header">
        <h4 className="card-title mb-0">Cashback</h4>
      </div>

      <div className="claim-status-table custom-responsive-border">
        <Table className="custom-page-table table-bordered">
          <TableHead>
            <TableRow>
              <TableCell align="center">Product Name</TableCell>
              <TableCell align="center">Policy Number</TableCell>
              <TableCell align="center">Due Date</TableCell>
              <TableCell align="center">Cashback Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCashbackStatusData && getCashbackStatusData.length > 0 ? (
              getCashbackStatusData.map((data) => (
                <TableRow>
                  <TableCell align="center">{data.productName}</TableCell>
                  <TableCell align="center">{data.policyNumber}</TableCell>
                  <TableCell align="center">
                    {moment(data.provisionDate, "YYYY-MM-DD").format(
                      "YYYY-MM-DD"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {data.payBack === null ? (
                      <h6>
                        N/A. The amount will be available once provided at due
                        date
                      </h6>
                    ) : (
                      data.payBack.toFixed(2)
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : getCashbackStatusData &&
              getCashbackStatusData.length === 0 &&
              !loading ? (
              <TableRow>
                <TableCell colSpan="5" align="center">
                  {noCashback}
                  {/* {console.log(getClaimStatusData, "Datas")} */}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan="6">
                  {loading && (
                    <div className="register-new-table-loader-view">
                      <CircularProgress />
                      {/* {console.log(getClaimStatusData, "Data")} */}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* {loading && loader && (
          <div className="loading-view reporter-details-loader-view">
            <CircularProgress />
          </div>
        )} */}
      </div>

      <div className="card-header">
        <h4 className="card-title mb-0">Maturity</h4>
      </div>

      <div className="claim-status-table custom-responsive-border">
        <Table className="custom-page-table table-bordered">
          <TableHead>
            <TableRow>
              {/*<TableCell align="center">Customer Name</TableCell>*/}
              <TableCell align="center">Product Name</TableCell>
              <TableCell align="center">Policy Number</TableCell>
              <TableCell align="center">Due Date</TableCell>
              <TableCell align="center">Maturity Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMaturityStatusData && getMaturityStatusData.length > 0 ? (
              getMaturityStatusData.map((data) => (
                <TableRow>
                  {/*<TableCell align="right">{data.custName}</TableCell>*/}
                  <TableCell align="center">{data.productName}</TableCell>
                  <TableCell align="center">{data.policyCode}</TableCell>
                  <TableCell align="center">
                    {moment(data.maturityDate, "YYYY-MM-DD").format(
                      "YYYY-MM-DD"
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <h6>
                      N/A. The amount will be available once provided at due
                      date
                    </h6>
                  </TableCell>
                  {/*<TableCell align="left">{data.productCode}</TableCell>*/}
                </TableRow>
              ))
            ) : getMaturityStatusData &&
              getMaturityStatusData.length === 0 &&
              !loading ? (
              <TableRow>
                <TableCell colSpan="5" align="center">
                  {noMaturity}
                  {/* {console.log(getClaimStatusData, "Datas")} */}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell colSpan="6">
                  {loading && (
                    <div className="register-new-table-loader-view">
                      <CircularProgress />
                      {/* {console.log(getClaimStatusData, "Data")} */}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* {loading && loader && (
          <div className="loading-view reporter-details-loader-view">
            <CircularProgress />
          </div>
        )} */}
      </div>
    </Card>
  );
}
