import {
  ADMIN_USER_REVIEW_REQUEST,
  ADMIN_USER_APPROVAL_SUCCESS,
  ADMIN_USER_REVIEW_ERROR_MESSAGE,
  ADMIN_USER_APPROVAL_LOADER,
  HIDE_ADMIN_USER_APPROVAL_ERROR_MESSAGE,
} from "constants/ActionTypes";

export const adminUserApprovalRequest = (data) => {
  return {
    type: ADMIN_USER_REVIEW_REQUEST,
    payload: data,
  };
};

export const adminUserApprovalSuccess = (data) => {
  return {
    type: ADMIN_USER_APPROVAL_SUCCESS,
    payload: data,
  };
};

export const showAdminUserApprovalError = (message) => {
  return {
    type: ADMIN_USER_REVIEW_ERROR_MESSAGE,
    payload: message,
  };
};

export const showAdminApprovalLoader = () => {
  return {
    type: ADMIN_USER_APPROVAL_LOADER,
  };
};

export const hideAdminUserApprovalError = () => {
  return {
    type: HIDE_ADMIN_USER_APPROVAL_ERROR_MESSAGE,
  };
};
