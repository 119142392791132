/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, CardText } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ContainerHeader from "components/ContainerHeader";
import { MmolokiFamilyWealthProtectorPlan } from "./ProductDetailsPages/MmolokiFamilyWealthProtectorPlan";
import { LoreMasterPlanEnhancements } from "./ProductDetailsPages/LoreMasterPlanEnhancements";
import { ISAGOMultiFundInvestmentPlan } from "./ProductDetailsPages/ISAGOMultiFundInvestmentPlan";
import { MasikaOltheExtendedFamilyCover } from "./ProductDetailsPages/MasikaOltheExtendedFamilyCover";
import { TapolgoMultiFund } from "./ProductDetailsPages/TapolgoMultiFund";
import { PoeloMortage } from "./ProductDetailsPages/PoeloMortage";
import { PoeloStandard } from "./ProductDetailsPages/PoeloStandard";
import { PoeloWholeOfLife } from "./ProductDetailsPages/PoeloWholeOfLIfe";
import { PoeloCreditLife } from "./ProductDetailsPages/PoeloCreditLife";
import { MOSAKO } from "./ProductDetailsPages/Mosako";

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// });

// function createData(hbc, pi, otherThanPi) {
//   return { hbc, pi, otherThanPi };
// }

const ProductDetails = (props) => {
  const { classes, match, location } = props;
  const [state, setState] = useState({
    internalId: null,
    productId: null,
    productName: null,
    productType: null,
  });

  useEffect(() => {
    if (!location.product) {
      props.history.push("/home/know-your-products");
    } else {
      setState(location.product);
    }
  }, [location]);

  const { productId, productName, productType } = state;

  return (
    <>
      <ContainerHeader match={match} />

      {productName === "MASIKA OTLHE" && (
        <MasikaOltheExtendedFamilyCover
          productId={productId}
          productName={productName}
        />
      )}
      {productName === "BOKAMOSO MMOLOKI FAMILY WEALTH PROTECTOR" && (
        <MmolokiFamilyWealthProtectorPlan
          productId={productId}
          productName={productName}
        />
      )}
      {productName === "ENHANCED LORE" && (
        <LoreMasterPlanEnhancements
          productId={productId}
          productName={productName}
        />
      )}
      {productName ===
        "POELO - SINGLE LIFE TERM ASSURANCE - WITH RETURN OF PREMIUM" && (
        <PoeloCreditLife productId={productId} productName={productName} />
      )}
      {/* <PoeloExclusive productId={productId} productName={productName} /> */}
      {productName === "MOSAKO" && (
        <MOSAKO productId={productId} productName={productName} />
      )}
      {productName === "POELO - MORTGAGE" && (
        <PoeloMortage productId={productId} productName={productName} />
      )}
      {productName === "POELO - SINGLE LIFE TERM ASSURANCE" && (
        <PoeloStandard productId={productId} productName={productName} />
      )}
      {productName === "POELO -WHOLE LIFE" && (
        <PoeloWholeOfLife productId={productId} productName={productName} />
      )}
      {productName === "TAPOLOGO - MULTI FUND RETIREMENT ANNUITY" && (
        <TapolgoMultiFund productId={productId} productName={productName} />
      )}
      {productName === "ISAGO - MULTI FUND INVESTMENT PLAN" && (
        <ISAGOMultiFundInvestmentPlan
          productId={productId}
          productName={productName}
        />
      )}
    </>
  );
};
export default ProductDetails;
