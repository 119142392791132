/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";

export const getData = (data, comparisionData) => {
  let temp = [];

  if (!comparisionData && data && data.length) {
    temp = data;
  } else if (comparisionData && comparisionData.userDetailsValidation) {
    const temp2 = comparisionData.userDetailsValidation;
    temp = data.map((elem) => {
      let temp2Elem = temp2.find((el) => el.policyNumber == elem.policyNumber);
      if (!temp2Elem) {
        temp2Elem = {};
      }

      return {
        ...elem,
        ...temp2Elem,
      };
    });
  }

  return temp;
};

const icon1 = (
  <i className="zmdi zmdi-check-circle" style={{ color: "green" }}></i>
);
const icon2 = (
  <i className="zmdi zmdi-close-circle" style={{ color: "red" }}></i>
);
const icon3 = (
  <i
    className="zmdi zmdi-block"
    style={{ color: "gray", fontWeight: "bolder" }}
  ></i>
);
const icon4 = <i className="zmdi zmdi-block" style={{ color: "red" }}></i>;

export const getIcon = (iconNumber) => {
  if (iconNumber === "1") {
    return icon1;
  } else if (iconNumber === "2") {
    return icon2;
  } else if (iconNumber === "3") {
    return icon3;
  } else if (iconNumber === "4") {
    return icon4;
  } else {
    return "";
  }
};
