/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import {
  ADMIN_COMPARE_REQUEST,
  ADMIN_COMPARE_SUCCESS,
  ADMIN_COMPARE_ERROR_MESSAGE,
  ADMIN_COMPARE_LOADER,
  HIDE_ADMIN_COMPARE_ERROR_MESSAGE,
  USER_REQUEST_REGISTRATION_SUCCESS,
} from "constants/ActionTypes";

export const adminCompareRequest = (body, usersData, tData) => {
  return {
    type: ADMIN_COMPARE_REQUEST,
    payload: { body, usersData, tData },
  };
};

export const adminCompareSuccess = (comparisionData, data, tData) => {
  let modifiedData = [];
  let temp = [];

  modifiedData = data.map((elem) => {
    let temp2Data = tData.find(
      (el) =>
        el.policyNumber == elem.policyNumber &&
        (elem.registrationStatus === "NEW" ||
          elem.registrationStatus === "ON-HOLD")
    );
    if (!temp2Data) {
      temp2Data = {};
    }

    delete elem["dateOfBirthValidation"];
    delete elem["emailValidation"];
    delete elem["mobileNumberValidation"];
    delete elem["omangNumberValidation"];
    delete elem["policyNumberValidation"];
    delete elem["policyOmangValidation"];

    return {
      ...elem,
      ...temp2Data,
    };
  });

  if (!comparisionData && modifiedData && modifiedData.length) {
    temp = modifiedData;
  } else if (comparisionData && comparisionData.userDetailsValidation) {
    const temp2 = comparisionData.userDetailsValidation;
    temp = modifiedData.map((elem) => {
      let temp2Elem = temp2.find(
        (el) =>
          el.policyNumber == elem.policyNumber &&
          (elem.registrationStatus === "NEW" ||
            elem.registrationStatus === "ON-HOLD")
      );
      if (!temp2Elem) {
        temp2Elem = {};
      }
      return {
        ...elem,
        ...temp2Elem,
      };
    });
  }

  return {
    type: USER_REQUEST_REGISTRATION_SUCCESS,
    payload: temp,
  };
};

export const showAdminCompareErrorMsg = (message) => {
  return {
    type: ADMIN_COMPARE_ERROR_MESSAGE,
    payload: message,
  };
};

export const showAdminCompareLoader = () => {
  return {
    type: ADMIN_COMPARE_LOADER,
  };
};

export const hideAdminCompareErrorMsg = () => {
  return {
    type: HIDE_ADMIN_COMPARE_ERROR_MESSAGE,
  };
};

export const resetComapareData = () => {
  return {
    type: "resetComapareData",
  };
};

export const resetAdminLoader = () => {
  return {
    type: "resetLoader",
  };
};
