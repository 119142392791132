import axios from "axios";
import { ApiUrl } from "helpers/getUrl";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

function buildHeaders() {
  if (
    localStorage.getItem("token") &&
    JSON.parse(localStorage.getItem("token")) !== "undefined"
  ) {
    const authToken = "Bearer " + JSON.parse(localStorage.getItem("token"));
    return { ...defaultHeaders, Authorization: authToken };
  } else {
    return { ...defaultHeaders };
  }
}

function authHeaders() {
  return { ...defaultHeaders };
}

// import axiosAuth from 'util/AuthApi'
// status check methods
export const checkData = (response) => {
  return response.data;
};

// REST API methods with token

// export const httpGet = (url) => axios.get(url).then(checkData)

export const httpGet = (url) => {
  console.log("backend url for get request");
  console.log(ApiUrl());

  return axios(ApiUrl() + url, {
    method: "GET",
    headers: buildHeaders(),
  }).then(checkData);

  // .catch(err => checkError(err));
};
// export const httpPost = (url, body) => axios.post(url, body).then(checkData)

export const httpPost = (url, body) => {
  console.log("backend url for post request");
  console.log(ApiUrl());

  return axios
    .post(ApiUrl() + url, body, {
      headers: buildHeaders(),
    })
    .then(checkData);
};

// export const httpPut = (url, body) => axios.put(url, body).then(checkData)

export const httpPut = (url, body) => {
  return axios
    .put(ApiUrl() + url, body, {
      headers: buildHeaders(),
    })
    .then(checkData);
};

// REST API methods for Auth APIs (without tokens)

// export const httpAuthGet = (url) => axios.get(url).then(checkData)
export const httpAuthGet = (url) => {
  return axios(ApiUrl() + url, {
    method: "GET",
    headers: authHeaders(),
  }).then(checkData);

  // .catch(err => checkError(err));
};

// export const httpAuthPost = (url, body) => axios.post(url, body).then(checkData)

export const httpAuthPost = (url, body) => {
  return axios
    .post(ApiUrl() + url, body, {
      headers: authHeaders(),
    })
    .then(checkData);
};

// export const httpAuthPut = (url, body) => axios.put(url, body).then(checkData)

export const httpAuthPut = (url, body) => {
  return axios
    .put(ApiUrl() + url, body, {
      headers: authHeaders(),
    })
    .then(checkData);
};
