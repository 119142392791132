import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ContainerHeader from "../../../components/ContainerHeader/index";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { httpGet, httpPost } from "util/REST";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PolicyScheduleData from "./PolicyScheduleData";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ErrorToaster, SuccessToaster } from "util/ReactToastify";
import { ToastContainer } from "react-toastify";
import botswanalife from "./botswana.jpg";
import { Base64 } from "js-base64";
// import {TextField} from '@material-ui/core/TextField';
import moment from "moment";
import signImageBase64 from "./signImageBase64";
import signature from "./Signature.png";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fromUnixTime, toDate } from "date-fns";

import { getBasicInfo } from "actions/PolicyDetails";

import { useDispatch, useSelector } from "react-redux";

//      <Route path={`${match.url}/statements/tax-certificate`} component={TaxCertificate} />
var taxCertificateResponseData;
var dateFrom;
var dateTo;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "100px",
  },
  element: {
    height: "150px",
    width: "100%",
  },
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    minWidth: 200,
  },
}));

export default function TaxCertificate({ props, match }) {
  const classes = useStyles();
  const [policyNumber, setPolicyNumber] = useState("");
  const [statusloader, setStatusloader] = useState(false);
  const [policyLists, setPolicyLists] = useState([]);
  const [taxCert, setTaxCert] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [callError, setCallError] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [warning, setWarning] = useState(false);
  const [policySuccess, setPolicySuccess] = useState(false);
  const [fromYear, setFromYear] = useState(new Date().getFullYear());
  const [year, setYear] = useState();
  const [toYear, setToYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = React.useState();
  const [selectedDateTo, setSelectedDateTo] = React.useState(new Date());
  const [selectionDate, setSelectionDate] = React.useState(
    moment(new Date(), "YYYY").format("YYYY")
  );
  const [startDate, setStartDate] = React.useState();
  const [requiredField, setRequiredField] = useState(false);
  const [getPolicyLoader, setGetPolicyLoader] = useState(false);

  let BWP = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "BWP",
  });

  const policyPdfDoc = () => {
    //   let   response= await axios.get(` http://localhost:8000/api/v1/getPolicyReports?policyCode=${4006228872}`)
    //   ;
    //console.log(dataraw);

    //let   response= dataraw;
    //let data = dataraw;
    //console.log(data);

    /*
    if (data.itemId === 0) {
  
      console.log("only active policies statements can be downloaded");
  
    }
    */

    console.log("download started");

    if (policyNumber) {
      console.log("tax details checked");

      var document = new jsPDF("p", "mm", "a4");

      var width = document.internal.pageSize.getWidth();

      var imageData = signImageBase64.imageData;

      // document.setDrawColor('#0767a2')
      // document.line(0, 55, 210, 55)

      //document.setFillColor('#FFFFFF')

      // var imgData = 'data:image/jpeg;base64,' + Base64.encode('botswanalife');

      var image = new Image();
      // image.src = path.resolve('botswana.jpg');

      //image.src =c 'botswana.jpg';
      // document.setFillColor('#0767a2');

      // document.rect(0, 5, 210, 55, 'FD');

      document.addImage(imageData, "jpg", 0, 0, 210, 70);

      // document.rect(10, 35, 210, 55, 'FD')
      // document.

      // document.setTextColor('#FFFFFF')
      //document.setFontSize(14)
      //document.text('Botswana Life', 26, 36)
      //document.setDrawColor('#FFFFFF')
      //document.setLineWidth(1)
      // document.line(12, 42, 62, 42)
      //document.setFontSize(10)

      // document.text('INCOME TAX CERTIFICATE', 12, 48)

      // document.lines([[2, 2], [-2, 2], [1, 1, 2, 2, 3, 3], [2, 1]], 75, 6, [10, 10])
      // document.lines([[100, 60]], 100, 5, [1, 1])
      //document.lines([[2,2], [-2,2], [1,1,2,2,3,3], [2,1]], 0, 0, [10,10]);

      //#c1e1ec
      //console.log(taxCertificateResponseData)
      document.setFontSize(10);
      // address: "P O BOX 201360 N/A GABORONE GABORONE Botswana N/A"
      // amountPaid: "0"
      // birthDay: "1976-07-08 00:00:00"
      // clientName: "ONAMEDITSE GORDIE  KEITEBETSE"
      // firstName: "ONAMEDITSE GORDIE"
      // fundName: "N/A"
      // idNumber: "988613306"
      // internalId: "BMFW-1"
      // lastName: "KEITEBETSE"
      // policyCode: "91074899
      //
      //

      console.log("value declaration");

      let clientName = policyNumber.clientName;
      let firstName = policyNumber.firstName;
      let policyCode = policyNumber.policyCode;
      let fundName = policyNumber.fundName;
      let internalId = policyNumber.internalId;
      let idNumber = policyNumber.idNumber;
      let address = policyNumber.address;
      let amountPaid = policyNumber.amountPaid;
      //doubel amount=amountPaid.pars
      let birthDay = policyNumber.birthDay;
      let lastName = policyNumber.lastName;
      //console.log(" ClientName ",clientName)

      console.log("value mapping");

      let birthDate = birthDay.slice(0, 10);
      document.text(address, 10, 90);
      document.text("Dear  " + firstName, 10, 106);
      document.text(firstName, 60, 130);
      document.text(birthDate, 60, 140);
      document.text(lastName, 160, 130);
      document.text(`${idNumber}`, 160, 135);

      console.log("first check");

      // document.text('First Name', 10, 130)
      // document.text('Date of Birth', 10, 144)
      // document.text('Surname', 110, 130)
      // document.text('ID/Passport', 110, 144)
      // document.text('Retirement Annunity Fund', 10, 175)
      // document.text('Premium Contributions', 10, 189)
      //document.text('Tax Year', 10, 161)

      document.text(fundName, 65, 175);
      document.text(`${BWP.format(amountPaid)}`, 65, 189);
      document.text(
        `01-July-${dateTo}` + " to " + `30-June-${dateFrom}`,
        65,
        161
      );
      document.setDrawColor("#daedf4");
      document.setLineWidth(10);
      document.line(0, 105, 210, 105);
      document.setFontSize(10);
      document.setTextColor("#000000");
      document.text("Fund Membership No:", 10, 135);
      document.text(`${policyCode}`, 60, 135);
      document.setLineWidth(25);
      document.line(0, 209, 210, 209);
      document.setDrawColor("#0767a2");
      document.setLineWidth(8);
      document.line(0, 120, 210, 120);
      document.setTextColor("#FFFAFA");
      document.text("CLIENT INFORMATION", 10, 121);
      document.setTextColor("#000000");
      document.text("First Name:", 10, 130);
      document.text("Date of Birth:", 10, 140);
      document.text("Surname:", 110, 130);
      document.text("Omang/Passport:", 110, 135);
      document.line(0, 151, 210, 151);
      document.setTextColor("#FFFAFA");
      document.text("TAX ASSESSMENT INFORMATION", 10, 152);
      document.setTextColor("#000000");
      document.text("Tax Year", 10, 161);
      document.text("Retirement Annuity Fund:", 10, 175);
      document.text("Premium Contributions:", 10, 189);
      document.setFontSize(10);
      document.setTextColor("#0c2026");
      document.text(
        "This certificate should be sent to Botswana United Revenue Services(BURS) together with the return for",
        16,
        205
      );
      document.text(
        "the year ending  30th June " +
          dateFrom +
          " which is due by 30th September " +
          dateTo +
          " .If you do not fall within the tax income",
        16,
        210
      );
      document.text(
        "bracket (i.e. if you are not taxed) please ignore  this letter.",
        16,
        215
      );

      console.log("second check");

      document.setTextColor("#323232");
      document.text("Yours faithfully", 13, 230);

      var signImage = signImageBase64.signImage;

      document.addImage(signImage, 14, 230, 19, 22);
      document.setTextColor("#000000");

      // document.setFont("helvetica", "bold")

      document.text("Duncan Hule", 13, 260);
      document.text("Principal Officer", 13, 264);

      document.setFontSize(8);
      document.setTextColor("#a3a3a3");
      document.text(
        "Trustees: Lesego Ntebela, Mavis Magosi**, Johane Tatlhego, Ezweni Tshuma, Mpho Morale*, Kagiso Mokgothu,",
        width / 6,
        275,
        { align: "left" }
      );
      document.text("** Independent Trustee *Chairman", width / 6, 279, {
        align: "left",
      });
      document.text("Principal Officer: Duncan Hule", width / 6, 283, {
        align: "left",
      });
      document.text(
        "NBFIRA Registration Number: 10/1/2/6 (018)",
        width / 6,
        287,
        { align: "left" }
      );

      console.log("document save starting.");

      document.save("TaxCertificate.pdf");
      console.log("document downloaded");
    }
  };

  const { basicInfoData } = useSelector(({ policyDetails }) => policyDetails);

  const dispatch = useDispatch();

  const handlePolicyNum = (event) => {
    try {
      console.log(event.target.value);
      setPolicyNumber(event.target.value);
      //setPolicySuccess(true)
    } catch (error) {
      setApiError(true);
    }
  };

  const fetchPolicyDetails = () => {
    try {
      setStatusloader(true);

      let certi_code = localStorage.getItem("omangNumber")
        ? JSON.parse(localStorage.getItem("omangNumber"))
        : null;
      let endDate = fromYear;
      let startDate = toYear;
      console.log("from date: ", endDate);
      console.log("to date: ", startDate);

      httpGet(
        "taxtCertificateReportData" +
          "?certiCode=" +
          certi_code +
          "&fromDate=" +
          `01-Jul-${startDate}` +
          "&toDate=" +
          `30-Jun-${endDate}`
      )
        .then((data) => {
          console.log("taxCertificate");
          console.log(data);

          let taxCertificateData = data.response;
          console.log("tax detail api results: ", taxCertificateData);

          let filteredData = taxCertificateData?.filter(
            (item) => item.amountPaid > 0
          );

          let hasBeenPaid = (element) => element.amountPaid > 0;

          let result = taxCertificateData?.some(hasBeenPaid);
          //taxCertificateResponseData = taxCertificateData

          if (!taxCertificateData || !result) {
            setStatusloader(false);
            setCallError(true);
            //setInactive(true);
          } else {
            setPolicySuccess(true);
            getTaxDetails(filteredData);
            setStatusloader(false);
          }
        })
        .catch(function (error) {
          setStatusloader(false);
          setApiError(true);
          console.log("Error****:", error.message);
        });
    } catch (error) {
      setApiError(true);
    }
  };
  const handleDateChangeFromDate = (date) => {
    try {
      setSelectedDate(date);
      setSelectionDate(date);
      console.log("picked date: ", selectedDate);
      console.log("other picked date: ", selectionDate);

      let yearFrom = date.getFullYear();
      dateFrom = yearFrom;
      setFromYear(yearFrom);

      handleDateChangeToDate(yearFrom);
    } catch (error) {}

    // setFromYear=((prevState) => ({

    //    // count: prevState.count + 1,
    // })
    // )
  };

  // useEffect(() => {
  //     // Update the document title using the browser API
  //     // document.title = `You clicked ${count} times`;
  //   },fromYear);
  // }
  const handleDateChangeToDate = (year) => {
    let yearTo = year - 1;
    dateTo = yearTo;
    // setSelectedDateTo(date);
    // let yearTo = date.getFullYear();
    setToYear(yearTo);
    setYear(toYear);
  };

  const handleFileDownload = () => {
    try {
      if (policyNumber) {
        console.log("tax doc details: ", policyNumber);
        policyPdfDoc();
        setDownloaded(true);
      } else {
        setWarning(true);
      }
    } catch (error) {
      console.log("not successful");
      setApiError(true);
    }
  };

  const fetchPolicyByOmganId = async () => {
    setGetPolicyLoader(true);
    console.log(localStorage.getItem("omangNumber"));
    console.log(JSON.parse(localStorage.getItem("omangNumber")));
    let data = JSON.parse(localStorage.getItem("omangNumber"));

    await httpGet("getTaxCertificatePolicies?IDNumber=" + data)
      .then((data) => {
        console.log("Response", data);
        console.log(data.response.policyListByOmang.length);
        if (data.response.policyListByOmang.length > 0) {
          test(data.response.policyListByOmang);
          setGetPolicyLoader(false);
        } else {
          setGetPolicyLoader(false);
          setCallError(true);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setGetPolicyLoader(false);
        setApiError(true);
      });
  };

  const test = async (value) => {
    await setPolicyLists(value);
    console.log(policyLists);
  };

  const getTaxDetails = async (value) => {
    await setTaxCert(value);
    console.log(policyLists);
  };

  //On page load
  useEffect(() => {
    fetchPolicyDetails();
  }, []);

  //used for calling api when taxCert changes
  useEffect(() => {
    console.log("get policy details.");
    if (year) {
      fetchPolicyDetails();
      setYear();
    }
    console.log("tax deatils: ", taxCert);
  }, [year]);

  useEffect(() => {
    console.log(policyLists);
  }, [policyLists]);

  useEffect(() => {
    if (basicInfoData) {
      setStatusloader(false);
      setStartDate(moment(basicInfoData.commencementDate).format("YYYY"));
      console.log("commencementDate: ", startDate);
      setSelectedDate(startDate);
      console.log("commencementDate: ", selectedDate);
    }
  }, [basicInfoData, startDate, selectedDate]);

  /*
      const handlePolicyNum = (event) => {
          console.log(event.target.value);
          setPolicyNumber(event.target.value);
      };
  */
  const DownloadedMessage = () => {
    setDownloaded(false);
    return (
      <SuccessToaster
        style={{ backgroundColor: "#28a745" }}
        message={"Thank you for downloading Tax Certificate Document."}
      />
    );
  };

  const PolicySuccess = () => {
    setPolicySuccess(false);
    return (
      <SuccessToaster
        style={{ backgroundColor: "#28a745" }}
        message={"Policies loaded successfully."}
      />
    );
  };

  const ErrorMessage = () => {
    setApiError(false);
    return (
      <>
        <ErrorToaster
          message={"Network Error occured! Please try after some time."}
        />
      </>
    );
  };

  const CallErrorMessage = () => {
    setCallError(false);
    return (
      <>
        <ErrorToaster message={"No Eligible Policy."} />
      </>
    );
  };

  const InactiveMessage = () => {
    setInactive(false);
    return (
      <ErrorToaster
        message={"Only active policies statements can be downloaded."}
      />
    );
  };

  const RequiredMessage = () => {
    console.log("RequiredMessage stated");
    setRequiredField(false);
    return <ErrorToaster message={" policy number is Required"} />;
  };

  const WarningMessage = () => {
    console.log("RequiredMessage stated");
    setWarning(false);
    return (
      <ErrorToaster message={"Please select a policy before downloading."} />
    );
  };

  return (
    <React.StrictMode>
      <div>
        <ContainerHeader match={match} />
        <div className={classes.gridRoot}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper className={classes.paper}>
              <Grid container spacing={0}>
                {/* <Grid item xs={2}></Grid> */}
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  {statusloader && <CircularProgress />}
                  <div className="row" style={{ marginTop: "22px" }}>
                    {/* <div className="col-md-6 col-sm-12"> */}

                    <div className="col-md-6 col-sm-12">
                      <h2 style={{ marginLeft: "193px" }}>Year:</h2>
                    </div>

                    <div className="col-md-5 col-sm-12">
                      <DatePicker
                        value={selectionDate}
                        onChange={handleDateChangeFromDate}
                        views={["year"]}
                        minDate={moment("01/07/2011", "DD/MM/YYYY").format(
                          "DD/MM/YYYY"
                        )}
                        maxDate={new Date()}
                        style={{ width: "45px", marginLeft: "-185px" }}

                        // InputProps={{
                        //   disableUnderline: true,
                        //   //lineWidth: '10px',
                        // }}
                      />
                    </div>
                    {/*     
                    <div className="col-md-6 col-sm-12">
                      <h2 style={{ marginLeft: "216px" }}>To Date:</h2>
                    </div>

                    <div className="col-md-5 col-sm-12">

                      <TextField

                        //onChange={handleDateChangeToDate}
                        // views={['year']} maxDate={new Date()}
                        style={{ width: "45px", marginLeft: "-185px" }}
                        value={toYear}
                      >
                      </TextField>
                     </DatePicker>

                    </div>*/}
                    {/* <div className="col-md-5  col-sm-12">
                        <DatePicker value={selectedDateTo}
                          onChange={handleDateChangeToDate} views={['year']} maxDate={new Date()}
                          style={{ width: "45px", marginLeft: "-185px" }}
  
                        // InputProps={{
  
                        //   disableUnderline: true,
                        // }}
                        />
  
                      </div> */}

                    <div className="col-md-6 col-sm-12">
                      <h2 style={{ marginLeft: "154px" }}>Policy Number:</h2>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handlePolicyNum}
                        value={policyNumber}
                        style={{ width: "145px", marginLeft: "-180px" }}
                      >
                        <MenuItem value="" disabled>
                          Please Select Policy Number
                        </MenuItem>

                        {taxCert.length > 0
                          ? taxCert.map((item) => (
                              <MenuItem value={item}>
                                {item.policyCode}
                              </MenuItem>
                            ))
                          : null}

                        {/* <MenuItem value={"4003832615"}>
                        4003832615
                            </MenuItem> */}
                      </Select>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "10px", textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#33aad0", color: "white" }}
                    onClick={() => handleFileDownload()}
                  >
                    Download
                  </Button>
                  {getPolicyLoader && <CircularProgress />}
                  {downloaded && <DownloadedMessage />}
                  {policySuccess && <PolicySuccess />}
                  {apiError && <ErrorMessage />}
                  {callError && <CallErrorMessage />}
                  {inactive && <InactiveMessage />}
                  {warning && <WarningMessage />}
                  {/*requiredField && (<RequiredMessage />)*/}

                  {/* <ToastContainer /> */}
                </Grid>
              </Grid>
            </Paper>
          </MuiPickersUtilsProvider>
        </div>
        <h1 style={{ marginLeft: "200px", marginTop: "35px" }}>
          Glossary of Botswana Life Insurance Limited Insurance Terminology
        </h1>

        <Table
          className="custom-page-table table-bordered"
          style={{ marginLeft: "5px", marginTop: "36px" }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Term</TableCell>
              <TableCell align="center">Meaning</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left" style={{}}>
                Active
              </TableCell>
              <TableCell align="left">
                This means the policy is in force and premium payments have been
                made
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Lapse
              </TableCell>
              <TableCell align="left">
                The termination of an insurance contract through non-payment of
                premiums
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Invested Amount
              </TableCell>
              <TableCell align="left">
                This is the balance of the premium after deduction of all policy
                charges that is used to purchase units
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Risk Premium
              </TableCell>
              <TableCell align="left">
                An amount to be retained by Botswana Life for assuming the risks
                under the policy insured
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Expected Premium
              </TableCell>
              <TableCell align="left">
                Payments due to the insurance company from the policy owner in
                exchange for benefits
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Unit Price
              </TableCell>
              <TableCell align="left">
                This is the investment fund price that determines the number of
                units purchased by the InvestedAmount, and also determines the
                policy value.
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Units
              </TableCell>
              <TableCell align="left">
                Units represent the way that each fund is split to identify a
                value that we can attribute to each policyholder's holding
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Partial Withdrawal
              </TableCell>
              <TableCell align="left">
                Part withdrawal of the value of the policy before maturity
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Policy Value
              </TableCell>
              <TableCell align="left">
                The value of the units purchased under a policy. Calculated as
                the number of units multiplied by the current unit price
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Risk Benefits
              </TableCell>
              <TableCell align="left">
                Benefits payable in the event of contingencies such as death or
                disability
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={{}}>
                Annual premium update
              </TableCell>
              <TableCell align="left">
                The annual increase in premium that occurs at each policy
                anniversary
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Waiver of Premiums
              </TableCell>
              <TableCell align="left">
                An additional benefit where future premiums due are ""waived""
                (paid by the insurer) in the event of contingencies such as
                death or disability
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Paid Up (PUP)
              </TableCell>
              <TableCell align="left">
                When a policy is made "paid up" future premiums cease and cover
                will continue at a reduced level or for the remaining term
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Cover Level
              </TableCell>
              <TableCell align="left">
                The amount of benefit payable on the death of the insured during
                the term of the policy
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Arrears
              </TableCell>
              <TableCell align="left">
                These are the amounts of premium payments that have been missed
                under the policy
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Renewal expense
              </TableCell>
              <TableCell align="left">
                Cost of collecting premiums from pay points and banks. It also
                includes the policy fee which runs throughout the term of the
                policy
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Commission charge
              </TableCell>
              <TableCell align="left">
                Cost of procurement of business by an agent or broker
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{}}>
                Insurance charge
              </TableCell>
              <TableCell align="left">
                Premium for risk cover (this is not invested)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={{}}>
                Maintenance charge
              </TableCell>
              <TableCell align="left">
                Charge associated with maintaining policy
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </React.StrictMode>
  );
}
