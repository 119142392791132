/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import FormikRadioGroup from "./FormikRadioGroup";
import {
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import {
  reportDownload,
  reportDownloadSuccess,
  showReportDownloadLoader,
  hideReportDownloadErrorMessage,
} from "../../../actions/ReportAction";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { ErrorToaster } from "util/ReactToastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { SystemUpdateOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: "#0066ab",
    },
  },
  checked: {},
  marginAuto: {
    margin: "0 auto",
  },
});

const applicantsPersonalDetails = [
  { label: "Download as *", name: "smoker", key: 1 },
];

export default ({
  open,
  handleRequestClose,
  handleRequestSubmit,
  setComments,
  startDate,
  endDate,
  status,
  isAdmin,
}) => {
  //const [reportdownloadPdf,setReportDownloadPdf]=useState(false);
  const classes = useStyles();

  const { loader, reportDownloadData, alertMessage, showMessage } = useSelector(
    ({ reportDownloadReducer }) => reportDownloadReducer
  );

  // after getting the data from backend we are creating the pdf and saving into the system here using jspdf
  if (reportDownloadData) {
    // window.location.href = reportDownloadData;
    // alert("reportDownloadData");

    console.log("reportDownloadData", reportDownloadData);

    window.open(reportDownloadData);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideReportDownloadErrorMessage());
      }, 100);
    }
    return () => dispatch(hideReportDownloadErrorMessage());
  }, [showMessage, reportDownloadData]);

  const handleDownload = (fileType, comments, adminIs) => {
    const data = {
      type: fileType,
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      status: status,
      includeComments: comments,
      admin: adminIs,
    };

    console.log("download data: ", data);
    setComments(comments);
    if (fileType === "PDF") {
      dispatch(showReportDownloadLoader());
      console.log("download data in if check: ", data);
      dispatch(reportDownload(data));
    } else {
      console.log("file type: ", fileType);
      //handleCsvDownload()
      dispatch(showReportDownloadLoader());
      console.log("download data in if check: ", data);
      dispatch(reportDownload(data));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={remarksSchema}
      onSubmit={(values, { resetForm }) => {
        const { radioGroup, comments } = values;
        handleRequestSubmit("download", radioGroup, comments);
        resetForm();
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <Dialog open={open} className="download-popup-dialog">
            <form autoComplete="on" noValidate onSubmit={handleSubmit}>
              <DialogContent className="custom-dialog-content">
                <DialogContentText className="custom-dialog-text">
                  Download as{" "}
                </DialogContentText>
                <div className={classes.marginAuto}>
                  <Field
                    name="radioGroup"
                    value={values.radioGroup}
                    options={["PDF"]}
                    component={FormikRadioGroup}
                  />

                  <Field
                    name="radioGroup"
                    value={values.radioGroup}
                    options={["CSV"]}
                    component={FormikRadioGroup}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange}
                        name="comments"
                        //   color="primary"
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Include comments in Report"
                  />
                </div>
              </DialogContent>
              <DialogActions className="confirm-btns">
                <Button
                  color="secondary"
                  onClick={() => {
                    handleReset();
                    handleRequestClose("download");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    handleDownload(values.radioGroup, values.comments, isAdmin)
                  }
                  color="primary"
                  type="submit"
                >
                  Download
                </Button>
              </DialogActions>
            </form>
            {loader && (
              <div className="loader-view">
                <CircularProgress />
              </div>
            )}
            {showMessage && <ErrorToaster message={alertMessage} />}
            <ToastContainer />
          </Dialog>
        );
      }}
    </Formik>
  );
};

const initialValues = {
  comments: "false",
  radioGroup: "",
};

const remarksSchema = Yup.object().shape({
  radioGroup: Yup.string().required("Please select a file format"),
});
