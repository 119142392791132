/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik } from "formik";

export default ({ open, handleRequestClose, handleRequestSubmit }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        const { remarks } = values;
        await handleRequestSubmit("accept", remarks);
        resetForm();
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;
        return (
          <Dialog className="" open={open} className="custom-confirm-dialog">
            <form autoComplete="on" noValidate onSubmit={handleSubmit}>
              <DialogTitle className="custom-dialog-title">
                Confirmation
              </DialogTitle>
              <DialogContent className="custom-dialog-content">
                <DialogContentText className="custom-dialog-text">
                  Are you sure you want to Approve?
                </DialogContentText>
                <TextField
                  onChange={handleChange}
                  className="custom-popup-label"
                  id="remarks"
                  placeholder="Reason"
                  label="Reason"
                  value={values.remarks}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  fullWidth
                  className="jr-wall-textarea"
                  margin="none"
                  variant="outlined"
                  rows="5"
                />
              </DialogContent>
              {/* <span>Optional</span> */}
              <DialogActions className="confirm-btns">
                <Button
                  color="secondary"
                  onClick={() => handleRequestClose("accept")}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  // onClick={() => handleRequestSubmit("accept")}
                >
                  Submit
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

const initialValues = {
  remarks: "",
};
