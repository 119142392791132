import {
  GET_CLAIM_STATUS_DATA,
  RESET_CLAIM_STATUS_DATA,
  GET_CASHBACK_STATUS_DATA,
  RESET_CASHBACK_STATUS_DATA,
  GET_MATURITY_STATUS_DATA,
  RESET_MATURITY_STATUS_DATA,
} from "constants/ActionTypes";

const INIT_STATE = {
  getClaimStatusData: [],
  getMaturityStatusData: [],
  getCashbackStatusData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLAIM_STATUS_DATA: {
      return {
        ...state,
        getClaimStatusData: action.payload,
      };
    }
    case RESET_CLAIM_STATUS_DATA: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    case GET_CASHBACK_STATUS_DATA: {
      return {
        ...state,
        getCashbackStatusData: action.payload,
      };
    }
    case RESET_CASHBACK_STATUS_DATA: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    case GET_CASHBACK_STATUS_DATA: {
      return {
        ...state,
        getCashbackStatusData: action.payload,
      };
    }
    case RESET_CASHBACK_STATUS_DATA: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    case GET_MATURITY_STATUS_DATA: {
      return {
        ...state,
        getMaturityStatusData: action.payload,
      };
    }
    case RESET_MATURITY_STATUS_DATA: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};
