import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ContainerHeader from "../../../components/ContainerHeader/index";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { httpGet, httpPost } from "util/REST";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PolicyScheduleData from "./PolicyScheduleData";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { SuccessToaster, ErrorToaster } from "util/ReactToastify";
import { ToastContainer } from "react-toastify";
import { accounting } from "accounting";
import { glossary } from "app/routes/Downloads/index";

export default function PolicyContract({ props, match }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      marginTop: "100px",
    },
    element: {
      height: "150px",
      width: "100%",
    },
    gridRoot: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    formControl: {
      minWidth: 200,
    },
  }));

  const classes = useStyles();
  const [policyNumber, setPolicyNumber] = useState("");
  const [statusloader, setStatusloader] = useState(false);
  const [policyList, setPolicyList] = useState([]);
  const [docError, setDocError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [availableError, setAvailableError] = useState(false);

  /*
    const fetchPolicyDetails = async () => {
      let policyno = policyNumber;
      setStatusloader(true);
      await httpGet("getPolicyReports" + "?policyCode=" + policyno)
        .then((data) => {
          console.log("policyAndInsured");
          console.log(data);
  
          if (data.response.itemId === 0) {
            setStatusloader(false);
            setInactive(true);
          } else {
            policyPdfDoc(data.response);
            setStatusloader(false);
            setDownloaded(true);
          }
  
        })
        .catch(function (error) {
          setStatusloader(false);
          setApiError(true);
          console.log("Error****:", error.message);
        });
    };
  */

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "policy_contract.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const fetchPolicyContract = async () => {
    let policyno = policyNumber;
    setStatusloader(true);
    await httpGet(
      `getPolicyContractDoc?policyCode=${policyno}&email&confirmEmailUpdate`
    )
      .then((data) => {
        console.log("policy schedule");
        console.log(data);

        if (data.response.requestStatus === "1") {
          downloadPDF(data.response.documentData);
          setStatusloader(false);
          setDownloaded(true);
        } else if (
          data.response.requestStatus === "2" &&
          data.response.requestStatusDesc ===
            "Document cannot be generated at the moment. Please try again after 5 mimutes"
        ) {
          setStatusloader(false);
          setDocError(true);
        } else if (
          data.response.requestStatus === "3" &&
          data.response.requestStatusDesc ===
            "Sorry, Requested policy schedule document not available. Please try again later"
        ) {
          setStatusloader(false);
          setAvailableError(true);
        } else {
          setStatusloader(false);
          setInactive(true);
          setDocError(true);
        }
      })
      .catch(function (error) {
        setStatusloader(false);
        setApiError(true);
        console.log("Error****:", error.message);
      });
  };

  const fetchPolicyByOmangId = async () => {
    console.log(localStorage.getItem("omangNumber"));
    console.log(JSON.parse(localStorage.getItem("omangNumber")));
    let data = JSON.parse(localStorage.getItem("omangNumber"));
    setStatusloader(true);
    await httpGet("getPoliciesByOmangId?IDNumber=" + data)
      .then((data) => {
        console.log(data);
        console.log(data.response.policyListByOmang.length);
        test(data.response.policyListByOmang);
        setStatusloader(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setStatusloader(false);
      });
  };

  const test = async (value) => {
    await setPolicyList(value);
    console.log(policyList);
  };

  //used for calling api while page is loading
  useEffect(() => {
    fetchPolicyByOmangId();
  }, []);

  useEffect(() => {
    console.log(policyList);
  }, [policyList]);

  const handlePolicyNum = (event) => {
    console.log(event.target.value);
    setPolicyNumber(event.target.value);
  };

  const DownloadedMessage = () => {
    setDownloaded(false);
    return (
      <SuccessToaster
        style={{ backgroundColor: "#28a745" }}
        message={"Thank you for downloading the Policy Schedule Contract."}
      />
    );
  };

  const ErrorMessage = () => {
    setApiError(false);
    return (
      <ErrorToaster
        message={"Network Error occured ! please try after some time."}
      />
    );
  };

  const DocErrorMessage = () => {
    setDocError(false);
    return (
      <ErrorToaster
        message={
          "Document cannot be generated at the moment. Please try again after 5 mimutes."
        }
      />
    );
  };

  const InactiveMessage = () => {
    setInactive(false);
    return (
      <ErrorToaster message={"Something went wrong, please try again later."} />
    );
  };

  const DocAvailableMessage = () => {
    setAvailableError(false);
    return (
      <ErrorToaster
        message={
          "Sorry, Requested policy schedule document not available. Please try again later."
        }
      />
    );
  };

  return (
    <div>
      <ContainerHeader match={match} />
      <div className={classes.gridRoot}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  <h2>Policy Number:</h2>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "15px" }}>
                  <FormControl className={classes.formControl}>
                    {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handlePolicyNum}
                      value={policyNumber}
                      style={{ width: "125px" }}
                    >
                      <MenuItem value="" disabled>
                        Please Select Policy Number
                      </MenuItem>

                      {policyList.length > 0
                        ? policyList.map((ph) => (
                            <MenuItem value={ph.policyNumber}>
                              {ph.policyNumber}
                            </MenuItem>
                          ))
                        : ""}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "10px", textAlign: "center" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#33aad0", color: "white" }}
              onClick={fetchPolicyContract}
            >
              Download
            </Button>
            {statusloader && <CircularProgress />}
            {downloaded && <DownloadedMessage />}
            {apiError && <ErrorMessage />}
            {docError && <DocErrorMessage />}
            {availableError && <DocAvailableMessage />}
            {inactive && <InactiveMessage />}

            <ToastContainer />
          </Grid>
        </Paper>
      </div>

      <h1 style={{ marginLeft: "200px", marginTop: "35px" }}>
        Glossary of Botswana Life Insurance Limited Insurance Terminology
      </h1>

      <Table
        className="custom-page-table table-bordered"
        style={{ marginLeft: "5px", marginTop: "36px" }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Term</TableCell>
            <TableCell align="center">Meaning</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" style={{}}>
              Active
            </TableCell>
            <TableCell align="left">
              This means the policy is in force and premium payments have been
              made
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Lapse
            </TableCell>
            <TableCell align="left">
              The termination of an insurance contract through non-payment of
              premiums
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Invested Amount
            </TableCell>
            <TableCell align="left">
              This is the balance of the premium after deduction of all policy
              charges that is used to purchase units
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Risk Premium
            </TableCell>
            <TableCell align="left">
              An amount to be retained by Botswana Life for assuming the risks
              under the policy insured
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Expected Premium
            </TableCell>
            <TableCell align="left">
              Payments due to the insurance company from the policy owner in
              exchange for benefits
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Unit Price
            </TableCell>
            <TableCell align="left">
              This is the investment fund price that determines the number of
              units purchased by the InvestedAmount, and also determines the
              policy value.
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Units
            </TableCell>
            <TableCell align="left">
              Units represent the way that each fund is split to identify a
              value that we can attribute to each policyholder's holding
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Partial Withdrawal
            </TableCell>
            <TableCell align="left">
              Part withdrawal of the value of the policy before maturity
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Policy Value
            </TableCell>
            <TableCell align="left">
              The value of the units purchased under a policy. Calculated as the
              number of units multiplied by the current unit price
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Risk Benefits
            </TableCell>
            <TableCell align="left">
              Benefits payable in the event of contingencies such as death or
              disability
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" style={{}}>
              Annual premium update
            </TableCell>
            <TableCell align="left">
              The annual increase in premium that occurs at each policy
              anniversary
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Waiver of Premiums
            </TableCell>
            <TableCell align="left">
              An additional benefit where future premiums due are ""waived""
              (paid by the insurer) in the event of contingencies such as death
              or disability
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Paid Up (PUP)
            </TableCell>
            <TableCell align="left">
              When a policy is made "paid up" future premiums cease and cover
              will continue at a reduced level or for the remaining term
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Cover Level
            </TableCell>
            <TableCell align="left">
              The amount of benefit payable on the death of the insured during
              the term of the policy
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Arrears
            </TableCell>
            <TableCell align="left">
              These are the amounts of premium payments that have been missed
              under the policy
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Renewal expense
            </TableCell>
            <TableCell align="left">
              Cost of collecting premiums from pay points and banks. It also
              includes the policy fee which runs throughout the term of the
              policy
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Commission charge
            </TableCell>
            <TableCell align="left">
              Cost of procurement of business by an agent or broker
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left" style={{}}>
              Insurance charge
            </TableCell>
            <TableCell align="left">
              Premium for risk cover (this is not invested)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" style={{}}>
              Maintenance charge
            </TableCell>
            <TableCell align="left">
              Charge associated with maintaining policy
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

const SumAssured = {
  align: "right",
};
