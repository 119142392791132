import React from "react";
import moment from "moment";
import { currencyConverter } from "util/currencyConverter";
import AgentInfo from "./AgentInfo";

const BasicInfo = ({ data, agentInfo, loading }) => {
  const {
    commencementDate,
    coverageEndDate,
    mainBenfit,
    policyNumber,
    policyStatus,
    premiumEndDate,
    proposalDate,
    servivingBranch,
    sumAssured,
  } = data;

  return (
    <>
      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Policy No:</h5>
        <p>{policyNumber}</p>
      </div>
      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1">Policy Status:</h5>
        <p>{policyStatus}</p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Servicing Branch :</h5>
        <p>{servivingBranch}</p>
      </div>

      {/* <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <p className="mr-3 policies-section label">DOB:</p>
        <p className="policies-section">{"desc"}</p>
      </div> */}

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Main Benefit:</h5>
        <p>{mainBenfit}</p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Sum Assured:</h5>
        <p>{sumAssured ? currencyConverter(sumAssured) : null}</p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Commencement Date:</h5>
        <p>
          {commencementDate
            ? moment(commencementDate).format("DD/MM/YYYY")
            : null}
        </p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Proposal Date:</h5>
        <p>{proposalDate ? moment(proposalDate).format("DD/MM/YYYY") : null}</p>
      </div>
      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Coverage End Date:</h5>
        <p>
          {coverageEndDate
            ? moment(coverageEndDate).format("DD/MM/YYYY")
            : null}
        </p>
      </div>
      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Premium End Date:</h5>
        <p>
          {premiumEndDate ? moment(premiumEndDate).format("DD/MM/YYYY") : null}
        </p>
      </div>
      <div className="row col-xl-8 custom-info-details col-lg-12 col-md-12 col-sm-12 col-12 agentInfo-row">
        <h5 className="mb-1 ">Agent Details:</h5>
        <AgentInfo loading={loading} agentInfo={agentInfo} />
      </div>
    </>
  );
};

export default BasicInfo;
