/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import FormikRadioGroup from "./FormikRadioGroup";
import {
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import {
  reportDownload,
  showReportDownloadLoader,
  hideReportDownloadErrorMessage,
} from "../../../actions/ReportAction";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { ErrorToaster } from "util/ReactToastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { SystemUpdateOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  radio: {
    "&$checked": {
      color: "#0066ab",
    },
  },
  checked: {},
  marginAuto: {
    margin: "0 auto",
  },
});

const applicantsPersonalDetails = [
  { label: "Download as *", name: "smoker", key: 1 },
];

export default ({
  open,
  handleRequestClose,
  handleRequestSubmit,
  setComments,
  startDate,
  endDate,
  status,
  isAdmin,
  KycRequestsData,
}) => {
  const [reportdownloadPdf, setReportDownloadPdf] = useState(false);
  const [approvedStatus, setApprovedStatus] = useState();
  const [newStatus, setNewStatus] = useState();
  const [onHold, setOnHold] = useState();
  const [pendingStatus, setPendingStatus] = useState();
  const [rejectedStatus, setRejectedStatus] = useState();
  const classes = useStyles();
  const { loader, reportDownloadData, alertMessage, showMessage } = useSelector(
    ({ reportDownloadReducer }) => reportDownloadReducer
  );

  const handleFileDownload = () => {
    // after getting the data from backend we are creating the pdf and saving into the system here using jspdf
    if (KycRequestsData) {
      // window.location.href = reportDownloadData;
      // alert("reportDownloadData");

      console.log("reportDownloadData: ", KycRequestsData);

      var document = new jsPDF("p", "mm", "a4");
      var document = new jsPDF();

      if (KycRequestsData.length === 0 || KycRequestsData.length > 0) {
        //const recordCount=reportDownloadData.userRecordCount;
        //const kycUserObject=reportDownloadData.kycUserCSVObject;

        //       alert('kycUserCSVObject');
        //  console.log('kycUserCSVObject',reportDownloadData.hasOwnProperty("kycUserCSVObject"))

        document.setFontSize(12);
        document.text("KYC User Requests", 20, 10);

        //console.log('recordCount',recordCount);
        let recordCountArray = [];
        //console.log('recordCount.approvedRecords',recordCount.approvedRecords)

        const approved = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "APPROVED") {
            return true;
          } else {
            return false;
          }
        }).length;

        const newstatus = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "NEW") {
            return true;
          } else {
            return false;
          }
        }).length;

        const onhold = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "ON-HOLD") {
            return true;
          } else {
            return false;
          }
        }).length;

        const pending = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "PENDING") {
            return true;
          } else {
            return false;
          }
        }).length;

        const rejected = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "REJECTED") {
            return true;
          } else {
            return false;
          }
        }).length;

        let obj = {
          approvedRecords: approved,
          newRecords: newstatus,
          onHoldRecords: onhold,
          pendingRecords: pending,
          rejectedRecords: rejected,
          totalRecords: KycRequestsData.length,
        };

        if (
          obj.approvedRecords !== undefined ||
          obj.newRecords !== undefined ||
          obj.onHoldRecords !== undefined ||
          obj.pendingRecords !== undefined ||
          obj.rejectedRecords !== undefined ||
          obj.totalRecords !== undefined
        ) {
          recordCountArray.push(obj);
        }

        if (recordCountArray.length > 0) {
          console.log("recordCountArray", recordCountArray);

          document.autoTable(
            [
              { header: "ApprovedRecords", dataKey: "approvedRecords" },
              { header: "NewRecords", dataKey: "newRecords" },
              { header: "OnHoldRecords", dataKey: "onHoldRecords" },
              { header: "PendingRecords", dataKey: "pendingRecords" },
              { header: "RejectedRecords", dataKey: "rejectedRecords" },
              { header: "TotalRecords", dataKey: "totalRecords" },
            ],
            recordCountArray,

            {
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              startY: 20,
              theme: "grid",
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              headerStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fillColor: "#add8e6",
                textColor: "black",
              },
              columnStyles: {
                // 0: { cellWidth: 15 }, //frequency
                // 1: { cellWidth: 15 }, //task name
                // 2: { cellWidth: 70 },
              },
              bodyStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fontSize: 10,

                fillColor: "white",
                textColor: "#5B5959",
              },
            }
          );
        } else {
          console.log("recordCountEmptyArray");
          let recordCountEmptyArray = [];

          let obj = {
            approvedRecords: "",
            newRecords: "",
            onHoldRecords: "",
            pendingRecords: "",
            rejectedRecords: "",
            totalRecords: "",
          };
          if (
            obj.approvedRecords !== undefined ||
            obj.newRecords !== undefined ||
            obj.onHoldRecords !== undefined ||
            obj.pendingRecords !== undefined ||
            obj.rejectedRecords !== undefined ||
            obj.totalRecords !== undefined
          ) {
            recordCountEmptyArray.push(obj);
          }

          document.autoTable(
            [
              { header: "ApprovedRecords", dataKey: "approvedRecords" },
              { header: "NewRecords", dataKey: "newRecords" },
              { header: "OnHoldRecords", dataKey: "onHoldRecords" },
              { header: "PendingRecords", dataKey: "pendingRecords" },
              { header: "RejectedRecords", dataKey: "rejectedRecords" },
              { header: "TotalRecords", dataKey: "totalRecords" },
            ],
            recordCountEmptyArray,

            {
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              startY: 20,
              theme: "grid",
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              headerStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fillColor: "#add8e6",
                textColor: "black",
              },
              columnStyles: {
                // 0: { cellWidth: 15 }, //frequency
                // 1: { cellWidth: 15 }, //task name
                // 2: { cellWidth: 70 },
              },
              bodyStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fontSize: 10,

                fillColor: "white",
                textColor: "#5B5959",
              },
            }
          );
        }

        let reportArray = [];

        for (let i = 0; i < KycRequestsData.length; i++) {
          let obj = {
            idNumber: KycRequestsData[i].idNumber,
            email: KycRequestsData[i].email,
            name: KycRequestsData[i].name,
            mobile: KycRequestsData[i].mobile,
            createdAt: moment
              .parseZone(KycRequestsData[i].createdAt)
              .format("DD/MM/YYYY"),
            adminStatus: KycRequestsData[i].adminStatus,
            comments: KycRequestsData[i].comments,
          };
          if (
            obj.createdAt !== undefined ||
            obj.idNumber !== undefined ||
            obj.email !== undefined ||
            obj.name !== undefined ||
            obj.mobile !== undefined ||
            obj.userSelectionStatus !== undefined ||
            obj.adminStatus !== undefined ||
            obj.comments
          ) {
            reportArray.push(obj);
          }
        }

        console.log("reportArray", reportArray);

        if (reportArray.length > 0) {
          document.autoTable(
            [
              { header: "Id Number", dataKey: "idNumber" },
              { header: "Email Address", dataKey: "email" },
              { header: "Name", dataKey: "name" },
              { header: "Mobile Number", dataKey: "mobile" },
              { header: "Request Date", dataKey: "createdAt" },
              { header: "Admin Status", dataKey: "adminStatus" },
              { header: "Comments", dataKey: "comments" },
            ],
            reportArray,
            {
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              // startY: 30,
              theme: "grid",
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              headerStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fillColor: "#add8e6",
                textColor: "black",
              },
              columnStyles: {
                // 0: { cellWidth: 15 }, //frequency
                // 1: { cellWidth: 15 }, //task name
                // 2: { cellWidth: 70 },
              },
              bodyStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fontSize: 10,

                fillColor: "white",
                textColor: "#5B5959",
              },
            }
          );
        } else {
          let reportEmptyArray = [];

          let obj = {
            idNumber: "",
            email: "",
            name: "",
            mobile: "",
            createdAt: "",
            adminStatus: "",
            comments: "",
          };

          if (
            obj.createdAt !== undefined ||
            obj.idNumber !== undefined ||
            obj.email !== undefined ||
            obj.name !== undefined ||
            obj.mobile !== undefined ||
            obj.userSelectionStatus !== undefined ||
            obj.adminStatus !== undefined ||
            obj.comments
          ) {
            reportEmptyArray.push(obj);
          }

          document.autoTable(
            [
              { header: "Request Date", datakey: "createdAt" },
              { header: "Id Number", datakey: "idNumber" },
              { header: "Email Address", datakey: "email" },
              { header: "Name", datakey: "name" },
              { header: "Mobile Number", datakey: "mobile" },
              {
                header: "User Selection Status",
                datakey: "userSelectionStatus",
              },
              { header: "Admin Status", datakey: "adminStatus" },
              { header: "Comments", datakey: "comments" },
            ],
            reportEmptyArray,
            {
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              //  startY: 20,
              theme: "grid",
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              headerStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fillColor: "#add8e6",
                textColor: "black",
              },
              columnStyles: {
                // 0: { cellWidth: 15 }, //frequency
                // 1: { cellWidth: 15 }, //task name
                // 2: { cellWidth: 70 },
              },
              bodyStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fontSize: 10,

                fillColor: "white",
                textColor: "#5B5959",
              },
            }
          );
        }
        document.save("kyc_report" + new Date().toLocaleDateString() + ".pdf");

        //setReportDownloadPdf(true);

        // window.location.href = reportDownloadData;
      } else {
        document.setFontSize(12);
        document.text("Registration User Requests", 20, 5);
        const userCSVDataObject = reportDownloadData.userCSVDataObject;
        const userRecordsCount = reportDownloadData.userRecordCount;

        console.log("userCSVDataObject", userCSVDataObject);
        console.log("userRecordsCount", userRecordsCount);
        let recordCountArray = [];
        console.log(
          "recordCount.approvedRecords",
          userRecordsCount.approvedRecords
        );

        const approved = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "APPROVED") {
            return true;
          } else {
            return false;
          }
        }).length;

        const newstatus = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "NEW") {
            return true;
          } else {
            return false;
          }
        }).length;

        const onhold = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "ON-HOLD") {
            return true;
          } else {
            return false;
          }
        }).length;

        const pending = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "PENDING") {
            return true;
          } else {
            return false;
          }
        }).length;

        const rejected = KycRequestsData.filter(function (item) {
          if (item.adminStatus === "REJECTED") {
            return true;
          } else {
            return false;
          }
        }).length;

        let obj = {
          approvedRecords: approved,
          newRecords: newstatus,
          onHoldRecords: onhold,
          pendingRecords: pending,
          rejectedRecords: rejected,
          totalRecords: KycRequestsData.length,
        };

        if (
          obj.approvedRecords !== undefined ||
          obj.newRecords !== undefined ||
          obj.onHoldRecords !== undefined ||
          obj.pendingRecords !== undefined ||
          obj.rejectedRecords !== undefined ||
          obj.totalRecords !== undefined
        ) {
          recordCountArray.push(obj);
        }

        if (recordCountArray.length > 0) {
          console.log("recordCountArray", recordCountArray);

          document.autoTable(
            [
              { header: "ApprovedRecords", dataKey: "approvedRecords" },
              { header: "NewRecords", dataKey: "newRecords" },
              { header: "OnHoldRecords", dataKey: "onHoldRecords" },
              { header: "PendingRecords", dataKey: "pendingRecords" },
              { header: "RejectedRecords", dataKey: "rejectedRecords" },
              { header: "TotalRecords", dataKey: "totalRecords" },
            ],
            recordCountArray,

            {
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              startY: 20,
              theme: "grid",
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              headerStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fillColor: "#add8e6",
                textColor: "black",
              },
              columnStyles: {
                // 0: { cellWidth: 15 }, //frequency
                // 1: { cellWidth: 15 }, //task name
                // 2: { cellWidth: 70 },
              },
              bodyStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fontSize: 10,

                fillColor: "white",
                textColor: "#5B5959",
              },
            }
          );
        } else {
          console.log("recordCountEmptyArray");
          let recordCountEmptyArray = [];

          let obj = {
            approvedRecords: "",
            newRecords: "",
            onHoldRecords: "",
            pendingRecords: "",
            rejectedRecords: "",
            totalRecords: "",
          };
          if (
            obj.approvedRecords !== undefined ||
            obj.newRecords !== undefined ||
            obj.onHoldRecords !== undefined ||
            obj.pendingRecords !== undefined ||
            obj.rejectedRecords !== undefined ||
            obj.totalRecords !== undefined
          ) {
            recordCountEmptyArray.push(obj);
          }

          document.autoTable(
            [
              { header: "ApprovedRecords", dataKey: "approvedRecords" },
              { header: "NewRecords", dataKey: "newRecords" },
              { header: "OnHoldRecords", dataKey: "onHoldRecords" },
              { header: "PendingRecords", dataKey: "pendingRecords" },
              { header: "RejectedRecords", dataKey: "rejectedRecords" },
              { header: "TotalRecords", dataKey: "totalRecords" },
            ],
            recordCountEmptyArray,

            {
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              startY: 20,
              theme: "grid",
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              headerStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fillColor: "#add8e6",
                textColor: "black",
              },
              columnStyles: {
                // 0: { cellWidth: 15 }, //frequency
                // 1: { cellWidth: 15 }, //task name
                // 2: { cellWidth: 70 },
              },
              bodyStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fontSize: 10,

                fillColor: "white",
                textColor: "#5B5959",
              },
            }
          );
        }

        let userCsvDataArray = [];

        for (let i = 0; i < userCSVDataObject.length; i++) {
          let obj = {
            createdOn: userCSVDataObject[i].createdOn,
            policyNumber: userCSVDataObject[i].policyNumber,
            email: userCSVDataObject[i].email,
            omangNumber: userCSVDataObject[i].omangNumber,
            contactNumber: userCSVDataObject[i].contactNumber,
            dateOfBirth: userCSVDataObject[i].dateOfBirth,
            registrationStatus: userCSVDataObject[i].registrationStatus,
          };
          if (
            obj.createdOn !== undefined ||
            obj.policyNumber !== undefined ||
            obj.email !== undefined ||
            obj.omangNumber !== undefined ||
            obj.contactNumber !== undefined ||
            obj.dateOfBirth !== undefined ||
            obj.registrationStatus !== undefined
          ) {
            userCsvDataArray.push(obj);
          }
        }
        //"Request Date", "Policy Number", "Email Address",
        //"Omang/Password No", "Mobile No", "Date of Birth", "Status"

        if (userCsvDataArray.length > 0) {
          document.autoTable(
            [
              { header: "Request Date", dataKey: "createdOn" },
              { header: "Policy Number", dataKey: "policyNumber" },
              { header: "Email Address", dataKey: "email" },
              { header: "Omang Number", dataKey: "omangNumber" },
              { header: "Mobile No", dataKey: "contactNumber" },
              { header: "Date of Birth", dataKey: "dateOfBirth" },
              { header: "Status", dataKey: "registrationStatus" },
            ],
            userCsvDataArray,
            {
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              //startY: 20,
              theme: "grid",
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              headerStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fillColor: "#add8e6",
                textColor: "black",
              },
              columnStyles: {
                // 0: { cellWidth: 15 }, //frequency
                // 1: { cellWidth: 15 }, //task name
                // 2: { cellWidth: 70 },
              },
              bodyStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fontSize: 10,

                fillColor: "white",
                textColor: "#5B5959",
              },
            }
          );
        } else {
          let userCsvEmptyDataArray = [];
          let obj = {
            createdOn: "",
            policyNumber: "",
            email: "",
            omangNumber: "",
            contactNumber: "",
            dateOfBirth: "",
            registrationStatus: "",
          };
          if (
            obj.createdOn !== undefined ||
            obj.policyNumber !== undefined ||
            obj.email !== undefined ||
            obj.omangNumber !== undefined ||
            obj.contactNumber !== undefined ||
            obj.dateOfBirth !== undefined ||
            obj.registrationStatus !== undefined
          ) {
            userCsvEmptyDataArray.push(obj);
          }

          //   //"Request Date", "Policy Number", "Email Address",
          //     //"Omang/Password No", "Mobile No", "Date of Birth", "Status"

          document.autoTable(
            [
              { header: "Request Date", dataKey: "createdOn" },
              { header: "Policy Number", dataKey: "policyNumber" },
              { header: "Email Address", dataKey: "email" },
              { header: "Omang Number", dataKey: "omangNumber" },
              { header: "Mobile No", dataKey: "contactNumber" },
              { header: "Date of Birth", dataKey: "dateOfBirth" },
              { header: "Status", dataKey: "registrationStatus" },
            ],
            userCsvEmptyDataArray,
            {
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              //startY: 20,
              theme: "grid",
              // tableLineColor: '#000000',
              // tableLineWidth: 0.50,
              headerStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fillColor: "#add8e6",
                textColor: "black",
              },
              columnStyles: {
                // 0: { cellWidth: 15 }, //frequency
                // 1: { cellWidth: 15 }, //task name
                // 2: { cellWidth: 70 },
              },
              bodyStyles: {
                lineWidth: 0.5,
                // lineColor: 'gray',
                fontSize: 10,

                fillColor: "white",
                textColor: "#5B5959",
              },
            }
          );
        }

        document.save("kyc_Report" + new Date().toLocaleDateString() + ".csv");
      }
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideReportDownloadErrorMessage());
      }, 100);
    }
    return () => dispatch(hideReportDownloadErrorMessage());
  }, [showMessage, KycRequestsData]);

  const handleDownload = (fileType, comments, adminIs) => {
    /*
    const data = {
      type: fileType,
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      status: status,
      includeComments: comments,
      admin: adminIs,
    };
    setComments(comments);
    */
    if (fileType === "PDF") {
      handleFileDownload();
      //dispatch(showReportDownloadLoader());
      //dispatch(reportDownload(data));
    } else {
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={remarksSchema}
      onSubmit={async (values, { resetForm }) => {
        const { radioGroup, comments } = values;
        await handleRequestSubmit("download", radioGroup, comments);
        resetForm();
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <Dialog open={open} className="download-popup-dialog">
            <form autoComplete="on" noValidate onSubmit={handleSubmit}>
              <DialogContent className="custom-dialog-content">
                <DialogContentText className="custom-dialog-text">
                  Download as{" "}
                </DialogContentText>
                <div className={classes.marginAuto}>
                  <Field
                    name="radioGroup"
                    value={values.radioGroup}
                    options={["PDF"]}
                    component={FormikRadioGroup}
                  />

                  {/*<Field
                    name="radioGroup"
                    value={values.radioGroup}
                    options={["CSV"]}
                    component={FormikRadioGroup}
                  />*/}

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange}
                        name="comments"
                        //   color="primary"
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Include comments in Report"
                  />
                </div>
              </DialogContent>
              <DialogActions className="confirm-btns">
                <Button
                  color="secondary"
                  onClick={() => {
                    handleReset();
                    handleRequestClose("download");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    handleDownload(values.radioGroup, values.comments, isAdmin)
                  }
                  color="primary"
                  type="submit"
                >
                  Download
                </Button>
              </DialogActions>
            </form>
            {/*loader && (
              <div className="loader-view">
                <CircularProgress />
              </div>
            )*/}
            {showMessage && <ErrorToaster message={alertMessage} />}
            <ToastContainer />
          </Dialog>
        );
      }}
    </Formik>
  );
};

const initialValues = {
  comments: "false",
  radioGroup: "",
};

const remarksSchema = Yup.object().shape({
  radioGroup: Yup.string().required("Please select a file format"),
});
