/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardText } from "reactstrap";
import Button from "@material-ui/core/Button";
// import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import ContainerHeader from "components/ContainerHeader";
import Widget from "../../../../../components/Widget";

export const MOSAKO = ({ productName, productId }) => {
  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile custom-kyp-fonts">
        <div className="card-header">
          <h4 className="card-title mb-0 text-uppercase">{productName}</h4>
        </div>
        <div className="jr-tabs-classic">
          <div className="jr-tabs-content jr-task-list pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <h3 className="mb-2">Product Code</h3>
                  <p>MOSK-1</p>
                  <h3 className="mb-2">Product Summary</h3>
                  <p>
                    "Mosako funeral plan is primarily for individuals who do not
                    meet the minimum salary requirements for other Botswana Life
                    products. It caters primarily for those in jobs such as
                    domestic workers, bus conductors, herd boys, gardeners,
                    church organisations, industrial workers, individuals in the
                    informal sector, self-employed persons etc. However, it does
                    not exclude individuals outside this market segment.
                  </p>
                  <p>
                    Mosako is available at Botswana Post post offices throughout
                    the country. Customers go to the post office to complete
                    application forms and are issued with membership cards.
                    Every time a member pays their premium, he/she produces his
                    membership card.
                  </p>
                  <p>
                    Premiums which are cash based are paid in advance and can be
                    paid at any post office throughout the country. Botswana
                    Life has added convenience to this product by enabling
                    payment of premiums through Mascom Myzaka. Payment can also
                    be made at any Botswana Life branch. Customers can pay up to
                    12 months in advance. Should the need to claim arise, all
                    claim documents should be submitted to your nearest Botswana
                    Life Insurance office and a payment will be made within 24
                    hours."
                  </p>
                </div>
                <div>
                  <h3 className="mb-2">Age Limits</h3>
                  <ul>
                    <li>Minimum age at entry: - 18 years</li>
                    <li>Maximum age at entry: - 59 years</li>
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2">Sum Assured</h3>
                  <p> N/A</p>
                  <h3 className="mb-2">Premium Limits</h3>
                  <p> N/A</p>
                </div>
                <div>
                  <h3 className="mb-2">Policy Term</h3>
                  <ul>
                    <li>Whole of life cover</li>
                  </ul>
                </div>
                <div>
                  <Button
                    className="custom-btn-ach"
                    component={Link}
                    to="/home/know-your-products"
                    variant="contained"
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </>
  );
};
