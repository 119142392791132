import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ErrorPage extends Component {
  render() {
    var admin = (
      <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
        <div className="page-error-content">
          <div className="error-code mb-4 animated zoomInDown">404</div>
          <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
            We are sorry, the page you are looking for is not found.
          </h2>
          <p className="text-center zoomIn animation-delay-20 animated">
            <Link to="/home/admin-dashboard" class="primary">
              Go to Admin Dashboard Page
            </Link>
          </p>
        </div>
      </div>
    );
    var customer = (
      <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
        <div className="page-error-content">
          <div className="error-code mb-4 animated zoomInDown">404</div>
          <h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
            We are sorry, the page you are looking for is not found.
          </h2>
          <p className="text-center zoomIn animation-delay-20 animated">
            <Link to="/home/my-policies" class="primary">
              Go to My Policies Page
            </Link>
          </p>
        </div>
      </div>
    );
    return (
      <>
        {JSON.parse(localStorage.getItem("role")) === "admin" && admin}
        {JSON.parse(localStorage.getItem("role")) === "customer" && customer}
      </>
    );
  }
}
