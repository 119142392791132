import {
  USER_REGISTRATION_SUCCESS,
  HIDE_REGISTRATION_ERROR_MESSAGE,
  ON_SHOW_REGISTRATION_LOADER,
  USER_REGISTRATION_ERROR_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  registrationStatus: "",
  alertMessage: "",
  showMessage: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        loader: false,
        registrationStatus: action.payload,
      };
    }

    case USER_REGISTRATION_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_REGISTRATION_ERROR_MESSAGE: {
      return {
        ...INIT_STATE,
      };
    }

    case ON_SHOW_REGISTRATION_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }

    default:
      return state;
  }
};
