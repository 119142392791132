/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import {
  forgotPassword,
  showPasswordLoader,
  hidePasswordErrorMessage,
} from "actions/forgotPassword";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { CircularProgress } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { ErrorToaster } from "util/ReactToastify";
import { signUpValidation } from "constants/Validations";
import { Select, MenuItem, Input } from "@material-ui/core";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";

const ForgotPassword = (props) => {
  const [infoData, setinfoData] = useState("");
  const { loader, forgotPasswordStatus, alertMessage, showMessage } =
    useSelector(({ forgotPassword }) => forgotPassword);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hidePasswordErrorMessage());
      }, 100);
    }
    if (forgotPasswordStatus === 200) {
      dispatch(hidePasswordErrorMessage());
      props.history.push("/forgot-mobile-otp", {
        mobileNumber: infoData.mobileNumber ? infoData.mobileNumber : null,
        countryCode: infoData.countryCode ? infoData.countryCode : null,
      });
    }
    return () => dispatch(hidePasswordErrorMessage());
  }, [showMessage, forgotPasswordStatus, props.history]);

  let countrycode = getCountries().sort(function (a, b) {
    if (en[a] < en[b]) return -1;
    else if (en[a] > en[b]) return 1;
    return 0;
  });
  return (
    <div style={{ margin: "0 auto" }} className="custom-forgot-password">
      <div className="login-container otp-holder d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div
          className="login-content"
          style={{
            width: "480px",
            margin: "12px 30px",
            boxShadow:
              "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
            padding: "35px 35px 20px",
          }}
        >
          <div className="login-header">
            <Link className="app-logo" to="/" title="Jambo">
              <img
                src={require("assets/images/logo-color.png")}
                alt="jambo"
                title="jambo"
              />
            </Link>
          </div>

          <div className="mb-2">
            <h2 style={{ color: "#3e484f", fontWeight: 500 }}>
              <IntlMessages id="appModule.forgotPassword" />
            </h2>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={ForgotPasswordSchema}
            onSubmit={(values) => {
              const { mobileNumber, countryCode } = values;
              let infoData = {
                mobileNumber,
                countryCode,
              };
              setinfoData(infoData);
              dispatch(showPasswordLoader());
              dispatch(forgotPassword(infoData));
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <div className="login-form">
                  <form autoComplete="on" noValidate onSubmit={handleSubmit}>
                    <div className="row">
                      <div
                        className="col-md-6"
                        style={{ padding: "11px 17px 0px " }}
                      >
                        <span style={{ padding: "0px 6px 0px 0px" }}>
                          Country Code:*
                        </span>
                        <Select
                          id="countryCode"
                          name="countryCode"
                          required
                          className="custom-dropdown-forgetPassword"
                          style={{ width: "100%" }}
                          countryCode={en[values.countryCode]}
                          onChange={handleChange}
                          defaultValue="+267"
                        >
                          {/* <option value="">
                                                                {en['ZZ']}
                                                            </option> */}
                          {countrycode.map((countryCode) => (
                            <MenuItem
                              key={countryCode}
                              value={"+" + getCountryCallingCode(countryCode)}
                            >
                              {en[countryCode]} +
                              {getCountryCallingCode(countryCode)}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <TextField
                          error={errors.mobileNumber && touched.mobileNumber}
                          className="form-textfield form-textfield-label"
                          label="Mobile Number:"
                          name="mobileNumber"
                          value={values.mobileNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.mobileNumber &&
                            touched.mobileNumber &&
                            errors.mobileNumber
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          required
                          margin="normal"
                          placeholder="xxxxxxxx"
                        />
                      </div>
                    </div>
                    <div className="mt-3 mb-2 d-flex justify-content-center align-items-center custom-submit">
                      <Button type="submit" variant="contained" color="primary">
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              );
            }}
          </Formik>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
        </div>
        <div>
          {showMessage && <ErrorToaster message={alertMessage} />}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};
const initialValues = {
  mobileNumber: "",
  countryCode: "+267",
};

const ForgotPasswordSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    // .mobileNumber("Please enter valid phone number")
    .matches(/^[0-9]+$/, signUpValidation.mobileNumberOnly)
    // .min(8, signUpValidation.phoneNumberLengthMsg)
    // .max(10, signUpValidation.phoneNumberLengthMsg)
    .required(signUpValidation.phoneNumberRequired)
    .test(
      "mobileNumber",
      signUpValidation.phoneNumberLengthMsg,
      function (value) {
        return (
          (this.parent.countryCode === "+267" && value?.length === 8) ||
          (this.parent.countryCode !== "+267" && value?.length > 1)
        );
      }
    ),
});

export default ForgotPassword;
