import {
  REGISTRATION_RESEND_OTP,
  REGISTRATION_RESEND_OTP_LOADER,
  REGISTRATION_RESEND_OTP_ERROR_MESSAGE,
  HIDE_REGISTRATION_RESEND_OTP_ERROR_MESSAGE,
  VERIFY_REGISTRATION_RESEND_OTP_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  resendLoader: false,
  resendAlertMessage: "",
  resendShowMessage: false,
  registrationResendOtpStatus: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REGISTRATION_RESEND_OTP:
      return {
        ...state,
        emailOtp: action.payload,
      };

    case REGISTRATION_RESEND_OTP_LOADER:
      return {
        ...state,
        resendLoader: true,
      };
    case HIDE_REGISTRATION_RESEND_OTP_ERROR_MESSAGE:
      return {
        ...INIT_STATE,
      };
    case REGISTRATION_RESEND_OTP_ERROR_MESSAGE:
      return {
        ...state,
        resendAlertMessage: action.payload,
        resendShowMessage: true,
        resendLoader: false,
      };
    case VERIFY_REGISTRATION_RESEND_OTP_SUCCESS:
      return {
        ...state,
        resendLoader: false,
        registrationResendOtpStatus: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
