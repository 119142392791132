/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import OTPConfirmDialog from "../DialogBox/OTPConfirmDialog.js";
import { CircularProgress } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  mobileOrEmailOtp,
  showOtpLoader,
  hideOtpErrorMessage,
} from "../../actions/mobileOrEmailOtp";
import {
  forgotPassword,
  showPasswordLoader,
  hidePasswordErrorMessage,
} from "../../actions/forgotPassword";
import { forgotMobileOtpValidations } from "../../constants/Validations";
import {
  ErrorToaster,
  SuccessToaster,
  toastMsgs,
} from "util/ReactToastify/index.js";
const ForgotMobileOTP = (props) => {
  const [otpData, setOtpData] = useState();

  const { otpLoader, otpAlertMessage, otpShowMessage, otpStatus } = useSelector(
    ({ mobileorEmailOtpData }) => mobileorEmailOtpData
  );
  const { loader, forgotPasswordStatus, alertMessage, showMessage } =
    useSelector(({ forgotPassword }) => forgotPassword);
  const dispatch = useDispatch();

  useEffect(() => {
    if (otpShowMessage) {
      setTimeout(() => {
        dispatch(hideOtpErrorMessage());
      }, 100);
    }

    if (otpStatus === 200) {
      setTimeout(() => {
        dispatch(hideOtpErrorMessage());
      }, 100);
      props.history.push("/reset-password", {
        mobileNumber: otpData.mobileNumber ? otpData.mobileNumber : null,
      });
    }
    return () => dispatch(hideOtpErrorMessage());
  }, [otpShowMessage, otpStatus, props.history]);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hidePasswordErrorMessage());
      }, 100);
    }

    if (forgotPasswordStatus === 200) {
      {
        toastMsgs.success(forgotMobileOtpValidations.otpResendSuccessMsg);
      }
      setTimeout(() => {
        dispatch(hidePasswordErrorMessage());
      }, 100);
    }
    return () => dispatch(hidePasswordErrorMessage());
  }, [showMessage, forgotPasswordStatus]);

  const handleResendOtp = () => {
    setSeconds(120);
    setIsActive(true);
    const mobileNumber =
      props &&
      props.location &&
      props.location.state &&
      props.location.state.mobileNumber
        ? props.location.state.mobileNumber
        : null;

    const countryCode =
      props &&
      props.location &&
      props.location.state &&
      props.location.state.countryCode
        ? props.location.state.countryCode
        : null;
    let otpResendData = {
      mobileNumber,

      countryCode,
    };

    dispatch(showPasswordLoader());
    dispatch(forgotPassword({ mobileNumber, countryCode }));
  };

  const [seconds, setSeconds] = useState(120);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isActive && seconds !== 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (isActive && seconds === 0) {
      setIsActive(false);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const minutes = Math.floor(seconds / 60);
  const secondsRem = seconds % 60;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          const { oTPNumber } = values;
          let mobileNumber = null;
          let countryCode = null;
          if (props && props.location && props.location.state) {
            mobileNumber = props.location.state.mobileNumber;
            countryCode = props.location.state.countryCode;
          }
          let otpData = {
            mobileNumber,
            oTPNumber,
            countryCode,
          };
          setOtpData(otpData);
          dispatch(showOtpLoader());
          dispatch(mobileOrEmailOtp(otpData));
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <div
              style={{ margin: "0 auto" }}
              className="custom-forgot-password"
            >
              <div className="login-container otp-holder align-center d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="login-content ">
                  <div className="login-header mb-4">
                    <Link className="app-logo" to="/" title="Jambo">
                      <img
                        src={require("assets/images/logo-color.png")}
                        alt="jambo"
                        title="jambo"
                      />
                    </Link>
                  </div>

                  <div className="login-form">
                    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <h4>
                        <IntlMessages id="appModule.OTPForEmailAndMobileText" />
                      </h4>
                      <TextField
                        error={errors.oTPNumber && touched.oTPNumber}
                        className="form-textfield form-textfield-label"
                        id="oTPNumber"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={values.oTPNumber}
                        onChange={handleChange}
                        placeholder="Enter OTP Number"
                        margin="normal"
                        required
                        onBlur={handleBlur}
                        fullWidth
                        helperText={
                          errors.oTPNumber &&
                          touched.oTPNumber &&
                          errors.oTPNumber
                        }
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {minutes}:
                          {secondsRem < 10 ? `0${secondsRem}` : secondsRem}
                        </div>
                        <div className="remove-btn-padding">
                          <Button
                            className="jr-btn"
                            color="primary"
                            onClick={handleResendOtp}
                            disabled={isActive}
                          >
                            Resend OTP
                          </Button>
                        </div>
                      </div>
                      <div className="mt-1 mb-2 d-flex justify-content-center align-items-center custom-verify-btn">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Verify
                        </Button>
                      </div>
                    </form>
                  </div>
                  {(otpLoader || loader) && (
                    <div className="loader-view">
                      <CircularProgress />
                    </div>
                  )}
                  {showMessage && <ErrorToaster message={alertMessage} />}
                  {otpShowMessage && <ErrorToaster message={otpAlertMessage} />}
                  <ToastContainer />
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

const initialValues = {
  oTPNumber: "",
};

const SignupSchema = Yup.object().shape({
  oTPNumber: Yup.string()

    .matches(/^[0-9]+$/, forgotMobileOtpValidations.OtpLengthMsg)
    .min(6, forgotMobileOtpValidations.OtpLengthMsg)
    .max(6, forgotMobileOtpValidations.OtpLengthMsg)
    .required(forgotMobileOtpValidations.OtpRequired),
});

export default ForgotMobileOTP;
