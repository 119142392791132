import { getConfig } from "./configSettings";

export const ApiUrl = () => {
  if (getConfig("saisAPIPort")) {
    return (
      (getConfig("sslEnabled") ? "https://" : "http://") +
      getConfig("saisAPIEndPoint") +
      ":" +
      getConfig("saisAPIPort") +
      "/" +
      getConfig("saisAPILabel") +
      "/" +
      getConfig("saisAPIVersion") +
      "/"
    );
  } else {
    return (
      (getConfig("sslEnabled") ? "https://" : "http://") +
      getConfig("saisAPIEndPoint") +
      "/" +
      getConfig("saisAPILabel") +
      "/" +
      getConfig("saisAPIVersion") +
      "/"
    );
  }
};
