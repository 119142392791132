/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ClaimsCases from "./claimCase";
import ClaimsCaseDetails from "./claimsCaseDetails";
import { Route, Switch, Redirect } from "react-router-dom";
import RegisterCase from "./registerNew";
import PaymentInfo from "./PaymentInfo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs({ match }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Switch>
        <Redirect
          exact
          from={`${match.url}/`}
          to={`${match.url}/claim-case-status`}
        />

        <Route
          path={`${match.url}/claim-case-status`}
          component={ClaimsCases}
        />

        <Route
          path={`${match.url}/register-new-claim`}
          component={RegisterCase}
        />

        <Route path={`${match.url}/payment-info`} component={PaymentInfo} />
      </Switch>
    </div>
  );
}
