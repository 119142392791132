import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();
const site_key = "6LfecewUAAAAALuQnaLRyNaVoVM-A63SoKAOUgBI"; // bLIL site key
// const site_key = "6Lcch-EUAAAAACQgELwzg6a99CNGGQRQZ1ZQvfyo"; // cashapona site key

const Captcha = (props) => {
  const { errors, touched, setFieldValue } = props;

  return (
    <div style={{ transform: "scale(0.77)" }}>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={site_key}
        onChange={(response) => {
          setFieldValue("recaptcha", response);
        }}
      />
      {errors.recaptcha && touched.recaptcha && (
        <p style={{ color: "red" }}>{errors.recaptcha}</p>
      )}
    </div>
  );
};
export default Captcha;
