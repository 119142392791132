import React from "react";
import moment from "moment";
import { currencyConverter } from "util/currencyConverter";

const FinancialInformation = ({ data }) => {
  const {
    premium,
    paymentMethod,
    nextDueDate,
    paymentFrequence,
    policyValue,
    arrears,
    policyNumber,
    extraOverPayAmount,
  } = data;

  return (
    <>
      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Policy No:</h5>
        <p>{policyNumber}</p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Premium:</h5>
        <p>{premium ? currencyConverter(premium) : null}</p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Payment Method:</h5>
        <p>{paymentMethod}</p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Next Due Date:</h5>
        <p> {nextDueDate ? moment(nextDueDate).format("DD/MM/YYYY") : null}</p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Payment Frequency:</h5>
        <p>{paymentFrequence}</p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Policy Value:</h5>
        <p> {policyValue ? currencyConverter(policyValue) : null}</p>
      </div>

      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Arrears:</h5>
        <p>{arrears}</p>
      </div>
      <div className="col-xl-4 custom-info-details col-lg-6 col-md-6 col-sm-6 col-12">
        <h5 className="mb-1 ">Excess Paid Amount:</h5>
        <p>
          {extraOverPayAmount ? currencyConverter(extraOverPayAmount) : null}
        </p>
      </div>
    </>
  );
};

export default FinancialInformation;
