/* eslint-disable no-script-url */
import React, { Component } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { connect } from "react-redux";
import { userSignOut } from "actions/Auth";
import { Button } from "@material-ui/core";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentDidUpdate() {
    const { history } = this.props;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      if (
        menuLi[i].classList.contains("menu") &&
        menuLi[i].classList.contains("open") &&
        !menuLi[i].classList.contains("active") &&
        pathname === "/home/KYC"
      ) {
        menuLi[i].classList.remove("open");
      } else if (pathname === "/home/KYC") {
        this.closest(activeLi, "li").classList.add("open");
      }
    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    const { dispatch } = this.props;

    const openLink = () => {
      window.open("https://www.botswanalife.co.bw/");
    };

    return (
      <CustomScrollbars className=" scrollbar">
        {JSON.parse(localStorage.getItem("role")) === "customer" ? (
          <>
            <ul className="nav-menu">
              <li className="nav-header">
                {/* <IntlMessages id="sidebar.main"/> */}
              </li>
              <li className="menu no-arrow">
                <NavLink to="/home/my-policies">
                  <i className="zmdi zmdi-accounts-list-alt zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.myPolicies" />{" "}
                  </span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/home/know-your-products">
                  <i className="zmdi zmdi-gradient zmdi-hc-fw " />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.knowYourProducts" />
                  </span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/home/my-profile">
                  <i className="zmdi zmdi-account zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.myProfile" />{" "}
                  </span>
                </NavLink>
              </li>

              {/*------------------------------------------------------*/}

              <li className="menu arrow">
                {/* <NavLink to="/home/my-claims">
                <i className="zmdi zmdi-lock zmdi-hc-fw" />
                <span className="nav-text"><IntlMessages id="sidebar.myClaims" /> </span>
              </NavLink> */}
                <Button
                  style={{ padding: "7px 35px 8px 26px" }}
                  href="javascript:void(0)"
                >
                  <i className="zmdi zmdi-search-in-file" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.myClaims" />
                  </span>
                </Button>{" "}
                <ul className="sub-menu custom-sub-menu">
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/home/my-claims/claim-case-status"
                    >
                      <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.claimCase" />
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/home/my-claims/register-new-claim"
                    >
                      <i className="zmdi zmdi-accounts-add zmdi-hc-fw" />
                      <span className="nav-text text-transform-none">
                        <IntlMessages id="sidebar.registerNew" />
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/home/my-claims/payment-info"
                    >
                      <i className="zmdi zmdi-receipt zmdi-hc-fw" />
                      <span className="nav-text text-transform-none">
                        <IntlMessages id="sidebar.paymentInfo" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              {/*------------------------------------------------------*/}

              <li className="menu no-arrow">
                <NavLink to="/home/KYC">
                  <i className="zmdi zmdi-assignment zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.kyc" />{" "}
                  </span>
                </NavLink>
              </li>

              {/*
              <li className="menu arrow">
                {/* <NavLink to="/home/my-claims">
                <i className="zmdi zmdi-lock zmdi-hc-fw" />
                <span className="nav-text"><IntlMessages id="sidebar.myClaims" /> </span>
              </NavLink> 
                <Button
                  style={{ padding: "7px 35px 8px 26px" }}
                  href="javascript:void(0)"
                >
                  <i className="zmdi zmdi-search-in-file" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.customerService" />
                  </span>
                </Button>{" "}
                <ul className="sub-menu custom-sub-menu">
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/home/customer-service/bank-details"
                    >
                      <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.bankDetails" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              */}

              {/*------------------------------------------------------*/}

              <li className="menu no-arrow">
                <NavLink to="/home/appointments">
                  <i className="zmdi zmdi-account-box-o zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.appointments" />{" "}
                  </span>
                </NavLink>
              </li>

              {/*------------------------------------------------------*/}

              <li className="menu no-arrow">
                <NavLink to="/home/feedback">
                  <i className="zmdi zmdi-comments zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.feedback" />{" "}
                  </span>
                </NavLink>
              </li>

              {/*------------------------------------------------------*/}

              <li className="menu arrow">
                <Button
                  style={{ padding: "7px 35px 8px 26px" }}
                  href="javascript:void(0)"
                >
                  <i className="zmdi zmdi-download" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.download" />
                  </span>
                </Button>{" "}
                <ul className="sub-menu custom-sub-menu">
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/home/statements/policy-schedule"
                    >
                      <i className="zmdi zmdi-accounts zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.policySchedule" />
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/home/statements/policy-contract"
                    >
                      <i className="zmdi zmdi-accounts-add zmdi-hc-fw" />
                      <span className="nav-text text-transform-none">
                        <IntlMessages id="Policy Contract" />
                      </span>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      className="prepend-icon"
                      to="/home/statements/investment-detailed-report"
                    >
                      <i className="zmdi zmdi-accounts-add zmdi-hc-fw" />
                      <span className="nav-text text-transform-none">
                        <IntlMessages id="sidebar.investmentDetailedReport" />
                      </span>
                    </NavLink>
                  </li> */}

                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/home/statements/tax-certificate"
                    >
                      <i className="zmdi zmdi-accounts-add zmdi-hc-fw" />
                      <span className="nav-text text-transform-none">
                        <IntlMessages id="Tax Certificate" />
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/*----------------------------------------------------------*/}

              <li className="menu no-arrow">
                <NavLink to="/home/change-password">
                  <i className="zmdi zmdi-lock zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.changePassword" />{" "}
                  </span>
                </NavLink>
              </li>

              {/*<li className="menu no-arrow" onClick={openLink}>
                <NavLink to="">
                  <i className="zmdi zmdi-globe zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.annuintantsLink" />{" "}
                  </span>
                </NavLink>
              </li>*/}

              {/* temporary admin views start*/}
              {/* <li className="menu no-arrow">
                <NavLink to="/home/admin-dashboard">
                  <i className="zmdi zmdi-account-circle zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.dashboard" />{" "}
                  </span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to="/home/kYC-requests">
                  <i className="zmdi zmdi-dropbox zmdi-hc-fw" />
                  <span className="nav-text"> KYC Requests </span>
                </NavLink>
              </li> */}
              {/* <li className="menu no-arrow">
                <NavLink to="/home/admin-claim-registration-page">
                  <i className="zmdi zmdi-money zmdi-hc-fw" />
                  <span className="nav-text">Claim Requests</span>
                </NavLink>
              </li> */}

              {/* temporary admin views end */}

              <li
                className="menu no-arrow"
                onClick={() => {
                  dispatch(userSignOut());
                }}
              >
                <NavLink to="/signin">
                  <i className="zmdi zmdi-power zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.logout" />{" "}
                  </span>
                </NavLink>
              </li>
            </ul>
          </>
        ) : null}

        {JSON.parse(localStorage.getItem("role")) === "KYCadmin" ? (
          <>
            <ul className="nav-menu">
              <li className="nav-header"></li>
              {/* <Route
                path={`${match.url}/policy-schedule`}

              /> */}

              <li className="menu no-arrow">
                <NavLink to="/home/kYC-requests">
                  <i className="zmdi zmdi-dropbox zmdi-hc-fw" />
                  <span className="nav-text">KYC Requests</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/home/change-password">
                  <i className="zmdi zmdi-lock zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.changePassword" />{" "}
                  </span>
                </NavLink>
              </li>
              <li
                className="menu no-arrow"
                onClick={() => {
                  dispatch(userSignOut());
                }}
              >
                <NavLink to="/signin">
                  <i className="zmdi zmdi-power zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.logout" />{" "}
                  </span>
                </NavLink>
              </li>
            </ul>
          </>
        ) : null}

        {JSON.parse(localStorage.getItem("role")) === "admin" ? (
          <>
            <ul className="nav-menu">
              <li className="nav-header">
                {/* <IntlMessages id="sidebar.main"/> */}
              </li>
              {/* <li className="menu no-arrow">
              <NavLink to="/home/know-your-products">
                <i className="zmdi zmdi-gradient zmdi-hc-fw " />
                <span className="nav-text"><IntlMessages id="sidebar.knowYourProducts" /></span>
              </NavLink>
            </li> */}
              {/* <li className="menu no-arrow">
              <NavLink to="/home/my-profile">
                <i className="zmdi zmdi-account zmdi-hc-fw" />
                <span className="nav-text"><IntlMessages id="sidebar.myProfile" /> </span>
              </NavLink>
            </li>         */}

              <li className="menu no-arrow">
                <NavLink to="/home/admin-dashboard">
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.dashboard" />{" "}
                  </span>
                </NavLink>
              </li>
              {/* <li className="menu no-arrow">
                <NavLink to="/home/kYC-requests">
                  <i className="zmdi zmdi-dropbox zmdi-hc-fw" />
                  <span className="nav-text">KYC Requests</span>

                </NavLink>
              </li> */}
              <li className="menu no-arrow">
                <NavLink to="/home/change-password">
                  <i className="zmdi zmdi-lock zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.changePassword" />{" "}
                  </span>
                </NavLink>
              </li>
              {/* <li className="menu no-arrow">
                <NavLink to="/home/admin-pdf-page">
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text"> Document Requests </span>
                </NavLink>
              </li> */}
              {/* <li className="menu no-arrow">
                <NavLink to="/home/admin-claim-registration-page">
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text">
                    {" "}
                    Claim Registration Requests{" "}
                  </span>
                </NavLink>
              </li> */}

              <li
                className="menu no-arrow"
                onClick={() => {
                  dispatch(userSignOut());
                }}
              >
                <NavLink to="/signin">
                  <i className="zmdi zmdi-power zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="sidebar.logout" />{" "}
                  </span>
                </NavLink>
              </li>
            </ul>
          </>
        ) : null}
      </CustomScrollbars>
    );
  }
}

export default connect()(withRouter(SidenavContent));
