import {
  KYC_REQUEST_SUCCESS,
  HIDE_KYC_REQUEST_ERROR_MESSAGE,
  ON_REQUEST_SHOW_KYC_LOADER,
  KYC_REQUEST_ERROR_MESSAGE,
  KYC_REQUEST_RESET,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  kycRequestData: [],
  statusCode: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case KYC_REQUEST_SUCCESS: {
      return {
        ...state,
        loader: false,
        kycRequestData: action.payload,
      };
    }

    case KYC_REQUEST_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload.message ? action.payload.message : "",
        showMessage: true,
        loader: false,
        statusCode: action.payload.status ? action.payload.status : "",
      };
    }
    case HIDE_KYC_REQUEST_ERROR_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
        statusCode: null,
      };
    }

    case ON_REQUEST_SHOW_KYC_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case KYC_REQUEST_RESET: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case "resetLoader": {
      return {
        ...state,
        loader: false,
      };
    }

    default:
      return state;
  }
};
