import {
  GET_KYC_DOC_PREVIEW,
  RESET_KYC_DOCUMENT,
  GET_ADMIN_KYC_DOC_PREVIEW,
  RESET_ADMIN_KYC_DOCUMENT,
  KYC_PREVIEW_STATUS,
} from "constants/ActionTypes";

const INIT_STATE = {
  KycDocument: "",
  adminKycDocument: "",
  adminKycStatus: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_KYC_DOC_PREVIEW: {
      return {
        ...state,
        KycDocument: action.payload,
      };
    }
    case GET_ADMIN_KYC_DOC_PREVIEW: {
      return {
        ...state,
        adminKycDocument: action.payload,
      };
    }

    case KYC_PREVIEW_STATUS: {
      return {
        ...state,
        adminKycStatus: action.payload,
      };
    }

    case RESET_KYC_DOCUMENT: {
      return {
        ...state,
        KycDocument: "",
        adminKycStatus: null,
      };
    }
    case RESET_ADMIN_KYC_DOCUMENT: {
      return {
        ...state,
        adminKycDocument: "",
        adminKycStatus: null,
      };
    }
    default:
      return state;
  }
};
