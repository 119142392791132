import {
  CLAIMS_DOCS_UPLOAD,
  EDRMS_DOCS_PUSH,
  RESET_EDRMS_DOCS_PUSH,
} from "constants/ActionTypes";

const INIT_STATE = {
  claimsDocsData: {},
  edrmsData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLAIMS_DOCS_UPLOAD: {
      return {
        ...state,
        claimsDocsData: action.payload,
      };
    }
    case EDRMS_DOCS_PUSH: {
      return {
        ...state,
        edrmsData: action.payload,
      };
    }
    case RESET_EDRMS_DOCS_PUSH: {
      return {
        ...state,
        edrmsData: [],
      };
    }
    default:
      return state;
  }
};
