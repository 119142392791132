/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardText } from "reactstrap";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ContainerHeader from "components/ContainerHeader";
import Widget from "../../../../../components/Widget";

export const TapolgoMultiFund = ({ productId, productName }) => {
  return (
    <>
      <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile custom-kyp-fonts">
        <div className="card-header">
          <h4 className="card-title mb-0 text-uppercase">{productName}</h4>
        </div>
        <div className="jr-tabs-classic">
          <div className="jr-tabs-content jr-task-list pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <h3 className="mb-2">Product Code</h3>
                  <p>MFRA</p>
                  <h3 className="mb-2">Product Summary</h3>
                  <p>
                    {" "}
                    This product is designed to assist clients to save for
                    retirement .On maturity the product will be converted to an
                    annuity. This product will be targeted at lower-middle to
                    high income earners.
                  </p>
                </div>
                <div>
                  <h3 className="mb-2">Age Limits</h3>
                  <ul>
                    <li>Minimum age at entry for life cover – 21</li>
                    <li>Maximum age at entry for life cover – 55</li>
                    <li>
                      Termination age for life cover and accidental death – 70
                    </li>
                    <li>Maximum age at entry for capital disability – 55</li>
                    <li>Maximum termination age for capital disability - 70</li>
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2">Sum Assured</h3>
                  <p>N/A</p>
                  <h3 className="mb-2">Premium Limits</h3>
                  <ul>
                    {" "}
                    <li>P 245</li>
                  </ul>
                </div>
                <div>
                  <h3 className="mb-2">Policy Term</h3>
                  <ul>
                    <li>Whole of Life</li>
                  </ul>
                </div>
                <div>
                  <Button
                    className="custom-btn-ach"
                    component={Link}
                    to="/home/know-your-products"
                    variant="contained"
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Widget>
    </>
  );
};
