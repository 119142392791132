import {
  REPORT_DOWNLOAD,
  REPORT_DOWNLOAD_SUCCESS,
  REPORT_DOWNLOAD_ERROR_MESSAGE,
  HIDE_REPORT_DOWNLOAD_ERROR_MESSAGE,
  ON_SHOW_REPORT_DOWNLOAD_LOADER,
} from "constants/ActionTypes";

export const reportDownload = (data) => {
  return {
    type: REPORT_DOWNLOAD,
    payload: data,
  };
};
export const reportDownloadSuccess = (data) => {
  return {
    type: REPORT_DOWNLOAD_SUCCESS,
    payload: data,
  };
};
export const reportDownloadError = (data) => {
  return {
    type: REPORT_DOWNLOAD_ERROR_MESSAGE,
    payload: data,
  };
};
export const hideReportDownloadErrorMessage = (data) => {
  return {
    type: HIDE_REPORT_DOWNLOAD_ERROR_MESSAGE,
  };
};
export const showReportDownloadLoader = (data) => {
  return {
    type: ON_SHOW_REPORT_DOWNLOAD_LOADER,
  };
};
