/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import AppLocale from "../lngProvider";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import RTL from "util/RTL";
import AppLayout from "./AppLayout";
import ForgotPassword from "./ForgotPassword";
import MobileOTP from "./SignUp/MobileOTP";
import ForgotMobileOTP from "./ForgotPassword/ForgotMobileOTP";
import ResetPassword from "./ForgotPassword/ResetPassword";
import CreatePassword from "./CreatePassword";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const App = (props) => {
  // const dispatch = useDispatch();
  const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(
    ({ settings }) => settings
  );
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const { match, location } = props;
  let applyTheme = createMuiTheme();
  if (location.pathname === "/") {
    if (authUser === null) {
      return <Redirect to={"/signin"} />;
    } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
      if (
        localStorage.getItem("role") &&
        JSON.parse(localStorage.getItem("role")) === "admin"
      ) {
        return <Redirect to={"/home/admin-dashboard"} />;
      } else if (
        localStorage.getItem("role") &&
        JSON.parse(localStorage.getItem("role")) === "KYCadmin"
      ) {
        return <Redirect to={"/home/kYC-requests"} />;
      } else {
        return <Redirect to={"/home/my-policies"} />;
      }
    } else {
      return <Redirect to={initURL} />;
    }
  }

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute
                  path={`${match.url}home`}
                  authUser={authUser}
                  component={AppLayout}
                />
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                {/* <Route path='/forgot-password' component={ForgotPassword}/> */}
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/mobile-otp" component={MobileOTP} />
                <Route path="/forgot-mobile-otp" component={ForgotMobileOTP} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/setup-password" component={CreatePassword} />
                {/*<Route*/}
                {/*  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>*/}
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
