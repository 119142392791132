/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  userRegistration,
  showRegistrationLoader,
  hideUserRegistrationMessage,
} from "actions/Registration";
import IntlMessages from "util/IntlMessages";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CardBox from "components/CardBox";
import DetailsConfirmDialog from "../DialogBox/DetailsConfirmDialog";
import DatePickers from "../DatePickers";
import { CircularProgress, Select, MenuItem, Input } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { ErrorToaster } from "util/ReactToastify";

import { signUpValidation } from "constants/Validations";
// import 'containers/fontStyle.scss';
import moment from "moment";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";

const SignUp = (props) => {
  const [userData, setuserData] = useState("");
  const [open, setOpen] = useState(false);
  const [validateData, setValidateData] = useState();
  //const [externalId, setExternalId] = useState();
  // const [countryCode, setCountry] = useState();
  const { contactNumber, countryCode, dateOfBirth, omangNumber, policyNumber } =
    userData;

  const { loader, alertMessage, showMessage, registrationStatus } = useSelector(
    ({ registration }) => registration
  );

  const dispatch = useDispatch();

  const history = useHistory();

  const handleRequestClose = () => {
    setOpen(false);
  };
  const handleRequestSumbit = () => {
    dispatch(showRegistrationLoader());
    //const { contactNumber, countryCode, dateOfBirth, omangNumber, policyNumber } = userData;
    const userDataModified = {
      contactNumber,
      countryCode,
      dateOfBirth,
      email: "",
      omangNumber,
      policyNumber,
    };
    dispatch(userRegistration(userDataModified));
    setOpen(false);
  };

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideUserRegistrationMessage());
      }, 100);
    }
    if (registrationStatus.status === 200) {
      //setExternalId(registrationStatus.response.externalID);
      const externalId = registrationStatus.response.externalID;
      console.log("registration data: ", registrationStatus);
      console.log("External Id: ", externalId);
      props.history.push("/mobile-otp", { userData, externalId });
      dispatch(hideUserRegistrationMessage());
    }
  }, [showMessage, registrationStatus.status, props.history]);
  let countrycode = getCountries().sort(function (a, b) {
    if (en[a] < en[b]) return -1;
    else if (en[a] > en[b]) return 1;
    return 0;
  });

  return (
    <div className="app-login-container login-holder d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-signup-content">
          <div className="app-login-form">
            <div className="logo-placement align-items-center justify-content-center">
              <Link className="logo-lg" to="/" title="botswana-life">
                {/* //signinlogo */}
                <img
                  src={require("assets/images/logo.png")}
                  alt="botswana-life"
                  title="botswana-life"
                />
              </Link>
            </div>
            <div className="registration-custom-card">
              <DetailsConfirmDialog
                userData={userData}
                open={open}
                handleRequestClose={handleRequestClose}
                handleRequestSumbit={handleRequestSumbit}
              />
              <div className="row" style={{ margin: "0px", width: "100%" }}>
                <div className="">
                  <CardBox styleName="col-sm-12 custom-form-card">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={SignupSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        const {
                          email,
                          policyNumber,
                          countryCode,
                          omangNumber,
                          mobileNumber,
                          dateOfBirth,
                        } = values;
                        let userData = {
                          contactNumber: mobileNumber,
                          countryCode,
                          dateOfBirth: moment(dateOfBirth).format("YYYY-MM-DD"),
                          email,
                          omangNumber,
                          policyNumber,
                        };
                        setOpen(true);
                        setuserData(userData);
                        setSubmitting(false);
                      }}
                    >
                      {(props) => {
                        const {
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                        } = props;

                        return (
                          <form
                            autoComplete="on"
                            noValidate
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <TextField
                                  error={
                                    errors.policyNumber && touched.policyNumber
                                  }
                                  className="form-textfield form-textfield-label"
                                  id="policyNumber"
                                  label="Policy Number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  value={values.policyNumber}
                                  onChange={handleChange}
                                  placeholder="12XXXXX"
                                  margin="normal"
                                  required
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.policyNumber &&
                                    touched.policyNumber &&
                                    errors.policyNumber
                                  }
                                  // error={true}
                                  // helperText="Incorrect entry."
                                  fullWidth
                                />
                              </div>

                              <div className="col-md-6 col-sm-12">
                                <TextField
                                  error={errors.email && touched.email}
                                  className="form-textfield form-textfield-label"
                                  label="Email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.email &&
                                    touched.email &&
                                    errors.email
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth
                                  margin="normal"
                                  placeholder="user@gmail.com"
                                />
                              </div>

                              <div
                                className="col-md-6 col-sm-12 form-textfield form-textfield-label"
                                required
                              >
                                <DatePickers
                                  handleDateChange={setFieldValue}
                                  handleBlur={handleBlur}
                                  dateOfBirth={values.dateOfBirth}
                                  error={
                                    errors.dateOfBirth && touched.dateOfBirth
                                  }
                                  helperText={
                                    errors.dateOfBirth &&
                                    touched.dateOfBirth &&
                                    errors.dateOfBirth
                                  }
                                />
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <TextField
                                  error={
                                    errors.omangNumber && touched.omangNumber
                                  }
                                  className="form-textfield form-textfield-label"
                                  id="omangNumber"
                                  label="Omang/Passport"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  value={values.omangNumber}
                                  onChange={handleChange}
                                  placeholder="XXXXXXXX"
                                  margin="normal"
                                  required
                                  onBlur={handleBlur}
                                  fullWidth
                                  helperText={
                                    errors.omangNumber &&
                                    touched.omangNumber &&
                                    errors.omangNumber
                                  }
                                />
                              </div>
                              <div
                                className="col-md-6 col-sm-12"
                                style={{ padding: "12px 13px 0px" }}
                              >
                                {/* 
                                                            <Select className="custom-countryCode-code"
                                                                id="countryCode"
                                                                name="countryCode"
                                                                countryCode={values.countryCode}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                input={<Input id="countryCode" />}
                                                                defaultValue="+267"
                                                            >
                                                                <MenuItem value="+267">+267</MenuItem>
                                                                <MenuItem value="+91">+91</MenuItem>
                                                            </Select> */}
                                <span>Country Code</span>
                                <Select
                                  id="countryCode"
                                  name="countryCode"
                                  style={{ width: "100%" }}
                                  countryCode={en[values.countryCode]}
                                  onChange={handleChange}
                                  defaultValue="+267"
                                >
                                  {/* <option value="">
                                                                    {en['ZZ']}
                                                                </option> */}
                                  {countrycode.map((countryCode) => (
                                    <MenuItem
                                      key={countryCode}
                                      value={
                                        "+" + getCountryCallingCode(countryCode)
                                      }
                                    >
                                      {en[countryCode]} +
                                      {getCountryCallingCode(countryCode)}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </div>

                              <div className="col-md-6 col-sm-12">
                                <TextField
                                  error={
                                    errors.mobileNumber && touched.mobileNumber
                                  }
                                  className="form-textfield form-textfield-label"
                                  label="Mobile Number"
                                  name="mobileNumber"
                                  required
                                  value={values.mobileNumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.mobileNumber &&
                                    touched.mobileNumber &&
                                    errors.mobileNumber
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  // InputProps={{
                                  //     startAdornment: (
                                  //         <InputAdornment position="start">
                                  //             (+267)
                                  //         </InputAdornment>
                                  //     )
                                  // }}
                                  fullWidth
                                  margin="normal"
                                  placeholder="XXXXXXXXX"
                                />
                              </div>
                            </div>

                            <div className="row proceed-custom-btn">
                              <div className="mt-1 mb-2 d-flex justify-content-center align-items-center">
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                >
                                  Proceed
                                </Button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </CardBox>
                </div>
              </div>
              {loader && (
                <div className="loader-view">
                  <CircularProgress />
                </div>
              )}
              {showMessage && <ErrorToaster message={alertMessage} />}
              <ToastContainer />
            </div>
          </div>
        </div>

        <div className="app-logo-content d-flex align-items-center justify-content-center">
          {/*<Link className="logo-lg" to="/" title="botswana-life">
              <img
                src={require("assets/images/logo.png")}
                alt="botswana-life"
                title="botswana-life"
              />
            </Link>*/}
          <div className="btn-con login-button-placement">
            <div className="custom-signin-page sign-up-font">
              <IntlMessages id="appModule.signInMessage" />
            </div>

            <Button
              style={{
                width: "100%",
                marginTop: "20px",
                border: "solid 1.5px #ffffff",
              }}
              onClick={() => history.push("/signin")}
              type="submit"
              variant="contained"
            >
              <IntlMessages id="appModule.signIn" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const initialValues = {
  policyNumber: "",
  email: "",
  countryCode: "+267",
  mobileNumber: "",
  omangNumber: "",
  dateOfBirth: null,
};

const SignupSchema = Yup.object().shape({
  // <h4><IntlMessages id="appModule.OTPForEmailAndMobileText"/></h4>
  email: Yup.string().email(signUpValidation.emailInvalid),
  policyNumber: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, signUpValidation.alphaNumeric)
    .min(1, signUpValidation.policyNumberLengthMsg)
    .max(10, signUpValidation.policyNumberLengthMsg)
    .required(signUpValidation.policyNumberRequired),
  omangNumber: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, signUpValidation.alphaNumericOnly)
    // .min(5, signUpValidation.omangNumberLengthMsg)
    .max(20, signUpValidation.omangNumberLengthMsg)
    .required(signUpValidation.omangRequired),
  mobileNumber: Yup.string()
    .matches(/^[0-9]+$/, signUpValidation.mobileNumberOnly)
    // .min(8, signUpValidation.phoneNumberLengthMsg)
    // .max(10, signUpValidation.phoneNumberLengthMsg)
    .required(signUpValidation.phoneNumberRequired)
    .test(
      "mobileNumber",
      signUpValidation.phoneNumberLengthMsg,
      function (value) {
        return (
          (this.parent.countryCode === "+267" && value?.length === 8) ||
          (this.parent.countryCode !== "+267" && value?.length > 1)
        );
      }
    ),
  dateOfBirth: Yup.date()
    .max(moment(), signUpValidation.dateOfBirthMaxLimitError)
    .typeError(signUpValidation.dateOfBirthTypeError)
    .required(signUpValidation.dateOfBirthRequired),
  // countryCode: Yup.string()
  //     .required("Counrty code is required")
});

export default SignUp;
