import {
  GET_KYC_DATA,
  POST_KYC_DATA,
  RESET_KYC_DATA,
  SET_KYC_LOADER,
  RESET_KYC_LOADER,
} from "constants/ActionTypes";

const INIT_STATE = {
  getKycData: [],
  postKycData: null,
  loader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_KYC_DATA: {
      return {
        ...state,
        loader: false,
        getKycData: action.payload,
      };
    }
    case POST_KYC_DATA: {
      return {
        ...state,
        loader: false,
        postKycData: action.payload,
      };
    }
    case RESET_KYC_DATA: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    case SET_KYC_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case RESET_KYC_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};
