import {
  CREATE_PASSWORD,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_ERROR_MESSAGE,
  HIDE_CREATE_PASSWORD_ERROR_MESSAGE,
  ON_SHOW_CREATE_PASSWORD_LOADER,
} from "../constants/ActionTypes";

export const createPassword = (data) => {
  return {
    type: CREATE_PASSWORD,
    payload: data,
  };
};

export const createPasswordSuccess = (data) => {
  return {
    type: CREATE_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const createPasswordError = (data) => {
  return {
    type: CREATE_PASSWORD_ERROR_MESSAGE,
    payload: data,
  };
};
export const onShowCreatePasswordLoader = () => {
  return {
    type: ON_SHOW_CREATE_PASSWORD_LOADER,
  };
};
export const hideCreatePasswordErrorMessage = () => {
  return {
    type: HIDE_CREATE_PASSWORD_ERROR_MESSAGE,
  };
};
