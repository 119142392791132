import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpPost } from "util/REST";
import {
  verifySignInResendOtpSuccess,
  verifySignInResendOtpErrorMessage,
} from "actions/SignInResendOTP";
import { POST_SIGNIN_MOBILE_RESEND_OTP } from "constants/ActionTypes";

const postMobileResendOtpApi = async (data) =>
  await httpPost("resendOTPS", data)
    .then((data) => data)
    .catch((error) => error);

function* PostMobileResendOtpReq({ payload }) {
  try {
    let body = payload;
    const otpResponse = yield call(postMobileResendOtpApi, body);
    if (otpResponse && otpResponse.status >= 400) {
      yield put(verifySignInResendOtpErrorMessage(otpResponse.message));
    } else {
      yield put(verifySignInResendOtpSuccess(otpResponse.status));
    }
  } catch (error) {
    yield put(verifySignInResendOtpErrorMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(POST_SIGNIN_MOBILE_RESEND_OTP, PostMobileResendOtpReq)]);
}
