import { all, call, put, takeEvery } from "redux-saga/effects";
import { httpPost } from "util/REST";
import {
  forgotPasswordSuccess,
  forgotPasswordError,
} from "actions/forgotPassword";
import { FORGOT_PASSWORD } from "constants/ActionTypes";

const postForgotPasswordApi = async (data) =>
  await httpPost("forgotPwd", data)
    .then((data) => data)
    .catch((error) => error);

function* PostForgotPasswordReq({ payload }) {
  try {
    let body = payload;
    const forgotPasswordData = yield call(postForgotPasswordApi, body);
    if (forgotPasswordData && forgotPasswordData.status >= 400) {
      yield put(forgotPasswordError(forgotPasswordData.message));
    } else {
      yield put(forgotPasswordSuccess(forgotPasswordData.status));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(FORGOT_PASSWORD, PostForgotPasswordReq)]);
}
