/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import AcceptPopUp from "./AcceptPopUp";
import RejectPopUp from "./RejectPopUp";
import HoldPopUp from "./HoldPopUp";
import { ToastContainer } from "react-toastify";
import { ErrorToaster, toastMsgs } from "util/ReactToastify";
import {
  requestRegistrationLoader,
  registrationRequestData,
  hideRequestRegistrationMessage,
} from "actions/RegistrationRequest";
import {
  showAdminApprovalLoader,
  adminUserApprovalRequest,
  hideAdminUserApprovalError,
} from "actions/AdminApproval";
import ErrorBoundary from "util/ErrorBoundary";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CardBox from "components/CardBox/index";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { adminTableMsgs } from "constants/Validations";
import { makeStyles } from "@material-ui/core/styles";
import { getData, getIcon } from "./helper";
import MaterialTable from "@material-table/core";
import PopOver from "./PopOver";
import {
  showAdminCompareLoader,
  adminCompareRequest,
  resetComapareData,
} from "actions/AdminCompare";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {
  DialogTitle,
  Dialog,
  Tooltip,
  CircularProgress,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AdminDownloadPopUp from "./AdminDownloadPopUp";
import { userSignOut } from "actions";

const AdminHomePage = () => {
  const [openAcceptDialog, setAcceptDialog] = useState(false);
  const [openRejectDialog, setRejectDialog] = useState(false);
  const [openHoldDialog, setHoldDialog] = useState(false);
  const [openDownloadDialog, setDownloadDialog] = useState(false);
  const [externalId, setExternalId] = useState(null);
  const dispatch = useDispatch();
  const { alertMessage, showMessage, usersData, loader, statusCode } =
    useSelector(({ registrationRequest }) => registrationRequest);
  const {
    verifyUseralertMessage,
    verifyUserShowMesssage,
    verifyUserAdminApprovalStatus,
  } = useSelector(({ adminApproval }) => adminApproval);

  const {
    alertCompareMessage,
    showCompareMessage,
    comparisionData,
    adminCompareloader,
  } = useSelector(({ adminCompare }) => adminCompare);
  const [startDate, setStartDate] = useState(moment().subtract(6, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [startDatenull, setstartDatenull] = useState(null);
  const [endDatenull, setendDatenull] = useState(null);
  const [compareDate, setcompareDate] = useState(null);
  const [bothDatenull, setbothDatenull] = useState(null);
  const [status, setStatus] = useState("ALL");
  const [validateButtonData, setValidateButtonData] = useState([]);
  const [isComments, setComments] = useState(false);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#0066ab",
      },
      secondary: {
        main: "#0066ab",
      },
    },
  });

  const [state] = useState({
    columns: [
      {
        field: "createdOn",
        type: "date",
        title: "Request Date",
        headerStyle: { minWidth: 145, color: "#3e484f" },
        render: (rowData) => (
          <span style={{ color: "#3e484f" }}>
            {moment(rowData.createdOn).format("DD/MM/YYYY")}{" "}
          </span>
        ),
      },
      {
        field: "policyNumber",
        title: "Policy No",
        headerStyle: { minWidth: 128, color: "#3e484f", textAlign: "right" },
        render: (rowData) => (
          <div className="custom-policy-table-row">
            <span style={{ color: "#3e484f" }}>{rowData.policyNumber} </span>
            <span>{getIcon(rowData.policyNumberValidation)}</span>
          </div>
        ),
      },
      {
        field: "email",
        title: "Email Address",
        headerStyle: { minWidth: 210, color: "#3e484f" },
        render: (rowData) => (
          <div>
            <span style={{ color: "#3e484f" }}>
              {rowData.email ? rowData.email : <>N/A</>}{" "}
            </span>
            <span>{getIcon(rowData.emailValidation)}</span>
          </div>
        ),
      },
      {
        field: "omangNumber",
        numeric: true,
        headerStyle: { minWidth: 190, color: "#3e484f" },
        // cellStyle: {textAlign: "center"}
        title: "Omang/Passport No",
        render: (rowData) => (
          <div style={{ paddingLeft: 45 }}>
            <span style={{ color: "#3e484f" }}>{rowData.omangNumber} </span>
            <span>{getIcon(rowData.omangNumberValidation)}</span>
          </div>
        ),
      },
      {
        field: "contactNumber",
        numeric: true,
        // cellStyle: { textAlign: "right" },
        headerStyle: { minWidth: 128, color: "#3e484f" },
        title: "Mobile No",
        render: (rowData) => (
          <div>
            <span style={{ color: "#3e484f" }}>{rowData.contactNumber} </span>
            <span>{getIcon(rowData.mobileNumberValidation)}</span>
          </div>
        ),
      },
      {
        field: "dateOfBirth",
        type: "date",
        title: "Date of Birth",
        headerStyle: { minWidth: 140, color: "#3e484f" },
        cellStyle: { paddingLeft: 20 },
        render: (rowData) => (
          <div>
            <span style={{ color: "#3e484f" }}>{rowData.dateOfBirth} </span>
            <span>{getIcon(rowData.dateOfBirthValidation)}</span>
          </div>
        ),
      },
      {
        field: "registrationStatus",
        title: "Status",
        headerStyle: { minWidth: 152, color: "#3e484f", textAlign: "center" },
        cellStyle: { textAlign: "center" },
        render: (rowData) => (
          <span
            className={`badge text-capitalize ${
              rowData.registrationStatus === "APPROVED"
                ? "text-white bg-success"
                : rowData.registrationStatus === "REJECTED"
                ? "text-white bg-danger"
                : rowData.registrationStatus === "NEW"
                ? "text-white bg-blue"
                : rowData.registrationStatus === "ON-HOLD"
                ? "text-white bg-warning"
                : ""
            }`}
          >
            {rowData.registrationStatus}
          </span>
        ),
      },
      {
        field: "finalDecision",
        title: "Decision",
        headerStyle: { minWidth: 148, color: "#3e484f", textAlign: "center" },
        render: (rowData) => (
          <div>
            <Tooltip title="Approve" aria-label="approve">
              <Button
                variant="contained"
                color="primary"
                className={`btn-5 jr-btn jr-btn-xs ${
                  rowData.registrationStatus === "APPROVED"
                    ? ""
                    : rowData.registrationStatus === "ON-HOLD" ||
                      rowData.registrationStatus === "NEW"
                    ? "bg-blue"
                    : ""
                } text-white`}
                onClick={() => handleClickAccept(rowData.externalId)}
                disabled={
                  // rowData.tableData.checked ||
                  rowData.registrationStatus !== "NEW" &&
                  rowData.registrationStatus !== "ON-HOLD"
                }
              >
                <i className="zmdi zmdi-check"></i>
              </Button>
            </Tooltip>

            {/* <Tooltip title="Reject" aria-label="reject">
              <Button
                variant="contained"
                color="primary"
                className={`jr-btn jr-btn-xs ${rowData.registrationStatus === "REJECTED"
                    ? ""
                    : rowData.registrationStatus === "ON-HOLD" ||
                      rowData.registrationStatus === "NEW"
                      ? "bg-danger"
                      : ""
                  } text-white`}
                onClick={() => handleClickReject(rowData.externalId)}
                disabled={
                  // rowData.tableData.checked ||
                  rowData.registrationStatus !== "NEW" &&
                  rowData.registrationStatus !== "ON-HOLD"
                }
              >
                <i className="zmdi zmdi-close"></i>
              </Button>
            </Tooltip> */}

            <Tooltip title="On Hold" aria-label="hold">
              <Button
                variant="contained"
                color="primary"
                className={`jr-btn jr-btn-xs 
                ${rowData.registrationStatus === "NEW" ? "bg-warning" : ""} 
                text-white`}
                onClick={() => handleClickHold(rowData.externalId)}
                disabled={
                  // rowData.tableData.checked ||
                  rowData.registrationStatus !== "NEW"
                }
              >
                <i className="zmdi zmdi-pause"></i>
              </Button>
            </Tooltip>
          </div>
        ),
      },
      {
        field: "reason",
        title: "Comments",
        headerStyle: { color: "#3e484f" },
        render: (rowData) => (
          <span>
            {rowData.reason ? (
              <PopOver data={rowData.reason} popOverData={rowData.reason} />
            ) : (
              <> -- </>
            )}
          </span>
        ),
      },
    ],
    options: {
      selection: true,
      selectionProps: (rowData) => ({
        disabled: rowData.registrationStatus !== "NEW",
        color: "primary",
      }),
      paging: true,
      search: false,
    },
  });

  const { columns } = state;

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleClickAccept = (externalId) => {
    setAcceptDialog(true);
    setExternalId(externalId);
  };

  const handleClickReject = (externalId) => {
    setExternalId(externalId);
    setRejectDialog(true);
  };

  const handleClickHold = (externalId) => {
    setExternalId(externalId);
    setHoldDialog(true);
  };
  const handleDownloadPopup = (externalId) => {
    setExternalId(externalId);
    setDownloadDialog(true);
  };

  // Modal pop up functions
  const handleRequestClose = (type) => {
    if (type === "accept") {
      setAcceptDialog(false);
    } else if (type === "reject") {
      setRejectDialog(false);
    } else if (type === "hold") {
      setHoldDialog(false);
    } else {
      setDownloadDialog(false);
    }
  };

  const handleRequestSubmit = (type, remarks = null) => {
    dispatch(showAdminApprovalLoader());
    const data = {};
    if (type === "accept") {
      data["externalId"] = externalId;
      data["registrationStatus"] = "APPROVED";
      data["reason"] = remarks;
      dispatch(adminUserApprovalRequest(data));
      setAcceptDialog(false);
    } else if (type === "reject") {
      data["externalId"] = externalId;
      data["registrationStatus"] = "REJECTED";
      data["reason"] = remarks;
      dispatch(adminUserApprovalRequest(data));
      setRejectDialog(false);
    } else if (type === "hold") {
      data["externalId"] = externalId;
      data["registrationStatus"] = "ON-HOLD";
      data["reason"] = remarks;
      dispatch(adminUserApprovalRequest(data));
      setHoldDialog(false);
      // data["reason"] = remarks;
    } else setDownloadDialog(false);
  };

  useEffect(() => {
    if (comparisionData) {
      toastMsgs.success("Records compared successfully");
    }
  }, [comparisionData]);

  useEffect(() => {
    dispatch(resetComapareData());
  }, []);

  useEffect(() => {
    if (statusCode === 401) {
      toastMsgs.error(alertMessage);
      dispatch(hideRequestRegistrationMessage());
      setTimeout(() => dispatch(userSignOut()), 300);
    }
  }, [statusCode]);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideRequestRegistrationMessage());
      }, 100);
    }
    return () => dispatch(hideRequestRegistrationMessage());
  }, [showMessage, dispatch]);

  useEffect(() => {
    dispatch(requestRegistrationLoader());
    dispatch(
      registrationRequestData({
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
        status: status,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (verifyUserShowMesssage) {
      setTimeout(() => {
        dispatch(hideAdminUserApprovalError());
      }, 100);
    }

    if (
      verifyUserAdminApprovalStatus === "Registration approved successfully"
    ) {
      {
        toastMsgs.success(adminTableMsgs.recordApproved);
      }
      dispatch(hideAdminUserApprovalError());
      dispatch(requestRegistrationLoader());
      dispatch(
        registrationRequestData({
          fromDate: moment(startDate).format("YYYY-MM-DD"),
          toDate: moment(endDate).format("YYYY-MM-DD"),
          status: status,
        })
      );
    }

    if (
      verifyUserAdminApprovalStatus === "Registration rejected successfully"
    ) {
      {
        toastMsgs.success(adminTableMsgs.recordRejected);
      }
      dispatch(hideAdminUserApprovalError());
      dispatch(requestRegistrationLoader());
      dispatch(
        registrationRequestData({
          fromDate: moment(startDate).format("YYYY-MM-DD"),
          toDate: moment(endDate).format("YYYY-MM-DD"),
          status: status,
        })
      );
    }
    if (
      verifyUserAdminApprovalStatus ===
      "Registration request is On-Hold for approval"
    ) {
      {
        toastMsgs.success(adminTableMsgs.recordOnHold);
      }
      dispatch(hideAdminUserApprovalError());
      dispatch(requestRegistrationLoader());
      dispatch(
        registrationRequestData({
          fromDate: moment(startDate).format("YYYY-MM-DD"),
          toDate: moment(endDate).format("YYYY-MM-DD"),
          status: status,
        })
      );
    }
    return () => dispatch(hideAdminUserApprovalError());
  }, [verifyUserShowMesssage, verifyUserAdminApprovalStatus]);

  const handlleStartDatechange = (startDate) => {
    setStartDate(startDate);
  };
  const handlleEndDatechange = (endDate) => {
    setEndDate(endDate);
  };
  const handleDateSubmit = () => {
    if (startDate || endDate) {
      if (startDate === null) {
        setstartDatenull(adminTableMsgs.selectStartDate);
      } else if (endDate === null) {
        setendDatenull(adminTableMsgs.selectEndDate);
      } else if (startDate > endDate || endDate < startDate) {
        setcompareDate(adminTableMsgs.startLessThanEndDate);
      } else if (startDate > moment() || endDate > moment()) {
        return;
      } else {
        setValidateButtonData([]);
        setstartDatenull(null);
        setendDatenull(null);
        setcompareDate(null);
        setbothDatenull(null);
        dispatch(
          registrationRequestData({
            fromDate: moment(startDate).format("YYYY-MM-DD"),
            toDate: moment(endDate).format("YYYY-MM-DD"),
            status: status,
          })
        );
      }
    } else {
      setbothDatenull(adminTableMsgs.selectStartAndEndDate);
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }));
  return (
    <ErrorBoundary>
      <AcceptPopUp
        open={openAcceptDialog}
        handleRequestClose={handleRequestClose}
        handleRequestSubmit={handleRequestSubmit}
      />

      <RejectPopUp
        open={openRejectDialog}
        handleRequestClose={handleRequestClose}
        handleRequestSubmit={handleRequestSubmit}
      />

      <AdminDownloadPopUp
        open={openDownloadDialog}
        handleRequestClose={handleRequestClose}
        handleRequestSubmit={handleRequestSubmit}
        setComments={setComments}
        startDate={startDate}
        endDate={endDate}
        status={status}
        isAdmin={true}
      />

      <HoldPopUp
        open={openHoldDialog}
        handleRequestClose={handleRequestClose}
        handleRequestSubmit={handleRequestSubmit}
      />

      <CardBox
        styleName="col-12"
        cardStyle="p-0"
        heading="User Registration Requests"
        headerOutside
      >
        <div className="row ">
          <div className="col">
            <div className="row custom-inputs">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid>
                  <div>
                    <KeyboardDatePicker
                      autoOk="true"
                      variant="inline"
                      required
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="From Date"
                      value={startDate}
                      maxDate={new Date()}
                      // onChange={(date) => setStartDate(date)}
                      onChange={(date) => handlleStartDatechange(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />{" "}
                    <KeyboardDatePicker
                      autoOk="true"
                      required
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="To Date"
                      value={endDate}
                      onChange={(date) => handlleEndDatechange(date)}
                      maxDate={new Date()}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    <div
                      className="error"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      <span>
                        {startDatenull ||
                          endDatenull ||
                          compareDate ||
                          bothDatenull}
                      </span>
                    </div>
                  </div>
                </Grid>
              </MuiPickersUtilsProvider>
              <div className="select-input-custom">
                <InputLabel
                  htmlFor="name-native-disabled"
                  className="MuiInputLabel-shrink"
                >
                  Status
                </InputLabel>
                <Select
                  native
                  value={status}
                  onChange={handleStatus}
                  input={<Input id="age-native-helper" />}
                >
                  <option value={"ALL"}>All</option>
                  <option value={"NEW"}>New</option>
                  <option value={"ON-HOLD"}>On-Hold</option>
                  <option value={"APPROVED"}>Approved</option>
                  {/* <option value={"REJECTED"}>Rejected</option> */}
                </Select>
              </div>

              <div
                style={{ position: "relative", top: "25px", left: "48px" }}
                className="custom-status-btn"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDateSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
            <div className="row" style={{ marginTop: 10 }}>
              <div className="col custom-legends">
                <div
                  className="legend-layer"
                  style={{ backgroundColor: "#FFF" }}
                >
                  <span>
                    <i
                      className="zmdi zmdi-check-circle"
                      style={{ color: "green" }}
                    ></i>
                    Matched
                  </span>
                  <span>
                    <i
                      className="zmdi zmdi-close-circle"
                      style={{ color: "red" }}
                    ></i>
                    Not Matched
                  </span>
                  <span>
                    <i
                      className="zmdi zmdi-block"
                      style={{ color: "gray", fontWeight: "bolder" }}
                    ></i>
                    Not available in the Thito{" "}
                  </span>
                  <span>
                    <i className="zmdi zmdi-block" style={{ color: "red" }}></i>
                    Not available in Customer Portal
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            title={""}
            columns={columns}
            data={usersData}
            isLoading={
              loader || adminCompareloader
              // showAdminApprovalLoader ||
            }
            components={{
              OverlayLoading: () => (
                <div className="loading-view">
                  <CircularProgress />
                </div>
              ),
            }}
            actions={[
              {
                icon: () => (
                  <Button
                    className={
                      usersData.length === 0
                        ? "ml-3 mr-3"
                        : "validateButton ml-3 mr-3"
                    }
                    variant="contained"
                  >
                    Reports
                    <SaveAltIcon className="ml-1" />{" "}
                  </Button>
                ),
                // tooltip: usersData.length === 0 ? "No records to export" : "Export Records",
                isFreeAction: true,
                disabled: usersData.length === 0 ? true : false,
                onClick: handleDownloadPopup,
              },
              {
                tooltip:
                  validateButtonData.length === 0
                    ? "Please select records to validate"
                    : "Compare selected records",
                //disabled: validateButtonData.length === 0 ? true : false,
                icon: () => (
                  <Button
                    className={
                      validateButtonData.length === 0 ? "" : "validateButton"
                    }
                    variant="contained"
                  >
                    Validate
                    <CompareArrowsIcon className="ml-1" />{" "}
                  </Button>
                ),
                onClick: (evt, data) => {
                  const tempData = data
                    .reduce((accumulator, currentValue) => {
                      return `${accumulator}:${currentValue.policyNumber}-${currentValue.omangNumber}`;
                    }, "")
                    .substring(1);
                  // dispatch(showAdminCompareLoader());
                  dispatch(requestRegistrationLoader());
                  dispatch(resetComapareData());
                  dispatch(adminCompareRequest(tempData, usersData, data));
                },
              },
            ]}
            options={{
              selection: true,
              emptyRowsWhenPaging: false,
              selectionProps: (rowData) => ({
                disabled:
                  rowData.registrationStatus !== "NEW" &&
                  rowData.registrationStatus !== "ON-HOLD",
                color: "primary",
              }),
            }}
            localization={{
              toolbar: {
                // nRowsSelected: '{0} row(s) selected'
                nRowsSelected: `{0} Record(s)  selected`,
              },
            }}
          />
        </MuiThemeProvider>
      </CardBox>
      {showMessage && <ErrorToaster message={alertMessage} />}
      {showCompareMessage && !comparisionData && (
        <ErrorToaster message={alertCompareMessage} />
      )}
      {verifyUserShowMesssage && (
        <ErrorToaster message={verifyUseralertMessage} />
      )}
      <ToastContainer />
    </ErrorBoundary>
  );
};

export default AdminHomePage;
