/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createPassword,
  onShowCreatePasswordLoader,
  hideCreatePasswordErrorMessage,
} from "actions/CreatePassword";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
// import ResetConfirmDialog from './AlertBox/ResetConfirmDialog.js';
import { CircularProgress } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { ErrorToaster, SuccessToaster, toastMsgs } from "util/ReactToastify";

import "react-toastify/dist/ReactToastify.css";
import { setPassword } from "../constants/Validations";
import SetupPasswordDialog from "./DialogBox/SetupPasswordDialog";
// import 'containers/fontStyle.scss';

const CreatePassword = (props) => {
  const [open, setDialog] = useState(false);
  const { loader, alertMessage, showMessage, createPasswordStatus } =
    useSelector(({ createPasswordSetup }) => createPasswordSetup);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showMessage) {
      dispatch(hideCreatePasswordErrorMessage());
    }

    const phoneNumber = window.location.search
      ? decodeURI(window.location.search).replace("?", "").split("=")[1]
      : null;
    if (phoneNumber) {
      setPhoneNumber(phoneNumber);
    }

    if (createPasswordStatus === 200) {
      {
        toastMsgs.success("Password created successfully");
      }
      setTimeout(() => {
        dispatch(hideCreatePasswordErrorMessage());
        setDialog(true);
      }, 100);
    }

    localStorage.clear();
    return () => dispatch(hideCreatePasswordErrorMessage());
  }, [showMessage, createPasswordStatus, phoneNumber, dispatch]);

  return (
    <div style={{ margin: "0 auto" }} className="custom-forgot-password">
      <SetupPasswordDialog
        open={open}
        handleRequestClose={() => setDialog(true)}
      />
      <div className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div
          className="login-content"
          style={{
            width: "480px",
            margin: "12px 30px",
            boxShadow:
              "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
            padding: "35px 35px 20px",
          }}
        >
          <div className="login-header">
            <Link className="app-logo" to="/" title="Jambo">
              <img
                src={require("assets/images/logo-color.png")}
                alt="jambo"
                title="jambo"
              />
            </Link>
          </div>

          <div className="mb-2">
            <h2>
              <IntlMessages id="appModule.setPassword" />
            </h2>
          </div>

          <div className="login-form">
            <Formik
              initialValues={initialValues}
              validationSchema={SetPasswordSchema}
              onSubmit={(values) => {
                const { newPassword, confirmNewPassword } = values;
                let phoneNumberValue = phoneNumber;
                let data = {
                  mobileNumber: phoneNumberValue,
                  newPassword,
                  confirmNewPassword,
                };
                if (newPassword === confirmNewPassword) {
                  dispatch(onShowCreatePasswordLoader());
                  dispatch(createPassword(data));
                }
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <label> Mobile Number : </label> {phoneNumber}
                    <TextField
                      error={errors.newPassword && touched.newPassword}
                      className="form-textfield form-textfield-label"
                      id="newPassword"
                      type="password"
                      label="Enter your password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.newPassword}
                      onChange={handleChange}
                      placeholder="*******"
                      margin="normal"
                      required
                      onBlur={handleBlur}
                      fullWidth
                      helperText={
                        errors.newPassword &&
                        touched.newPassword &&
                        errors.newPassword
                      }
                    />
                    <TextField
                      error={
                        errors.confirmNewPassword && touched.confirmNewPassword
                      }
                      className="form-textfield form-textfield-label"
                      id="confirmNewPassword"
                      type="password"
                      label="Re-type your password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={values.confirmNewPassword}
                      onChange={handleChange}
                      placeholder="*******"
                      margin="normal"
                      required
                      onBlur={handleBlur}
                      fullWidth
                      helperText={
                        errors.confirmNewPassword &&
                        touched.confirmNewPassword &&
                        errors.confirmNewPassword
                      }
                    />
                    <div className="mt-1 mb-2 d-flex justify-content-center align-items-center create-pwd-btn">
                      <Button type="submit" variant="contained" color="primary">
                        Submit
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && <ErrorToaster message={alertMessage} />}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

const initialValues = {
  newPassword: "",
  confirmNewPassword: "",
};
const SetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /^(?=[A-Z])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      setPassword.passwordMustContain
    )
    .required(setPassword.passwordRequired),
  confirmNewPassword: Yup.string()
    .matches(
      /^(?=[A-Z])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      setPassword.passwordMustContain
    )
    .required(setPassword.passwordRequired)
    .test("", "Passwords do not match", function (value) {
      return this.parent.newPassword === value;
    }),
});

export default CreatePassword;
