/* eslint-disable no-unused-vars */
import React from "react";
import Widget from "components/Widget";
//import { aboutList } from "./data";
//import AboutItem from "./AboutItem";
import Auxiliary from "util/Auxiliary";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

let c = "#00518c";

const PersonalDetails = ({ data, loading }) => {
  const {
    name = null,
    nationality = null,
    birthday = null,
    gender = null,
    idNum = null,
    idType = null,
    maritalStatus = null,
    affluent = null,
  } = data;

  return (
    <Widget styleName="jr-card-full jr-card-tabs-right jr-card-profile">
      <div className="card-header">
        <h4 className="card-title mb-0">Personal Details</h4>
      </div>
      <div className="jr-tabs-classic">
        <div className="jr-tabs-content jr-task-list pt-3">
          <div className="row">
            <div className="col-md-6">
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-account icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">Name</h6>
                    <p className="mb-0 custom-fields"> {name}</p>
                  </div>
                </div>
              </Auxiliary>

              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-map icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">Nationality</h6>
                    <p className="mb-0 custom-fields"> {nationality}</p>
                  </div>
                </div>
              </Auxiliary>
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-cake icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">DOB</h6>
                    <p className="mb-0 custom-fields">
                      {" "}
                      {birthday ? moment(birthday).format("DD/MM/YYYY") : null}
                    </p>
                  </div>
                </div>
              </Auxiliary>
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-pin icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">ID Type</h6>
                    <p className="mb-0 custom-fields"> {idType}</p>
                  </div>
                </div>
              </Auxiliary>
            </div>
            <div className="col-md-6">
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i
                      className={`zmdi zmdi-male-female icon-size text-cyan`}
                    />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">Gender</h6>
                    <p className="mb-0 custom-fields"> {gender}</p>
                  </div>
                </div>
              </Auxiliary>
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-pin icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">ID Number</h6>
                    <p className="mb-0 custom-fields"> {idNum}</p>
                  </div>
                </div>
              </Auxiliary>
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-favorite icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">Marital Status</h6>
                    <p className="mb-0 custom-fields"> {maritalStatus}</p>
                  </div>
                </div>
              </Auxiliary>
              <Auxiliary>
                <div className="media flex-nowrap mt-3 mt-lg-4 mb-2">
                  <div className="mr-3">
                    <i className={`zmdi zmdi-star icon-size text-cyan`} />
                  </div>
                  <div className="media-body">
                    <h6 className="mb-1 text-label-blue">Affluent</h6>
                    <p className="mb-0 custom-fields"> {affluent}</p>
                  </div>
                </div>
              </Auxiliary>
            </div>
          </div>
        </div>

        {loading && (
          <div className="loading-view">
            <CircularProgress />
          </div>
        )}
      </div>
    </Widget>
  );
};

export default PersonalDetails;
