import {
  ADMIN_COMPARE_SUCCESS,
  ADMIN_COMPARE_ERROR_MESSAGE,
  ADMIN_COMPARE_LOADER,
  HIDE_ADMIN_COMPARE_ERROR_MESSAGE,
} from "constants/ActionTypes";

const INIT_STATE = {
  adminCompareloader: false,
  alertCompareMessage: "",
  showCompareMessage: false,
  comparisionData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADMIN_COMPARE_SUCCESS: {
      return {
        ...state,
        adminCompareloader: false,
        comparisionData: action.payload,
      };
    }

    case ADMIN_COMPARE_ERROR_MESSAGE: {
      return {
        ...state,
        alertCompareMessage: action.payload,
        showCompareMessage: true,
        adminCompareloader: false,
      };
    }
    case HIDE_ADMIN_COMPARE_ERROR_MESSAGE: {
      return {
        ...state,
        alertCompareMessage: "",
        showCompareMessage: false,
        adminCompareloader: false,
      };
    }

    case ADMIN_COMPARE_LOADER: {
      return {
        ...state,
        adminCompareloader: true,
      };
    }
    case "resetComapareData": {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    default:
      return state;
  }
};
