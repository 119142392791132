import React from "react";
import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import axios from "axios";

function Index() {
  const { shortLink } = useParams();
  
  const uri = "http://102.37.61.33:8000/api/v1/" + shortLink;
  //const uri = "http://52.168.14.3:8000/api/v1/" + shortLink;
  // const uri ='http://localhost:8000/api/v1/'+shortLink;

  // const uri = `${address}/`+shortLink;
  console.log("The Uri " + uri);

  function GetUrl() {
    const rps = axios.get(uri).then((response) => {
      return response.data;
    });

    console.log(rps);
  }

  GetUrl();

  return <div />;
}

export default Index;
