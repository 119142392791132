import {
  REGISTRATION_OTP,
  REGISTRATION_OTP_LOADER,
  HIDE_REGISTRATION_OTP_ERROR_MESSAGE,
  REGISTRATION_OTP_ERROR_MESSAGE,
  VERIFY_REGISTRATION_OTP_SUCCESS,
} from "constants/ActionTypes";
const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  registrationOtpStatus: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REGISTRATION_OTP:
      return {
        ...state,
        emailOtp: action.payload,
      };

    case REGISTRATION_OTP_LOADER:
      return {
        ...state,
        loader: true,
      };
    case HIDE_REGISTRATION_OTP_ERROR_MESSAGE:
      return {
        ...INIT_STATE,
      };
    case REGISTRATION_OTP_ERROR_MESSAGE:
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    case VERIFY_REGISTRATION_OTP_SUCCESS:
      return {
        ...state,
        loader: false,
        registrationOtpStatus: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
